angular.module('cerberus.core')
    .directive('nimFormTable', function() {
        return {
            restrict: 'AE',
            templateUrl: 'core/forms/nim-form-table.tpl.html',
            controller: function(_, kendo, $scope, $attrs, $element, $compile, $timeout, DesignerUtilityService, FormsService, /*InstancesService,*/ InstancesWindowService, printService) {
                $scope.openSubForm = openSubForm;
                $scope.selectRow = selectRow;
                $scope.rowSelected = rowSelected;
                $scope.selectAllRows = selectAllRows;
                $scope.printLabels = printLabels;
                $scope.printChecks = printChecks;
                $scope.openAdvSelPage = _.partial(InstancesWindowService.openAdvSelPage, $scope, advancedSelectCallback);
                $scope.deleteSelectedRecords = deleteSelectedRecords;

                init();

                $scope.$on('nim-form-table-auto-fill-' + $scope.nimFormObject.config.modelId , function (event, args) {
                    autoFillTable(args.data);
                });

                //Clean up - avoid memory leaks
                $scope.$on('$destroy', function () {
                    $element.off('keyup');
                });

                $scope.$on('nim-remove-form-objects', function () {
                    if ($scope.grid) {
                        $scope.grid.destroy();
                        $scope.grid = null;
                    }

                    if ($scope.pager){
                        $scope.pager.destroy();
                        $scope.pager = null;
                    }
                });

                $scope.$on('nim-sub-form-closed', function (event, args) {
                    if(args.modelId === $scope.nimFormObject.config.modelId) {
                        event.preventDefault();

                        var data = $scope.dataSource.data();

                        if ($scope.isTreeList) {
                            setTreeFields(data, null);
                        }    

                        // This event may fire for the grid on the subform, which is now closed
                        if ($scope.grid && $scope.grid.content) {
                            try {
                                $scope.dataSource.data(data);
                                //$scope.grid.refresh();
                            } catch (e) {
                            }
                        }
                    }
                });

                function init() {
                    $scope.isStatic = $attrs.nimformtablestatic != null && $attrs.nimformtablestatic != 'false';
                    $scope.isTreeList = $scope.nimFormObject.param.isTreeList;
                    $scope.selectedIds = [];
                    $scope.allRowsSelected = false;
                    $scope.editSubData = null;
                    $scope.schemaFields = {};
                    $scope.columns = [];
                    
                    //Get Set Defaults
                    var pageSize = $scope.nimFormObject.tag.attrs.pageSize,
                        pageable = !$scope.isStatic && pageSize;

                    buildColumnsAndSchema(angular.copy($scope.nimFormObject.tag.inner));

                    // For removing HTML tags from strings
                    //function stripHTML(string){
                    //    if(string) {
                    //        // Wraps in div to ensure the string is not treated as a selector
                    //        return angular.element('<div>' + string + '</div>').text();
                    //    }
                    //    return string;
                    //}

                    //If transcluded scope.dataset
                    if ($scope.dataSet) {
                        var d = $scope.dataSet[$scope.nimFormObject.config.modelId];
                        
                        _.forEach(d, function (dataItem) {
                            FormsService.fixDataseriesCols(dataItem, $scope.schemaFields);
                        });

                        //Create DataSource
                        var dsOptions = {
                            data: d,
                            serverFiltering: false,
                            filter: {
                                field: '__nimDelete',
                                operator: 'neq',
                                value: true
                            },
                            pageSize: pageable ? pageSize : null,
                            schema: {
                                model: {
                                    fields: $scope.schemaFields
                                }
                            },
                            aggregate: getAggregate($scope.columns),
                            change: function (e) {
                                $timeout(function () {
                                    var aggregateConfig = e.sender.aggregate();
                                    
                                    if (!_.isEmpty(aggregateConfig)) {
                                        var dataSet = $scope.dataSet,
                                            aggregateValues = e.sender.aggregates();
                                        
                                        _.forEach(aggregateConfig, function (aggregate) {
                                            if (aggregate.modelId && aggregateValues[aggregate.field]) {
                                                dataSet[aggregate.modelId] = _.get(aggregateValues, [aggregate.field, aggregate.aggregate]);
                                            }
                                        });
                                    }
                                });
                            }
                        };

                        if ($scope.isTreeList) {
                            dsOptions = angular.extend(dsOptions, {
                                schema: {
                                    model: {
                                        id: 'id',
                                        fields: {
                                            id: {
                                                type: 'number',
                                                from: '__instanceId'
                                            },
                                            parentId: {
                                                nullable: true,
                                                type: 'number'
                                            },
                                            expanded: {
                                                defaultValue: false,
                                                type: 'boolean'
                                            },
                                            hasChildren: {
                                                defaultValue: false,
                                                type: 'boolean'
                                            }
                                        }
                                    }
                                }
                            });

                            if (d) {
                                setTreeFields(d, null, true);
                            }

                            $scope.dataSource = new kendo.data.TreeListDataSource(dsOptions);

                            $scope.dataSource.data(d);
                            $scope.columns[0].width = 78;
                        }
                        else {
                            $scope.dataSource = new kendo.data.DataSource(dsOptions);
                        }
                    }

                    /**
                     * Kendo Grid Settings
                     */
                    $scope.options = {
                        columnMenu: false,
                        columns: $scope.columns,
                        dataSource: $scope.dataSource,
                        editable: false,
                        filterable: false,
                        mobile: false,
                        navigatable: true,
                        pageable: pageable,
                        reorderable: false,
                        resizable: false,
                        scrollable: false,
                        selectable: true,
                        sortable: false,
                        toolbar: false,
                        dataBound: function () {
                            $timeout(function () {
                                if ($scope.gridsterItem && $scope.gridsterItem.$element) {
                                    var formObjectElement = $element.closest('.form-group');
                                    var oldRows = $scope.gridsterItem.getSizeY();
                                    var rows = Math.ceil(formObjectElement.height() / 8);
                                
                                    if (rows != oldRows) {
                                        if ($scope.itemOpts) {
                                            $scope.itemOpts.sizeY = rows;
                                        }
                                        else {
                                            $scope.gridsterItem.setSizeY(rows);
                                        }
                                    }
                                }

                                checkRowSelection();
                            }, 100);
                        },
                        save: function (e) {
                            var modelId = $scope.nimFormObject.config.modelId;
                            $scope.dataSet[modelId] = $scope.dataSet[modelId] || [];
                            var item = e.sender.dataSource.getByUid(e.model.uid);
                            var index = e.sender.dataSource.indexOf(item);
                            $scope.dataSet[modelId].pop();
                            if (index >= 0 && $scope.dataSet[modelId][index]) {
                                for (var v in e.values) {
                                    $scope.dataSet[modelId][index][v] = e.values[v];
                                }
                            }
                            else {
                                var newRow = {};
                                for (var val in e.values) {
                                    newRow[val] = e.values[val];
                                }
                                $scope.dataSet[modelId].push(newRow);
                            }
                            $scope.grid.refresh();

                            $timeout(function () {
                                $scope.dataSet[$scope.nimFormObject.config.modelId].push($scope.grid.dataSource.aggregates());
                            });
                        },
                        //expand: function(e){
                        //    if(!e.model.__loadedChildRows) {
                        //        e.preventDefault();
                        //
                        //        var formInfo = {
                        //            formId: $scope.isStatic ? $scope.dataSet.__formId : $scope.dataSet[$scope.formOriginId].__formId,
                        //            isPk: true,
                        //            displayOrder: '1'
                        //        };
                        //
                        //        if(e.model.id < 10000000){
                        //            InstancesService.getFormData(e.model.id, [formInfo]).then(function (formData) {
                        //                addFormData(e.model, formData[0].defaultDataSet[$scope.nimFormObject.config.modelId]);
                        //            });
                        //        }
                        //        else {
                        //            addFormData(e.model, angular.copy(e.model.__nimData[$scope.formOriginId][$scope.nimFormObject.config.modelId]));
                        //        }
                        //    }
                        //},
                        change: function (e) {
                            // Edit record on selecting a row only if editing is not disabled
                            if ($scope.nimFormObject.param.disabled || !$scope.nimFormObject.param.disableEdit) {
                                var selected = e.sender.select();
                                if (selected.length > 0) {
                                    var cell = selected.find('#aria_active_cell');
                                    if (!cell.data('selection-disabled')) { // Checks if subform should open when clicking this cell
                                        var data = e.sender.dataItem(selected[0]),
                                            id = data.__instanceId;

                                        if ($scope.isStatic || $scope.nimFormObject.param.disabled) {
                                            InstancesWindowService.openWindow({
                                                action: 'readonly',
                                                instanceId: id
                                            });
                                        }
                                        else {
                                            $scope.editSubData = e.sender.dataSource.getByUid(data.uid);
                                            openSubForm('read', id);
                                        }
                                    }
                                }
                            }
                        }
                    };

                    $scope.pagerOptions = {
                        dataSource: $scope.dataSource
                    };
                }

                function deleteRecord(e){
                    e.preventDefault();
                    var data = getRowData(e);

                    $timeout(function () {
                        if (data.__instanceId) {
                            var nimDataRecord = _.find($scope.dataSet[$scope.nimFormObject.config.modelId], '__instanceId', data.__instanceId);
                            nimDataRecord.__nimDelete = true;
                            $scope.grid.dataSource.data($scope.dataSet[$scope.nimFormObject.config.modelId]);
                        }
                        else {
                            var dataItem = $scope.grid.dataSource.getByUid(data.uid);
                            if (dataItem) {
                                $scope.grid.dataSource.remove(dataItem);
                            }
                            $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.grid.dataSource.data().toJSON();
                        }

                        var index = $scope.selectedIds.indexOf(data.__rowId);
                        if (index >= 0) {
                            _.pullAt($scope.selectedIds, index);
                        }
                    });
                }

                function deleteSelectedRecords() {
                    if ($scope.selectedIds.length > 0) {
                        var data = $scope.dataSet[$scope.nimFormObject.config.modelId];
                        
                        _.forEach($scope.selectedIds, function (id) {
                            var index = _.findIndex(data, '__rowId', id);
                            if (index >= 0) {
                                var dataItem = data[index];

                                if (dataItem.__instanceId) {
                                    dataItem.__nimDelete = true;
                                }
                                else {
                                    _.pullAt(data, index);
                                }
                            }
                        });

                        $scope.selectedIds = [];
                        $scope.dataSource.data(data);
                    }
                }

                function duplicateRecord(e){
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    var data = getRowData(e);

                    var newData = angular.copy(data.toJSON());
                    delete newData.__instanceId;

                    newData.__optionId = $scope.nimFormObject.param.optionId;
                    newData.__column = $scope.nimFormObject.param.column;
                    newData.__modelId = $scope.nimFormObject.param.fk;
                    newData.__widgetId = $scope.nimFormObject.param.widgetId;

                    $scope.grid.dataSource.add(newData);
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.grid.dataSource.data().toJSON();
                }

                //function editRecord(e){
                //    e.preventDefault();
                //    var data = getRowData(e);
                //    $scope.editSubData = $scope.grid.dataSource.getByUid(data.uid);
                //    openSubForm('read', data.id || data.__instanceId);
                //}

                function getRowData(e){
                    var row = $(e.target).closest("tr");
                    return $scope.grid.dataItem(row);
                }

                function setTreeFields(data, id, isTop){
                    if($scope.isTreeList){
                        var widgetId = $scope.$eval('vm.widgetId');
                        if($scope.nimFormObject.param.widgetId == widgetId) { // For recursive table
                            for (var i = 0; i < data.length; i++) {
                                var item = data[i],
                                    tableData = item.__nimData[$scope.nimFormObject.config.modelId];

                                if (isTop) {
                                    item.parentId = null;
                                }
                                else if (!item.parentId) {
                                    item.parentId = id;
                                }
                                item.hasChildren = !!(tableData && tableData.length > 0);
                                item.expanded = !!(item.expanded && item.hasChildren);
                                if (!item.hasOwnProperty('id')) {
                                    if (id) {
                                        item.id = id * 10 + (i + 1);
                                    }
                                    else {
                                        item.id = (i + 1);
                                    }
                                    if (item.id < 10000000) {
                                        item.id += 10000000;
                                    }
                                }
                            }
                        }
                        else {
                            // For lookup
                            var param = $scope.nimFormObject.param;
                            for(var j = 0; j < data.length; j++){
                                var dataItem = data[j];

                                if(!isTop && dataItem.__nimData[param.lookup]){
                                    dataItem.parentId = dataItem.__nimData[param.lookup].id;
                                }
                                else {
                                    dataItem.parentId = null;
                                }
                                dataItem.__loadedChildRows = true;
                            }
                        }
                    }
                }

                //function addFormData(parentRow, formData){
                //    if(!parentRow.__loadedChildRows){
                //        parentRow.__loadedChildRows = true;
                //        parentRow.expanded = !parentRow.expanded;
                //
                //        setTreeFields(formData, parentRow.id);
                //        var data = $scope.dataSource.data().toJSON();
                //        $scope.dataSource.data(data.concat(formData));
                //    }
                //}

                function advancedSelectCallback(data) {
                    if (data) {
                        // autoFillTable(data);
                        _.forEach(data, function (dataItem) {
                            dataItem.__optionId = $scope.nimFormObject.param.optionId;
                            dataItem.__column = $scope.nimFormObject.param.column;
                            dataItem.__modelId = $scope.nimFormObject.param.fk;
                            dataItem.__widgetId = $scope.nimFormObject.param.widgetId;
                            if (dataItem.__nimData) {
                                FormsService.fixDataseriesCols(dataItem, $scope.schemaFields);
                            }
                            else {
                                createNimData(dataItem, $scope.schemaFields);
                            }

                            var reject = false,
                                gridData = $scope.grid.dataSource.data();

                            _.forEach($scope.columns, function (c) {
                                if (c.unique) {
                                    var value = dataItem[c.field],
                                        modelId = c.field;
                                    
                                    if (c.isLookUp || c.isCustomLookup) {
                                        var idField = c.field.replace(/_display$/, '_id');
                                        value = dataItem[idField];
                                        modelId = c.field.replace(/^__/, '').replace(/_display$/, '.id');
                                    }
                                    
                                    if (_.find(gridData, '__nimData.' + modelId, value)) {
                                        reject = true;
                                        return false;
                                    }
                                }
                            });

                            if (!reject) {
                                $scope.grid.dataSource.add(dataItem);
                            }
                        });

                        $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.grid.dataSource.data().toJSON();
                    }
                }

                function getAggregate(columns, aggregate){
                    aggregate = aggregate || [];

                    var params = $scope.nimFormObject.param;

                    _.forEach(columns, function (column) {
                        var field = column.field;

                        if(_.has(params, 'aggregates', field)) {
                            if (!params.hideAggregates && !_.isEmpty(params.aggregates[field])) {
                                column.footerTemplate = _.partial(aggregateTemplate, column, params.aggregates);
                            }
                            
                            _.forEach(params.aggregates[field], function (aggrObj, a) {
                                aggregate.push({
                                    field: field,
                                    modelId: aggrObj.field,
                                    aggregate: a
                                });
                            });
                        }

                        if(column.columns){
                            getAggregate(column.columns, aggregate);
                        }
                    });
                    
                    return aggregate;
                }

                function aggregateTemplate(column, aggregates, data) {
                    var displayValues = [],
                        field = column.field;

                    _.forEach(aggregates[field], function (aggregateObj, aggregateFunc) {
                        var value = _.get(data, [field, aggregateFunc]) || 0;

                        if (_.isNaN(value)) {
                            value = 0;
                        }
                        if (column.format) {
                            value = kendo.format(column.format, value);
                        }
                        if (aggregateObj.title) {
                            value = aggregateObj.title + ': ' + value;
                        }

                        displayValues.push(value);
                    });

                    return displayValues.join('<br/>');
                }

                function buildColumnsAndSchema(configColumns) {
                    var checkboxHeaderTemplate = '',
                        checkboxTemplate = '',
                        indexColumnWidth = 0,
                        isStatic = $scope.isStatic || $scope.nimFormObject.param.disabled,
                        printingEnabled = $scope.nimFormObject.param.printLabels || $scope.nimFormObject.param.printChecks,
                        rowNumbersEnabled = !$scope.nimFormObject.param.disableRowNumbers;

                    if (isStatic && printingEnabled) {
                        checkboxHeaderTemplate = '<input type="checkbox" ng-checked="allRowsSelected" ng-click="selectAllRows(!allRowsSelected)" style="width:13px;height:13px" />';
                        checkboxTemplate = '<input type="checkbox" ng-checked="rowSelected(#: __instanceId #)" ng-model="selected" ng-click="selectRow(#: __instanceId #)" style="width:13px;height:13px" />';
                        indexColumnWidth += 30;
                    }
                    else if (!isStatic && $scope.nimFormObject.param.batchDelete) {
                        checkboxHeaderTemplate = '<input type="checkbox" ng-checked="allRowsSelected" ng-click="selectAllRows(!allRowsSelected)" style="width:13px;height:13px" />';
                        checkboxTemplate = '<input type="checkbox" ng-checked="rowSelected(\'#: __rowId #\')" ng-model="selected" ng-click="selectRow(\'#: __rowId #\')" style="width:13px;height:13px" />';
                        indexColumnWidth += 30;
                    }

                    if (rowNumbersEnabled) {
                        indexColumnWidth += 30;
                    }

                    if (rowNumbersEnabled || checkboxTemplate) {
                        $scope.columns.push({
                            title: '',
                            headerTemplate: checkboxHeaderTemplate,
                            template: function (dataItem) {
                                var tempItems = [];
                                if (checkboxTemplate) {
                                    var checkbox = kendo.template(checkboxTemplate)(dataItem);
                                    tempItems.push(checkbox);
                                }

                                if (rowNumbersEnabled) {
                                    var index = recordIndex(dataItem);
                                    tempItems.push(index);
                                }

                                return tempItems.join(' ');
                            },
                            width: indexColumnWidth
                        });
                    }    

                    _.forEach(configColumns, function (col) {
                        col.encoded = false;
                        if (col.format) {
                            var format = col.format.replace('{0:', '').replace('}', '');
                            col.template = dataTemplate(col.field, format);
                        }
                        else if (col.type === 'location') {
                            col.template = '{{dataItem.' + col.field + '.properties.formatted_address}}';
                        }
                        else if (col.type === 'dataseries') {
                            col.template = _.partial(dataSeriesColTemplate, col);
                        }

                        if (col.type) {
                            $scope.schemaFields[col.field] = col;
                        }

                        $scope.columns.push(col);
                    });

                    if (!$scope.isStatic) {
                        var commandButtons = [];

                        //Add Duplicate Button to Grid
                        if (!$scope.nimFormObject.param.disabled && !_.get($scope.nimFormObject.param, 'disableDuplicate', true)) {
                            commandButtons.push({
                                name: 'duplicateRecord',
                                text: '<i class="fa fa-files-o"></i>',
                                className: 'btn btn-sm btn-default',
                                click: duplicateRecord
                            });
                        }

                        //Add Delete Button to Grid
                        if (!$scope.nimFormObject.param.disabled && !$scope.nimFormObject.param.disableDelete) {
                            commandButtons.push({
                                name: 'deleteRecord',
                                text: '<i class="fa fa-trash"></i>',
                                className: 'btn btn-sm btn-danger',
                                click: deleteRecord
                            });
                        }

                        if (commandButtons.length > 0) {
                            $scope.columns.push({
                                attributes: { 'data-selection-disabled': true }, // Makes this cell not open subform
                                command: commandButtons,
                                title: ' ',
                                width: (57 * commandButtons.length) + 'px'
                            });
                        }
                    }
                }

                function openSubForm(action, id){
                    var readingNewRecord = false;
                    action = action || 'create';
                    id = id || '';
                    // if(id > 9999999){ // Don't accept temporary ids
                    //     id = '';
                    // }
                    if(!id){ // For reading newly created subform data
                        if(action === 'read'){
                            // Record was added to table and should be edited, not created,
                            // but record has not been saved as an instance, yet.
                            readingNewRecord = true;
                        }
                        action = 'create';
                    }
                    var windowId = 'sub_form_' + DesignerUtilityService.generateGuid();
                    var html = '<div kendo-window="' + windowId + '" k-options="subWindowOptions">' +
                        '<div nim-instance class="nim-instance container-fluid" nim-action="' + action + '" nim-id="' + id + '" nim-widget-id="' + $scope.nimFormObject.param.widgetId + '" nim-window-id="' + windowId + '" nim-reading-new="' + readingNewRecord + '" nim-validate-on-open="' + $scope.validateOnOpen + '"></div>' +
                        '</div>';

                    // Populate Window Scope
                    var windowScope = $scope.$new();
                    windowScope.defaultData = [];
                    windowScope.windowId = windowId;
                    windowScope.subWindowOptions = InstancesWindowService.buildWindowOptions(windowScope, null, function () {
                        $scope.editSubData = null;
                        $scope.dataSource.data($scope.dataSource.data()); // Forces aggregates to update
                    });
                    windowScope.subWindowOptions.title = $scope.nimFormObject.label.text;
                    windowScope.subWindowOptions.actions = ['Close'];
                    windowScope.subWindowOptions.modal = true;
                    windowScope.subWindowOptions.autoFocus = true;

                    if(action === 'create' && !_.isEmpty($scope.nimFormObject.param.otherData)){
                        windowScope.defaultData = [];
                        
                        _.forEach($scope.nimFormObject.param.otherData, function (dataMap) {
                            var value = $scope.dataSet[dataMap.sourceModelId];
                            if(dataMap.type === 'value'){
                                value = dataMap.sourceValue;
                            }

                            var data = {
                                value: value
                            };

                            if ((dataMap.targetField.isLookup || dataMap.targetField.isCustomLookup)) {
                                if (_.isEmpty(value)) {
                                    return;
                                }
                                
                                data = {
                                    lookupDisplay: value.display,
                                    value: value.id
                                };
                            }

                            windowScope.defaultData.push({
                                data: data,
                                field: dataMap.targetField
                            });
                        });
                    }

                    // Open Window
                    InstancesWindowService.windowArea.append(html);
                    $compile(InstancesWindowService.windowArea.find('div[kendo-window="' + windowId + '"]'))(windowScope);
                }

                function autoFillTable(data) {
                    var newData = [],
                        oldData = $scope.grid.dataSource.data().toJSON();

                    _.remove(oldData, function (oldDataItem) {
                        return !oldDataItem.__instanceId;
                    });  

                    _.forEach(oldData, function (oldDataItem) {
                        oldDataItem.__nimDelete = true;
                    });  

                    if (data) {
                        _.forEach(data, function (dataItem) {
                            dataItem.__optionId = $scope.nimFormObject.param.optionId;
                            dataItem.__column = $scope.nimFormObject.param.column;
                            dataItem.__modelId = $scope.nimFormObject.param.fk;
                            dataItem.__widgetId = $scope.nimFormObject.param.widgetId;

                            if(dataItem.__nimData) {
                                FormsService.fixDataseriesCols(dataItem, $scope.schemaFields);
                            }
                            else {
                                createNimData(dataItem, $scope.schemaFields);
                            }
                            
                            newData.push(dataItem);
                        });
                    }

                    $scope.grid.dataSource.data(oldData.concat(newData));
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.grid.dataSource.data().toJSON();
                }

                function createNimData(dataItem, schema){
                    _.defaults(dataItem, { __nimData: {} });
                    _.forEach(schema, function(schemaField){
                        var modelId = schemaField.field;
                        if(schemaField.isLookUp || schemaField.isCustomLookup){
                            var fieldName = modelId.replace(/^__/, '').replace(/_display$/, '');
                            _.set(dataItem.__nimData, fieldName, { display: dataItem[modelId] });

                            if(dataItem['__' + fieldName + '_id']){
                                _.set(dataItem.__nimData, fieldName + '.id', dataItem['__' + fieldName + '_id']);
                            }
                        }
                        else {
                            _.set(dataItem.__nimData, modelId, dataItem[modelId]);
                        }
                    });
                }
                
                function recordIndex(dataItem) {
                    var data = _.reject($scope.dataSource.data(), '__nimDelete');
                    return data.indexOf(dataItem) + 1;
                }

                // Builds template function
                function dataTemplate(field, format){
                    return function(dataItem){
                        if (format === 'boolCheck') {
                            return dataItem[field] == 'true' || dataItem[field] === true ? '<i class="fa fa-check"></i>' : '';
                        }
                        // Checks if format uses date-specific characters
                        else if(format.search(/[DdFfGgHhMmSsTtuYy]/) >= 0){
                            var dateValue = dataItem[field];
                            if(dateValue){
                                if(_.isString(dateValue)){
                                    dateValue = kendo.parseDate(dateValue, ['MMMM, dd yyyy HH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                                }
                                return kendo.toString(dateValue, format);
                            }
                        }
                        else if(dataItem[field] !== null && dataItem[field] !== undefined){
                            var value = parseFloat(dataItem[field]);
                            if(isNaN(dataItem[field])){
                                return '';
                            }
                            else if(isNaN(value)) {
                                return dataItem[field];
                            }
                            else {
                                return kendo.toString(value, format);
                            }
                        }
                        return '';
                    };
                }

                function dataSeriesColTemplate(column, dataItem) {
                    var dataseries = dataItem[column.field];

                    if (!dataseries || !column.nim_dataseries_display) {
                        return '';
                    }

                    var displayValues = _.map(_.reject(dataseries, '__nimDelete'), function (item) {
                        if (_.has(item, column.nim_dataseries_display)) {
                            var value = item[column.nim_dataseries_display];

                            if (_.has(value, 'display')) {
                                value = value.display;
                            }

                            return value;
                        }
                        else if (_.has(item, 'display')) {
                            return item.display;
                        }

                        return '';
                    });

                    return displayValues.join(', ');
                }

                // Row selection
                function selectRow(id) { 
                    var index = selectedRowIndex(id);
                    if (index < 0) {
                        $scope.selectedIds.push(id);
                    }
                    else {
                        _.pullAt($scope.selectedIds, index);
                    }
                    
                    checkRowSelection();
                }

                function rowSelected(id) {
                    return selectedRowIndex(id) >= 0;
                }

                function selectAllRows(selected) {
                    var rows = $scope.dataSource.data();
                    var isStatic = $scope.isStatic || $scope.nimFormObject.param.disabled;
                    var idField = isStatic ? '__instanceId' : '__rowId';

                    _.forEach(rows, function(row) {
                        var id = row[idField],
                            index = selectedRowIndex(id);
                        
                        if (selected && index < 0) {
                            $scope.selectedIds.push(id);
                        }
                        else if (!selected && index >= 0){
                            _.pullAt($scope.selectedIds, index);
                        }
                    });

                    checkRowSelection();
                 }

                function checkRowSelection(){
                    var rows = $scope.dataSource.data();
                    var isStatic = $scope.isStatic || $scope.nimFormObject.param.disabled;
                    var idField = isStatic ? '__instanceId' : '__rowId';
                    var rowIds = _.pluck(rows, idField);

                    $scope.allRowsSelected = _.every(rowIds, rowSelected);
                }

                function selectedRowIndex(id){
                    return $scope.selectedIds.indexOf(id);
                }

                // Printing
                function printLabels() {
                    var widgetId = $scope.nimFormObject.param.widgetId,
                        instanceIds = angular.copy($scope.selectedIds);
                    
                    if (widgetId && !_.isEmpty(instanceIds)) {
                        var displayValues = {},
                            enabledPrintTypes = {
                                label: true
                            },
                            defaultConfig = {
                                type: 'label'
                            },
                            defaultFilename = kendo.format('{0} - {1:u}', $scope.nimFormObject.label.text, new Date());
                        
                        _.forEach(instanceIds, function(id) {
                            var data = _.reject($scope.dataSource.data(), '__nimDelete');
                            var index = _.findIndex(_.reject(data, '__nimDelete'), '__instanceId', id) + 1;

                            displayValues[id] = index;
                        });

                        printService.configureAndPrint(widgetId, instanceIds, displayValues, enabledPrintTypes, defaultConfig, defaultFilename);
                    }
                }

                function printChecks() {
                    var widgetId = $scope.nimFormObject.param.widgetId,
                        instanceIds = angular.copy($scope.selectedIds);
                    
                    if (widgetId && !_.isEmpty(instanceIds)) {
                        var displayValues = {},
                            enabledPrintTypes = {
                                check: true
                            },
                            defaultConfig = {
                                type: 'check'
                            },
                            defaultFilename = kendo.format('{0} - {1:u}', $scope.nimFormObject.label.text, new Date());
                        
                        _.forEach(instanceIds, function(id) {
                            var data = _.reject($scope.dataSource.data(), '__nimDelete');
                            var index = _.findIndex(_.reject(data, '__nimDelete'), '__instanceId', id) + 1;

                            displayValues[id] = index;
                        });

                        printService.configureAndPrint(widgetId, instanceIds, displayValues, enabledPrintTypes, defaultConfig, defaultFilename);
                    }
                }
            }
        };
    })
;