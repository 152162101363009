angular.module('cerberus.admin')
    .controller('ViewsDesignerAddWidgetModalCtrl', function ViewsDesignerAddWidgetModalCtrl($scope, $uibModalInstance, WidgetsService) {
        var vm = this;

        vm.widgetsArray = [];
        vm.data = null;

        // Modal Actions
        vm.ok = function (){
            $uibModalInstance.close(vm.data);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        _init();

        function _init(){
            WidgetsService.getWidgets().then(function(widgetsArray) {
                vm.widgetsArray = widgetsArray;
            });
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;