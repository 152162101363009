angular.module('cerberus.util')
    .controller('nimCheckBuilderCtrl', function(_, kendo, $q, $scope, $timeout, FormsService, InstancesService){
        var vm = this;

        vm.checkForms = {};
        vm.dataSet = {};
        vm.filters = {};
        vm.doneLoading = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.autoFillData = {};
        vm.staticGridsterOptions = {
            pushing: true
        };

        // Each page is an array of instanceIds with a length matching the specified checksPerPage
        vm.pages = _.chunk($scope.instanceIds, $scope.checksPerPage);

        vm.sectionFilter = FormsService.sectionFilter;

        init();

        function init(){

            if ($scope.autoFillData) {
                vm.autoFillData = $scope.autoFillData;
            }

            if($scope.carbonCopy){
                var pages = [];

                // If carbon copy is desired, double up each page
                _.forEach(vm.pages, function(page){
                    pages.push(page);
                    pages.push(page);
                });

                vm.pages = pages;
            }

            var updateArray = [],
                promises = [];

            _.forEach($scope.instanceIds, function(id){
                vm.doneLoading[id] = false;
                vm.dataSet[id] = {};
                vm.filters[id] = {};
                vm.sectionsShown[id] = {};
                vm.fieldsShown[id] = {};

                InstancesService.getInstance(id).then(function(data){
                    vm.doneLoading[id] = true;
                    
                    var stateId = _.findKey(data.instanceWorkflow, $scope.formOriginId),
                        workflowState = data.instanceWorkflow[stateId],
                        formId = workflowState[$scope.formOriginId],
                        updateData = {};

                    updateData[$scope.pageCounter] = $scope.checkNumbers[id];

                    updateArray.push({
                        id: id,
                        currentStateId: stateId,
                        data: updateData,
                        ignoreSections: []
                    });

                    if($scope.formData[id]){
                        var form = $scope.formData[id];

                        vm.checkForms[id] = form;
                        form.defaultDataSet[$scope.pageCounter] = $scope.checkNumbers[id];
                        _.assign(vm.dataSet[id], form.defaultDataSet);
                        vm.filters[id] = {};
                        checkSectionConditions(id, form.form.sections);
                        checkFieldConditions(id, form.form.objects);
                    }
                    else {
                        var displayOrder = data.workflowObjects[stateId].display.order,
                            trimmedFormId = parseInt(_.last(formId.split('-'))),
                            checkForm = _.find(data.workflowObjects[stateId].model.forms, 'formId', trimmedFormId),
                            forms = [];
                        
                        _.forEach(data.workflowObjects, function (wfo) {
                            if (wfo.display.order <= displayOrder) {
                                forms = forms.concat(wfo.model.forms);
                            }
                        });

                        _.remove(forms, 'formId', trimmedFormId);

                        var formDataPromise = InstancesService.getFormData(id, [checkForm]),
                            recordDataPromise = InstancesService.getFormData(id, forms, null, true);
                        
                        promises.push(formDataPromise);
                        promises.push(recordDataPromise);

                        formDataPromise.then(function(result){
                            var form = result.formData[0];

                            vm.checkForms[id] = form;
                            form.defaultDataSet[$scope.pageCounter] = $scope.checkNumbers[id];

                            recordDataPromise.then(function (recordResult) {
                                _.defaults(form.defaultDataSet, recordResult.recordData);
                                _.assign(vm.dataSet[id], form.defaultDataSet);
                                vm.filters[id] = {};
                                checkSectionConditions(id, form.form.sections);
                                checkFieldConditions(id, form.form.objects);
                            });
                        });
                    }

                    if(_.every(vm.doneLoading)){
                        promises.push(
                            InstancesService.batchUpdate(updateArray)
                        );

                        // When data has been updated and all forms have loaded,
                        // trigger the print function
                        $q.all(promises).then(function(){
                            $timeout(function(){
                                $scope.done();
                            }, 400);
                        });
                    }
                });
            });
        }

        function checkSectionConditions(id, sections){
            _.forEach(sections, function(s){
                if(!_.isEmpty(s.condition)) {
                    vm.sectionsShown[id][s.name] = FormsService.sectionCondition(s.condition, vm.dataSet[id], s.logic, 'read');
                }
                else {
                    vm.sectionsShown[id][s.name] = true;
                }
            });
        }

        function checkFieldConditions(id, formObjects){
            _.forEach(formObjects, function (formObj) {
                var modelId = formObj.model.config.modelId,
                    section = formObj.model.display.section,
                    params = formObj.model.param,
                    fieldValue = vm.dataSet[modelId];
                    
                if (formObj.model.config.type == 'datetime' && fieldValue && _.isString(fieldValue)) {
                    vm.dataSet[modelId] = kendo.parseDate(fieldValue, ['yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                }

                var sectionShown = !section || section == '__nimHiddenSection' || vm.sectionsShown[id][section];
                if (sectionShown && !_.isEmpty(params.condition) && (params.conditionalAction === 'show' || params.conditionalAction === 'hide')) {
                    var result = FormsService.sectionCondition(params.condition, vm.dataSet[id], params.conditionalLogic, 'read');

                    if (params.conditionalAction === 'hide') {
                        vm.fieldsShown[id][modelId] = !result;
                    }
                    else {
                        vm.fieldsShown[id][modelId] = result;
                    }
                }
                else {
                    vm.fieldsShown[id][modelId] = true;
                }
            });
        }
    })
    .directive('nimCheckBuilder', function(){
        return {
            restrict: 'AE',
            templateUrl: 'util/nim-print-check/nim-check-builder.tpl.html',
            controller: 'nimCheckBuilderCtrl',
            controllerAs: 'vm',
            link: function(scope, element){
                element.css('height', scope.paperOptions.paperSize[1] + 'in')
                    .css('width', scope.paperOptions.paperSize[0] + 'in');

                scope.paperOptions.paperSize[0] = element.css('width');
                scope.paperOptions.paperSize[1] = element.css('height');

                element.css('width', '-=' + scope.paperOptions.margin.left + 'px')
                    .css('width', '-=' + scope.paperOptions.margin.right + 'px')
                    .css('height', '');
                
                scope.checkWidth = element.css('width');

                element.css('paddingLeft', scope.paperOptions.margin.left + 'px')
                    .css('paddingRight', scope.paperOptions.margin.right + 'px');
            }
        };
    })
;