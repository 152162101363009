angular.module('cerberus.admin')
    .factory('TemplatesServices',
    function ($http, $q, apiPath, toaster) {
        var service = {
            create: create,
            get: get,
            getSecurityLevels: getSecurityLevels,
            getUserTemplates: getUserTemplates,
            getDetails: getDetails,
            update: update,
            deleteTemplate: deleteTemplate
        };
        return service;
        ////////////////////
        function create(data){
            var deferred = $q.defer();
            $http.post(apiPath + 'templates', data)
                .success(function (value) {
                    toaster.pop('success', 'Created');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function get(type){
            var deferred = $q.defer();
            $http.get(apiPath + 'templates?type='+type)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getSecurityLevels(){
            return ['Private','Organization','Public'];
        }
        function getUserTemplates(){
            var deferred = $q.defer();
            $http.get(apiPath + 'users/templates')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getDetails(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'templates/details/'+id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function update(id, data){
            var deferred = $q.defer();
            $http.put(apiPath + 'templates/details/'+id,data)
                .success(function (value) {
                    toaster.pop('success', 'Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function deleteTemplate (id) {
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'templates/details/' + id})
                .success(function(value){
                    toaster.pop('info', 'Template deleted.');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    }
)
;