angular.module('cerberus.account')
    .controller('UsersAddressBookCtrl', function UsersAddressBookCtrl($scope, $location, usersProfileService) {
        $scope.activeRoute = 'address-book';
        $scope.userProfile = [];

        $scope.goToAddressBookDetails = _goToAddressBookDetails;
        $scope.removeAddress = _removeAddress;

        _init();

        function _init(){
            usersProfileService.getAll('Address').then(function(userProfile){
                $scope.userProfile = userProfile;
            });
        }

        function _goToAddressBookDetails(id){
            if(id){
                $location.url('users/settings/address-book/' + id);
            }
            else{
                $location.url('users/settings/address-book/create');
            }
        }

        function _removeAddress(id, index){
            usersProfileService.remove(id).then(function(){
                $scope.userProfile.splice(index, 1);
            });
        }
    })
    .controller('UsersAddressBookCreateCtrl', function UsersAddressBookCreateCtrl($scope, $location, usersProfileService, UsersProfileListService) {
        $scope.activeRoute = 'address-book';
        $scope.countries = UsersProfileListService.addressBookCountries();
        $scope.tempData = {
            country: 'United States'
        };
        $scope.origData = angular.copy($scope.tempData);

        $scope.hasChanges = _hasChanges;
        $scope.submit = _submit;

        function _hasChanges(){
            return !angular.equals($scope.origData, $scope.tempData);
        }

        function _submit(isValid, data){
            if(isValid){
                usersProfileService.create({data:data, type:'Address'}).then(function(){
                    $location.url('users/settings/address-book');
                });
            }
        }
    })
    .controller('UsersAddressBookDetailsCtrl', function UsersAddressBookDetailsCtrl($scope, $location, profileId, usersProfileService, UsersProfileListService) {
        $scope.activeRoute = 'address-book';
        $scope.countries = UsersProfileListService.addressBookCountries();
        $scope.tempData = {};
        $scope.origData = {};

        $scope.hasChanges = _hasChanges;
        $scope.submit = _submit;

        _init();

        function _init(){
            usersProfileService.get(profileId).then(function(addressModel){
                $scope.tempData = angular.copy(addressModel.data);
                $scope.origData = angular.copy(addressModel.data);
            });
        }

        function _hasChanges(){
            return !angular.equals($scope.origData, $scope.tempData);
        }

        function _submit(isValid, data){
            if(isValid){
                usersProfileService.update(profileId, {data: data}).then(function(){
                    $location.url('users/settings/address-book');
                });
            }
        }
    })
;