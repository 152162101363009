angular.module('cerberus.core')
    .controller('nimVizMapCtrl', function (_, $scope, $timeout, VizUtilityService, LeafletDefaultsService, VizMapService, $firebaseObject, fbUtil) {
        var vm = this;

        //Firebase
        var initialized = {},
            fbUnwatchers = [],
            dataSource = {},
            // debouncedRefresh = _.debounce(refresh, 5000, { leading: true, trailing: true });
            debouncedRefresh = {},
            delayedBoundsRefresh = _.debounce(function () {
                _.forEach(debouncedRefresh, function (fn) {
                    fn();
                });
            }, 1000);
        
        $scope.skipBoundsCheck = false;
        $scope.data = {};

        vm.bounds = null;
        vm.leafletId = $scope.pageObject.id;

        init();

        // Watchers
        $scope.$watch('vm.bounds', function (newValue, oldValue) {
            if ($scope.skipBoundsCheck) {
                $scope.skipBoundsCheck = false;
                return;
            }

            if (newValue && !angular.equals(newValue, oldValue)) {
                $timeout(delayedBoundsRefresh);
            }
        }, true);

        // Events
        $scope.$on('gridster-item-transition-end', function () {
            if($scope.map) {
                $scope.map.invalidateSize();
            }
        });

        $scope.$on('resize', function () {
            if($scope.map) {
                $scope.map.invalidateSize();
            }
        });

        $scope.$on('nim-viz-reload', function (event) {
            //Stop propagation
            event.preventDefault();

        });

        $scope.$on('nim-leaflet-popup-open', function(event, dataSourceId, instanceId){
            var filters = $scope.pageObject.params.filters;
            if(filters && filters[dataSourceId] && filters[dataSourceId].asFilter) {
                var filterSettings = filters[dataSourceId];
                $timeout(function(){
                    $scope.filters[filterSettings.id] = {
                        logic: 'or',
                        filters: [{
                            field: '__nimColumn__',
                            operator: 'eq',
                            value: instanceId,
                            id: instanceId
                        }]
                    };
                });
            }
        });

        $scope.$on('nim-leaflet-popup-close', function(event, dataSourceId, instanceId){
            var filters = $scope.pageObject.params.filters;
            if(filters && filters[dataSourceId] && filters[dataSourceId].asFilter) {
                var filterSettings = filters[dataSourceId];
                $timeout(function(){
                    var filter = $scope.filters[filterSettings.id];
                    if(filter && filter.filters.length && filter.filters[0].id === instanceId){
                        $scope.filters[filterSettings.id] = null;
                    }
                }, 1);
            }
        });

        $scope.$on('$destroy', function(){
            _.forEach(fbUnwatchers, function(unwatchFb){
                unwatchFb();
            });
        });

        function init() {
            var mapOptions = angular.copy($scope.pageObject.viz.settings.map);

            if ($scope.nimEditing === true) {
                mapOptions = angular.extend(mapOptions, {
                    dragging: false,
                    scrollWheelZoom: false
                });
            }

            vm.options = {
                basemaps: angular.copy($scope.pageObject.viz.settings.basemaps),
                overlays: angular.copy($scope.pageObject.viz.settings.overlays),
                map: mapOptions,
                controls: angular.copy($scope.pageObject.viz.settings.controls)
            };

            if (!vm.leafletId) {
                vm.leafletId = VizMapService.generateLeafletId();
            }

            // Build Data Sources
            _.forEach($scope.pageObject.viz.settings.dataSource, function (ds, id) {
                // Builds dataSource's color map by category
                var colorMap = {};
                _.forEach($scope.pageObject.viz.settings.groupColors[id], function (groupColor) {
                    if (groupColor.group && groupColor.color) {
                        colorMap[groupColor.group] = groupColor.color;
                    }
                });

                // Creates Kendo dataSource
                dataSource[id] = VizMapService.buildDataSource($scope, $scope.pageObject, vm.options, id, colorMap);

                // Subscribe to filters
                VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap[id], $scope, dataSource[id]);

                // Watch firebase
                if (ds.nim_viewId) {
                    debouncedRefresh[id] = _.debounce(_.partial(refresh, id),
                        5000, { leading: true, trailing: true });
                    
                    var unwatchFb = $firebaseObject(fbUtil.ref('queries/' + ds.nim_viewId)).$watch(function () {
                        if (!initialized[id]) {
                            //Stops first firing of the event
                            $timeout(function () {
                                initialized[id] = true;
                            });
                        } else {
                            debouncedRefresh[id]();
                        }
                    });

                    fbUnwatchers.push(unwatchFb);
                }
            });
        }

        function refresh(queryId) {
            dataSource[queryId].read();
        }
    })
    .directive('nimVizMap', function() {
        return {
            restrict: 'AE',
            templateUrl: 'core/pages/viz/map/map.tpl.html',
            scope: true,
            require: '^nimPageObject',
            controller: 'nimVizMapCtrl',
            controllerAs: 'vm'
        };
    })
;