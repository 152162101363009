angular.module('cerberus.core')
    .config(function($routeProvider){
        $routeProvider.when('/instances/:id', {
            templateUrl: 'core/instances/instances-page.tpl.html',
            controller: 'InstancesPageCtrl',
            controllerAs: 'vm',
            resolve: {
                _wait: 'WaitForUserData',
                instanceId: function($route){
                    return $route.current.params.id;
                }
            }
        });
    })
;