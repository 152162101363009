angular.module('cerberus.admin')
    .controller('FormsDesigner_readonlyCtrl', function FormsDesigner_readonlyCtrl(_, $scope, $uibModalInstance, $window, autoTabIndex, currentFormObjects,
                                                                                  editFlag, formObj, formOriginId, idArray, sections, widgetId,
                                                                                  DesignerUtilityService, FormsDesignerListService, WidgetsSettingsService) {
        // Populate Scope
        $scope.formOriginId = formOriginId;
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.formFields = [];
        $scope.sections = sections;
        $scope.widgetFieldIds = idArray;
        $scope.formFieldObjects = [];

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.setAddInfo = setAddInfo;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFields = formFieldArray;

                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);
                $scope.widgetFieldIds = _.union(idArray, _.pluck($scope.formFieldObjects, 'modelId'));
                $scope.widgetFieldIds.sort();

                // Grid
                $scope.mainGridOptions = DesignerUtilityService.formGrid($scope.formFields.display);
                $scope.detailGridOptions = DesignerUtilityService.formDetailGrid($scope);

                // Codemirror
                $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded($scope.formFields.operands);
            });

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        function setAddInfo(obj, formFieldObjects, formId){
            for(var i = 0; i < formFieldObjects.length; i++){
                var target = formFieldObjects[i];
                if(obj.modelId === target.modelId){
                    obj.formId = target.formId;
                    return;
                }
            }
            obj.formId = _.last(formId.split('-'));
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;