angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormsClassService
     * @alias cerberus/core:FormsClassService
     * @description Constructs Field classes
     */
    .factory('FormsClassService', function FormsClassService(FormsBuildDynamicService, FormsBuildStaticService, LeafletDefaultsService) {
        /**
         * Constructs a new field
         * @class Field
         * @classdesc decribes a form object
         * @memberOf FormsClassService
         * @param {Config} config
         * @param {Validation} validation
         * @param {Display} display
         * @param {Label} label
         * @param {Help} help
         * @param {Tag} tag
         * @param {Option} option
         * @param {Calculation} calculation
         * @param {object} param - for field specific (non-standard) parameters
         */
        function Field(config, validation, display, label, help, tag, option, calculation, param) {
            this.config = config;
            this.validation = validation;
            this.display = display;
            this.label = label;
            this.help = help;
            this.tag = tag;
            this.option = option;
            this.calculation = calculation;
            this.param = param;
        }

        /**
         * setData method - revive/extend json objects
         * @memberOf Field
         */
        Field.prototype.setData = function (field) {
            angular.extend(this, field);
        };

        /**
         * build method - builds html for angular $compile
         * @memberOf Field
         */
        Field.prototype.build = function () {
            return FormsBuildDynamicService[this.config.type](this);
        };

        /**
         * buildStatic method - builds html for static display
         * @memberOf Field
         */
        Field.prototype.buildStatic = function (value) {
            return FormsBuildStaticService[this.config.type](this, value);
        };

        return {
            newField: newField,
            statictext: statictext,
            hr: hr,
            reference: reference,
            text: text,
            autocomplete: autocomplete,
            location: location,
            map: map,
            textarea: textarea,
            number: number,
            datetime: datetime,
            radio: radio,
            checkbox: checkbox,
            select: select,
            multiselect: multiselect,
            signature: signature,
            table: table,
            readonly: readonly,
            timer: timer,
            image: image,
            rule: rule
        };
        ////////////////////
        /**
         * Constructs a new config
         * @class Config
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {string} type
         * @param {string} dataType
         * @param {string} modelId
         * @param {*} defaultValue
         */
        function Config(type, dataType, modelId, defaultValue) {
            this.type = type;
            this.dataType = dataType;
            this.modelId = modelId;
            this.defaultValue = defaultValue;
        }

        /**
         * Constructs a new validation
         * @class Validation
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {boolean} required
         * @param {string|number|date} min
         * @param {string|number|date} max
         * @param {regex} pattern
         * @param {string} mask
         * @param {Array} rules
         */
        function Validation(required, min, max, pattern, mask, rules) {
            this.required = required;
            this.min = min;
            this.max = max;
            this.pattern = pattern;
            this.mask = mask;
            this.rules = rules;
        }

        /**
         * Constructs a new display
         * @class Display
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {boolean} isInline
         * @param {string} placeholder
         * @param {string} filterType
         * @param {string} filterParams
         * @param {string} format
         */
        function Display(isInline, placeholder, filterType, filterParams, format) {
            this.isInline = isInline;
            this.placeholder = placeholder;
            this.filter = {
                type: filterType,
                params: filterParams
            };
            this.format = format;

        }

        /**
         * Constructs a new label
         * @class Label
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {string} text
         * @param {object} attrs
         * @param {boolean} isHidden
         */
        function Label(text, attrs, isHidden) {
            this.text = text;
            this.attrs = attrs;
            this.isHidden = isHidden;
        }

        /**
         * Constructs a new help
         * @class Help
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {string} [text]
         * @param {object} [attrs]
         * @param {string} type
         */
        function Help(text, attrs, type) {
            this.text = text;
            this.attrs = attrs;
            this.type = type;
        }

        /**
         * Constructs a new calculation
         * @class Calculation
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {string} formula
         */
        function Calculation(formula) {
            this.formula = formula;
        }

        /**
         * Constructs a new option
         * @class Option
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {string} type - custom, widget, upload, etc.
         * @param {string} widgetId - public key to the widget
         * @param {string} id - public key to type of option
         * @param {Array} values - only use if type custom (id would be null)
         * @param {Array} autoFill - stores mapping for auto fill
         * @param {string} orderBy - default order option
         * @param {int} returnNum - number of records to return on a call

         */
        function Option(type, widgetId, id, values, autoFill, orderBy, returnNum) {
            this.type = type;
            this.widgetId = widgetId;
            this.id = id;
            this.values = values;
            this.autoFill = autoFill;
            this.orderBy = orderBy;
            this.returnNum = returnNum;
        }

        /**
         * Constructs a new tag
         * @class Tag
         * @classdesc for use with a field
         * @memberOf FormsClassService
         * @param {object} attrs
         * @param {Array} inner
         */
        function Tag(attrs, inner) {
            this.attrs = attrs;
            this.inner = inner;
        }

        /**
         * Builds an empty Field object
         * @function newField
         * @returns {Field} with null values
         */
        function newField() {
            return new Field(
                new Config(null, null, null, null),
                new Validation(false, null, null, null, null,[]),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a static text field
         * @function statictext
         * @returns {Field}
         */
        function statictext() {
            return new Field(
                new Config("statictext", "static", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, true),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {text: ''}
            );
        }

        /**
         * Builds a static horizontal rule field
         * @function hr
         * @returns {Field}
         */
        function hr() {
            return new Field(
                new Config("hr", "static", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, true),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    type: 'text',
                    value: '',
                    orientation: 'horizontal'
                }
            );
        }

        /**
         * Builds a readonly reference form field
         * @function reference
         * @returns {Field}
         */
        function reference() {
            return new Field(
                new Config("reference", "readonly", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, true),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic text form field
         * @function text
         * @returns {Field}
         */
        function text() {
            return new Field(
                new Config("text", "string", null, null),
                new Validation(false, 0, 255, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({},[]),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic autocomplete text form field
         * @function autocomplete
         * @returns {Field}
         */
        function autocomplete() {
            return new Field(
                new Config("autocomplete", "string", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option('custom', null, null, [], [],'asc',20),
                new Calculation(null),
                {
                    'separator': 'None'
                }
            );
        }

        /**
         * Builds a dynamic location form field
         * @function location
         * @returns {Field}
         */
        function location() {
            return new Field(
                new Config("location", "geoJson", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], 'asc', 20),
                new Calculation(null),
                {
                    'fields': {}
                }
            );
        }

        /**
         * Builds a dynamic map form object
         * @function map
         * @returns {Field}
         */
        function map() {
            return new Field(
                new Config("map", "geoJson", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    geometry: 'point',
                    center: [33.7, -117.8],
                    zoom: 10,
                    allowMultiple: false,
                    pathOptions: {
                        weight: 5,
                        color: 'blue',
                        fillColor: 'blue'
                    },
                    basemaps: [
                        {
                            type: "mapbox.streets-basic",
                            name:"Basic",
                            visible: true
                        },
                        {
                            type: "mapbox.streets-satellite",
                            name:"Satellite",
                            visible: false
                        }
                    ],
                    overlays: [],
                    controls: angular.extend(LeafletDefaultsService.controls(), {
                        draw: true
                    })
                }
            );
        }

        /**
         * Builds a dynamic textarea form field
         * @function textarea
         * @returns {Field}
         */
        function textarea() {
            return new Field(
                new Config("textarea", "string", null, null),
                new Validation(false, 0, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({
                    rows: 5
                }, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {showToolbar: false}
            );
        }

        /**
         * Builds a dynamic number form field
         * @function number
         * @returns {Field}
         */
        function number() {
            return new Field(
                new Config("number", "number", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    step: null,
                    decimals: 0
                }
            );
        }

        /**
         * Builds a dynamic datetime form field
         * @function datetime
         * @returns {Field}
         */
        //TODO: find out how to implement k parse formats
        //TODO: CSS hack to disable time or date buttons
        function datetime() {
            return new Field(
                new Config("datetime", "datetime", null, '@nim_today'),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, 'M/d/yyyy h:mm tt'),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({
                    'k-interval': 30
                }, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    parseFormats: []
                }
            );
        }

        /**
         * Builds a dynamic radio(s) selection form field
         * @function radio
         * @returns {Field}
         */
        function radio() {
            return new Field(
                new Config("radio", "selection", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option('custom', null, null, [
                    {
                        id: 'opt0',
                        display: 'Yes'
                    },
                    {
                        id: 'opt1',
                        display: 'No'
                    }
                ], [],'asc',20),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic checkbox(es) selection form field
         * @function checkbox
         * @returns {Field}
         */
        function checkbox() {
            return new Field(
                new Config("checkbox", "selection", null, false),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({
                    'ng-required': false
                }, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic select selection form field
         * @function select
         * @returns {Field}
         */
        function select() {
            return new Field(
                new Config("select", "selection", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option('custom', null, null, [], [],'asc',20),
                new Calculation(null),
                {
                    filterOperator: "'contains'",
                    hideAddItem: true
                }
            );
        }

        /**
         * Builds a dynamic multi-select selection form field
         * @function multiselect
         * @returns {Field}
         */
        function multiselect() {
            return new Field(
                new Config("multiselect", "dataseries", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option('views', null, null, [], [], 'asc', 20),
                new Calculation(null),
                {
                    filterOperator: "'contains'"
                }
            );
        }

        /**
         * Builds a dynamic signature vector form field
         * @function signature
         * @returns {Field}
         */
        function signature() {
            return new Field(
                new Config("signature", "signature", null, null),
                new Validation(true, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    type: 'signature',
                    penColor: '#000000'
                }
            );
        }

        /**
         * Builds a dynamic table data series form field
         * @function table
         * @returns {Field}
         */
        function table() {
            return new Field(
                new Config("table", "dataseries", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic readonly form field
         * @function readonly
         * @returns {Field}
         */
        function readonly() {
            return new Field(
                new Config("readonly", "readonly", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {}
            );
        }

        /**
         * Builds a dynamic readonly form field
         * @function timer
         * @returns {Field}
         */
        function timer() {
            return new Field(
                new Config("timer", "readonly", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    start:{},
                    end:{}
                }
            );
        }

        /**
         * Builds an image upload/crop field
         * @function image
         * @returns {Field}
         */
        function image(){
            return new Field(
                new Config("image", "image", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    areaType: 'circle',
                    resultImageSize: 200
                }
            );
        }

        function rule(){
            var newRule = new Field(
                new Config("rule", "rule", null, null),
                new Validation(false, null, null, null, null, []),
                new Display(false, null, null, null, null),
                new Label(null, {}, false),
                new Help(null, {}, 'popup'),
                new Tag({}, []),
                new Option(null, null, null, [], [], null, null),
                new Calculation(null),
                {
                    ruleId: '',
                    parameterMap: {},
                    returnMap: {}
                }
            );

            newRule.display.section = '__nimHiddenSection';

            return newRule;
        }
    })
;