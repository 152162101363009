angular.module('cerberus.core')
    .controller('nimFormMapCtrl', function nimFormMapCtrl($scope, $attrs, $element, $timeout, LeafletDefaultsService){
        var vm = this;

        var isStatic = $attrs.nimformmapstatic != null && $attrs.nimformmapstatic != 'false';
        var mapOptions = $scope.nimFormObject.param;
        var drawOptions = {
            draw: {
                polygon: false,
                polyline: false,
                circle: false,
                marker: false,
                rectangle: false
            }
        };
        var mapId = $scope.formOriginId + '_' + $scope.nimFormObject.config.modelId;
        var windowRevisionId = angular.element($element).parents('[nim-window-id]').attr('nim-revision-id');
        if (windowRevisionId) {
            mapId = mapId + '_rev_' + windowRevisionId;
        }
        var elementsWithSameId = angular.element('#' + mapId);
        if (elementsWithSameId.length > 0) {
            mapId = mapId + '_dup_' + angular.element('[nim-instance]').length;
        }
        vm.leafletId = mapId;
        vm.result = null;

        drawOptions.draw[mapOptions.geometry] = {
            shapeOptions: mapOptions.pathOptions
        };

        vm.options = {
            basemaps: mapOptions.basemaps || [],
            overlays: mapOptions.overlays || [],
            map: LeafletDefaultsService.map(),
            controls: isStatic ? {} : mapOptions.controls,
            drawOptions: drawOptions,
            allowMultiple: mapOptions.allowMultiple,
            isStatic: isStatic
        };

        //TODO: Figure out a better way to implement this.  It is pretty rough...
        if (angular.isArray($scope.nimFormObject.param.center)) {
            vm.options.map.center = $scope.nimFormObject.param.center;
        }
        if (angular.isNumber($scope.nimFormObject.param.zoom)) {
            vm.options.map.zoom = $scope.nimFormObject.param.zoom;
        }
        if (angular.isDefined($scope.nimFormObject.param.scrollWheelZoom)) {
            vm.options.map.scrollWheelZoom = $scope.nimFormObject.param.scrollWheelZoom;
        }
        if (angular.isNumber($scope.nimFormObject.param.minZoom)) {
            vm.options.map.minZoom = $scope.nimFormObject.param.minZoom;
        }
        if (angular.isNumber($scope.nimFormObject.param.maxZoom)) {
            vm.options.map.maxZoom = $scope.nimFormObject.param.maxZoom;
        }

        if(isStatic){
            vm.options.map = angular.extend(vm.options.map,{
                touchZoom: false,
                dragging: false,
                scrollWheelZoom: false,
                doubleClickZoom: false,
                boxZoom: false,
                zoomControl: false
            });
        }
        else if($scope.nimEditing){
            vm.options.map = angular.extend(vm.options.map, {
                dragging: false,
                scrollWheelZoom: false
            });
        }

        if($scope.dataSet){
            vm.options.drawings = $scope.dataSet[$scope.nimFormObject.config.modelId];
        }

        vm.zoomTo = function(){
            if($scope.location){
                $scope.map.setView({
                    lng: $scope.location.geometry.coordinates[0],
                    lat: $scope.location.geometry.coordinates[1]
                }, 16);
            }
        };

        $scope.$watch("vm.result", function(result){
            if(result) {
                if(result.features.length > 0) {
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = result.features[0];
                    delete $scope.dataSet[$scope.nimFormObject.config.modelId].type;
                }
                else {
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = {};
                }

                if($scope.validateFormObject) {
                    $scope.validateFormObject($scope.dataSet[$scope.nimFormObject.config.modelId], $scope.isRequired);
                }
            }
        });

        // Gridster resize
        $scope.$on('gridster-item-transition-end', function () {
            if($scope.map) {
                $scope.map.invalidateSize();
            }
        });
    })
    .directive('nimFormMap', function(){
        return {
            restrict: 'AE',
            templateUrl: 'core/forms/nim-form-map.tpl.html',
            scope: true,
            controller: 'nimFormMapCtrl',
            controllerAs: 'vm'
        };
    })
;