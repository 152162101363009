angular.module('cerberus.util')
    // bugLogConfig: Settings for the bug logging service
    .constant('bugLogConfig', {
        // This is the location of the BugLog server that will be receiving the error reports
        // example: 'https://domain/buglog/listeners/bugLogListenerREST.cfm'
        listener: "https://nimbuglog.nimbussw.com/buglog/listeners/bugLogListenerREST.cfm",
        // Tell BugLog which application is submitting this bug
        applicationCode: 'IntelliBrief_Client',
        // If the BugLog server requires an API Key to talk to it, then here is where you put it.
        apiKey: "55082F09-481D-44E5-BAF6DFA2149FD03D",
        // The hostname to tell BugLog from where the report is coming from. Leave empty to get the info from the browser.
        hostName: 'nimbussw.com',
        // Default bug serverity code
        defaultSeverity: 'ERROR'
    })
    .config(function ($provide, $httpProvider) {

        $provide.decorator('$exceptionHandler', function (_, $delegate, $log, BugLogService) {
            var errorsBeingLogged = {};
            return function (exception, cause) {
                if (!errorsBeingLogged[exception]) {
                    //Custom error handling.
                    try {
                        BugLogService.logBug(cause, exception);
                        delayErrorLogging(_, errorsBeingLogged, exception);
                    }
                    catch (e) {
                        $log.warn("BugLog Service: Error logging bug", e);
                    }
                }    

                // Calls the original $exceptionHandler.
                $delegate(exception, cause);
            };
        });
        $httpProvider.interceptors.push(function (_, $q, $log, toaster, BugLogService) {
            var errorsBeingLogged = {};
            return {
                responseError: function (rejection) {
                    // If no URL provided with the rejection object, assume
                    // this is an error with the parsing, not the HTTP call
                    if (_.has(rejection, 'config.url')) {

                        //Ignore Authentication, Authorization, and validation errors
                        if ([400, 401, 403, 409, 422].indexOf(rejection.status) === -1) {
                            // For 404 - Not Found, do not log error, just show toaster
                            if (rejection.status === 404) {
                                toaster.pop('warning', 'Not Found');
                                delayErrorLogging(_, errorsBeingLogged, '404');
                            }
                            else if (rejection.status === -1) {
                                toaster.pop('warning', 'Looks like the Connection failed. Try the action again or refresh the page.');
                                delayErrorLogging(_, errorsBeingLogged, '-1');
                            }
                            else {
                                // Build $http error message
                                var errorMsg = '' +
                                    _.get(rejection, 'config.method', '') + ' ' +
                                    _.get(rejection, 'config.url', '') + ' ' +
                                    _.get(rejection, 'status', '') + '(' +
                                    _.get(rejection, 'text', '') + ')';
                            
                                if (!errorsBeingLogged[errorMsg]) {
                                    // Try to log the bug
                                    try {
                                
                                        BugLogService.logBug('$http', new Error(errorMsg));
                                        // Tell the user that we are working on it
                                        toaster.pop('error', 'Error', 'Support Ticket Opened');
                                        delayErrorLogging(_, errorsBeingLogged, errorMsg);
                                    }
                                    catch (e) {
                                        $log.warn("BugLog Service: Error logging bug", e);
                                        // Mommy, mommy hit the reset button -- there is no reset button in life!
                                        toaster.pop('error', 'Error', 'Please contact support');
                                    }
                                }
                            }
                        }
                    }
                    
                    // otherwise, default behaviour
                    return $q.reject(rejection);
                }
            };
        });

        function delayErrorLogging(_, errorsBeingLogged, error) {
            errorsBeingLogged[error] = true;
            _.delay(function () {
                errorsBeingLogged[error] = false;
            }, 5000);
        }
    })
;