angular.module('cerberus.core')
/**
 * @ngdoc service
 * @name VizMapService
 * @alias cerberus/core:VizMapService
 * @description Provides functions for configuring viz maps
 */
    .factory('VizMapService', function(_, kendo, $http, $timeout, VizUtilityService){
        return {
            buildDataSource: buildDataSource,
            generateLeafletId: generateLeafletId
        };

        /**
         * Builds dataSource for map page object
         * @param scope
         * @param object - copy of original page object
         * @param mapOptions - leaflet options
         * @param id - dataSource/query ID
         * @param colorMap - map of color options for categories
         */
        function buildDataSource(scope, object, mapOptions, id, colorMap) {
            var initialDataRead = false; // Used to prevent zooming into points on initial load
            var oldPageFilter = null;
            var source = object.viz.settings.dataSource[id];
            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                pageSize: 20,
                requestStart: function (e) {
                    var requiredFilters = _.get(object.params, ['requiredFilters', id], {});
                    if(!_.isEmpty(requiredFilters)){
                        _.forEach(requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                },
                change: change
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(source)
            };

            var baseFilter = angular.copy(source.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: object.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, source, sortOptions, filterOptions);

            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if (field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var filtered = false;
                if (options.data.filter && options.data.filter.filters) {
                    var pageFilter = _.find(options.data.filter.filters, 'pageFilter');
                    filtered = !!(pageFilter && !_.isEmpty(pageFilter.filters));
                }

                var httpOptions = VizUtilityService.buildBaseHttpOptions(object, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                httpOptions.params.$ds = id;
                httpOptions.params.$format = 'geoJson';

                var bounds = scope.$eval('vm.bounds');
                if (bounds && !filtered) {
                    var sw = bounds.getSouthWest(),
                        ne = bounds.getNorthEast(),
                        geoCol = source.schema.model.fields.location.from + '_nim_geometry';

                    httpOptions.params.$geoCol = 'bbox(' + geoCol + ',' + sw.lng + ',' + sw.lat + ',' + ne.lng + ',' + ne.lat + ')';
                }
                
                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }

            function change(e) {
                var data = e.sender.data().toJSON(),
                    filter = e.sender.filter(),
                    filtered = false,
                    columns = scope.pageObject.viz.settings.columns[id],
                    fields = source.schema.model.fields,
                    locationField = fields.location.from,
                    titleField = fields.title.from;

                scope.data[id] = angular.copy(data);
                    
                var otherColumns = _.reject(columns, function (c) {
                    return c.field === locationField || c.field === titleField;
                });

                // Additional fields to be listed in popup
                var otherFields = _.map(otherColumns, function (c) {
                    return {
                        title: c.title,
                        fieldName: c.field
                    };
                });

                // Check if page filter has been set
                var pageFilter = null;
                if (filter) {
                    pageFilter = _.find(filter.filters, 'pageFilter');
                    filtered = !!(pageFilter && !_.isEmpty(pageFilter.filters));
                }
                
                var markers = buildMarkers(data, fields, otherFields, colorMap);
                var hasOverlay = false;

                _.forEach(mapOptions.overlays, function (overlay) {
                    if (overlay.queryId == id) {
                        overlay.data = markers;
                        hasOverlay = true;
                    }
                });

                if (filtered && hasOverlay && !angular.equals(pageFilter, oldPageFilter) && initialDataRead) {
                    scope.skipBoundsCheck = true;
                    scope.$broadcast('nim-map-layer-filtered', markers);
                }
                oldPageFilter = pageFilter;
                initialDataRead = true;
            }
        }

        function buildMarkers(data, fields, otherFields, colorMap) {
            var titleField = fields.title.from,
                categoryField = fields.category.from,
                locationField = fields.location.from,
                markers = [];
            
            _.forEach(data, function (dataItem) {
                var geometry = _.get(dataItem.properties[locationField], 'geometry');
                if (geometry) {
                    var newMarker = {
                        geometry: geometry,
                        properties: {
                            title: dataItem.properties[titleField],
                            markerColor: colorMap[dataItem.properties[categoryField]] || colorMap['_default'],
                            id: dataItem.properties.id
                        },
                        type: dataItem.type
                    };

                    _.forEach(otherFields, function (otherField) {
                        var property = dataItem.properties[otherField.fieldName];
                        if (fields[otherField.fieldName].type === 'location') {
                            if (property) {
                                newMarker.properties[otherField.title] = property.properties.formatted_address;
                            }
                            else {
                                newMarker.properties[otherField.title] = dataItem.properties.formatted_address;
                            }
                        }
                        else if (fields[otherField.fieldName].type === 'date') {
                            newMarker.properties[otherField.title] = VizUtilityService.parseDateField(property);
                        }
                        else {
                            newMarker.properties[otherField.title] = property;
                        }
                    });

                    markers.push(newMarker);
                }
            });

            return markers;
        }
        
        function generateLeafletId() {
            return _.uniqueId('temp_leaflet_id_');
        }
    })
;