angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizUtilityService
     * @alias cerberus/core:VizUtilityService
     * @description Provides functions for viz directives and configuration
     */
    .factory('VizUtilityService', function VizUtilityService(_, apiPath, kendo, moment, $rootScope, $location, $log, $timeout, $window, nimUtilityService, OdataUtilityService) {
        return {
            createOdataUrl: createOdataUrl,
            subscribeToPageFilters: subscribeToPageFilters,
            setFilter: setFilter,
            buildBaseHttpOptions: buildBaseHttpOptions,
            buildHttpParams: buildHttpParams,
            cleanFilterConfiguration: cleanFilterConfiguration,
            clientSort: clientSort,
            createSortArray: createSortArray,
            formatOrderBy: formatOrderBy,
            buildRecurrenceData: buildRecurrenceData,
            buildAggregateParams: buildAggregateParams,
            parseDateField: parseDateField,
            parseNumberField: parseNumberField,
            isFilterEmpty: isFilterEmpty,
            openPageObjectRoute: openPageObjectRoute,
            setRequestHeaders: setRequestHeaders
        };
        ////////////////////
        /**
         * Create an odata url
         * @function createOdataUrl
         * @param {object} id - pageObject Id
         * @return {string} of an odata url
         */
        function createOdataUrl(id) {
            return apiPath + 'page/objects/' + id + '/odata.svc';
        }

        /**
         * Creates watchers on specific page filters for a pageObject
         * @function subscribeToPageFilters
         * @param {object} filterMap
         * @param {object} scope - angularJs directive scope
         * @param {object} dataSource - viz datasource
         */
        function subscribeToPageFilters(filterMap, scope, dataSource) {
            if(_.size(filterMap) > 0) {
                // On page object load, some filters may already be set
                var filterSet = false;
                angular.forEach(filterMap, function (value, key) {
                    scope.$watch('filters.' + key, function (newValue, oldValue) {
                        //Only fire the callback if there is a newValue
                        //Or if there is an oldValue but no newValue
                        if (newValue || !newValue && oldValue) {
                            setFilter(dataSource, key, value, newValue);
                        }
                    });

                    // Checks if this filter has already been set
                    if(!_.isNull(_.get(scope.filters, key, null))){
                        filterSet = true;
                    }
                });

                if(!filterSet){ // If no filters set when page object is loaded, read without those filters
                    dataSource.read();
                }
            }
            else {
                dataSource.read();
            }
        }
        /**
         * Used as a callback to a pageFilter watcher
         * @function setFilter
         * @param {object} dataSource
         * @param {string} key
         * @param {object} value
         * @param {object} newFilter
         */
        function setFilter(dataSource, key, value, newFilter) {
            //Set filter values
            var filter = angular.copy(newFilter);

            try {
                filter.key = key;        // Mark this filter as originating from the page filter described by key
                for (var i = 0; i < filter.filters.length; i++) {
                    filter.filters[i].field = value;    // Replace "__nimColumn__" with data source's field name

                    if(!filter.filters[i].value && filter.filters[i].value !== 0){
                        filter.filters.splice(i, 1);
                        i--;
                    }
                }
            }
            catch (e) {}

            var dataSourceFilter = dataSource.filter(),         // Original dataSource filters
                allFilters = angular.copy(dataSourceFilter);    // Copy that will be modified and pushed to dataSource

            if(!allFilters){
                allFilters = {
                    filters: [],
                    logic: 'and'
                };
            }

            var pageFilters = _.find(allFilters.filters, 'pageFilter');

            if(!pageFilters){
                pageFilters = {
                    pageFilter: true,
                    logic: 'and',
                    filters: []
                };

                allFilters.filters.push(pageFilters);
            }

            if(allFilters){
                _.remove(pageFilters.filters, 'key', key); // If old filter is from the given page filter, remove it

                //Push new filters
                if(filter){
                    pageFilters.filters.push(filter);
                }
            }
            else{
                allFilters = filter;
            }

            if(!angular.equals(dataSourceFilter, allFilters)) {
                dataSource.filter(allFilters);
            }
        }

        /**
         * Builds options for $http call for datasource read (sans URL parameters)
         * @param {*} pageObject 
         * @param {*} isPreview 
         */
        function buildBaseHttpOptions(pageObject, isPreview) {
            var options = {
                url: apiPath + 'page/objects/' + pageObject.id + '/odata.svc',
                method: 'GET',
                params: {}
            };

            if (isPreview || !pageObject.id) {
                options.url = apiPath + 'page/objects/preview';
                options.method = 'POST';
                options.data = angular.toJson({ pageObject: pageObject });
            }
            
            return options;
        }

        function buildHttpParams(scope, dataSourceOptions, options) {
            var params = {};
            if (dataSourceOptions.serverPaging) {
                params.$inlinecount = 'allpages';

                if (_.get(options, 'data.pageSize')) {
                    params.$top = options.data.pageSize;
                }

                if (_.get(options, 'data.skip')) {
                    params.$skip = options.data.skip;
                }
            }

            if (dataSourceOptions.serverFiltering) {
                var filter = options.data.filter;
                if (!filter) {
                    filter = {
                        logic: 'and',
                        filters: []
                    };
                }

                cleanFilterConfiguration(_.get(filter, 'filters', []));

                if (scope.nimParentId && scope.nimParentLookUp) {
                    filter.filters.push({
                        operator: 'eq',
                        field: scope.nimParentLookUp,
                        value: scope.nimParentId
                    });
                }
                    
                if (!_.isEmpty(filter.filters)) {
                    params.$filter = OdataUtilityService.toOdataFilter(filter);
                }
            }

            if (dataSourceOptions.serverSorting && !_.isEmpty(options.data.sort)) {
                params.$orderBy = formatOrderBy(options.data.sort);
            }

            if (dataSourceOptions.serverAggregates && !_.isEmpty(options.data.aggregate)) {
                buildAggregateParams(params, options.data.aggregate);
            }

            return params;
        }

        // Removes empty filters and escapes "and" and "or"
        function cleanFilterConfiguration(filterArray){
            for(var i = 0; i < filterArray.length;){
                var filterObj = filterArray[i];
                if(_.isString(filterObj.value)){
                    filterObj.value = filterObj.value.replace(' and ', ' \\and ').replace(' or ', ' \\or ');
                }

                if(_.isArray(filterObj.filters)){
                    cleanFilterConfiguration(filterObj.filters);

                    if(filterObj.filters.length === 0){
                        _.pullAt(filterArray, i);
                        continue;
                    }
                    else if(filterObj.filters.length === 1){
                        filterArray[i] = _.head(filterObj.filters);
                    }
                }

                i++;
            }
        }

        /**
         * For sorting data client-side
         * @param data
         * @param sortOptions
         * @returns {Function}
         */
        function clientSort(data, sortOptions) {
            var fields = [],
                dirs = [],
                sortedData = data;
            
            if (_.isArray(sortOptions)) {
                _.forEach(sortOptions, function (opt) {
                    if (opt.field) {
                        fields.push(opt.field);
                        dirs.push(opt.dir || 'asc');
                    }
                });
            }
            else if (sortOptions.field) {
                fields.push(sortOptions.field);
                dirs.push(sortOptions.dir || 'asc');
            }
            
            if (fields.length > 0) {
                sortedData = _.sortByOrder(data, fields, dirs);
            }

            return sortedData;
        }

        /**
         * Adds group config to sort config as a work-around for not having server grouping
         * @param dataSourceOptions
         * @returns {Array}
         */
        function createSortArray(dataSourceOptions) {
            var sort = []; // New sort config, which can be an array of objects

            if (_.get(dataSourceOptions, 'group.field')) {
                sort.push(dataSourceOptions.group);
            }
            if(_.isArray(dataSourceOptions.sort)){
                _.forEach(dataSourceOptions.sort, function(sortOpt){
                    // Make sure each sort option has a field
                    if(sortOpt.field){
                        sort.push(sortOpt);
                    }
                });
            }
            else if(_.get(dataSourceOptions, 'sort.field')){
                sort.push(dataSourceOptions.sort);
            }

            return sort;
        }

        /**
         * Builds $orderBy URL parameter
         * @param {Array} sortOpts 
         */
        function formatOrderBy(sortOpts) {
            if (_.isArray(sortOpts)) {
                var sortItems = _.map(sortOpts, sortItemToString);

                return sortItems.join(',');
            }
            else if(_.isObject(sortOpts) && sortOpts.field) {
                return sortItemToString(sortOpts);
            }
            
            return '';
        }

        function sortItemToString(item) {
            var dir = item.dir || 'asc';
            return item.field + ' ' + dir;
        }

        /**
         * Builds $recurrence URL parameter
         * @param pageObject
         * @param scope
         * @param config
         * @returns {string}
         */
        function buildRecurrenceData(pageObject, scope, config){
            // Field the recurrence applies to
            var filterId = pageObject.params.repeatEnabled;
            var filter = scope.filters[filterId];
            if(filterId && filter) {
                var values = [];
                var field;
                // Start date only
                if (filter.value) {
                    values.push(filter.value);
                    field = filter.field;
                }
                // Date Range with start date and [optional] end date
                else if (filter.filters && filter.filters.length && filter.filters[0].value) {
                    for (var i = 0; i < filter.filters.length; i++) {
                        if (filter.filters[i].value) {
                            values.push(filter.filters[i].value);
                        }
                    }
                    field = filter.filters[0].field;
                }
                // No date values, no recurrence
                else {
                    return '';
                }

                // Remove the start/end dates from filters
                for (var j = 0; j < config.filter.filters.length; j++) {
                    var f = config.filter.filters[j];
                    if (f.field === field && f.operator === 'gte' && f.value == values[0]) {
                        config.filter.filters.splice(j);
                        j--;
                    }
                    else if (values.length >= 2 && f.field === field && f.operator === 'lte' && f.value == values[1]) {
                        config.filter.filters.splice(j);
                        j--;
                    }
                }

                // $recurrence=field,startDate[,endDate]
                return field + ',' + values.map(function(v){
                        // Makes sure the dates are in ISO format
                        var dateValue = new Date(v);
                        return dateValue.toISOString();
                    }).join(',');
            }
        }

        /**
         * Builds aggregate URL parameters
         * @param {Object} params 
         * @param {Array} aggregates 
         */
        function buildAggregateParams(params, aggregates) {
            _.forEach(aggregates, function (columnAggregate, i) {
                _.forEach(columnAggregate, function (value, key) {
                    if (!_.startsWith(key, '$')) {
                        var param = kendo.format('aggregate[{0}][{1}]', i, key);
                        params[param] = value;
                    }    
                });
            });
        }

        /**
         * The parse function for data-source schema fields of type date.
         * Changes date string back to a Date object
         * @param value - date string serialized by Coldfusion
         * @returns {Date, string} - corrected Date or empty string if no value
         */
        function parseDateField(value){
            if(value && !_.isDate(value)){
                try {
                    var dateValue = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss');
                    dateValue.local();

                    return dateValue.toDate();
                }
                catch(e){}
            }
            else if(_.isDate(value)){
                return value;
            }

            return '';
        }

        function parseNumberField(value) {
            if (!value && value !== 0) {
                return '';
            }

            return kendo.parseFloat(value);
        }

        /**
         * Recursively checks if filter value has been set
         * @param filterObj {Object} - Filter configuration object
         * @param filterObj.value {*} - Value of filter (only for single filter)
         * @param filterObj.filters {Array} - Array of filters
         * @returns {boolean}
         */
        function isFilterEmpty(filterObj){
            if(!_.isEmpty(filterObj)){
                if(_.has(filterObj, 'value')){
                    return false;
                }
                else if(!_.isEmpty(filterObj.filters)){
                    return _.every(filterObj.filters, isFilterEmpty);
                }
            }

            return true;
        }

        /**
         * Compiles and opens route for selected record
         * @param routeTemplateString - Path template specified in page object configuration
         * @param dataItem - Selected record
         * @param target - Where the route will be opened ("_self" or "_blank")
         */
        function openPageObjectRoute(routeTemplateString, dataItem, target) {
            var user = $rootScope.userData,
                compileData = {
                    data: dataItem,
                    nim: nimUtilityService,
                    user: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        fullName: user.firstName + ' ' + user.lastName
                    }
                };
            

            try {
                var template = kendo.template(routeTemplateString),
                    path = template(compileData),
                    isLocal = _.startsWith(path, '/');
                
                $timeout(function () { 
                    if (isLocal) {
                        if (target !== '_self') {
                            var absUrl = $location.absUrl(),
                                currentPath = $location.path(),
                                pathIndex = absUrl.indexOf(currentPath),
                                newUrl = absUrl.slice(0, pathIndex);
                            
                            $window.open(newUrl + path, target);
                        }
                        else {
                            $location.url(path);
                        }    
                    }
                    else {
                        $window.open(path, target);
                    }
                });
            }
            catch (e) {
                $log.error('Error compiling route:', e);
            }    
        }

        /**
         * Sets the XMLHttpRequest headers for dataSource requests
         * @param xhr
         */
        function setRequestHeaders(xhr) {
            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
            xhr.setRequestHeader('WSID', _.get($rootScope, 'userData.currentWorkspace.id', ''));
        }
    })
;