angular.module('cerberus.account')
    .controller('OrganizationsSettingsCtrl', function OrganizationsSettingsCtrl($scope, orgId, OrganizationsService) {
        $scope.orgId = orgId;
        $scope.orgData = {};

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }
    })
;