angular.module('cerberus.core')
    .factory('NotificationsService', function (_, apiPath, moment, $http, $q) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getNotifications: getNotifications,
            markAsRead: markAsRead,
            markAllAsRead: markAllAsRead
        };

        function getNotifications(top, skip) {
            var deferred = $q.defer(),
                config = {
                    params: {
                        $top: top,
                        $skip: skip
                    }
                };

            $http.get(apiPath + 'notifications', config)
                .success(function (response) {
                    deferred.resolve(
                        formatNotificationsResponseData(response.DATA)
                    );
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });

            return deferred.promise;
        }

        function markAsRead(notificationId) {
            var deferred = $q.defer();

            $http.put(apiPath + 'notifications/' + notificationId, {})
                .success(function (response) {
                    deferred.resolve(response.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });

            return deferred.promise;
        }

        function markAllAsRead(notificationIds) {
            var promises = [];
            _.forEach(notificationIds, function (id) {
                promises.push(markAsRead(id));
            });

            return $q.all(promises);
        }

        /********** Helpers **********/

        function formatNotificationsResponseData(responseData) {
            responseData.totalUnread = _.get(responseData, 'totalUnread[0].TOT_UNREAD', 0);
            _.forEach(responseData.notifications, function (notification) { 
                notification.date = moment.utc(notification.date, 'MMMM, DD YYYY hh:mm:ss').toDate();
            });

            return responseData;
        }
    })    
;