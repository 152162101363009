angular.module('cerberus.ui')
.directive('nimSelectAllOnFocus', function () {
       return {
           restrict: 'A',
           link: function(scope, elm) {
               elm.mouseup(function(event){
                   event.preventDefault();
               });
               elm.focus(function() {
                   elm.select();
               });
           }
       };
   })
;