angular.module('cerberus.admin')
    .controller('WidgetsWorkflowCtrl', function WidgetsWorkflowCtrl($scope, $location, widgetId, WidgetsService, WorkflowsService, WorkflowDiagramService) {
        /* Populate Scope */
        $scope.widgetPublicKey = widgetId;
        $scope.widgetModel = {};
        $scope.activeRoute = 'workflow';

        /* Workflow Logic */
        $scope.openWorkflowDesigner = function (widgetId, workflowId) {
            $location.url('/settings/widgets/' + widgetId + '/workflow/' + workflowId);
        };

        init();

        ////////////////////

        function init() {
            WidgetsService.getWidget($scope.widgetPublicKey).then(function(widgetModel){
                $scope.widgetModel = widgetModel;

                WorkflowsService.getPublishedWorkflow(widgetModel.workflow.originatorId).then(function(data){
                    $scope.workflow = data.workflow;
                    $scope.diagramOptions = WorkflowDiagramService.buildWFDiagram($scope, 'workflow', true);
                });
            });
        }

    })
;