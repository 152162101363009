angular.module('cerberus.ui')
/**
 * @ngdoc service
 * @name nimTitleService
 * @alias cerberus/ui:nimTitleService
 * @description Getter/Setter of page title
 */
    .factory('nimTitleService', function nimTitleService() {
        var _title = '';
        var service = {
            get: get,
            set: set
        };
        return service;
        ////////////////////////
        function get() {
            return _title;
        }
        function set(string) {
            _title = string;
            return _title;
        }
    })
;