angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name ViewsPropertiesService
 * @alias cerberus/views:ViewsPropertiesService
 * @description Provides REST service for "views" resource
 */
    .factory('ViewsPropertiesService', function ViewsPropertiesService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            get: get,
            add: add,
            remove: remove,
            update: update,
            modelData: modelData
        };
        ////////////////////

        /**
         * Makes a get call REST api "views/{id}/properties" resource
         * @function get
         * @return {promise}
         */
        function get(viewId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'views/' + viewId + '/properties')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Makes a get call REST api "views/{id}/properties" resource
         * @function add
         * @return {promise}
         */
        function add(viewId, data) {
            var deferred = $q.defer();
            $http.post(apiPath + 'views/' + viewId + '/properties',data)
                .success(function(value){
                    toaster.pop('success', 'View Property Added');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Makes a delete call REST api "views/properties/{id}" resource
         * @function remove
         * @return {promise}
         */

        function remove(id) {
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'view/properties/' + id})
                .success(function(value){
                    toaster.pop('info', 'View Property Deleted');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Makes a put call to REST api "views/properties/{id}" resource
         * @function update
         * @param id
         * @param data
         * @returns {promise}
         */
        function update(id, data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'view/properties/' + id, {property:data.property, description: data.description})
                .success(function(value){
                    toaster.pop('success', 'View Property Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * models data before post
         * @function modelDaa
         * @returns {object}
         */
        function modelData(data,adv,type) {
            var isValid = false;
            var d = {
                name: data.name,
                description: data.description,
                type: type,
                property:{}
            };
            if(data.name){
                if(adv && data.adv){
                    d.property.adv = data.adv;
                    if(data.where){
                        d.property.where = data.where;
                    }
                    isValid = true;
                }
                else if(data.function && data.field){
                    d.property = {
                        function: data.function.func,
                        field: data.field,
                        argType: data.function.argType
                    };
                    isValid = true;
                }
            }
            return {
                d: d,
                isValid: isValid
            };
        }
    })
;
