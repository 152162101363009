angular.module('cerberus.core')
    .directive('nimSideMenuItem', function($location, $timeout){
        return {
            link: function (scope, element) {
                var children = element.children('.route');
                children.on('click', clickHandler);
                if(scope.item.isTopLevel){
                    scope.$watch(hasActiveChildren, function (newValue) {
                        var val = newValue || false;
                        element.toggleClass('sub-active', val);
                    });
                }
                scope.$on('$destroy', function () {
                    children.off('click');
                });
                ////////////////////
                function hasActiveChildren() {
                    return $(element).find('.active').length > 0;
                }
                function clickHandler(e) {
                    e.preventDefault();

                    $timeout(function(){
                        if(scope.userData.currentWorkspace.isAdmin === true && e.ctrlKey){
                            $location.url('/settings/pages/' + scope.item.pageId);
                        }
                        else {
                            $location.url('/' + scope.item.pageId);
                        }
                    });
                }
            }
        };
    })
;