angular.module('cerberus.admin')
    .controller('GanttConfigCtrl', function GanttConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters,
                                                            DesignerUtilityService, PagesDesignerListService,
                                                            pageObjectConfigService, ViewsService, vizListService) {
        var vm = this;
        
        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.views = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.viewColumns = [];
        vm.editView = _.get(pageObject,'viz.settings.dataSource.nim_viewId');
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.filterType = null;
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };

        vm.geoProperties = [
            { value: 'formatted_address', display: 'Full Address' },
            { value: 'country_long_name', display: 'Country (Long)' },
            { value: 'country_short_name', display: 'Country (Short)' },
            { value: 'administrative_area_level_1_long_name', display: 'State (Long)' },
            { value: 'administrative_area_level_1_short_name', display: 'State (Short)' },
            { value: 'administrative_area_level_2_long_name', display: 'County (Long)' },
            { value: 'administrative_area_level_2_short_name', display: 'County (Short)' },
            { value: 'locality_long_name', display: 'City (Long)' },
            { value: 'locality_short_name', display: 'City (Short)' },
            { value: 'postal_code_long_name', display: 'ZIP (Long)' },
            { value: 'postal_code_short_name', display: 'ZIP (Short)' },
            { value: 'street_address', display: 'Street Address' }
        ];

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/gantt/gantt-data-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.calendarViews = [
            // { value: 'day', display: 'Day' },
            { value: 'week', display: 'Week' },
            { value: 'month', display: 'Month' }
        ];

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);

        vm.vizDict = vizListService.vizDictionary();

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns);

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.setBaseWidget = _.partial(pageObjectConfigService.setBaseWidget, vm, vm.temp);

        // Column selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        vm.selectedCalendarView = selectedCalendarView;

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        //Init
        init();

        function init() {
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            vm.loadColumns(vm.editView);

            if (vm.temp.viz.settings.views[0] == 'day'){
                vm.temp.viz.settings.views = [
                    { type: "week", selected: false },
                    { type: "month", selected: true }
                ];
            }

            _.defaults(vm.temp.viz.settings, { height: 600 });
            _.defaults(vm.temp.params, {
                requiredFilters: {}
            });

            pageObjectConfigService.updateSortConfig(vm.temp.viz.settings.dataSource);
            vm.sortBySortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.dataSource.sort);
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var model = vm.temp.viz.settings.dataSource.schema.model;
            var type = model.fields[colField].type;

            if (type === 'number') {
                if(!model.percentComplete){ // Completion Percentage
                    model.percentComplete = colField;
                }
                // else if (!fields.orderId.from) { // Sort Order ID
                //     fields.orderId.from = colField;
                // }
            }
            else if(type === 'date'){
                if(!model.start){ // Start Date
                    model.start = colField;
                }
                else if(!model.end){ // End Date
                    model.end = colField;
                }
            }
            else if(!model.title) { // Title
                model.title = colField;
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var source = vm.temp.viz.settings.dataSource;
            var model = source.schema.model;

            // Title
            if(model.title === colField){
                delete model.title;
            }

            // WBS
            if(model.wbs === colField){
                delete model.wbs;
            }

            // Parent ID
            if(model.parentId === colField){
                delete model.parentId;
            }

            // Start Date
            if(model.start === colField){
                delete model.start;
            }

            // End Date
            if(model.end === colField){
                delete model.end;
            }

            // Completion Percentage
            if(model.percentComplete === colField){
                delete model.percentComplete;
            }

            // // Sort Order ID
            // if(fields.orderId.from === colField){
            //     delete fields.orderId.from;
            // }

            // // Show Summary
            // if(fields.summary.from === colField){
            //     delete fields.summary.from;
            // }

            // // Task Expanded
            // if(fields.expanded.from === colField){
            //     delete fields.expanded.from;
            // }

            // Dependency
            if(model.dependency === colField){
                delete model.dependency;
            }

            // Sort By
            _.remove(source.sort, 'field', colField);

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        // Getter/Setter for selected calendar view
        function selectedCalendarView(type){
            var views = vm.temp.viz.settings.views;
            if(arguments.length){
                _.forEach(views, function(view){
                    view.selected = view.type === type;
                });

                return type;
            }
            else {
                var selectedView = _.find(views, 'selected', true);
                return selectedView.type;
            }
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;