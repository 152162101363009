angular.module('cerberus.account')
    .controller('UsersSignatureCtrl', function UsersSignatureCtrl($scope, $timeout, usersProfileService){
        $scope.activeRoute = 'signature';
        $scope.profileId = null;
        $scope.tempData = {
            sig_img: '',
            init_img: ''
        };
        $scope.origData = angular.copy($scope.tempData);

        $scope.sig_path = '';
        $scope.init_path = '';

        $scope.isUnchanged = isUnchanged;
        $scope.submit = submit;
        $scope.clear = clear;

        init();

        function init(){
            usersProfileService.getAll('Signature').then(function(data){
                if(data.length > 0){
                    $scope.profileId = data[0].id;
                    $scope.sig_path = setPath(data[0].data.sig_img);
                    $scope.init_path = setPath(data[0].data.init_img);
                }
            });
        }

        function isUnchanged(){
            return angular.equals($scope.tempData, $scope.origData);
        }

        function submit(isValid, data){
            if(isValid){
                if($scope.profileId){
                    usersProfileService.update($scope.profileId, {data: data}).then(function(){
                        $scope.origData = angular.copy($scope.tempData);
                        $scope.sig_path = setPath($scope.sig_path);
                        $scope.init_path = setPath($scope.init_path);
                    });
                }
                else {
                    usersProfileService.create({data: data, type: 'Signature'}).then(function (newData) {
                        $scope.origData = angular.copy($scope.tempData);

                        $scope.profileId = newData.id;
                        $scope.sig_path = setPath(newData.data.sig_img);
                        $scope.init_path = setPath(newData.data.init_img);
                    });
                }
            }
        }

        function setPath(path){
            var qm = path.indexOf('?');
            var newPath = path;
            if(qm > 0){
                newPath = path.substring(0, qm);
            }
            var date = new Date();
            return newPath + '?' + date.toISOString();
        }

        function clear(field){
            $timeout(function(){
                $scope.tempData[field] = '';
            });
        }
    })
;