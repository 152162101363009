angular.module('cerberus.admin')
    .controller('MapConfigCtrl', function MapConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters,
                                                        DesignerUtilityService, PagesDesignerListService, pageObjectConfigService,
                                                        LeafletListService, ViewsService, vizListService, WidgetsService, $timeout, kendo) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters= filters;
        vm.views = [];
        vm.widgets = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.markerColors = PagesDesignerListService.markerColors();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];

        vm.viewColumns = [];
        vm.editView =  null;
        vm.editDataSource =  null;
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.filterType = null;
        vm.idArray = [];
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };
        vm.formFieldsArray = [];

        vm.basemaps = _.defaults(LeafletListService.basemaps(), LeafletListService.layers(false));
        vm.overlays = LeafletListService.layers();
        vm.usesUrl = LeafletListService.usesUrlLookUp();
        vm.layerType = LeafletListService.layerTypeLookUp();

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);

        vm.vizDict = vizListService.vizDictionary();

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/map/data-tab.tpl.html"},
            {name: "Record Actions", url: "admin/pages/designer/viz/map/records-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-multiple-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.colors = [
            {value: 'none', display: 'None'},
            {value: 'default', display: 'Default'},
            {value: 'red', display: 'Red'},
            {value: 'orange', display: 'Orange'},
            {value: 'yellow', display: 'Yellow'},
            {value: 'green', display: 'Green'},
            {value: 'blue', display: 'Blue'},
            {value: 'purple', display: 'Purple'}
        ];

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns, vm);
        vm.sortBasemapOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.basemaps);
        vm.sortOverlayOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.overlays);

        vm.percentSliderOptions = {
            min: 0,
            max: 1,
            smallStep: 0.01,
            largeStep: 0.1,
            tickPlacement: 'none',
            tooltip: {
                format: '{0:p0}'
            }
        };

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.labelToUniqueId = function (item){
            item.id = DesignerUtilityService.labelToUniqueId(item.label, vm.idArray);
        };

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;

        vm.toPercentageString = function(val){
            //return Math.round(val * 100) + '%';
            return kendo.format('{0:p0}', val);
        };

        // Map functions
        vm.addLayer = function(array){
            array.push({
                name: '',
                type: null,
                visible: false,
                settings: {
                    opacity: 1
                }
            });
        };

        vm.addGroup = function(groupColors){
            groupColors.push({
                group: null,
                color: null
            });
        };

        vm.checkBasemapVisibility = function(index, visible, basemaps){
            if(visible){
                for(var i = 0; i < basemaps.length; i++){
                    if(i !== index && basemaps[i]){
                        basemaps[i].visible = false;
                    }
                }
            }
        };

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);
        vm.setBaseWidget = setBaseWidget;
        vm.resetRecordFields = resetRecordFields;

        // Column selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        vm.addDataSource = function(dataSources, columns){
            var ids = Object.keys(dataSources);
            var max = 0;
            for(var i = 0; i < ids.length; i++){
                var id = parseInt(ids[i].replace(/^ds/, ''));
                if(id > max){
                    max = id;
                }
            }
            var newId = 'ds' + (max + 1);
            dataSources[newId] = {
                schema: {
                    model: {
                        fields: {
                            location: {from: '', type: ''},
                            title: {from: '', type: 'string'},
                            category: {from: '', type: 'string'}
                        }
                    }
                },
                aggregate: [],
                filter: {
                    filters: [],
                    logic: 'and'
                }
            };
            columns[newId] = [];
            vm.temp.filterMap[newId] = {};
            vm.temp.viz.settings.groupColors[newId] = [
                {
                    group: '_default',
                    color: 'cyan'
                }
            ];
        };
        // DataSource
        vm.setEditDataSource = function(id){
            vm.editDataSource = id;
            vm.editView =  vm.temp.viz.settings.dataSource[id].nim_viewId;
            vm.tempFilter = {};
            vm.filterType = null;
            vm.loadColumns(vm.editView);
        };

        vm.removeDataSource = function(id){
            if(vm.editDataSource === id){
                vm.editDataSource = null;
                vm.editView = null;
            }
            delete vm.temp.viz.settings.columns[id];
            delete vm.temp.viz.settings.dataSource[id];
            delete vm.temp.viz.settings.groupColors[id];
            delete vm.temp.filterMap[id];
        };

        vm.setLocationType = function(schemaFields){
            schemaFields.location.type = schemaFields[schemaFields.location.from].type;
        };

        //Init
        init();

        function init(){
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            vm.loadFormFields(pageObject.viz.widgetId);

            WidgetsService.getWidgets().then(function(widgets){
                vm.widgets = widgets;
            });

            for(var i = 0; i < filters.length; i++){
                if(filters[i].id){
                    vm.idArray.push(filters[i].id);
                }
            }

            _.defaults(vm.temp.params, {
                defaultValues: {},
                requiredFilters: {}
            });

            for(var j = 0; j < vm.temp.viz.settings.overlays.length; j++){
                var overlay = vm.temp.viz.settings.overlays[j];

                if(!overlay.hasOwnProperty('settings')){
                    overlay.settings = {opacity: 1};
                }
                else if(!overlay.settings.hasOwnProperty('opacity')){
                    overlay.settings.opacity = 1;
                }
            }

            _.forEach(vm.temp.viz.settings.dataSource, function(ds, id){
                vm.temp.filterMap[id] = vm.temp.filterMap[id] || {};
                vm.temp.params.requiredFilters[id] = vm.temp.params.requiredFilters[id] || {};
                pageObjectConfigService.updateSortConfig(ds);
            });

            vm.sortBySortableOptions = {
                ignore: "input,select",
                change: function (e) {
                    $timeout(function () {
                        pageObjectConfigService.reorderItem(vm.temp.viz.settings.dataSource[vm.editDataSource].sort, e.oldIndex, e.newIndex);
                    },0);
                }
            };
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;

            if(schemaFields[colField].type === 'location' && !schemaFields.location.from){ // Location
                schemaFields.location.from = colField;
            }
            else if(!schemaFields.title.from){ // Title
                schemaFields.title.from = colField;
            }
            else if(!schemaFields.category.from){ // Category
                schemaFields.category.from = colField;
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;

            // Location
            if(schemaFields.location.from === colField){
                delete schemaFields.location.from;
            }

            // Title
            if(schemaFields.title.from === colField){
                delete schemaFields.title.from;
            }

            // Category
            if(schemaFields.category.from === colField){
                delete schemaFields.category.from;
            }

            // Sort By
            _.remove(source.sort, 'field', colField);

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        // Map-specific implementation for setBaseWidget
        function setBaseWidget(){
            // Only automatically set base widget if not already set.
            // This is important since this page object has multiple data sources.
            if(!vm.temp.viz.widgetId) {
                var selectedView = _.find(vm.views, 'id', vm.editView);
                vm.temp.viz.widgetId = _.get(selectedView, 'base_widget_id');
                vm.loadFormFields = (vm.temp.viz.widgetId);
            }
        }

        function resetRecordFields(){
            vm.temp.params.defaultValues = {};
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;