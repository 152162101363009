angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name InstancesWindowService
     * @alias cerberus/core:InstancesWindowService
     * @description Provides functions for creating a window that contains an instance
     */
    .factory('InstancesWindowService', function InstancesWindowService(_, moment, kendo, $rootScope, $compile, $window, $timeout, $location, $log, $uibModal,
                                                                       CalculationService, ConfirmModalService, DesignerUtilityService,
                                                                       DeviceSniffService, InstancesService, nimCalcService) {
        // Cache Window Area
        var windowAreaSelector = '#nim-window-area';
        var windowArea = angular.element(windowAreaSelector);

        var service = {
            windowArea: windowArea,
            buildWindowOptions: buildWindowOptions,
            openWindow: checkOpenWindows,
            openRevision: openRevision,
            buildToolbar: buildToolbar,
            deleteInstanceModal: deleteInstanceModal,
            parseDefaultData: parseDefaultData,
            //parseFormulaFields: parseFormulaFields,
            populateDataAndParseFormulas: populateDataAndParseFormulas,
            addFormObjects: addFormObjects,
            openAdvSelPage: openAdvSelPage,
            pickShownFields: pickShownFields,
            openConfirmInstanceCloseModal: openConfirmInstanceCloseModal
        };
        return service;
        ////////////////////
        /**
         * Helper function for sizing windows
         * @returns {{top: number, left: number, width: number, device: number}}
         */
        function determineWindowPosition() {
            var deviceWidth = DeviceSniffService.deviceWidth();
            var existingWindows = windowArea.find('.k-window');
            var maxTop = 0, maxLeft = 0, top = 0, left = 0, width = 320;

            if(deviceWidth >= 1126) {
                width = 1024;
            }
            else if (deviceWidth >= 768 && deviceWidth < 1126) {
                width = deviceWidth - (20 * 2);
            }
            else if (deviceWidth < 768) {
                width = deviceWidth;
            }

            // Determine new Position
            for (var i = 0; i < existingWindows.length; i++) {
                var ele = angular.element(existingWindows[i]);
                maxTop = Math.max(30, ele.position().top);
                maxLeft = Math.max(0, ele.position().left);
            }
            top = maxTop + 30;
            left = width === deviceWidth ? 0 : (deviceWidth - width) / 2;

            return {
                top: top,
                left: left,
                width: width,
                device: deviceWidth
            };
        }
        /**
         * Builds Kendo Window Options with sensible defaults
         * @param {object} scope
         * @param {Function} [openCallback]
         * @param {Function} [closeCallback]
         * @returns {{appendTo: string, visible: boolean, actions: string[], autoFocus: boolean, maxWidth: number, minWidth: number, width: number, position: {top: number, left: number}, open: Function, close: Function, dragstart: Function, dragend: Function}}
         */
        function buildWindowOptions(scope, openCallback, closeCallback) {
            var pos = determineWindowPosition();
            return {
                appendTo: windowAreaSelector,
                visible: true,
                actions: [
                    'Minimize',
                    'Close'
                ],
                autoFocus: false,
                maxWidth: Math.max(pos.device, 1024),
                minWidth: Math.min(pos.device, 480),
                height: '80%',
                width: pos.width,
                position: {
                    top: pos.top,
                    left: pos.left
                },
                open: function () {
                    var othis = this;
                    // Add event Listener
                    angular.element($window).on('orientationchange', function () {
                        var pos = determineWindowPosition();
                        othis.setOptions({
                            width: pos.width,
                            maxWidth: Math.max(pos.device, 1024),
                            minWidth: Math.min(pos.device, 480)
                        });
                    });
                    if (angular.isFunction(openCallback)) {
                        openCallback();
                    }
                },
                close: function (e) {
                    var vm = scope.$eval('vm');

                    if (e.userTriggered && vm.activeState && !vm.isUnchanged()) {
                        e.preventDefault();
                        openConfirmInstanceCloseModal().then(function (result) {
                            if (result == 'submit') {
                                if (vm.isSubForm) {
                                    vm.subFormCreate(false);
                                }
                                else {
                                    var currentWorkflowObject = vm.instance.workflowObjects[vm.createState],
                                        nextWorkflowState = _.get(currentWorkflowObject, 'model.to[0]', '');
                                        
                                    vm.submitForm(vm.activeState, nextWorkflowState, 'submitAndClose');
                                }
                            }
                            else {
                                angular.element($window).off('orientationchange');
                                scope.$broadcast('nim-remove-form-objects');
                                scope.$destroy();
                                if (angular.isFunction(closeCallback)) {
                                    closeCallback();
                                }
                                e.sender.destroy();
                            }
                        });
                    }
                    else {
                        angular.element($window).off('orientationchange');
                        scope.$broadcast('nim-remove-form-objects');
                        scope.$destroy();
                        if (angular.isFunction(closeCallback)) {
                            closeCallback();
                        }
                        e.sender.destroy();
                    }
                },
                dragstart: function () {
                    this.element.parent().css('opacity', 0.7);
                    this.element.find('[nim-instance]').css('opacity', 0);
                },
                dragend: function () {
                    this.element.parent().css('opacity', 1);
                    this.element.find('[nim-instance]').css('opacity', 1);
                }
            };
        }
        /**
         * Intercepts call to InstancesWindowService.openWindow and checks for other
         * open windows for the same instance
         * @param {Object} options
         * @param {string} options.action
         * @param {string} options.widgetId
         * @param {string} options.instanceId
         * @param {string} options.title
         * @param {string} options.draftId
         * @param {string} options.revisionId
         * @param {Array}  options.defaultData
         * @param {Object} options.navFunctions
         */
        function checkOpenWindows(options) {
            var instanceId = _.get(options, 'instanceId'),
                action = _.get(options, 'action');

            if(!instanceId && (action == 'read' || action == 'readonly')){
                return;
            }

            var instanceOpen = false;
            if(instanceId && instanceId !== ''){
                // Checks if there are already windows open for this instance
                var len = $('#instance-' + instanceId).length;
                instanceOpen = len && len > 0;
            }

            if(instanceOpen){
                ConfirmModalService.showModal(null, {
                    headerText: 'Record Already Open',
                    bodyText: 'There is another window open for the selected record. Open new window anyway?',
                    actionButtonText: 'Open New Window',
                    closeButtonText: 'Cancel',
                    confirm: function () {
                        openWindow(options);
                    },
                    check: function () {
                        return true;
                    }
                });
            }
            else {
                openWindow(options);
            }
        }
        /**
         * Opens an instance in a window for creates, edits, etc.
         * @param {Object} options
         * @param {string} options.action
         * @param {string} options.widgetId
         * @param {string} options.instanceId
         * @param {string} options.title
         * @param {string} options.draftId
         * @param {string} options.revisionId
         * @param {Array}  options.defaultData
         * @param {Object} options.navFunctions
         */
        function openWindow(options) {
            var windowId = DesignerUtilityService.generateGuid();
            var windowScope = $rootScope.$new(true);

            var action = _.get(options, 'action', ''),
                widgetId = _.get(options, 'widgetId', ''),
                instance = _.get(options, 'instanceId', ''),
                draft = _.get(options, 'draftId', ''),
                revision = _.get(options, 'revisionId', ''),
                validateOnOpen = _.get(options, 'validateOnOpen', false) ? 'validate' : '';

            var html = '<div id="instance-' + instance + '" kendo-window="' + windowId + '" k-options="windowOptions" style="overflow:visible;">' +
                    '<div nim-instance class="nim-instance container-fluid" nim-action="' + action + '" nim-id="' + instance + '" nim-widget-id="' + widgetId + '" nim-window-id="' + windowId + '" nim-draft-id="' + draft + '" nim-revision-id="' + revision + '" nim-validate-on-open="' + validateOnOpen + '"></div>' +
                '</div>';

            // Populate new scope
            windowScope.defaultData = _.get(options, 'defaultData', []);
            windowScope.windowOptions = buildWindowOptions(windowScope);
            windowScope.windowId = windowId;
            windowScope.windowOptions.title = _.get(options, 'title', '');
            windowScope.navFunctions = _.get(options, 'navFunctions', {});

            // Create Window
            windowArea.append(html);
            $compile(windowArea.find('div[kendo-window="' + windowId + '"]'))(windowScope);
        }

        function openRevision(widgetId, instanceId, revisionId, title){
            openWindow({
                action: 'revision',
                title: title,
                widgetId: widgetId,
                instanceId: instanceId,
                revisionId: revisionId
            });
        }

        /**
         * Given a PageObject this function will build the appropriate
         * Kendo UI Toolbar options object
         * @param {object} scope - show only relevant buttons for an action
         * @param {object} controller
         * @return {object}
         */

        function buildToolbar(scope, controller) {
            scope._recurrenceScope = null;
            scope._recurrenceWindowOptions = {
                title: 'Repeat',
                modal: true,
                autoFocus: false,
                //width: 600,
                //position: {
                //    top: 80,
                //    left: 475
                //},
                open: function () {
                    var othis = this;
                    // Add event Listener
                    angular.element($window).on('orientationchange', function () {
                        var pos = determineWindowPosition();
                        if(pos.width > 600){
                            pos.left += (pos.width - 600) / 2;
                        }

                        othis.setOptions({
                            width: Math.min(pos.width, 600),
                            maxWidth: Math.max(pos.device, 1024),
                            minWidth: Math.min(pos.device, 480),
                            position: {
                                top: pos.top,
                                left: pos.left
                            }
                        });
                    });
                },
                close: function () {
                    angular.element($window).off('orientationchange');
                    this.destroy();
                    scope._recurrenceScope.$destroy();
                    scope._recurrenceScope = null;
                }
            };
            scope._openRecurrenceWindow = function () {
                if (!_.has(controller, 'instance.widgetSettings.recurrence.modelId')) { 
                    $log.warn('Recurrence field not defined');
                    return;
                }
                
                var pos = determineWindowPosition();
                if(pos.width > 600){
                    pos.left += (pos.width - 600) / 2;
                }

                _.assign(scope._recurrenceWindowOptions, {
                    maxWidth: Math.max(pos.device, 1024),
                    minWidth: Math.min(pos.device, 480),
                    width: Math.min(pos.width, 600),
                    position: {
                        top: pos.top,
                        left: pos.left
                    }
                });

                if (!controller.instance.recurrence) {
                    controller.instance.recurrence = null;
                }
                var html = '<div kendo-window="instanceRecurrence" k-options="_recurrenceWindowOptions">' +
                    '    <div class="instances-subwindow" nim-recurrence="$parent.vm.instance" nim-recurrence-id="$parent.vm.instanceId" start-date="$parent.vm.temp[1].' + controller.instance.widgetSettings.recurrence.modelId + '"></div>' +
                    '</div>';
                var newScope = scope.$new();
                windowArea.append(html);
                $compile(windowArea.find('div[kendo-window="instanceRecurrence"]'))(newScope);
                scope._recurrenceScope = newScope;
            };
            scope._relationsScope = null;
            scope._relationsWindowOptions = {
                title: 'Instance Relationships',
                modal: true,
                autoFocus: false,
                //width: 800,
                //position: {
                //    top: 80,
                //    left: 475
                //},
                open: function () {
                    var othis = this;
                    // Add event Listener
                    angular.element($window).on('orientationchange', function () {
                        var pos = determineWindowPosition();
                        if(pos.width > 800){
                            pos.left += (pos.width - 800) / 2;
                        }

                        othis.setOptions({
                            width: Math.min(pos.width, 800),
                            maxWidth: Math.max(pos.device, 1024),
                            minWidth: Math.min(pos.device, 480),
                            position: {
                                top: pos.top,
                                left: pos.left
                            }
                        });
                    });
                },
                close: function () {
                    angular.element($window).off('orientationchange');
                    this.destroy();
                    scope._relationsScope.$destroy();
                    scope._relationsScope = null;
                }
            };
            scope._openRelationshipWindow = function () {
                var pos = determineWindowPosition();
                if(pos.width > 800){
                    pos.left += (pos.width - 800) / 2;
                }

                _.assign(scope._relationsWindowOptions, {
                    maxWidth: Math.max(pos.device, 1024),
                    minWidth: Math.min(pos.device, 480),
                    width: Math.min(pos.width, 800),
                    position: {
                        top: pos.top,
                        left: pos.left
                    }
                });

                var html = '<div kendo-window="instanceRelationship" k-options="_relationsWindowOptions">' +
                    '    <div class="instances-subwindow" nim-relationship></div>' +
                    '</div>';
                var newScope = scope.$new();
                windowArea.append(html);
                $compile(windowArea.find('div[kendo-window="instanceRelationship"]'))(newScope);
                scope._relationsScope = newScope;
            };

            return {
                resizable: true,
                items: [
                    /* Save */
                    {
                        id: 'instance-toolbar-save',
                        type: 'button',
                        text: '<i class="fa fa-floppy-o"></i>',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.activeState && vm.action !== 'create' ? 'inline-block' : 'none'}",
                            title: 'Save'
                        },
                        click: function(){
                            if(controller.activeState){
                                $timeout(function(){
                                    if(controller.isSubForm){
                                        controller.subFormCreate(controller.action === 'create' && !controller.readingNewRecord);
                                    }
                                    else {
                                        var state = controller.activeState,
                                            workflowObject = _.get(controller.instance.workflowObjects, state),
                                            to = _.get(workflowObject, 'model.to', []),
                                            nextState = _.head(to),
                                            submitAction = _.get(workflowObject, 'model.defaultAction');

                                        if (!submitAction || controller.action === 'create') {
                                            submitAction = controller.instance.widgetSettings.defaultAction;
                                        }

                                        controller.submitForm(state, nextState, submitAction);
                                    }
                                });
                            }
                        }
                    },

                    /* Cancel */
                    // {
                    //     id: 'instance-toolbar-cancel',
                    //     type: 'button',
                    //     text: '<i class="fa fa-ban"></i>',
                    //     overflow: 'never',
                    //     attributes: {
                    //         'ng-style': "{'display': vm.activeState ? 'inline-block' : 'none'}",
                    //         title: 'Cancel'
                    //     },
                    //     click: function(){
                    //         $timeout(function(){
                    //             controller.cancel();
                    //         });
                    //     }
                    // },

                    /* Duplicate */
                    {
                        id: 'instance-toolbar-duplicate',
                        type: 'button',
                        text: '<i class="fa fa-files-o"></i>',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.action === 'read' && vm.instance.widgetSettings.enableDuplication && !vm.isSubForm ? 'inline-block' : 'none'}",
                            title: 'Duplicate'
                        },
                        click: function () {
                            if (!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                                $timeout(function () {
                                    controller.duplicateInstance();
                                });
                            }    
                        }
                    },
                    {
                        type: 'separator',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.activeState || !vm.instance.widgetSettings.enableDuplication ? 'none' : 'inline-block'}"
                        }
                    },

                    /* Delete */
                    {
                        id: 'instance-toolbar-delete',
                        type: 'button',
                        text: '<i class="fa fa-trash-o"></i>',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.activeState ? 'none' : 'inline-block'}",
                            title: 'Delete'
                        },
                        click: function(){
                            if (!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                                $timeout(function () {
                                    controller.deleteInstance();
                                });
                            }    
                        }
                    },
                    // {
                    //     type: 'separator',
                    //     overflow: 'never',
                    //     attributes: {
                    //         'ng-style': "{'display': vm.activeState ? 'none' : 'inline-block'}"
                    //     }
                    // },

                    /* PDF */
                    // {
                    //     id: 'instance-toolbar-pdf',
                    //     type: 'button',
                    //     overflow: 'never',
                    //     attributes: {
                    //         'ng-style': "{'display': vm.activeState ? 'none' : 'inline-block'}",
                    //         title: 'PDF'
                    //     },
                    //     text: "<i class=\"fa fa-file-pdf-o\"></i>",
                    //     click: function () {
                    //         if (!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                    //             $timeout(function () {
                    //                 controller.exportInstance();
                    //             });
                    //         }    
                    //     }
                    // },
                    {
                        type: 'separator',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.instance.widgetSettings.recurrence.modelId ? 'inline-block' : 'none'}"
                        }
                    },

                    /* Repeat */
                    {
                        id: 'instance-toolbar-repeat',
                        type: 'button',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.instance.widgetSettings.recurrence.modelId ? 'inline-block' : 'none'}",
                            title: 'Repeat'
                        },
                        text: "<i class=\"fa fa-calendar\"></i>",
                        click: function(){
                            if (!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                                $timeout(function () {
                                    scope._openRecurrenceWindow();
                                });
                            }    
                        }
                    },
                    {
                        type: 'separator',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': !vm.activeState && (navFunctions.getPrevious || navFunctions.getNext) ? 'inline-block' : 'none'}"
                        }
                    },

                    /* Previous */
                    {
                        id: 'instance-toolbar-previous',
                        type: 'button',
                        text: '<i class="fa fa-chevron-circle-left"></i>',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': navFunctions.getPrevious && !vm.activeState ? 'inline-block' : 'none'}",
                            title: 'Previous'
                        },
                        click: function () {
                            if(!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                                $timeout(function () {
                                    if (_.isFunction(scope.navFunctions.getPrevious)) {
                                        scope.navFunctions.getPrevious(controller);
                                    }    
                                });
                            }
                        }
                    },

                    /* Next */
                    {
                        id: 'instance-toolbar-next',
                        type: 'button',
                        text: '<i class="fa fa-chevron-circle-right"></i>',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': navFunctions.getNext && !vm.activeState ? 'inline-block' : 'none'}",
                            title: 'Next'
                        },
                        click: function () {
                            if(!_.isEmpty(controller.dataLoading) && !_.some(controller.dataLoading)) {
                                $timeout(function() {
                                    if (_.isFunction(scope.navFunctions.getNext)) {
                                        scope.navFunctions.getNext(controller);
                                    }    
                                });
                            }
                        }
                    },
                    {
                        type: 'separator',
                        overflow: 'never',
                        attributes: {
                            'ng-style': "{'display': vm.action === 'create' ? 'none' : 'inline-block'}"
                        }
                    },
                    /* FILE */
                    {
                        overflow: 'auto',
                        template: '<div class="btn-group" uib-dropdown dropdown-append-to="\'div[kendo-window=' + controller.windowId + ']\'">' +
                        '<a id="instance-toolbar-file" href="" class="k-button" uib-dropdown-toggle>File</a>' +
                        '<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="instance-toolbar-file">' +
                        '<li role="menuitem" ng-if="!vm.isSubForm"><a href="" ng-click="vm.newInstance()">New</a></li>' +
                        '<li role="menuitem" ng-if="vm.action !== \'create\' && vm.instance.widgetSettings.enableDuplication && !vm.isSubForm"><a href="" ng-click="vm.duplicateInstance()">Duplicate</a></li>' +
                        '<li class="divider" ng-if="vm.activeState && (!vm.isSubForm || vm.action !== \'create\')"></li>' +
                        '<li role="menuitem" ng-if="vm.activeState"><a href="" ng-click="vm.cancel()">Cancel</a></li>' +
                        '<li class="divider" ng-if="vm.action === \'create\' && !vm.isSubForm"></li>' +
                        '<li role="menuitem" ng-if="vm.action === \'create\' && !vm.isSubForm"><a href="" ng-click="vm.saveDraft(vm.activeState)">Save Draft</a></li>' +
                        '<li class="divider" ng-if="vm.activeState"></li>' +
                        '<li role="menuitem" ng-if="vm.activeState && !vm.isSubForm">' +
                        '<a href="" ng-click="vm.submitForm(vm.activeState, vm.instance.workflowObjects[vm.createState].model.to[0], \'submit\')">Submit</a>' +
                        '</li>' +
                        '<li role="menuitem" ng-if="vm.activeState && !vm.isSubForm">' +
                        '<a href="" ng-click="vm.submitForm(vm.activeState, vm.instance.workflowObjects[vm.createState].model.to[0], \'submitAndClose\')">Submit & Close</a>' +
                        '</li>' +
                        '<li role="menuitem" ng-if="vm.activeState && vm.action === \'create\' && !vm.isSubForm">' +
                        '<a href="" ng-click="vm.submitForm(vm.activeState, vm.instance.workflowObjects[vm.createState].model.to[0], \'submitAndNew\')">Submit & New</a>' +
                        '</li>' +
                        '<li role="menuitem" ng-if="vm.activeState && vm.isSubForm && vm.action === \'create\' && !vm.readingNewRecord">' +
                        '<a href="" ng-click="vm.subFormCreate(true)">Save & New</a>' +
                        '</li>' +
                        '<li role="menuitem" ng-if="vm.activeState && vm.isSubForm">' +
                        '<a href="" ng-click="vm.subFormCreate(false)">Save & Close</a>' +
                        '</li>' +
                        '<li class="divider" ng-if="vm.action !== \'create\'"></li>' +
                        '<li role="menuitem" ng-if="vm.action !== \'create\'"><a href="" ng-click="vm.deleteInstance()">Delete</a></li>' +
                        '</ul>' +
                        '</div>'
                    },

                    /* PRINT */
                    {
                        overflow: 'auto',
                        template: '<div class="btn-group" uib-dropdown dropdown-append-to="\'div[kendo-window=' + controller.windowId + ']\'">' +
                        '<a id="instance-toolbar-print" href="" class="k-button" uib-dropdown-toggle ng-if="!vm.activeState">Print</a>' +
                        '<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="instance-toolbar-print">' +
                        '<li role="menuitem"><a href="" ng-click="vm.exportInstance()">Normal</a></li>' +
                        '<li class="divider"></li>' +
                        '<li role="menuitem"><a href="" ng-click="vm.printInstanceLabels()">Labels</a></li>' +
                        '<li role="menuitem"><a href="" ng-click="vm.printInstanceChecks()">Checks</a></li>' +
                        // '<li class="divider"></li>' +
                        // '<li role="menuitem"><a href="" ng-click="vm.exportInstance(\'png\')">Export PNG</a></li>' +
                        // '<li role="menuitem"><a href="" ng-click="vm.exportInstance(\'svg\')">Export SVG</a></li>' +
                        '</ul>' +
                        '</div>'
                    },

                    /* ACTIONS */
                    {
                        overflow: 'auto',
                        template: '<div class="btn-group" uib-dropdown dropdown-append-to="\'div[kendo-window=' + controller.windowId + ']\'">' +
                        '<a id="instance-toolbar-actions" href="" class="k-button" uib-dropdown-toggle>Actions</a>' +
                        '<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="instance-toolbar-actions">' +
                        '<li class="divider" ng-if="vm.action !== \'create\'"></li>' +
                        '<li role="menuitem" ng-if="navFunctions.getPrevious && vm.dataSetLoaded">' +
                        '<a href="" ng-click="navFunctions.getPrevious(vm)">Previous</a>' +
                        '</li>' +
                        '<li role="menuitem" ng-if="navFunctions.getNext && vm.dataSetLoaded">' +
                        '<a href="" ng-click="navFunctions.getNext(vm)">Next</a>' +
                        '</li>' +
                        '<li class="divider" ng-if="(navFunctions.getNext || navFunctions.getPrevious) && vm.dataSetLoaded"></li>' +
                        '<li role="menuitem"><a href="" ng-click="vm.expandAll()">Expand All</a></li>' +
                        '<li role="menuitem"><a href="" ng-click="vm.collapseAll()">Collapse All</a></li>' +
                        '<li class="divider" ng-if="vm.instance.widgetSettings.recurrence.modelId"></li>' +
                        '<li role="menuitem" ng-if="vm.instance.widgetSettings.recurrence.modelId">' +
                        '<a href="" ng-click="_openRecurrenceWindow()">Repeat</a>' +
                        '</li>' +
                        '<li class="divider" ng-if="\'vm.instance.widgetSettings.enableInstanceRelationship === true\'"></li>' +
                        '<li role="menuitem" ng-if="\'vm.instance.widgetSettings.enableInstanceRelationship === true\'">' +
                        '<a href="" ng-click="_openRelationshipWindow()">Relate</a>' +
                        '</li>' +
                        '</ul>' +
                        '</div>'
                    },

                    /* REVISIONS */
                    {
                        overflow: 'auto',
                        template: '<div class="btn-group" uib-dropdown dropdown-append-to="\'div[kendo-window=' + controller.windowId + ']\'" ng-if="vm.action !== \'create\'">' +
                        '<a id="instance-toolbar-revisions" href="" class="k-button" uib-dropdown-toggle>Revisions: {{vm.instance.recordRevision.length || 0}}</a>' +
                        '<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="instance-toolbar-revisions">' +
                        '<li role="menuitem" ng-repeat="rev in vm.instance.recordRevision | orderBy: \'createDate\'">' +
                        '<a href="" ng-click="vm.revisionOnClick(rev)">{{::(rev.createDate | kendoFormat:\'{0:MM/dd/yyyy h:mm tt}\')}} - {{::rev.createBy}}</a>' +
                        '</li>' +
                        '</ul>' +
                        '</div>'
                    },

                    /* PARTICIPANTS */
                    {
                        overflow: 'auto',
                        template: '<div class="btn-group" uib-dropdown dropdown-append-to="\'div[kendo-window=' + controller.windowId + ']\'" ng-if="vm.action !== \'create\'">' +
                        '<a id="instance-toolbar-participants" href="" class="k-button" uib-dropdown-toggle>Participants: {{vm.participants.length || 0}}</a>' +
                        '<ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="instance-toolbar-participants">' +
                        '<li role="menuitem" ng-repeat="p in vm.participants">' +
                        '<a href="" ng-click="$event.preventDefault()">{{::p.name}}</a>' +
                        '</li>' +
                        '</ul>' +
                        '</div>'
                    }
                ]
            };
        }

        function deleteInstanceModal(idArray, closeWindowCallback){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: '<strong>Are you sure you want to delete the record(s) FOREVER?</strong>',
                actionButtonText: 'Delete ' + idArray.length + ' record(s)',
                closeButtonText: 'Cancel',
                confirm: function () {
                    InstancesService.hardDelete(idArray).then(function () {
                        $timeout(function(){
                            closeWindowCallback();
                        });
                    });
                },
                check: function () {
                    return true;
                }
            });
        }

        /**
         * Joins old populateFormData and parseFormulaFields functions
         * Populates dataSet and fieldOptions with existing data and sets up calculations
         * @param {string} state - workflow object id (key)
         * @param {array} formsArray - workflowObject.model.forms
         * @param {object} dataSet
         * @param {object} fieldOptions
         * @param {function} calculationWatcherFunc - Creates watcher for calculated fields
         * @param {function} sectionWatcherFunc - Creates watcher for section conditions
         * @param {function} fieldConditionWatcherFunc - Creates watcher for form object conditions
         * @param {function} autoFillWatcherFunc - Creates watcher for auto-populating fields
         * @returns {Function} - Call this when ready to create watchers
         */
        function populateDataAndParseFormulas(state, formsArray, dataSet, fieldOptions, calculationWatcherFunc, sectionWatcherFunc, fieldConditionWatcherFunc, autoFillWatcherFunc){
            var data = {},
                options = {},
                createWatchers = [];

            _.forEach(formsArray, function (form) {
                var formOriginId = form.originatorId;

                // Set data from existing data (supplied by server; empty if creating)
                _.assign(data, form.defaultDataSet);

                // Set fieldOptions (for dropdown)
                if(form.fieldOptions){
                    _.assign(options, form.fieldOptions);
                }

                _.forEach(form.form.sections, function (section) {
                    createWatchers.push(
                        _.partial(sectionWatcherFunc, state, formOriginId, section)
                    );
                });

                //Parse Formulas
                _.forEach(form.form.objects, function (formObj) {
                    if(formObj.model.config.type === 'datetime'){
                        data[formObj.model.config.modelId] = new Date(data[formObj.model.config.modelId]);
                    }

                    if(formObj.model.option.type !== 'custom' && !_.isEmpty(formObj.model.option.autoFill)){
                        createWatchers.push(
                            _.partial(autoFillWatcherFunc, state, formObj.model.config.modelId, formObj.model.param.filter, formObj.model.option)
                        );
                    }

                    if(formObj.model.calculation.formula){
                        var resultObj = {
                            id: formOriginId,
                            model: formObj.model.config.modelId,
                            section: formObj.model.display.section,
                            condition: formObj.model.param.condition,
                            conditionalAction: formObj.model.param.conditionalAction,
                            conditionalLogic: formObj.model.param.conditionalLogic
                        };

                        if(formObj.model.config.type === 'readonly'){
                            var val = CalculationService.calculate(formObj.model.calculation.tokens).value;
                            data[resultObj.model] = CalculationService.toDate(val);
                        }
                        else {
                            var tokenArr = nimCalcService.parse(formObj.model.calculation.formula).items,
                                operands = [];

                            _.forEach(tokenArr, function (token, i) {
                                if (token.type === 'operand' && token.subtype === 'range' && token.value) {
                                    var operand = token.value,
                                        pathComponents = operand.split('.'),
                                        modelId = _.head(pathComponents),
                                        tokValArr = modelId.split('_');

                                    token.value = '';

                                    pathComponents[0] = _.last(tokValArr);

                                    var operandModelId = pathComponents.join('.');

                                    operands.push({
                                        index: i,
                                        modelId: _.trim(operandModelId)
                                    });
                                }
                             });

                            if(operands.length > 0){
                                createWatchers.push(
                                    _.partial(calculationWatcherFunc, state, resultObj, operands, tokenArr)
                                );
                            }
                            else {
                                // If there are no fields in the formula, add the calculated value to the dataSet
                                createWatchers.push(
                                    _.partial(pseudoWatcher, data, resultObj.model, tokenArr)
                                );
                            }
                        }
                    }

                    if(!formObj.model.config.modelId){
                        var guid = DesignerUtilityService.generateGuid();
                        formObj.model.config.modelId = kendo.format('__nim_static_text_{0}', guid);
                    }

                    createWatchers.push(
                        _.partial(fieldConditionWatcherFunc, state, formOriginId, formObj.model)
                    );
                });
            });

            if(!fieldOptions[state]){
                fieldOptions[state] = {};
            }

            _.assign(fieldOptions[state], options);

            if(!dataSet[state]){
                dataSet[state] = {};
            }

            _.defaultsDeep(dataSet[state], data);

            // Returns function to set watchers so they don't get set before the dataSet is ready.
            return function(){
                return _.map(createWatchers, function(fn){
                    return fn();
                });
            };
        }

        function pseudoWatcher(dataSet, modelId, tokenArray){
            var value = dataSet[modelId];

            if(_.isNull(value) || value === ''){
                var result = CalculationService.calculate(tokenArray);
                if(result.type !== 'error') {
                    dataSet[modelId] = result.value;
                }
            }

            return angular.noop;
        }

        /**
         * addFormObjects
         * @param instanceFormArray
         * @param formData
         */
        function addFormObjects(instanceFormArray, formData){
            for(var i = 0; i < instanceFormArray.length; i++){
                instanceFormArray[i] = angular.extend(instanceFormArray[i], formData[i]);
            }
        }

        /**
         * parseDefaultData
         * @param workflowObjects
         * @param state
         * @param instanceWorkflowData
         */
        function parseDefaultData(workflowObjects,state,instanceWorkflowData){
            if(workflowObjects[state].model.defaultData){
                for (var sId in workflowObjects[state].model.defaultData) {
                    for (var f in workflowObjects[sId].model.forms) {
                        if(workflowObjects[state].display.order == "-1"){
                            for(var f2 in workflowObjects[state].model.forms){
                                if(f.originatorId == f2.originatorId){
                                    f2.defaultDataSet = angular.copy(f.defaultDataSet);
                                }
                            }
                        }
                        else{
                            for(var d in instanceWorkflowData[sId].defaultData){
                                f.defaultDataset[d] = instanceWorkflowData[sId].defaultData[d];
                            }
                        }

                    }
                }
            }
        }

        function openAdvSelPage(scope, /*tableData,*/ cb) {
            var advSelConfig = scope.nimFormObject.param.advSel;
            var html = '<div kendo-window="adv_sel_window" k-options="advSelWindowOptions">' +
                '<div nim-advanced-selection nim-page-id="' + advSelConfig.pageId + '" nim-page-object-id="' + advSelConfig.pageObjectId + '"></div>' +
                '</div>';
            var windowScope = scope.$new();
            windowScope.advSelWindowOptions = service.buildWindowOptions(windowScope, null, function () {
                if (windowScope.selectedData && angular.isFunction(cb)) {
                    cb(windowScope.selectedData);
                    }
            });

            windowScope.advSelWindowOptions.title = scope.nimFormObject.label.text;
            windowScope.advSelWindowOptions.actions = ['Close'];
            windowScope.advSelWindowOptions.autoFocus = true;
            windowScope.advSelWindowOptions.dragstart = function (e) {
                e.sender.element.parent().css('opacity', 0.7);
                e.sender.element.find('[nim-advanced-selection]').css('opacity', 0);
            };
            windowScope.advSelWindowOptions.dragend = function (e) {
                e.sender.element.parent().css('opacity', 1);
                e.sender.element.find('[nim-advanced-selection]').css('opacity', 1);
            };

            var originalCloseHandler = windowScope.advSelWindowOptions.close || angular.noop;
            windowScope.advSelWindowOptions.close = function (e) {
                windowScope.$broadcast('nim-remove-page-objects');

                // Call original handler
                originalCloseHandler(e);
            };

            windowScope.advSelConfig = advSelConfig;

            if (scope.nimFormObject.config.type === 'table'){
                var idField = '';

                if (advSelConfig.baseLookup) {
                    idField = advSelConfig.baseLookup.replace(/_display$/, '.id').replace(/^__/, '');
                }

                windowScope.columns = angular.copy(scope.nimFormObject.tag.inner);
                // windowScope.defaultRows = _.reject(tableData, '__nimDelete');
                // windowScope.defaultData = _.map(windowScope.defaultRows, function (tableItem) {
                //     if (idField) {
                //         return _.get(tableItem.__nimData, idField);
                //     }

                //     return tableItem.__instanceId || tableItem.id;
                // });
            }
            else {
                windowScope.columns = [{field: 'display', title: scope.nimFormObject.label.text, type: 'string'}];
                // windowScope.defaultRows = [];
                // windowScope.defaultData = [];
                
                // if (!_.isEmpty(tableData)) {
                //     windowScope.defaultRows = [angular.copy(tableData)];
                //     windowScope.defaultData = [tableData.id];
                // }
            }

            // Open Window
            service.windowArea.append(html);
            $compile(service.windowArea.find('div[kendo-window="adv_sel_window"]'))(windowScope);
        }

        function pickShownFields(dataSet, origDataSet, forms, sectionsShown, fieldsShown){
            var fields = ['nimupload'];
            // var removedFields = [],
            //     removedDataSeries = [];

            _.forEach(forms, function(f){
                var formSectionsShown = sectionsShown[f.originatorId];

                _.forEach(f.form.objects, function(formObj){
                    var modelId = formObj.model.config.modelId;

                    if(!modelId || fields.indexOf(modelId) >= 0){
                        return;
                    }

                    var section = formObj.model.display.section,
                        sectionShown = formSectionsShown[section];

                    if(!section || section === '__nimHiddenSection'){
                        sectionShown = true;
                    }

                    if(fieldsShown[modelId] && sectionShown){
                        fields.push(modelId);
                        // _.remove(removedFields, modelId);
                        // _.remove(removedDataSeries, modelId);

                        if (formObj.model.config.type === 'multiselect') {
                            var origValues = _.get(origDataSet, modelId, []),
                                newValues = _.get(dataSet, modelId, []);
                            _.forEach(origValues, function (item) {
                                var origInstanceId = item.__instanceId,
                                    origId = _.get(item, [formObj.model.param.lookup, 'id']);
                                var newItem = _.find(newValues, function (n) {
                                    return n.__instanceId == origInstanceId || n.id == origId;
                                });
                                if (!newItem) {
                                    newValues.push({
                                        __instanceId: item.__instanceId,
                                        __nimDelete: true
                                    });
                                }
                            });
                        }
                    }
                    // else if(formObj.model.config.dataType === 'dataseries') {
                    //     removedDataSeries.push(modelId);
                    // }
                    // else {
                    //     removedFields.push(modelId);
                    // }
                });
            });

            var finalDataSet = _.pick(dataSet, fields);

            // _.forEach(removedDataSeries, function (modelId) {
            //     var existingRows = _.filter(dataSet[modelId], '__instanceId');
            //     finalDataSet[modelId] = _.map(existingRows, function (row) { 
            //         row.__nimDelete = true;
            //         return row;
            //     });
            // });

            // _.forEach(removedFields, function (modelId) { 
            //     finalDataSet[modelId] = '';
            // });

            return finalDataSet;
        }

        function openConfirmInstanceCloseModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'core/instances/instances-confirm-close-modal.tpl.html',
                controller: 'InstanceConfirmCloseCtrl',
                controllerAs: 'vm',
                backdropClass: 'confirm',
                windowClass: 'modal-confirm',
                backdrop: 'static'
            });

            return modalInstance.result;
        }
    })
;
