angular.module('cerberus.les')
    .controller('LESLoanApplicationFindProductsModalCtrl', function (_, $scope, $uibModalInstance, queryParams, validation, lesLoanApplicationsService) {
        var vm = this,
            allowNavigation = angular.noop,
            defaultParams = {
                arm: true,
                fixed: true,
                interestOnly: false,
                noPrepaymentPenalty: true,
                '12monthPrepaymentPenalty': false,
                '24monthPrepaymentPenalty': false,
                '36monthPrepaymentPenalty': false,
                fullDocumentation: false,
                '12monthBankStatements': false,
                '24monthBankStatements': false,
                assetUtilization: false,
                debtServiceCoverage: false
            };

        vm.queryParams = {};
        vm.isValid = false;
        vm.messages = {};

        vm.ok = ok;
        vm.cancel = cancel;

        init();
        
        function init() {
            vm.queryParams = _.defaults({}, queryParams, defaultParams);
            vm.isValid = validation.isValid;
            vm.messages = _.groupBy(validation.messages, 'type');
        }

        function ok() {
            var result = lesLoanApplicationsService.buildProductsQueryParams(vm.queryParams);
            allowNavigation();
            $uibModalInstance.close(result);
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Prevents navigation while modal is open
        allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;