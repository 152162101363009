angular.module('cerberus.admin')
    .controller('WidgetsCreateCtrl', function WidgetsCreateCtrl(_, $location, WidgetsService, WidgetsSettingsService, DesignerUtilityService){
        var vm = this;
        vm.views = [];
        vm.widgetData = {};
        vm.widgetMaster = {};
        vm.idArray = [];
        vm.isUnchanged = function(data){
            return angular.equals(data, vm.widgetMaster);
        };
        vm.save = function(data){
            WidgetsSettingsService.addWidget(data).then(function(id){
                $location.replace().url('/settings/widgets/' + id);
            });
        };
        vm.cancel = function(){
            $location.replace().url('/settings/widgets');
        };

        vm.preventSpace = DesignerUtilityService.preventSpace;
        vm.labelToUniqueId = function (widget){
            widget.uniqueName = DesignerUtilityService.labelToUniqueId(widget.name, vm.idArray);
        };

        init();

        function init(){
            WidgetsService.getWidgets().then(function(widgets){
                vm.idArray = _.pluck(widgets, 'uniqueName');
            });

            vm.widgetData = {
                icon: 'puzzle-piece'
            };
            vm.widgetMaster = angular.copy(vm.widgetData);
        }
    })
;