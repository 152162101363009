angular.module('cerberus.admin')
    .controller('WorkspacesPermissionsCtrl', function WorkspacesPermissionsCtrl(_, $location, $uibModal, statusList, currentUserId, workspaceId,
                                                                                ConfirmModalService, WorkspacesGroupsService, WorkspacesUserService){
        var vm = this;

        vm.userEmails = null;
        vm.organizationUsers = null;
        vm.statusList = statusList;
        vm.currentUserId = currentUserId;
        vm.usersArray = [];
        vm.selectedUsers = {
            checked: false,
            users:{}
        };
        vm.userEmailsBatch = null;
        vm.groups = [];
        vm.records = { 'checked': false, items: {} };
        vm.workspaceId = workspaceId;

        // Users Functions
        vm.openBatchUserModal = openBatchUserModal;
        vm.removeUser = removeUser;
        vm.enableUsers = enableUsers;
        vm.disableUsers = disableUsers;
        vm.addUsers = addUsers;
        vm.openUserDetails = openUserDetails;

        // Groups Functions
        vm.openGroup = openGroup;
        vm.deleteGroup = deleteGroup;

        init();

        function init(){
            WorkspacesUserService.getUsers().then(function(data){
                vm.usersArray = data;
            });
            WorkspacesUserService.getOrgUserList().then(function(data){
                vm.organizationUsers = data;
            });
            WorkspacesGroupsService.getGroups(workspaceId).then(function(data){
                vm.groups = data;
            });
        }

        // Users Functions
        function parseSelected(sel){
            var idArray = [];
            angular.forEach(sel,function(v,k){
                if(v){
                    idArray.push(k);
                }
            });
            return idArray;
        }

        function openBatchUserModal(emails){
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/workspaces/users-batch-add-modal.tpl.html',
                controller: 'WorkspacesUsersBatchAddModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    emailList: function () {
                        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        var d = {
                            existUser: [],
                            newUser: [],
                            invalidUser: []
                        };
                        var emailArr = emails.split(',');
                        for(var i = 0; i < emailArr.length; i++){
                            var userIsInWorkspace = false;
                            for(var j = 0; j < vm.usersArray; j++){
                                if(emailArr[i] === vm.usersArray[j].email){
                                    userIsInWorkspace = true;
                                }
                            }
                            if(!userIsInWorkspace){
                                if(!re.test(emailArr[i])){
                                    d.invalidUser.push(emailArr[i]);
                                }
                                else{
                                    if(vm.organizationUsers.indexOf(emailArr[i]) > -1){
                                        d.existUser.push(emailArr[i]);
                                    }
                                    else{
                                        d.newUser.push(emailArr[i]);
                                    }
                                }
                            }
                        }
                        return d;
                    }
                }
            });
            modalInstance.result.then(function () {
                WorkspacesUserService.addUsers({userEmails:emails}).then(function(){
                    init();
                });
            });
        }

        function removeUser(data){
            var users = parseSelected(data.users);
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to remove these users from the current workspace?',
                actionButtonText: 'Remove',
                closeButtonText: 'Cancel',
                check: function(){
                    return true;
                },
                confirm: function(){
                    return WorkspacesUserService.removeUsers(users);
                },
                callback: function(){
                    init();
                }
            });
        }

        function enableUsers(data){
            var users = parseSelected(data.users);
            WorkspacesUserService.enableUsers({userIds:users}).then(function(){
                init();
            });
        }

        function disableUsers(data){
            var users = parseSelected(data.users);
            WorkspacesUserService.disableUsers({userIds:users}).then(function(){
                init();
            });
        }

        function addUsers(data){
            WorkspacesUserService.addUsers({userEmails:data}).then(function(){
                init();
            });
        }

        function openUserDetails(id){
            $location.url('/settings/users/' + id);
        }

        //Groups Functions
        function openGroup(id){
            if(id){
                $location.url('settings/groups/' + id);
            }
            else {
                $location.url('settings/groups/create');
            }
        }

        function deleteGroup(){
            var idArray = _.keys(vm.records.items);
            if(idArray.length > 0){
                WorkspacesGroupsService.deleteGroups(idArray).then(init);
            }
        }
    })
;