angular.module('cerberus.admin')
    .controller('WorkspacesGroupsDetailsCtrl', function WorkspacesGroupsDetailsCtrl(kendo, $location, groupId, workspaceId, WorkspacesGroupsService) {
        var vm = this;
        vm.groupId = groupId;
        vm.tempGroupModel = {
            details: {
                adv:{
                    ip:[]
                }
            },
            members: {
                current: [],
                add: [],
                remove: [],
                input: []
            },
            memberOf:{current:null}
        };
        vm.origGroupModel = angular.copy(vm.tempGroupModel);
        vm.selectedMembers = {};
        vm.availableMembersDs = null;
        vm.membersSelectOptions = {
            placeholder: 'Enter users/groups here...',
            dataTextField: 'name'
        };

        vm.add = WorkspacesGroupsService.addItem;
        vm.remove = WorkspacesGroupsService.removeItem;
        vm.addIp = WorkspacesGroupsService.addIp;
        vm.removeIp = WorkspacesGroupsService.removeIp;
        vm.isUnchanged = function(){
            return WorkspacesGroupsService.isUnchanged(vm.tempGroupModel,vm.origGroupModel);
        };
        vm.submit = function(data){
            if(data.details.name){
                WorkspacesGroupsService.updateGroup(groupId, data, vm.origGroupModel).then(function(){
                    $location.url('/settings/permissions');
                });
            }
        };

        vm.cancel = function(){
            $location.url('/settings/permissions');
        };

        init();

        function init(){
            WorkspacesGroupsService.getGroupDetails(vm.groupId).then(function(data){
                vm.tempGroupModel.details = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            WorkspacesGroupsService.getMembers(vm.groupId).then(function(data){
                vm.tempGroupModel.members.current = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            WorkspacesGroupsService.getAvailableMembers(workspaceId,vm.groupId).then(function(data){
                vm.availableMembersDs = new kendo.data.ObservableArray(data);
            });
        }
    })
;