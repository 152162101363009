angular.module('cerberus.ui')
    .directive('nimSignaturePad', function(SignaturePad, $timeout, $window) {
        return {
            restrict: 'A',
            require: '^ngModel',
            template: '<canvas></canvas>',
            link: function(scope, $element, attrs, ngModel) {
                // Cache Elements
                var canvas = $element[0].querySelector('canvas');
                var penColor = attrs.penColor || 'black';

                // Handle Resize Events (with an iron hammer)
                scope.$watch(function() {
                    return $element.width();
                }, function(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        resizeCanvas();
                    }
                });

                // Create Signature Pad: Expects an element (not a jQuery reference)
                var signaturePad = new SignaturePad(canvas, {
                    minWidth: 0.5,
                    maxWidth: 3,
                    penColor: penColor
                });

                // Expose Signature Pad
                if (attrs.nimSignaturePad) {
                    scope[attrs.nimSignaturePad] = signaturePad;
                }

                // TODO: Document what this does; I think it renders a picture...
                ngModel.$render = function() {
                    if(ngModel.$viewValue) {
                        signaturePad.fromDataURL(ngModel.$viewValue);
                    }
                    else {
                        // If parent controller clears ngModel, clear canvas
                        signaturePad.clear();
                    }
                };

                // Watch for changes -- especially useful if signature is cleared
                scope.$watch(function() {
                    return signaturePad.toDataURL();
                }, function() {
                    read();
                });



                // Get the signature pad value every stroke end
                signaturePad.onEnd = function() {
                    scope.$evalAsync(read);
                };

                scope.$on('$destroy', function () {
                    signaturePad.off();
                });

                // Init
                read();
                resizeCanvas();
                ////////////////////
                /**
                 * Read dataURL from canvas and populate ngModel
                 */
                function read() {
                    var dataUrl = signaturePad.toDataURL();
                    ngModel.$setViewValue(dataUrl);
                }

                /**
                 * Resize Function
                 * Adjust canvas coordinate space taking into account pixel ratio,
                 * to make it look crisp on mobile devices.
                 * This also causes canvas to be cleared.
                 */
                function resizeCanvas() {
                    // When zoomed out to less than 100%, for some very strange reason,
                    // some browsers report devicePixelRatio as less than 1
                    // and only part of the canvas is cleared then.
                    var ratio =  Math.max($window.devicePixelRatio || 1, 1);
                    canvas.width = canvas.offsetWidth * ratio;
                    canvas.height = canvas.offsetHeight * ratio;
                    canvas.getContext("2d").scale(ratio, ratio);
                }
            }
        };
    })
;