angular.module('cerberus.core')
    .controller('NotificationsCtrl', function (_, $scope, NotificationsService) {
        var vm = this,
            pageSize = 5;
            // pageSize = 20;

        vm.notifications = [];
        vm.notificationsBeingMarkedAsRead = {};
        vm.markingAsRead = false;
        vm.markingAllAsRead = false;
        vm.loadingNotifications = false;
        vm.allNotificationsLoaded = false;

        vm.markAsRead = markAsRead;
        vm.markAllAsRead = markAllAsRead;
        vm.loadNextPage = loadNextPage;

        init();

        function init() {
            loadNextPage();
        }

        function markAsRead(notification) {
            if (!notification.isRead && !vm.notificationsBeingMarkedAsRead[notification.id]) {
                updateMarkingAsRead(notification.id, true);

                NotificationsService.markAsRead(notification.id).then(function () {
                    updateMarkingAsRead(notification.id, false);
                    notification.isRead = true;
                    vm.totalUnread--;
                }, function () {
                    updateMarkingAsRead(notification.id, false);
                });
            }
        }

        function markAllAsRead(notifications) {
            var unreadNotifications = _.reject(notifications, 'isRead');
            if (unreadNotifications.length) {
                vm.markingAllAsRead = true;
                NotificationsService.markAllAsRead(_.pluck(unreadNotifications, 'id')).then(function () {
                    vm.markingAllAsRead = false;
                    // refresh();
                    _.forEach(unreadNotifications, function (n){
                        n.isRead = true;
                        vm.totalUnread--;
                    });
                });
            }
        }

        function updateMarkingAsRead(id, value) {
            vm.notificationsBeingMarkedAsRead[id] = value;
            vm.markingAsRead = _.some(vm.notificationsBeingMarkedAsRead);
        }
        
        function loadNextPage() {
            vm.loadingNotifications = true;
            NotificationsService.getNotifications(pageSize, vm.notifications.length).then(function (result) {
                vm.loadingNotifications = false;
                vm.notifications = vm.notifications.concat(result.notifications);
                vm.totalUnread = result.totalUnread;

                if (result.notifications.length < pageSize) {
                    vm.allNotificationsLoaded = true;
                }
            });
        }
    })
;