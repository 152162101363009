angular.module('cerberus.account')
    .controller('UsersMailServersCtrl', function UsersMailServersCtrl($scope, $location, ConfirmModalService, usersProfileService) {
        $scope.activeRoute = 'mail-servers';
        $scope.servers = [];

        $scope.openMailServer = _openMailServer;
        $scope.deleteMailServer = _deleteMailServer;

        _init();

        function _init(){
            usersProfileService.getAll('Mail Server').then(function(userProfile){
                $scope.servers = userProfile;
            });
        }

        function _openMailServer(id){
            if(id){
                $location.url('/users/settings/mail-servers/' + id);
            }
            else {
                $location.url('/users/settings/mail-servers/create');
            }
        }

        function _deleteMailServer(id, index){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this Mail Server?',
                instructionText: 'Type "DELETE" to confirm.',
                actionButtonText: 'Delete Mail Server',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE');
                },
                confirm: function(){
                    return usersProfileService.remove(id);
                },
                callback: function(){
                    $scope.servers.splice(index, 1);
                }
            });
        }
    })
    .controller('UsersMailServersCreateCtrl', function UsersMailServersCreateCtrl($scope, $location, usersProfileService, UsersProfileListService) {
        $scope.activeRoute = 'mail-servers';
        $scope.masterServer = UsersProfileListService.mailServerDataModel();
        $scope.server = angular.copy($scope.masterServer);
        $scope.verifyHtml = '<span class="text-warning">Unverified</span>';
        $scope.priorities = UsersProfileListService.mailServerPriorities();
        $scope.versions = UsersProfileListService.mailServerVersions();

        $scope.verifyConn = _verifyConn;
        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.cancel = _cancel;

        function _verifyConn(){
            $scope.verifyHtml = '<i class="fa fa-spinner"></i>';
            usersProfileService.verifyMailServerConnection($scope.server).then(function(data){
                if(data){
                    $scope.verifyHtml = '<span class="text-success">Success</span>';
                }
                else{
                    $scope.verifyHtml = '<span class="text-danger">Failed</span>';
                }
            });
        }

        function _isUnchanged(server){
            return angular.equals(server, $scope.masterServer);
        }

        function _save(server){
            var data = {
                data: server,
                type: "Mail Server"
            };
            usersProfileService.create(data).then(function(){
                $location.url('users/settings/mail-servers');
            });
        }

        function _cancel(){
            $location.url('/users/settings/mail-servers');
        }
    })
    .controller('UsersMailServersDetailsCtrl', function UsersMailServersDetailsCtrl($scope, $location, serverId, usersProfileService, UsersProfileListService) {
        $scope.activeRoute = 'mail-servers';
        $scope.masterServer = {};
        $scope.server = {};
        $scope.verifyHtml = '<span class="text-warning">Unverified</span>';
        $scope.priorities = UsersProfileListService.mailServerPriorities();
        $scope.versions = UsersProfileListService.mailServerVersions();

        $scope.verifyConn = _verifyConn;
        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.cancel = _cancel;

        _init();

        function _init(){
            usersProfileService.get(serverId).then(function(userProfile){
                $scope.masterServer = angular.copy(userProfile.data);
                $scope.server = angular.copy(userProfile.data);
            });
        }

        function _verifyConn(){
            $scope.verifyHtml = '<i class="fa fa-spinner"></i>';
            usersProfileService.verifyMailServerConnection($scope.server).then(function(data){
                if(data){
                    $scope.verifyHtml = '<span class="text-success">Success</span>';
                }
                else{
                    $scope.verifyHtml = '<span class="text-danger">Failed</span>';
                }
            });
        }

        function _isUnchanged(server){
            return angular.equals(server, $scope.masterServer);
        }

        function _save(server){
            var data = {
                data: server,
                type: "Mail Server"
            };
            usersProfileService.update(serverId, data).then(function(){
                $location.url('users/settings/mail-servers');
            });
        }

        function _cancel(){
            $location.url('/users/settings/mail-servers');
        }
    })
;