angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name RecurrencesService
     * @alias cerberus/ui:RecurrencesService
     * @description Provides functions for handling configuration of recurrence rules
     */
    .factory('RecurrencesService', function RecurrencesService(_, RRule, $http, $q, apiPath, toaster) {
        var service = {
            update: update,
            deleteRecurrence: deleteRecurrence,
            weekdays: weekdays,
            frequencies: frequencies
        };
        return service;
        ////////////////////
        /**
         * update
         * @param id
         * @param data
         * @returns {*|r.promise|Function|promise|Document.promise}
         */
        function update(id, data){
            var deferred = $q.defer();
            $http.put(apiPath + 'recurrences/' + id, data)
                .success(function (value) {
                    toaster.pop('success', 'Recurrence data updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    if(_.has(reason, 'DATA.errMsg')) {
                        toaster.pop({
                            type: 'warning',
                            bodyOutputType: 'trustedHtml',
                            body: reason.DATA.errMsg
                        });
                    }
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function deleteRecurrence(id){
            var deferred = $q.defer();
            $http.delete(apiPath + 'recurrences/' + id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function weekdays() {
            return [
                {
                    display:'Mon',
                    value:RRule.MO,
                    selected: false
                },
                {
                    display:'Tues',
                    value:RRule.TU,
                    selected: false
                },
                {
                    display:'Wed',
                    value:RRule.WE,
                    selected: false
                },
                {
                    display:'Thu',
                    value:RRule.TH,
                    selected: false
                },
                {
                    display:'Fri',
                    value:RRule.FR,
                    selected: false
                },
                {
                    display:'Sat',
                    value:RRule.SA,
                    selected: false
                },
                {
                    display:'Sun',
                    value:RRule.SU,
                    selected: false
                }
            ];
        }
        function frequencies() {
            return [
                {
                    display:'Daily',
                    value:RRule.DAILY
                },
                {
                    display:'Weekly',
                    value:RRule.WEEKLY
                },
                {
                    display:'Monthly',
                    value:RRule.MONTHLY
                },
                {
                    display:'Yearly',
                    value:RRule.YEARLY
                },
                {
                    display:'Every Weekday (Monday to Friday)',
                    value:'weekday'
                }
            ];
        }
    })
;
