angular.module('cerberus.util')
    .factory('CalculationService', function CalculationService(_, kendo, moment, writtenNumber, rollCalculationService){
        return {
            enforcedDecimalPrecision: 13,

            /**
             * Takes array of parsed tokens and calculates the result of the equation.
             *
             * @param parsedItems Array of tokens that have been parsed
             * @return {object} Resulting number value or string
             */
            calculate: function(parsedItems){
                try {
                    var startIndex = 0;
                    var endIndex = parsedItems.length;

                    var operatorStack = [];
                    var operandStack = [];

                    var hasAddOrSubtractOp = false;
                    var hasMultiplyorDivideOp = false;
                    var hasExponentOp = false;
                    var hasConcatenateOp = false;
                    var hasLogicalOp = false;

                    for (var tokenIndex = startIndex; tokenIndex < endIndex; tokenIndex++) {     // FOR LOOP: operands and operators are pushed to the appropriate stack
                        var token = parsedItems[tokenIndex];

                        switch (token.type) {
                            case 'error':
                                return token;
                            case 'subexpression':
                                var end = this.getSubexpressions(parsedItems, tokenIndex, false)[1];          // Gets subexpression indices
                                //var subResult = this.calculate(null, i + 1, end);
                                var operand = this.calculate(parsedItems.slice(tokenIndex + 1, end));       // Calculates value of the subexpression
                                if (operand.type == 'error') {
                                    return operand;
                                }
                                tokenIndex = end;                                                           // Moves iterator to the end of the subexpression
                                operandStack.push(operand);                                                 // Pushes value of subexpression to stack
                                break;
                            case 'function': // TODO: handle IF
                                var indices = this.getSubexpressions(parsedItems, tokenIndex, true);          // Gets indices of function arguments
                                var funcArgs = [];
                                if (indices[0] + 1 != indices[indices.length - 1]) {
                                    for (var j = 0; j < indices.length - 1; j++) {            // Calculates value of each argument
                                        var subExpression = parsedItems.slice(indices[j] + 1, indices[j + 1]);

                                        if (token.value === 'IF' && j > 0) {
                                            funcArgs[j] = subExpression;
                                        }
                                        else {
                                            var funcArgResult = this.calculate(subExpression);
                                            if (funcArgResult.type == 'error') {
                                                return funcArgResult;
                                            }
                                            funcArgs[j] = funcArgResult.value;
                                        }
                                    }
                                }
                                tokenIndex = indices[indices.length - 1];
                                var oper = {
                                    type: "operand"
                                };
                                oper.value = this.excel(token.value, funcArgs);
                                if(oper.value === null || oper.value === undefined){
                                    oper.value = '';
                                }
                                if (oper.value.error) {
                                    oper.type = 'error';
                                    oper.value = oper.value.error;
                                    return oper;
                                } else if (oper.type === 'error'){
                                    return oper;
                                } else if (typeof oper.value == "number") {
                                    oper.subtype = "number";
                                } else if (typeof oper.value == "string") {
                                    oper.subtype = "text";
                                } else if (typeof oper.value == "boolean") {
                                    oper.subtype = "logical";
                                } else if (oper.value instanceof Date) {
                                    oper.subtype = "date";
                                }
                                operandStack.push(oper);
                                break;
                            case 'operand':
                                operandStack.push(token);
                                break;
                            case 'operator-infix':
                                if ("+-".indexOf(token.value) >= 0) {
                                    hasAddOrSubtractOp = true;
                                } else if ("*/".indexOf(token.value) >= 0) {
                                    hasMultiplyorDivideOp = true;
                                } else if (token.value == "^") {
                                    hasExponentOp = true;
                                } else if (token.value == "&") {
                                    hasConcatenateOp = true;
                                } else if (token.subtype == 'logical'){
                                    hasLogicalOp = true;
                                }
                                operatorStack.push(token);
                                break;
                        }
                    } // END FOR LOOP

                    // Now that the functions and subexpressions are solved, we solve for each operator in order of operations:
                    if (hasExponentOp) {                                  // Solves for power operators
                        for (var exponentIndex = 0; exponentIndex < operatorStack.length; exponentIndex++) {
                            if (operatorStack[exponentIndex].value == "^") {
                                var exponentResult = {
                                    type: "operand",
                                    subtype: "number"
                                };
                                exponentResult.value = this.power(operandStack[exponentIndex].value, operandStack[exponentIndex + 1].value);
                                operandStack.splice(exponentIndex, 2, exponentResult);
                                operatorStack.splice(exponentIndex, 1);
                                exponentIndex--;
                            }
                        }
                    }
                    if (hasMultiplyorDivideOp) {                   // Solves for Multiplication and Division operators
                        for (var multDivIndex = 0; multDivIndex < operatorStack.length; multDivIndex++) {
                            if (operatorStack[multDivIndex].value == "*") {
                                var multiplyResult = {
                                    type: "operand",
                                    subtype: "number"
                                };
                                multiplyResult.value = this.product([operandStack[multDivIndex].value, operandStack[multDivIndex + 1].value]);
                                operandStack.splice(multDivIndex, 2, multiplyResult);
                                operatorStack.splice(multDivIndex, 1);
                                multDivIndex--;
                            } else if (operatorStack[multDivIndex].value == "/") {
                                var divideResult = {
                                    type: "operand",
                                    subtype: "number"
                                };
                                divideResult.value = this.quotient(operandStack[multDivIndex].value, operandStack[multDivIndex + 1].value);
                                if (divideResult.value.error) {
                                    divideResult.value = divideResult.value.error;
                                    divideResult.type = 'error';
                                    divideResult.subtype = null;
                                    return divideResult;
                                }
                                operandStack.splice(multDivIndex, 2, divideResult);
                                operatorStack.splice(multDivIndex, 1);
                                multDivIndex--;
                            }
                        }
                    }
                    if (hasAddOrSubtractOp) {                      // Solves for Addition and Subtraction operators
                        for (var addSubIndex = 0; addSubIndex < operatorStack.length; addSubIndex++) {
                            if (operatorStack[addSubIndex].value == "+") {
                                var additionResult = {
                                    type: "operand",
                                    subtype: "number"
                                };
                                additionResult.value = this.sum([operandStack[addSubIndex].value, operandStack[addSubIndex + 1].value]);
                                operandStack.splice(addSubIndex, 2, additionResult);
                                operatorStack.splice(addSubIndex, 1);
                                addSubIndex--;
                            } else if (operatorStack[addSubIndex].value == "-") {
                                var subtractionResult = {
                                    type: "operand",
                                    subtype: "number"
                                };
                                subtractionResult.value = this.sum([operandStack[addSubIndex].value, -1 * this.parseNum(operandStack[addSubIndex + 1].value)]);
                                operandStack.splice(addSubIndex, 2, subtractionResult);
                                operatorStack.splice(addSubIndex, 1);
                                addSubIndex--;
                            }
                        }
                    }
                    if (hasConcatenateOp) {                        // Solves for Concatenation operators
                        for (var concatIndex = 0; concatIndex < operatorStack.length; concatIndex++) {
                            if (operatorStack[concatIndex].value == "&") {
                                var concatenationResult = {
                                    type: "operand",
                                    subtype: "text"
                                };
                                var val1 = operandStack[concatIndex].value;
                                var val2 = operandStack[concatIndex + 1].value;
                                if (typeof val1 === 'object') {
                                    val1 = _.get(val1, 'display', '');
                                }
                                if (typeof val2 === 'object') {
                                    val2 = _.get(val2, 'display', '');
                                }
                                concatenationResult.value = val1 + "" + val2;
                                operandStack.splice(concatIndex, 2, concatenationResult);
                                operatorStack.splice(concatIndex, 1);
                                concatIndex--;
                            }
                        }
                    }
                    if(hasLogicalOp){
                        for(var logicIndex = 0; logicIndex < operatorStack.length; logicIndex++){
                            var leftLogicValue = operandStack[logicIndex].value,
                                rightLogicValue = operandStack[logicIndex + 1].value,
                                logicOperator = operatorStack[logicIndex].value,
                                logicResult = {
                                    type: 'operand',
                                    subType: 'logical'
                                };

                            if (typeof leftLogicValue === 'object') {
                                leftLogicValue = _.get(leftLogicValue, 'display', '');
                            }
                            if (typeof rightLogicValue === 'object') {
                                rightLogicValue = _.get(rightLogicValue, 'display', '');
                            }

                            if(logicOperator == '>'){
                                logicResult.value = leftLogicValue > rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '<'){
                                logicResult.value = leftLogicValue < rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '>='){
                                logicResult.value = leftLogicValue >= rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '<='){
                                logicResult.value = leftLogicValue <= rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '='){
                                logicResult.value = leftLogicValue == rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '<>'){
                                logicResult.value = leftLogicValue != rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '&&'){
                                logicResult.value = leftLogicValue && rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                            else if(logicOperator == '||'){
                                logicResult.value = leftLogicValue || rightLogicValue;
                                operandStack.splice(logicIndex, 2, logicResult);
                                operatorStack.splice(logicIndex, 1);
                                logicIndex--;
                            }
                        }
                    }
                    return operandStack[0];     // After solving all operations, there should just be one operand on the stack
                }
                catch(e){
                    return {
                        value: {error: e}
                    };
                }
            },

            /**
             * Iterates through the list of items to determine the range of a subexpression and returns the indices
             * of the starting bracket, the ending bracket, and each comma in between (for function arguments).
             *
             * @param parsedItems List of items containing the subexpression
             * @param startIndex Index of the open bracket that begins the subexpression
             * @param isFunction Boolean value - true if getting indices for a function's arguments
             * @return {Array} Array of indices
             */
            getSubexpressions: function(parsedItems, startIndex, isFunction){
                var openBrackets = 1; // Number of open brackets found without matching end brackets
                var subexpIndex = startIndex;
                var indices = [subexpIndex];
                while(subexpIndex < parsedItems.length && openBrackets > 0){
                    subexpIndex++;
                    var token = parsedItems[subexpIndex];
                    if(token.subtype && token.subtype == 'start'){
                        openBrackets++;
                    } else if(token.subtype && token.subtype == 'stop'){
                        openBrackets--;
                    } else if(token.type == 'argument' && isFunction && openBrackets == 1){
                        indices.push(subexpIndex);
                    }
                }
                indices.push(subexpIndex);
                return indices;
            },

            /**
             * Passes function parameters to the corresponding Excel function and returns the result.
             *
             * @param functionName Name of the Excel function to run
             * @param funcArgs Arguments to be passed to the Excel function
             * @returns {string, number, object} Result of Excel function
             */
            excel: function(functionName, funcArgs){
                switch(functionName){
                    // STRING
                    case 'CONCATENATE':
                        return this.concatenate(funcArgs);
                    case 'LEFT':
                        return this.left(funcArgs[0], funcArgs[1]);
                    case 'RIGHT':
                        return this.right(funcArgs[0], funcArgs[1]);
                    case 'SUBSTITUTE':
                        return this.substitute(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'LEN':
                        return this.len(funcArgs[0]);
                    case 'TEXT':
                        return this.text(funcArgs[0], funcArgs[1]);
                    case 'TRIM':
                        return this.trim(funcArgs[0]);
                    case 'VALUE':
                        return this.value(funcArgs[0]);
                    case 'WRITTENNUMBER':
                        return this.writtenNumber(funcArgs[0]);

                    // MATH
                    case 'SUM':
                        return this.sum(funcArgs);
                    case 'POWER':
                        return this.power(funcArgs[0], funcArgs[1]);
                    case 'PRODUCT':
                        return this.product(funcArgs);
                    case 'QUOTIENT':
                        return this.quotient(funcArgs[0], funcArgs[1]);
                    case 'AVERAGE':
                        return this.average(funcArgs);
                    case 'ROUND':
                        return this.round(funcArgs[0], funcArgs[1]);
                    case 'FLOOR':
                        return this.floor(funcArgs[0], funcArgs[1]);
                    case 'CEILING':
                        return this.ceiling(funcArgs[0], funcArgs[1]);
                    case 'PI':
                        return this.pi();
                    case 'ABS':
                        return this.abs(funcArgs[0]);
                    case 'SQRT':
                        return this.sqrt(funcArgs[0]);
                    case 'MIN':
                        return this.min.apply(this, funcArgs);
                    case 'MAX':
                        return this.max.apply(this, funcArgs);

                    // DATE
                    case 'NOW':
                        return this.now();
                    case 'DATEADD':
                        return this.dateAdd(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'DATESUB':
                        return this.dateSub(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'DATEDIFF':
                        if(funcArgs.length < 3){
                            return this.dateDiff(funcArgs[0], funcArgs[1], null);
                        }
                        return this.dateDiff(funcArgs[0], funcArgs[1], funcArgs[2]);

                    // LOGIC
                    case 'IF':
                        return this['if'](funcArgs[0], funcArgs[1], funcArgs[2]);

                    // ROLL
                    case 'ROLLLENGTH':
                        return rollCalculationService.rollLength(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'ROLLOUTERDIAMETER':
                        return rollCalculationService.rollOuterDiameter(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'ROLLINNERDIAMETER':
                        return rollCalculationService.rollInnerDiameter(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'INROLLCALIPER':
                        return rollCalculationService.inRollCaliper(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'ROLLWEIGHT':
                        return rollCalculationService.rollWeight(funcArgs[0], funcArgs[1], funcArgs[2]);
                    case 'ROLLBASISWEIGHT':
                        return rollCalculationService.rollBasisWeight(funcArgs[0], funcArgs[1], funcArgs[2]);
                }
            },

            /**
             * Helper function to parse strings and boolean values to number values. String representations of
             * numbers are parsed to floats, while boolean values are parsed to 1 if true or 0 if false.
             *
             * @param number - The value to be parsed to number value
             * @returns {number}
             */
            parseNum: function(number){
                if(typeof number == 'number' && !isNaN(number)){
                    return number;
                } else if(typeof number == 'string' && !isNaN(parseFloat(number))){
                    return parseFloat(number);
                } else if(number === true){
                    return 1;
                } else if(number === false){
                    return 0;
                } else {
                    return null;
                }
            },

            /**
             * Helper function that checks sign of a number for comparison with another number
             *
             * @param number - Number to find the sign for
             * @returns {number}
             */
            sign: function(number){
                if(number === 0){
                    return 0;
                }
                else if(number > 0){
                    return 1;
                }
                else{
                    return -1;
                }
            },

            /**
             * Helper function that formats an ISO date string
             *
             * @param value - may or may not be a date string
             * @returns {*}
             */
            toDate: function(value){
                if(value && typeof value == 'string' && value.search(/^[0-9]{4}-[0-9]{2}-[0-9]{2}[Tt][0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{1,4})?[Zz]?$/) >= 0){ // Checks if is ISO-format date string
                    return kendo.toString(new Date(value), 'g');
                }
                else if(value instanceof Date){
                    return kendo.toString(value, 'g');
                }
                else {
                    return value;
                }
            },

            /**
             * Takes a floating point number and finds the multiple of 10 that can be used to move the decimal
             * point to make the number an integer.  Used for a workaround for floating point errors.
             *
             * @param number Floating point number that needs to be converted to integer
             * @returns {number} Multiple of 10 that, when multiplied with the float, will result in an integer
             */
            getDecimalPlace: function(number){
                var dec = 1;
                var num = number;
                while(num % 1 !== 0){
                    num *= 10;
                    dec *= 10;
                }
                return dec;
            },

            // STRING FUNCTIONS
            /**
             * Joins two or more values into one string object. Equivalent to & operator.
             *
             * @param strings Array of arguments to be concatenated
             * @return {string}
             */
            concatenate: function(strings){
                return strings.join("");
            },

            /**
             * Finds the leftmost character(s) for a given string.
             *
             * @param text String to retrieve characters from
             * @param numChar Number of characters to retrieve from left of string
             * @returns {string}
             */
            left: function(text, numChar){
                text = text.toString();
                if(numChar == null){
                    numChar = 1;
                } else {
                    numChar = this.parseNum(numChar);
                    if(numChar < 0){
                        numChar = 1;
                    } else if(numChar >= text.length){
                        return text;
                    }
                }
                return text.substr(0, numChar);
            },

            /**
             * Finds the rightmost character(s) for a given string.
             *
             * @param text String to retrieve characters from
             * @param numChar Number of characters to retrieve from right of string
             * @returns {string}
             */
            right: function(text, numChar){
                text = text.toString();
                if(numChar == null){
                    numChar = 1;
                } else {
                    numChar = this.parseNum(numChar);
                    if(numChar < 0){
                        numChar = 1;
                    } else if(numChar >= text.length){
                        return text;
                    }
                }
                return text.substr(text.length - numChar, numChar);
            },

            /**
             * Does a global text replacement on the given string
             * @param text
             * @param find
             * @param replace
             * @returns {string}
             */
            substitute: function(text, find, replace){
                text = text.toString();
                if(find){
                    var regex = new RegExp(find, 'g');

                    text = text.replace(regex, replace);
                }

                return text || '';
            },

            /**
             * Returns length of string.
             *
             * @param text String return length of.
             * @returns {number}
             */
            len: function(text){
                text = text.toString();
                return text.length;
            },

            /**
             * Formats dates and numbers to strings. Uses kendo formatting.
             *
             * @param value {number|Date}
             * @param format {string}
             * @returns {string}
             */
            text: function(value, format){
                return kendo.toString(value, format);
            },

            /**
             * Removes leading and trailing whitespace from a string
             *
             * @param text {string}
             * @returns {string}
             */
            trim: function(text){
                return _.trim(text);
            },

            /**
             * Converts string to numeric value
             *
             * @param text
             * @returns {*}
             */
            value: function(text){
                var numberValue = this.parseNum(text);

                // Throws error if text is parsed to NaN or Infinity
                if(_.isNaN(numberValue) || !_.isFinite(numberValue)){
                    return {error: '#VALUE!: Can not be interpreted as a numeric value'};
                }

                return numberValue;
            },

            /**
             * Returns number as text (2 -> "two")
             *
             * @param number
             * @returns {*}
             */
            writtenNumber: function(number){
                // writtenNumber function does not accept floats
                var integer = parseInt(number);

                return writtenNumber(integer);
            },

            // MATH FUNCTIONS
            /**
             * Adds two or more values together and returns the result. Equivalent to + operator.
             * Boolean and string values will be parsed to number values.
             *
             * @param numbers Array of numbers to be added
             * @return {number}
             */
            sum: function(numbers){
                var result = 0;
                var dec = 1;
                for(var sumIndex = 0; sumIndex < numbers.length; sumIndex++){
                    var num = this.parseNum(numbers[sumIndex]);
                    if(num != null){
                        var tempDec = this.getDecimalPlace(num);
                        if(tempDec > dec){
                            result /= dec;
                            dec = tempDec;
                            result *= dec;
                            num *= dec;
                        } else if(dec > 1) {
                            num *= dec;
                        }
                        result += num;
                    }
                }
                result /= dec;
                return result;
            },

            /**
             * Raises the value of the first argument to the power of the second argument. Equivalent to ^ operator.
             *
             * @param number Number value that will be raised to nth power
             * @param power Power that the number will be raised to
             * @return {number}
             */
            power: function(number, power){
                var num = number;
                var dec = this.getDecimalPlace(num);
                num *= dec;
                var result = Math.pow(num, power);
                result /= Math.pow(dec, power);
                return result;
            },

            /**
             * Multiplies two or more number values together and returns the result. Equivalent to * operator.
             * Boolean and string values will be parsed to number values.
             *
             * @param numbers Array of numbers that will be multiplied
             * @return {number|string}
             */
            product: function(numbers){
                var result = null;
                for(var productIndex = 0; productIndex < numbers.length; productIndex++){
                    var num = this.parseNum(numbers[productIndex]);
                    if(num != null){
                        if(result != null) {
                            var resultDec = this.getDecimalPlace(result);
                            var numDec = this.getDecimalPlace(num);
                            result *= resultDec;
                            num *= numDec;
                            result *= num;
                            result /= resultDec;
                            result /= numDec;
                        } else {
                            result = num;
                        }
                    }
                    else {
                        return '';
                    }
                }
                return this.round(result, this.enforcedDecimalPrecision);
            },

            /**
             * Divides the first argument by the second and returns the result. Equivalent to / operator
             *
             * @param dividend Number that will be divided
             * @param divisor Magnitude by which numerator will be divided
             * @return {number, object}
             */
            quotient: function(dividend, divisor){ // handle #VALUE! error
                if(typeof dividend != 'number' || typeof divisor != 'number') {
                    return {error: '#VALUE!: Attempted to divide with non-number value'};
                } else if(divisor === 0){
                    return {error: '#VALUE!: Attempted to divide by zero'};
                } else {
                    var result = dividend / divisor;
                    return this.round(result, this.enforcedDecimalPrecision);
                }
            },

            /**
             * Finds the average of two or more number values and returns the result. Empty (null) values are not
             * counted, but boolean values and string representations of numbers are.
             *
             * @param numbers Array of arguments to be averaged
             * @return {number}
             */
            average: function(numbers){
                var sum;
                var length = 0;
                for(var averageIndex = 0; averageIndex < numbers.length; averageIndex++){
                    var num = this.parseNum(numbers[averageIndex]);
                    if(num != null){
                        //sum += num;
                        length++;
                    }
                }

                if(length === 0){
                    return 0;
                }

                sum = this.sum(numbers);
                var dec = this.getDecimalPlace(sum);
                var result = sum * dec;
                result /= length;
                result /= dec;
                return this.round(result, this.enforcedDecimalPrecision);
            },

            /**
             * Rounds a number to the specified number of digits.
             *
             * @param number Number value to be rounded
             * @param numberDigits Number of digits the number value will be rounded to
             * @return {number}
             */
            round: function (number, numberDigits) {
                numberDigits = numberDigits || 0;
                var place = this.power(10, numberDigits);
                var numSign = this.sign(number);
                return numSign * Math.round(numSign * number * place) / place;
            },

            /**
             * Rounds number down, toward zero, to the nearest multiple of significance.
             *
             * @param number Number value to be rounded
             * @param significance Multiple of significance to which the number will be rounded
             * @return {number, object}
             */
            floor: function(number, significance){
                var result = 0;
                if(typeof number != 'number') { // #VALUE! error
                    return {error: '#VALUE!: Attempted to FLOOR non-number value'};
                } else if(typeof significance != 'number') {
                    return {error: '#VALUE!: Multiple of significance for FLOOR is not a number'};
                } else if(this.sign(number) != this.sign(significance)) { // #NUM! error
                    return {error: '#NUM!: FLOOR: Arguments\' signs must match'};
                } else {
                    var numSign = this.sign(number);
                    var num = number * numSign;
                    var sig = significance * numSign;
                    var dec = 1;
                    while(sig % 1 !== 0 || num % 1 !== 0){ // Workaround for some issues with floating points
                        sig *= 10;
                        num *= 10;
                        dec *= 10;
                    }
                    var mult = Math.floor(num / sig);
                    result = ( mult * sig ) / dec;
                    result *= numSign;
                    return result;
                }
            },

            /**
             * Rounds number up, away from zero, to the nearest multiple of significance.
             *
             * @param number Number value to be rounded
             * @param significance Multiple of significance to which the number will be rounded
             * @return {number, object}
             */
            ceiling: function(number, significance){
                var result = 0;
                if(typeof number != 'number') { // #VALUE! error
                    return {error: '#VALUE!: Attempted to find ceiling of non-number value'};
                } else if(typeof significance != 'number') {
                    return {error: '#VALUE!: Multiple of significance for CEILING is not a number'};
                } else if(this.sign(number) != this.sign(significance)) { // #NUM! error
                    return {error: '#NUM!: CEILING: Arguments\' signs must match'};
                } else {
                    var numSign = this.sign(number);
                    var num = number * numSign;
                    var sig = significance * numSign;
                    var dec = 1;
                    while(sig % 1 !== 0 || num % 1 !== 0){
                        sig *= 10;
                        num *= 10;
                        dec *= 10;
                    }
                    var mult = Math.ceil(num / sig);
                    result = ( mult * sig ) / dec;
                    result *= numSign;
                    return result;
                }
            },

            /**
             * Returns value of pi
             *
             * @returns {number|Math.PI|*}
             */
            pi: function(){
                return Math.PI;
            },

            /**
             * Finds absolute value of number value
             *
             * @param n
             * @returns {*}
             */
            abs: function(n){
                return Math.abs(n);
            },

            /**
             * Finds square root of number value
             *
             * @param n
             * @returns {*}
             */
            sqrt: function(n){
                return Math.sqrt(n);
            },

            /**
             * Finds and returns the smallest number value
             *
             * @returns {*}
             */
            min: function(){
                var minimumValue = '';

                for (var i = 0; i < arguments.length; i++){
                    var numberValue = this.parseNum(arguments[i]);

                    if(_.isNaN(numberValue) || !_.isFinite(numberValue)){
                        // return { error: '#VALUE!: Can not be interpreted as a numeric value' };   // Throws error if value cannot be parsed to finite number
                        continue;   // Skips this value if it cannot be parsed to a valid number
                    }

                    if (minimumValue === '' || numberValue < minimumValue) {
                        minimumValue = numberValue;
                    }
                }

                return minimumValue;
            },

            /**
             * Finds and returns the largest number value
             *
             * @returns {*}
             */
            max: function(){
                var maximumValue = '';

                for (var i = 0; i < arguments.length; i++){
                    var numberValue = this.parseNum(arguments[i]);

                    if(_.isNaN(numberValue) || !_.isFinite(numberValue)){
                        // return { error: '#VALUE!: Can not be interpreted as a numeric value' };   // Throws error if value cannot be parsed to finite number
                        continue;   // Skips this value if it cannot be parsed to a valid number
                    }

                    if (maximumValue === '' || numberValue > maximumValue) {
                        maximumValue = numberValue;
                    }
                }

                return maximumValue;
            },

            /**
             * Returns date Now
             *
             * @returns {string}
             */
            now: function(){
                return moment().toISOString();
            },

            /**
             * Increments date by number of units
             *
             * @param date Date to be modified
             * @param value Number of units to add
             * @param unit Type of unit to add (e.g., days, months, years...)
             * @returns {string}
             */
            dateAdd: function(date, value, unit){
                var newDate;
                
                if(!date){
                    return null;
                }
                else if(date instanceof Date){
                    if(date.toString() == 'Invalid Date'){
                        return null;
                    }

                    newDate = moment(date.toISOString());
                }
                else {
                    newDate = moment(date);
                }

                newDate.add(this.parseNum(value), unit);
                return newDate.toDate();
            },

            /**
             * Decrements date by number of units
             *
             * @param date Date to be modified
             * @param value Number of units to subtract
             * @param unit Type of unit to subtract (e.g., days, months, years...)
             * @returns {string}
             */
            dateSub: function(date, value, unit){
                var newDate;

                if(!date){
                    return null;
                }
                else if(date instanceof Date){
                    if(date.toString() == 'Invalid Date'){
                        return null;
                    }
                    
                    newDate = moment(date.toISOString());
                }
                else {
                    newDate = moment(date);
                }

                newDate.subtract(this.parseNum(value), unit);
                return newDate.toDate();
            },

            /**
             * Finds difference between two dates
             *
             * @param a First Date string
             * @param b Second Date string
             * @param unit Type of unit to find difference
             * @returns {*}
             */
            dateDiff: function(a, b, unit){
                var dateA = a instanceof Date ? moment(a.toISOString()) : moment(a);
                var dateB = b instanceof Date ? moment(b.toISOString()) : moment(b);
                if(unit) {
                    return Math.ceil(dateB.diff(dateA, unit, true));
                }
                else {
                    return dateB.diff(dateA);
                }
            },

            /**
             * Returns value based on the result of the given condition
             *
             * @param conditionResult - Condition that is used to determine which value to return
             * @param trueValue - Returned if the given condition resulted in a truthy value
             * @param falseValue - Returned if the condition resulted in a falsey value
             * @returns {*}
             */
            'if': function(conditionResult, trueValue, falseValue){
                if (!!conditionResult) {
                    if (trueValue instanceof Array) { // If true value is array of tokens, evaluate it first
                        var trueResult = this.calculate(trueValue);

                        if (trueResult.type === 'error') {
                            return { error: trueResult.value };
                        }

                        trueValue = trueResult.value;
                    }

                    return trueValue;
                }
                if (falseValue === null || typeof falseValue  === 'undefined') {
                    falseValue = '';
                }
                else if (falseValue instanceof Array){ // If false value is array of tokens, evaluate it first
                    var falseResult = this.calculate(falseValue);

                    if (falseResult.type === 'error') {
                            return { error: falseResult.value };
                    }

                    falseValue = falseResult.value;
                }
                return falseValue;
            }
        };
    })
;