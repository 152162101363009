angular.module('cerberus.admin')
    .controller('FormsDesigner_staticCtrl', function FormsDesigner_staticCtrl(_, $scope, $uibModalInstance, autoTabIndex, currentFormObjects, editFlag, formObj, formOriginId, idArray,
                                                                              sections, widgetId, DesignerUtilityService, FormsDesignerListService, WidgetsSettingsService) {
        // Populate Scope
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.sections = sections;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.widgetFieldIds = [];
        $scope.conditionalActions = FormsDesignerListService.conditionalActions(true);
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };
        $scope.editorTools = DesignerUtilityService.textEditorOptions($scope).tools;

        init();

        function init(){
            // Hack
            if(formObj.model.config.type !== 'hr') { // For divider, first element is radio button
                DesignerUtilityService.focusFirstElement('configForm');
            }

            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);
                $scope.widgetFieldIds = _.compact(_.union(idArray, _.pluck($scope.formFieldObjects, 'modelId')));
                $scope.widgetFieldIds.sort();

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);
            });
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;