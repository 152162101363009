angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartBubbleService
     * @alias cerberus/core:VizChartBubbleService
     * @description Provides functions for configuring bubble charts
     */
    .factory('VizChartBubbleService', function VizChartBubbleService(){
        return {
            //configBubble: configBubble
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configBubble
         * @param {object} data
         * @param {object} options
         * function configBubble(data, options){}
         */

    })
;