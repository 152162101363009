angular.module('cerberus.admin')
    .controller('nimPageObjectListCtrl', function nimPageObjectListCtrl(_, $scope, $rootScope, $attrs, $parse, apiPath, localStorageService, PagesService, pageObjectsService, PageObjectsDesignerService, ConfirmModalService){
        $scope.showDelete = $scope.$eval($attrs.showDelete);

        var importFunction = null;

        if($attrs.nimPageObjectImport){
            importFunction = $scope.$eval($attrs.nimPageObjectImport);
        }

        $scope.pages = [
            {
                id: 'unused',
                name: '(Unused)'
            }
        ];
        $scope.pageFilter = localStorageService.get('pageObjectFilter.' + $rootScope.userData.currentWorkspace.id) || '';
        $scope.reloadPageObjects = reloadPageObjects;

        $scope.options = {
            columns: [
                {
                    title: 'Name',
                    field: 'name'
                },
                {
                    title: 'Type',
                    template: typeColTemplate
                }
            ],
            columnMenu: {
                columns: false
            },
            dataSource: {
                transport: {
                    read: transportRead
                }
            },
            filterable: {
                extra: false,
                operators: {
                    string: {
                        contains: 'Contains'
                    }
                }
            },
            scrollable: false,
            selectable: 'row',
            change: selectPageObject
        };

        if(_.isFunction(importFunction)){
            $scope.options.columns.push({
                command: [
                    {
                        name: 'importPageObject',
                        text: '<i class="fa fa-upload"></i>&nbsp;Import',
                        className: 'btn-primary',
                        click: _.partialRight(importClickHandler, false)
                    },
                    {
                        name: 'importAndCopyPageObject',
                        text: '<i class="fa fa-copy"></i>&nbsp;Duplicate',
                        className: 'btn-primary',
                        click: _.partialRight(importClickHandler, true)
                    }
                ],
                width: '70px'
            });
        }

        if($scope.showDelete){
            $scope.options.columns.push({
                command: {
                    name: 'deletePageObject',
                    text: '<i class="fa fa-trash"></i>&nbsp;Delete',
                    className: 'btn-danger',
                    click: deleteClickHandler
                },
                width: '70px'
            });
        }

        PagesService.getPages().then(function(pages){
            $scope.pages = $scope.pages.concat(pages);
        });

        function typeColTemplate(dataItem){
            var type = dataItem.type; // Show object type by default

            if(dataItem.type === 'viz'){ // For viz objects, show viz type\
                if(dataItem.vizSubType) { // Show viz subtype
                    type = dataItem.vizSubType + ' ' + _.capitalize(dataItem.vizType);
                }
                else {
                    type = dataItem.vizType;
                }
            }
            return _.capitalize(type);
        }

        function transportRead(options){
            var cb = readCallback(options);

            if($scope.pageFilter === 'unused'){
                pageObjectsService.getUnusedPageObjects().then(cb);
            }
            else if($scope.pageFilter){
                PagesService.getPage($scope.pageFilter).then(cb);
            }
            else{
                pageObjectsService.getPageObjects().then(cb);
            }
        }

        function readCallback(options){
            return function(result){
                options.success(parseData(result));
            };
        }

        function selectPageObject(e){
            var row = e.sender.select();
            var dataItem = e.sender.dataItem(row);
            // Let parent controller determine what happens when page object is selected
            $scope.$emit('nim-page-object-list-selected', dataItem.toJSON());
        }

        function importClickHandler(e, duplicate){
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            var tr = $(e.target).closest('tr');
            var pageObject = $scope.grid.dataItem(tr);

            if(_.isFunction(importFunction)){
                importFunction(pageObject.id, duplicate);
            }
        }

        function deleteClickHandler(e){
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            var tr = $(e.target).closest('tr');
            var pageObject = $scope.grid.dataItem(tr);

            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this page object and remove it from each page that uses it?',
                instructionText: 'Type "DELETE ' + pageObject.name + '" to confirm.',
                actionButtonText: 'Delete Page Object',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + pageObject.name);
                },
                confirm: function(){
                    return PageObjectsDesignerService.deletePageObject(pageObject.id);
                },
                callback: function(){
                    reloadPageObjects();
                }
            });
        }

        function reloadPageObjects(){
            localStorageService.set('pageObjectFilter.' + $rootScope.userData.currentWorkspace.id, $scope.pageFilter);
            _.result($scope, 'grid.dataSource.read');
        }

        function parseData(resultData){
            if($scope.pageFilter && $scope.pageFilter !== 'unused'){
                var data = [];
                _.forEach(resultData.items, function(pageObject){
                    var dataItem = _.pick(pageObject, ['id', 'name', 'type']);
                    dataItem.vizType = _.get(pageObject, 'viz.type', '');
                    dataItem.vizSubType = _.get(pageObject, 'viz.subType', '');
                    data.push(dataItem);
                });
                return data;
            }
            else {
                return resultData;
            }
        }
    })
    .directive('nimPageObjectList', function(){
        return {
            restrict: 'AE',
            controller: 'nimPageObjectListCtrl',
            template: '<div class="form-group">' +
                '<label for="pageFilter">Page:</label>' +
                '<select id="pageFilter" class="form-control" ng-model="pageFilter" ng-options="p.id as p.name for p in pages" ng-change="reloadPageObjects()" style="width:100%">' +
                    '<option value="">(All)</option>' +
                '</select>' +
            '</div>' +
            '<div kendo-grid="grid" k-options="options"></div>'
        };
    })
;
