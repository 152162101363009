angular.module('cerberus.core')
    .directive('nimSideMenu', function(DeviceSniffService){
        return {
            link: function (scope, element) {

                scope.appUI.isLeftBarCollapsed = DeviceSniffService.deviceWidth() <= 768;

                // Collapse Side Menu on small device
                element.bind('resize', function() {
                    scope.appUI.isLeftBarCollapsed = DeviceSniffService.deviceWidth() <= 768;
                });
            }
        };
    })
;