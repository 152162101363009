angular.module('cerberus.ui')
    .constant('dropdownFormConfig', {
        openClass: 'open',
        containerClass: 'dropdown-form-container',
        uiLibraryClass: 'k-animation-container'
    })
    .controller('DropdownFormController', function($scope, $element, $document, $attrs, $parse, dropdownFormConfig, dropdownFormService, $animate, $uibPosition) {
        var self = this,
            scope = $scope.$new(), // create a child scope so we are not polluting original one
            openClass = dropdownFormConfig.openClass,
            getIsOpen,
            setIsOpen = angular.noop,
            toggleInvoker = $attrs.onToggle ? $parse($attrs.onToggle) : angular.noop,
            appendToBody = false,
            keynavEnabled =false;
            //selectedOption = null;

        this.init = function( element ) {
            element.addClass('dropdown');
            self.$element = element;
            self.$element.addClass(dropdownFormConfig.containerClass);

            if ( $attrs.isOpen ) {
                getIsOpen = $parse($attrs.isOpen);
                setIsOpen = getIsOpen.assign;

                $scope.$watch(getIsOpen, function(value) {
                    scope.isOpen = !!value;
                });
            }

            appendToBody = angular.isDefined($attrs.dropdownAppendToBody);
            keynavEnabled = angular.isDefined($attrs.uibKeyboardNav);

            if (appendToBody && self.dropdownFormMenu) {
                $document.find('body').append(self.dropdownFormMenu);
                $element.on('$destroy', function handleDestroyEvent() {
                    self.dropdownFormMenu.remove();
                });
            }
        };

        this.toggle = function( open ) {
            return scope.isOpen = arguments.length ? !!open : !scope.isOpen;
        };

        // Allow other directives to watch status
        this.isOpen = function() {
            return scope.isOpen;
        };

        scope.getToggleElement = function() {
            return self.toggleElement;
        };

        scope.getAutoClose = function() {
            return $attrs.autoClose || 'always'; //or 'outsideClick' or 'disabled'
        };

        scope.getElement = function() {
            return $element;
        };

        scope.isKeynavEnabled = function() {
            return keynavEnabled;
        };

        scope.focusDropdownEntry = function(keyCode) {
            var elems = self.dropdownFormMenu ? //If append to body is used.
                (angular.element(self.dropdownFormMenu).find('a')) :
                (angular.element($element).find('ul').eq(0).find('a'));

            switch (keyCode) {
                case (40): {
                    if (!angular.isNumber(self.selectedOption)) {
                        self.selectedOption = 0;
                    } else {
                        self.selectedOption = (self.selectedOption === elems.length - 1 ?
                            self.selectedOption :
                        self.selectedOption + 1);
                    }
                    break;
                }
                case (38): {
                    if (!angular.isNumber(self.selectedOption)) {
                        self.selectedOption = elems.length - 1;
                    } else {
                        self.selectedOption = self.selectedOption === 0 ?
                            0 : self.selectedOption - 1;
                    }
                    break;
                }
            }
            elems[self.selectedOption].focus();
        };

        scope.getDropdownElement = function() {
            return self.dropdownFormMenu;
        };

        scope.focusToggleElement = function() {
            if ( self.toggleElement ) {
                self.toggleElement[0].focus();
            }
        };

        scope.$watch('isOpen', function(isOpen, wasOpen) {
            if (appendToBody && self.dropdownFormMenu) {
                var pos = $uibPosition.positionElements($element, self.dropdownFormMenu, 'bottom-left', true);
                var css = {
                    top: pos.top + 'px',
                    display: isOpen ? 'block' : 'none'
                };

                var rightalign = self.dropdownFormMenu.hasClass('dropdown-menu-right');
                if (!rightalign) {
                    css.left = pos.left + 'px';
                    css.right = 'auto';
                } else {
                    css.left = 'auto';
                    css.right = (window.innerWidth - (pos.left + $element.prop('offsetWidth'))) + 'px';
                }

                self.dropdownFormMenu.css(css);
            }

            $animate[isOpen ? 'addClass' : 'removeClass'](self.$element, openClass);

            if ( isOpen ) {
                scope.focusToggleElement();
                dropdownFormService.open( scope );
            } else {
                dropdownFormService.close( scope );
            }

            setIsOpen($scope, isOpen);
            if (angular.isDefined(isOpen) && isOpen !== wasOpen) {
                toggleInvoker($scope, { open: !!isOpen });
            }
        });

        $scope.$on('$locationChangeSuccess', function() {
            scope.isOpen = false;
        });

        $scope.$on('$destroy', function() {
            scope.$destroy();
        });
    })
    .directive('dropdownForm', function() {
        return {
            controller: 'DropdownFormController',
            link: function(scope, element, attrs, dropdownFormCtrl) {
                dropdownFormCtrl.dropdownFormMenu = element.find('[uib-dropdown-menu]');
                dropdownFormCtrl.init( element );
            }
        };
    })
    .directive('dropdownFormToggle', function() {
        return {
            require: '?^dropdownForm',
            link: function(scope, element, attrs, dropdownFormCtrl) {
                if ( !dropdownFormCtrl ) {
                    return;
                }

                dropdownFormCtrl.toggleElement = element;

                var toggleDropdown = function(event) {
                    event.preventDefault();

                    if ( !element.hasClass('disabled') && !attrs.disabled ) {
                        scope.$apply(function() {
                            dropdownFormCtrl.toggle();
                        });
                    }
                };

                element.bind('click', toggleDropdown);

                // WAI-ARIA
                element.attr({ 'aria-haspopup': true, 'aria-expanded': false });
                scope.$watch(dropdownFormCtrl.isOpen, function( isOpen ) {
                    element.attr('aria-expanded', !!isOpen);
                });

                scope.$on('$destroy', function() {
                    element.unbind('click', toggleDropdown);
                });
            }
        };
    })
;