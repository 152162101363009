angular.module('cerberus.ui-util')
    /**
     * @ngdoc service
     * @name DeviceSniffService
     * @alias cerberus/ui-util:DeviceSniffService
     * @description Provides functions for detecting device and browser information
     */
    .factory('DeviceSniffService', function DeviceSniffService($window){
        var service = {
            deviceBrowser: deviceBrowser,
            isMobile: isMobile,
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight
        };

        return service;

        /*******************************************************/

        function deviceBrowser(){
            // borrowed from online
            var ua= $window.navigator.userAgent, tem,
                M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE '+(tem[1] || '');
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                if(tem!= null) {return tem.slice(1).join(' ').replace('OPR', 'Opera');}
            }
            M= M[2]? [M[1], M[2]]: [$window.navigator.appName, $window.navigator.appVersion, '-?'];
            if((tem= ua.match(/version\/(\d+)/i))!= null){ M.splice(1, 1, tem[1]);}
            return M.join(' ');
        }

        function isMobile(){
            return $window.document.documentElement.classList.contains('k-mobile');
        }

        function deviceWidth(){
            return ($window.innerWidth > 0) ? $window.innerWidth : $window.screen.width;
        }

        function deviceHeight(){
            return $window.screen.height;
        }
    })
;