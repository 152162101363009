angular.module('cerberus.ui')
    .directive('nimThumbnail', function($window) {
        var helper = {
            support: !!($window.FileReader && $window.CanvasRenderingContext2D),
            isFile: function(item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function(file) {
                var type =  '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        };

        return {
            restrict: 'A',
            template: '<canvas></canvas>',
            link: function(scope, element, attributes) {
                if (!helper.support) { return; }

                var params = scope.$eval(attributes.nimThumbnail);

                if (!helper.isFile(params.file)) { return; }
                if (!helper.isImage(params.file)) { return; }

                var canvas = element.find('canvas');
                var reader = new FileReader();

                reader.onload = onLoadFile;
                reader.readAsDataURL(params.file);

                function onLoadFile(event) {
                    var img = new Image();
                    img.onload = onLoadImage;
                    img.src = event.target.result;
                }

                function onLoadImage() {
                    var width = params.width || this.width / this.height * params.height;
                    var height = params.height || this.height / this.width * params.width;
                    canvas.attr({ width: width, height: height });
                    canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
                }
            }
        };
    })
    .directive('nimFileThumb', function (InstancesService) {
        var isImage = function (ext) {
            return ['jpg', 'png', 'jpeg', 'bmp', 'gif'].indexOf(ext) !== -1;
        };
        //Font Awesome Icons
        var getFontAwesomeIcon = function (ext) {
            var fileTypeIcons = {
                // Images
                'bmp':  'fa-file-image-o',
                'jpeg': 'fa-file-image-o',
                'jpg':  'fa-file-image-o',
                'gif':  'fa-file-image-o',
                'png':  'fa-file-image-o',

                // Adobe
                'pdf': 'fa-file-pdf-o',

                // Microsoft
                'ppt':  'fa-file-powerpoint-o',
                'pptx': 'fa-file-powerpoint-o',
                'xlsx': 'fa-file-excel-o',
                'xls':  'fa-file-excel-o',
                'doc':  'fa-file-word-o',
                'docx': 'fa-file-word-o',

                // Other Documents
                'txt':   'fa-file-text-o',
                'rtf':   'fa-file-text-o',
                'odt':   'fa-file-text-o',
                'pages': 'fa-file-text-o',
                'wpd':   'fa-file-text-o',
                'wp':    'fa-file-text-o',
                'wp7':   'fa-file-text-o',

                // Archives
                'zip': 'fa-file-archive-o',
                'rar': 'fa-file-archive-o',
                '7z':  'fa-file-archive-o',
                'iso': 'fa-file-archive-o',
                'tar': 'fa-file-archive-o',
                'dmg': 'fa-file-archive-o',
                'war': 'fa-file-archive-o'

            };
            return fileTypeIcons[ext] || 'fa-file-o';
        };

        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                if (attrs.nimFileThumb) {
                    var html = '';
                    var extArr = attrs.nimFileThumb.split('.');
                    var ext = extArr[extArr.length - 1].toLowerCase();
                    var style = '';

                    if(attrs.size){
                        var size = attrs.size.split('x');
                        style = 'style="max-width: ' + size[0] + 'px; max-height: ' + size[1] + 'px"';
                    }
                    else {
                        style = 'style="max-width:100%"';
                    }

                    /* Removing image for now */
                    if (isImage(ext) && !attrs.nimIconOnly) {
                        if(attrs.nimAttachmentId){
                            InstancesService.getDownloadKey(attrs.nimAttachmentId).then(function(url){
                                element.empty().append('<img src="' + url + '" ' + style + ' />');
                            });
                        }
                        else {
                            var newFilePath = attrs.nimFileThumb;
                            if (attrs.size) {
                                newFilePath = extArr[0] + '_' + attrs.size + '.' + extArr[1];
                            }
                            html += '<img style="max-height:100%; max-width:100%;" src="' + newFilePath + '"/>';
                            element.empty().append(html);
                        }
                    }
                    else {
                        html += '<i class="fa fa-fw ' + getFontAwesomeIcon(ext) + '"></i>';
                        element.empty().append(html);
                    }
                }
            }
        };
    })
;