angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name AuthPasswordService
     * @alias cerberus/core:AuthPasswordService
     * @description Makes calls to users/password resource
     */
    .factory('AuthPasswordService', function AuthPasswordService($http, $q, apiPath, toaster) {
            return {
                sendEmail: sendEmail,
                resetPassword: resetPassword,
                changePassword: changePassword
            };
            /**
             * Requests email for password reset link/token
             * @function sendEmail
             * @param data
             * @returns {promise}
             */
            function sendEmail(data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'users/password/recovery/email', data)
                    .success(function(value){
                        toaster.pop('success', 'Please check your email for further instructions.');
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        toaster.pop('error', 'Invalid email.');
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Reset user password
             * @function resetPassword
             * @param data
             * @returns {promise}
             */
            function resetPassword(data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'users/password/reset', data)
                    .success(function(value){
                        toaster.pop('success', 'Password has been changed.');
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        toaster.pop('error', 'Invalid.');
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Change user password
             * @function changePassword
             * @param data
             * @returns {promise}
             */
            function changePassword(data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'users/password/change', data)
                    .success(function (value) {
                        deferred.resolve(value);
                    })
                    .error(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }
        })
;