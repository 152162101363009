angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name WrapperClassService
     * @alias cerberus/core:WrapperClassService
     * @description
     *   Constructs Wrapper classes
     */
    .factory('WrapperClassService', function WrapperClassService() {
        /**
         * Constructs a new header
         * @class Header
         * @classdesc describes a PageObject's Header
         * @memberOf WrapperClassService
         * @param {string} icon
         * @param {string} title
         * @param {string} subTitle
         * @param {Object} style
         */
        function Header(icon, title, subTitle, style) {
            this.icon = icon;
            this.title = title;
            this.subTitle = subTitle;
            this.style = style;
        }

        /**
         * Constructs a new body
         * @class Body
         * @classdesc describes a PageObject's Body
         * @memberOf WrapperClassService
         * @param {string} text
         * @param {Object} style
         */
        function Body(text, style) {
            this.text = text;
            this.style = style || {background:'#ffffff'};
        }

        /**
         * Constructs a new footer
         * @class Footer
         * @classdesc describes a PageObject's Footer
         * @memberOf WrapperClassService
         * @param {string} text
         * @param {Object} style
         */
        function Footer(text, style) {
            this.text = text;
            this.style = style;
        }

        /**
         * Constructs a new wrapper
         * @class Wrapper
         * @classdesc describes a PageObject's wrapper
         * @memberOf WrapperClassService
         * @param {string} type
         * @param {Header} header
         * @param {Body} body
         * @param {Footer} footer
         * @param {boolean} shadow
         * @param {Object} style
         */
        function Wrapper(type, header, body, footer, shadow, style) {
            this.type = type;
            this.header = header;
            this.body = body;
            this.footer = footer;
            this.shadow = shadow;
            this.style = style;
        }

        return {
            wrapper: Wrapper,
            header: Header,
            body: Body,
            footer: Footer,
            newWrapper: newWrapper
        };
        ////////////////////
        /**
         * Builds a empty wrapper
         * @function wrapper
         * @returns {Wrapper}
         */
        function newWrapper() {
            return new Wrapper(
                null,
                new Header(null, null, null, {}),
                new Body(null, {}),
                new Footer(null, {}),
                false,
                {}
            );
        }
    })
;