angular.module('cerberus.ui-util')
    .directive('nimTabHandler', function(_, $timeout){
        return {
            restrict: 'A',
            link: function(scope, element){
                var jqElement = $(element);
                //var state = scope.$eval('vm.instance.workflowObjects[state]');
                var dataSet = scope.$eval('vm.temp[state]'),
                    sectionsShown = scope.$eval('vm.sectionsShown'),
                    fieldsShown = scope.$eval('vm.fieldsShown'),
                    fieldsActive = scope.$eval('vm.fieldsActive');

                // Get necessary form info from workflow state
                var forms = [];

                scope.$watch('vm.instance.workflowObjects[state]', function(state){
                    forms = _.map(_.get(state, 'model.forms', []), function(formModel){
                        return {
                            // Sort form objects by tabindex after rejecting ignored form object types
                            objects: _.sortBy(_.reject(_.get(formModel, 'form.objects', []), function(formObj){
                                var config = formObj.model.config;
                                var type = config.type;
                                var dataType = config.dataType;

                                // Ignore form objects that meet these criteria
                                return config.isHidden || type == 'table' || type == 'map' || type == 'signature' || dataType == 'readonly' || dataType == 'static';
                            }), 'model.tag.attrs.tabindex'),
                            sections: _.get(formModel, 'form.sections', []),
                            formOriginId: formModel.originatorId
                        };
                    });
                }, true);

                // Sets the active state to catch all keypress events
                jqElement
                    .keypress(function(e){
                        // Tab keyCode = 9
                        if(e.keyCode === 9) {
                            e.preventDefault();
                        }
                    })
                    .keyup(function(e){
                        if(e.keyCode === 9) {
                            e.preventDefault();
                        }
                    })
                    .keydown(function(e){
                        if(e.keyCode === 9) {
                            e.preventDefault();
                            tabHandler(e.shiftKey);
                        }
                    })
                ;

                /**
                 * Find the next form object or button and focus on it
                 * @param shift - User pressed shift + tab
                 */
                function tabHandler(shift){
                    // Focused form object
                    var focusedElement = jqElement.find('.nim-form-object *:focus, .nim-form-object:focus').closest('.nim-form-object'),
                        modelId = focusedElement.attr('nim-form-object-id'),
                        formOriginId = focusedElement.attr('form-origin-id'),
                        section = focusedElement.closest('div[gridster]').attr('nim-section-name');

                    var focusedButton = jqElement.find('.nim-instances-buttons button:focus');

                    // Next form object to be focused, if there is one
                    var nextFormObject = null;

                    // If modelId and formOriginId are present, current item is a form object
                    if(modelId && formOriginId) {
                        // Finds any radios in form object
                        var radios = focusedElement.find('input[type="radio"]');

                        // If current element is a radio/checkbox, focus the next one
                        if(radios.length && focusNextRadio(radios, shift)){
                            return;
                        }

                        // If current form object is not a radio button or the selected item is the
                        // last radio in the form object, find the next available form object
                        nextFormObject = getNextFormObject(forms, formOriginId, modelId, dataSet, section, shift);
                    }
                    else if(focusedButton.is('button')){
                        // If currently selected item is an instance button, focus the next one
                        focusButton(focusedButton, shift);
                        return;
                    }
                    else {
                        // If user was focused on some other element in the window,
                        // focus on the first available form object
                        var formIndex = shift ? forms.length - 1 : 0;
                        nextFormObject = getNextFormObject(forms, forms[formIndex].formOriginId, null, dataSet, section, shift);
                    }

                    // If an available form object was found, focus on it
                    if(nextFormObject){
                        focusFormObject(nextFormObject.model, shift);
                    }
                    else {
                        // If there are no more form objects after the previous one, move to instance buttons
                        focusButton(focusedElement, shift);
                    }
                }

                /**
                 * Starting from currently selected form object, find next available form object to focus
                 * @param forms
                 * @param formOriginId - ID of current form
                 * @param modelId - ID of currently selected form object
                 * @param dataSet
                 * @param sectionName - Section containing current form object
                 * @param shift - If true, find previous form object
                 * @returns {*} - Next form object
                 */
                function getNextFormObject(forms, formOriginId, modelId, dataSet, sectionName, shift){
                    if(shift){
                        return getPreviousFormObject(forms, formOriginId, modelId, dataSet, sectionName);
                    }

                    // Find the index of the form we should be starting from
                    var currentFormIndex = _.findIndex(forms, 'formOriginId', parseInt(formOriginId));

                    for(var formIndex = currentFormIndex; formIndex < forms.length; formIndex++){
                        var form = forms[formIndex];
                        var nextObjectIndex = 0;

                        // If form object's modelId is provided, start from the next form object
                        if(modelId && formIndex == currentFormIndex){
                            var matchCriteria = {
                                model: {
                                    config: {
                                        modelId: modelId
                                    }
                                }
                            };

                            if(sectionName){
                                matchCriteria.model.display = {
                                    section: sectionName
                                };
                            }

                            nextObjectIndex = _.findIndex(form.objects, matchCriteria) + 1;
                        }

                        for(nextObjectIndex; nextObjectIndex < form.objects.length; nextObjectIndex++){
                            var formObj = form.objects[nextObjectIndex],
                                id = formObj.model.config.modelId,
                                section = formObj.model.display.section,
                                isSectionShown = section !== '__nimHiddenSection' && (!section || _.get(sectionsShown, scope.state + '.' + formOriginId + '.' + section));

                            if(fieldsActive[scope.state][id] && fieldsShown[scope.state][id] && isSectionShown){
                                // Change to tab of next form object
                                changeFormTab(formIndex);
                                return formObj;
                            }
                        }
                    }

                    return null;
                }

                /**
                 * Starting from currently selected form object, find previous available form object to focus
                 * @param forms
                 * @param formOriginId - ID of current form
                 * @param modelId - ID of currently selected form object
                 * @param dataSet
                 * @param sectionName - Section containing current form object
                 * @returns {*} - Previous form object
                 */
                function getPreviousFormObject(forms, formOriginId, modelId, dataSet, sectionName){
                    // Find the index of the form we should be starting from
                    var currentFormIndex = _.findIndex(forms, 'formOriginId', parseInt(formOriginId));

                    for(var formIndex = currentFormIndex; formIndex >= 0; formIndex--){
                        var form = forms[formIndex];
                        var previousObjectIndex = form.objects.length - 1;

                        // If form object's modelId is provided, start from the next form object
                        if(modelId && formIndex == currentFormIndex){
                            var matchCriteria = {
                                model: {
                                    config: {
                                        modelId: modelId
                                    }
                                }
                            };

                            if(sectionName){
                                matchCriteria.model.display = {
                                    section: sectionName
                                };
                            }

                            previousObjectIndex = _.findIndex(form.objects, matchCriteria) - 1;
                        }

                        for(previousObjectIndex; previousObjectIndex >= 0; previousObjectIndex--){
                            var formObj = form.objects[previousObjectIndex],
                                id = formObj.model.config.modelId,
                                section = formObj.model.display.section,
                                isSectionShown = section !== '__nimHiddenSection' && (!section || _.get(sectionsShown, scope.state + '.' + formOriginId + '.' + section));

                            if(fieldsActive[scope.state][id] && fieldsShown[scope.state][id] && isSectionShown){
                                // Change to tab of next form object
                                changeFormTab(formIndex);
                                return formObj;
                            }
                        }
                    }

                    return null;
                }

                /**
                 * Focuses on a form object
                 * @param formObject
                 * @param shift
                 */
                function focusFormObject(formObject, shift){
                    var modelId = formObject.config.modelId;
                    var id = '#' + modelId;
                    var selector = '.nim-form-object[nim-form-object-id=' + modelId + ']';

                    $timeout(function(){
                        switch(formObject.config.type){
                            case 'text':
                            case 'autocomplete':
                            case 'location':
                            case 'textarea':
                            case 'datetime':
                                jqElement.find(id).focus();
                                break;
                            case 'number':
                                jqElement.find(selector).find('.k-numerictextbox').find('.k-input').focus();
                                break;
                            case 'select':
                                jqElement.find(selector).find('.k-dropdown').click();
                                break;
                            case 'multiselect':
                                jqElement.find(selector).find('.k-multiselect').find('.k-input').focus();
                                break;

                            // Focus on first radio
                            case 'radio':
                                var radio = jqElement.find(id + ' input[type="radio"]');
                                var radioIndex = shift ? radio.length - 1 : 0;
                                radio.eq(radioIndex).focus();
                                break;
                            case 'checkbox':
                                jqElement.find('input[type="checkbox"]' + id).focus();
                                break;
                        }
                    });
                }

                /**
                 * If one of the instance buttons is currently focused, focus the next one
                 * @param focusedElement - Currently focused jQuery element that may or may not be a button
                 * @param shift
                 */
                function focusButton(focusedElement, shift){
                    // Save/Submit/Close buttons on toolbar at the bottom of the state
                    var buttons = jqElement.find('.nim-instances-buttons button');
                    var nextIndex = buttons.index(focusedElement);

                    if(shift){
                        // Previous button
                        if(nextIndex == -1){
                            // If focused element is not a button, get last button
                            nextIndex = buttons.length - 1;
                        }
                        else {
                            nextIndex--;
                        }
                    }
                    else {
                        // Next button
                        nextIndex++;
                    }

                    // If currently selected item is not the last button, focus the next one
                    if(nextIndex < buttons.length && nextIndex >= 0){
                        buttons.eq(nextIndex).focus();
                    }
                    else {
                        // Get first available form object that we can tab to
                        var formIndex = shift ? forms.length - 1 : 0;
                        var firstFormObject = getNextFormObject(forms, forms[formIndex].formOriginId, null, dataSet, null, shift);

                        if(firstFormObject){
                            focusFormObject(firstFormObject.model, shift);
                        }
                        else if(shift){
                            // focus last button
                            buttons.eq(buttons.length - 1).focus();
                        }
                        else {
                            // If there are no available form objects,
                            // for whatever reason, focus on the first button
                            buttons.eq(0).focus();
                        }
                    }
                }

                /**
                 * For radio form objects, focus on the next item
                 * @param items - jQuery selected inputs of type radio
                 * @param shift - If true, focus the previous radio button
                 * @returns {boolean} - Tells calling function if next item was focused
                 */
                function focusNextRadio(items, shift){
                    var currentItem = items.filter(':focus');
                    var nextIndex = items.index(currentItem);

                    if(shift){
                        // Index of previous item
                        nextIndex--;
                    }
                    else {
                        // Index of next item
                        nextIndex++;
                    }

                    // If current item is not the last radio, focus the next one
                    if(nextIndex < items.length && nextIndex >= 0){
                        items.eq(nextIndex).focus();

                        // Let calling function know not to focus next form object
                        return true;
                    }

                    // Let calling function focus next form object instead
                    return false;
                }

                /**
                 * Clicks the tab header at specified index to change tabs
                 * @param index - Index of form in workflow state
                 */
                function changeFormTab(index){
                    // There are no tabs if there is only one form
                    if(forms.length > 1) {
                        // Tab headers
                        var tabs = jqElement.find('ul.nav-tabs > li');

                        // Rather than creep on the uib-tab scope,
                        // click the anchor tag to run its select function
                        tabs.eq(index).find('a').click();
                    }
                }
            }
        };
    })
;