angular.module('cerberus.les')
    .controller('LESLoanApplicationCloseAndFundModalCtrl', function(_, moment, kendo, $scope, $uibModalInstance, loanApplicationId, loanAmount, noteRate, lesLoanApplicationsService){
        var vm = this;

        $scope._finalLoanAmount = loanAmount;
        $scope._finalNoteRate = noteRate;

        vm.loanApplicationId = loanApplicationId;
        vm.loanAmount = kendo.toString(loanAmount, 'c2');
        vm.noteRate = kendo.toString(noteRate, 'p2');
        vm.closing = false;
        vm.fundingData = {
            finalLoanAmount: loanAmount,
            finalNoteRate: noteRate
        };
        vm.dateFundedOptions = {
            format: 'g',
            parseFormats: [
                'yyyy-MM-ddTHH:mm:ss.fffZ',
                'yyyy-MM-dd HH:mm:ss.fff',
                'dddd, MMMM dd, yyyy h:mm:ss tt',
                'M/d/yy',
                'M-d-yy',
                'M/d/yyyy',
                'M-d-yyyy',
                'MM/dd/yy',
                'MM-dd-yy',
                'MM/dd/yyyy',
                'MM-dd-yyyy',
                'M/d/yy h:mm tt',
                'M-d-yy h:mm tt',
                'M/d/yyyy h:mm tt',
                'M-d-yyyy h:mm tt',
                'MM/dd/yy h:mm tt',
                'MM-dd-yy h:mm tt',
                'MM/dd/yyyy h:mm tt',
                'MM-dd-yyyy h:mm tt',
                'M/d/yy h:mm:ss tt',
                'M-d-yy h:mm:ss tt',
                'M/d/yyyy h:mm:ss tt',
                'M-d-yyyy h:mm:ss tt',
                'MM/dd/yy h:mm:ss tt',
                'MM-dd-yy h:mm:ss tt',
                'MM/dd/yyyy h:mm:ss tt',
                'MM-dd-yyyy h:mm:ss tt'
            ]
        };
        vm.finalLoanAmountOptions = {
            decimals: 2,
            format: 'c2',
            min: 0,
            step: 0.01
        };
        vm.finalNoteRateOptions = {
            decimals: 4,
            format: 'p2',
            min: 0,
            step: 0.01
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok(){
            vm.closing = true;

            var data = angular.copy(vm.fundingData);

            if (_.isDate(data.dateFunded)) {
                data.dateFunded = data.dateFunded.toISOString();
            }    
            
            lesLoanApplicationsService.updateLoan(vm.loanApplicationId,{action:'closed',data:data}).then(function(){
                vm.closing = false;
                $uibModalInstance.close();
            }, function(){
                vm.closing = false;
            });
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Removes Kendo widgets
        $scope.$on('$destroy', function(){
            _.result(vm, 'dateFundedPicker.destroy');
            _.result(vm, 'finalLoanAmountBox.destroy');
        });

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;