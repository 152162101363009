angular.module('cerberus.admin')
    .controller('WidgetsFormsCtrl', function WidgetsFormsCtrl(_, moment, $scope, $location, $uibModal, widgetId, DesignerUtilityService,
                                                              FormsDesignerService, TemplatesServices, WidgetsService) {
        /* Populate Scope */
        $scope.widgetPublicKey = widgetId;
        $scope.widgetModel = {};
        $scope.activeRoute = 'forms';
        $scope.formArray = [];
        $scope.predicate1 = 'name';
        $scope.reverse1 = false;

        /* Forms Logic */
        $scope.openFormDesigner = function (widgetId, formId) {
            $location.url('/settings/widgets/' + widgetId + '/forms/' + formId);
        };

        $scope.openFormModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/forms/form-modal.tpl.html',
                controller: 'FormModalCtrl',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    form: function (){
                        return {
                            templateId: '',
                            name: '',
                            description: ''
                        };
                    }
                }
            });
            modalInstance.result.then(function (data) {
                FormsDesignerService.create(data, $scope.widgetPublicKey).then(function(){
                    $scope.init();
                });
            });
        };
        $scope.init = function(){
            WidgetsService.getAllForms($scope.widgetPublicKey).then(function(formArray){
                $scope.formArray = formArray;

                _.forEach(formArray, function(form){
                    form.lastModified = moment.utc(form.lastModified, 'MMMM, DD YYYY HH:mm:ss').toDate();
                });
            });

            // Still needed, for now, to get the widget name and icon for the title
            WidgetsService.getWidget($scope.widgetPublicKey).then(function(widgetModel){
                $scope.widgetModel = angular.copy(widgetModel);
            });
        };
        $scope.init();
    })
;