angular.module('cerberus.admin')
    .controller('FormsDesigner_imageCtrl', function FormsDesigner_imageCtrl($scope, $uibModalInstance, $window, autoTabIndex,
                                                                                  editFlag, formObj, idArray, sections, widgetId,
                                                                                  DesignerUtilityService, FormsDesignerListService, _, formOriginId) {
        // Populate Scope
        $scope.formOriginId = formOriginId;
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.formFields = [];
        $scope.sections = sections;
        $scope.widgetFieldIds = idArray;
        $scope.formFieldObjects = [];

        $scope.areaTypeOptions = [
            {
                display: 'Circle',
                value: 'circle'
            },
            {
                display: 'Square',
                value: 'square'
            }
        ];

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;