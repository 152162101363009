angular.module('cerberus.admin')
    .controller('WorkspacesUsersBatchAddModalCtrl', function WorkspacesUsersBatchAddModalCtrl($scope, $uibModalInstance, emailList) {
        var vm = this;

        vm.emails = emailList;
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        vm.ok = function(){
            $uibModalInstance.close();
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;