angular.module('cerberus.les')
    .factory('lesQuickApplicationService', function (_, apiPath, kendo, $rootScope, $q, $http, $location, $uibModal, $timeout, OdataUtilityService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';

        return {
            submitQuickApplication: submitQuickApplication,
            getQuickApplication: getQuickApplication,
            updateQuickApplication: updateQuickApplication,
            openQuickAppEligibilityPage: openQuickAppEligibilityPage,
            defaultQuickAppData: defaultQuickAppData,
            loanPurposeOptions: loanPurposeOptions,
            occupancyOptions: occupancyOptions,
            propertyTypeOptions: propertyTypeOptions,
            citizenshipOptions: citizenshipOptions,
            currencyOptions: currencyOptions,
            bankruptcyTypeOptions: bankruptcyTypeOptions,
            dispositionTypeOptions: dispositionTypeOptions,
            dispositionDateOptions: dispositionDateOptions,
            unitNumberOptions: unitNumberOptions,
            ficoScoreOptions: ficoScoreOptions,
            ltvOptions: ltvOptions,
            employmentDurationOptions: employmentDurationOptions,
            popoverHTML: popoverHTML,
            delinquencyNumberOptions: delinquencyNumberOptions,
            delinquencyNumberLookupOptions: delinquencyNumberLookupOptions,
            showFormFieldValidation: showFormFieldValidation,
            productEligibilityTableOptions: productEligibilityTableOptions,
            productRateDetailTableOptions: productRateDetailTableOptions,
            openFindingsReportModal: openFindingsReportModal,
            openNewQuickAppModal: openNewQuickAppModal,
            setNgModels: setNgModels,
            toggleMortgageLates12Months: toggleMortgageLates12Months,
            toggleMortgageLates24Months: toggleMortgageLates24Months,
            toggleMortgageLates36Months: toggleMortgageLates36Months,
            isCondominium: isCondominium,
            runCalculations: runCalculations,
            displayPercentage: displayPercentage
        };
      
        function submitQuickApplication(data) {
            var deferred = $q.defer();

            $http.post(apiPath + 'les/quickApp', { quickAppData: data })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
  
            return deferred.promise;
        }

        function getQuickApplication(id) {
            var deferred = $q.defer();

            $http.get(apiPath + 'les/quickApp/' + id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
  
            return deferred.promise;
        }
      
        function updateQuickApplication(id, data) {
            var deferred = $q.defer();

            $http.post(apiPath + 'les/quickApplications/' + id, { quickAppData: data })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
  
            return deferred.promise;
        }

        function openQuickAppEligibilityPage(instanceId) {
            $location.url('quick-application/' + instanceId + '/eligibility').replace();
        }

        function defaultQuickAppData() {
            return {
                // LOAN
                loanPurpose: { display: 'Purchase', type: 'purchase', id: 'opt1' },
                estimatedClosingCosts: 0,
                baseLoanAmount: '',
                ltv: 0, // !R - static
                cltv: 0, // !R - static

                monthsOwned: 0, // !R
                existingLienAmount: 0,
                otherLienAmount: 0,
                cashOut: 0, // !R - static

                // PROPERTY
                propertyType: {},
                occupancyType: {},
                numberOfUnits: 1,
                state: '',
                nonWarrantable: false,

                appraisedValue: '',
                purchasePrice: '',
                originalCost: '', // !R
                borrowerTaxesInsuranceAndDues: '',

                // BORROWER
                borrowerName: '',
                citizenship: {},
                // employmentDurationMonths: 0,
                fico: '',
                hasForeignCredit: false,
                nonOccupantCoBorrower: false,
                firstTimeHomeBuyer: false,
                selfEmployed: false,
                totalIncome: '',
                presentHousingExpense: 0,

                // REO
                rentalIncome: 0,
                subjPropGrossRent: 0, // !R
                // reoMortAndLiensAmount: 0,
                // reoTaxesInsuranceAndDues: 0,

                // ASSETS
                totalLiquidAssets: 0,
                totalGiftAssets: 0,

                // LIABILITIES
                totalLiabilities: 0,

                delinquent1to12Months: false,
                num30DayDelinquencies12Months: 0,
                num60DayDelinquencies12Months: 0,
                num90DayDelinquencies12Months: 0,
                num120DayDelinquencies12Months: 0,

                delinquent13to24Months: false,
                num30DayDelinquencies24Months: 0,
                num60DayDelinquencies24Months: 0,
                num90DayDelinquencies24Months: 0,
                num120DayDelinquencies24Months: 0,

                delinquent25to36Months: false,
                num30DayDelinquencies36Months: 0,
                num60DayDelinquencies36Months: 0,
                num90DayDelinquencies36Months: 0,
                num120DayDelinquencies36Months: 0,

                // PUBLIC RECORDS        
                hasBankruptcy: false,
                bankruptcyType: {},
                bankruptcyDisposition: {},
                bankruptcyDispositionDate: '',
        
                hasDeedInLieu: false,
                // deedInLieuDisposition: {},
                deedInLieuDispositionDate: '',
        
                hasForeclosure: false,
                // foreclosureDisposition: {},
                foreclosureDispositionDate: '',
        
                hasShortSale: false,
                // shortSaleDisposition: {},
                shortSaleDispositionDate: ''
            };
        }
      
        function loanPurposeOptions(/*scope*/) {
            // return oDataLookup(scope, 'loanPurpose', '4AEB01AB5CC5FF6A4F134BC55601EC84-62', 'LoanPurposeType_LoanPurposeTypeName');
            
            // return {
            //     dataTextField: 'display',
            //     dataValueField: 'id',
            //     dataSource: {
            //         data: [
            //             { display: 'Purchase', id: 'opt1' },
            //             { display: 'Refinance', id: 'opt2' },
            //             { display: 'Refinance (Cash Out)', id: 'opt3' }
            //         ]
            //     }
            // };

            return [
                { display: 'Purchase', type: 'purchase', id: 'opt1' },
                { display: 'Refinance', type: 'refinance', id: 'opt2' }
                // { display: 'Refinance (Cash Out)', type: 'refinance', subType: 'cash-out', id: 'opt3' }
            ];
        }
      
        function occupancyOptions(scope) {
            return oDataLookup(scope, 'occupancyType', '152D91996210747934ADF46B440255E3-64', 'PropertyUsageType_PropertyUsageTypeName');
        }
      
        function propertyTypeOptions(scope) {
            return oDataLookup(scope, 'propertyType', '76AB0251EF9DCDEEF0F8A057E94695BD-50', 'GSEPropertyType_GSEPropertyTypeName');
        }
      
        function citizenshipOptions(scope) {
            return oDataLookup(scope, 'citizenship', '63A8134B2CD17356F209AD47B39B284A-93', 'CitizenshipResidencyType_CitizenshipResidencyTypeName');
        }
      
        function bankruptcyTypeOptions(scope) {
            // return oDataLookup(scope, 'bankruptcyType', 'DF6FE69518E85D5A8DC7353842D02CAB-199', 'BankruptcyType_Name');
            var options = oDataLookup(scope, 'bankruptcyType', '4A8F11E8303E962ACBA34DC10F3C2F57-165', 'PublicRecordsType_PublicRecordTypeName');

            options.dataSource.filter = {
                logic: 'and',
                filters: [{
                    field: 'PublicRecordsType_PublicRecordTypeName',
                    operator: 'startswith',
                    value: 'Bankruptcy'
                }]
            };

            return options;
        }
      
        function dispositionTypeOptions(scope) {
            // TODO: Double-check this query
            return oDataLookup(scope, 'bankruptcyDisposition', 'FC3E253972637FEC4EE555D22A9ADC76-167', 'PublicRecordsDispositionTypes_DispositionTypeName');
        }
      
        function dispositionDateOptions() {
            return {
                format: 'MM/dd/yyyy',
                depth: 'month',
                start: 'month'
            };
        }
      
        function currencyOptions() {
            return {
                decimals: 2,
                min: 0,
                format: 'c',
                step: 0.01
            };
        }
    
        function unitNumberOptions() {
            return {
                value: 1,
                decimals: 0,
                max: 4,
                min: 0,
                format: '###'
            };
        }
    
        function ficoScoreOptions() {
            return {
                decimals: 0,
                max: 850,
                min: 0,
                format: '###'
            };
        }
    
        function ltvOptions() {
            return {
                decimals: 4,
                min: 0,
                step: 0.01,
                format: 'p2'
            };
        }
    
        function employmentDurationOptions() {
            return {
                decimals: 0,
                min: 0,
                max: 99,
                format: '##',
                placeholder: '(Months)'
            };
        }

        function popoverHTML(helpText) {
            return '<div class="help-popover">' + helpText + '</div>';
        }

        function delinquencyNumberOptions() {
            return {
                decimals: 0,
                min: 0
            };
        }

        function delinquencyNumberLookupOptions() {
            return {
                dataTextField: 'display',
                dataValueField: 'value',
                valuePrimitive: true,
                dataSource: {
                    data: [
                        { display: '0', value: 0 },
                        { display: '1', value: 1 },
                        { display: '2', value: 2 },
                        { display: '3', value: 3 },
                        { display: '4', value: 4 },
                        { display: '5', value: 5 },
                        { display: '6', value: 6 },
                        { display: '7', value: 7 },
                        { display: '8', value: 8 },
                        { display: '9', value: 9 }
                    ]
                }
            };
        }

        function oDataLookup(scope, modelId, viewId, display) {
            return {
                dataTextField: 'display',
                dataValueField: 'id',
                optionLabel: { display: 'Choose...', id: '' },
                filter: 'contains',
                dataSource: {
                    type: 'odata',
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            url: '/server/rest/v1/views/' + viewId + '/odata.svc',
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authentication', 'Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            },
                            data: function (options) {
                                var params = {
                                    $lookupDisplay: display,
                                    $top: 20,
                                    $orderBy: display
                                };

                                if (options.filter) {
                                    params.$filter = OdataUtilityService.toOdataFilter(options.filter);
                                    delete options.filter;
                                }

                                return params;
                            },
                            dataType: 'json'
                        }
                    },
                    change: function (e) {
                        if (!e.action && e.items.length == 1) {
                            delete e.items[0].__count;
                        }    
                    }
                },
                change: function (e) {
                    if (e.sender.value() == -1) {
                        scope.$apply('vm.dataSet.' + modelId + ' = {}');
                    }
                },
                dataBinding: function (e) {
                    var value = scope.vm.dataSet[modelId];
                    
                    if (!_.isEmpty(value) && !e.sender.dataSource.get(value.id)) {
                        e.sender.dataSource.add(value);
                    }
                }
            };
        }

        function showFormFieldValidation(form, fieldName) {
            var field = form[fieldName];
            if (field) {
                // return field.$invalid;
                return field.$invalid && (field.$dirty || form.$submitted);
            }

            return false;
        }

        function productEligibilityTableOptions(eligibilityType, lenderData) {
            var products = [],
                filters = [];
            
            if (eligibilityType) {
                filters.push({
                    field: 'eligibility',
                    operator: 'eq',
                    value: eligibilityType
                });
            }

            _.forEach(lenderData, function (lender) {
                _.forEach(lender.programs, function (lenderProgram) {
                    _.forEach(lenderProgram.programs, function (prog) {
                        _.forEach(prog.products, function (product) {
                            var productProgramData = {
                                lenderName: lender.name,
                                // lenderId: lender.id,
                                // conduit: lender.conduit || {},
                                programName: lenderProgram.progName,
                                // programId: lenderProgram.id,
                                investorName: prog.investorName,
                                docType: prog.docType,
                                grade: prog.grade,
                                creditScore: prog.creditScore,
                                // conditions: prog.conditions,
                                reasons: prog.reasons,
                                adjustments: prog.adjustments
                            };

                            products.push(
                                _.assign({ rate: lowestEligibleRate(product) }, product, productProgramData)
                            );
                        });
                    });
                });
            });

            var openRows = {},
                clickHandlerSet = false;

            return {
                selectable: false,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: 'Contains',
                            startswith: 'Starts with'
                        },
                        number: {
                            lte: 'Is less than or equal to'
                        }
                    }
                },
                resizable: false,
                sortable: true,
                detailTemplate: '<table kendo-grid k-options="::vm.productRateDetailTableOptions(vm.quickApplicationId, \'#: data.id #\', vm.eligibilityType, #: kendo.stringify(data) #)"></table>',
                columns: [
                    {
                        title: 'Eligibility', width: 100, filterable: false,
                        template: function (dataItem) {
                            var labelClass = '',
                                text = '';
                            
                            switch (dataItem.eligibility) {
                                case 'eligible':
                                    labelClass = 'label-success';
                                    text = 'Eligible';
                                    break;

                                case 'nearmiss':
                                    labelClass = 'label-warning';
                                    text = 'Near Miss';
                                    break;

                                case 'ineligible':
                                    labelClass = 'label-danger';
                                    text = 'Refer/Ineligible';
                                    break;
                                    
                                default:
                                    return '';
                            }

                            return '<span class="label ' + labelClass + '">' + text + '</span>';
                        }
                    },
                    { title: 'Investor', field: 'investorName', width: 120 },
                    { title: 'Program', field: 'programName', width: 120 },
                    { title: 'Product', field: 'productName', width: 160 },
                    { title: 'Doc Type', field: 'docType', width: 120, filterable: { multi: true, checkAll: false } },
                    { title: 'Rate', field: 'rate', format: '{0:n2} %', width: 80 },
                    {
                        title: 'Tags', filterable: false,
                        template: function(dataItem){
                            var labels = [];

                            if (dataItem.prepaymentPenaltyTermMonths !== '') {
                                labels.push('<span class="label label-info">PPP ' + dataItem.prepaymentPenaltyTermMonths + 'M</span> ');
                            }
                            
                            if (dataItem.interestOnlyTerm !== '') {
                                labels.push('<span class="label label-info">IO ' + (dataItem.interestOnlyTerm / 12) + 'YR</span> ');
                            }

                            return labels.join(' ');
                        }
                    }
                ],
                dataSource: {
                    serverFiltering: false,
                    data: products,
                    sort: [
                        {
                            field: 'rate',
                            dir: 'asc'
                        }       
                    ],
                    filter: {
                        logic: 'and',
                        filters: filters
                    },
                    schema: {
                        model: {
                            fields: {
                                conduit: { default: {} },
                                rate: { type: 'number' }
                            }
                        }
                    }
                },
                dataBound: function (e) {
                    var grid = e.sender;

                    if (!clickHandlerSet) {
                        clickHandlerSet = true;

                        grid.tbody.on('click', '.k-master-row', function (event) {
                            var uid = angular.element(event.currentTarget).data('uid');

                            if (!angular.element(event.target).is('a.k-icon')) {
                                if (openRows[uid]) {
                                    grid.collapseRow(event.currentTarget);
                                    openRows[uid] = false;
                                }
                                else {
                                    grid.expandRow(event.currentTarget);
                                    openRows[uid] = true;
                                }
                            }
                        });
                    }

                    openRows = {};
                },
                detailExpand: function (e) {
                    var uid = e.masterRow.data('uid');
                    openRows[uid] = true;
                },
                detailCollapse: function (e) {
                    var uid = e.masterRow.data('uid');
                    openRows[uid] = false;
                }
            };
        }

        function productRateDetailTableOptions(loanAppId, productId, eligibilityType, productData) {
            return {
                filterable: false,
                resizable: false,
                sortable: true,
                columns: [
                    {
                        title: ' ', width: 31, attributes: { 'class': 'text-center' },
                        template: function (dataItem) {
                            var iconClass = '';
                            
                            switch (dataItem.eligibility) {
                                case 'eligible':
                                    iconClass = 'text-success';
                                    break;

                                case 'nearmiss':
                                    iconClass = 'text-warning';
                                    break;

                                case 'ineligible':
                                    iconClass = 'text-danger';
                                    break;
                                    
                                default:
                                    return '';
                            }

                            return '<i class="fa fa-circle ' + iconClass + '"></i>';
                        }
                    },
                    { title: 'Rate', field: 'noteRate', format: '{0:p3}', width: 80 },
                    // { title: 'Price', field: 'lenderPrice', format: '{0:n2}', width: 80 },
                    { title: 'Discount Points', template: _.partial(discountTemplate, 'discount', '{0}'), width: 110 },
                    { title: 'Discount Cost', template: _.partial(discountTemplate, 'buyUpDown', '{0:c0}') },
                    { title: 'P & I', field: 'payment', format: '{0:c0}', width: 80 },
                    { title: 'PITIA', field: 'pitia', format: '{0:c0}', width: 80 },
                    { title: 'DTI', field: 'dti', format: '{0:p2}', width: 80 },
                    {
                        title: ' ',
                        width: 92,
                        command: [
                            {
                                name: 'detailsButton', text: 'Findings', className: 'btn btn-sm k-primary',
                                click: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.stopImmediatePropagation();

                                    var grid = this,
                                        row = angular.element(e.target).closest("tr"),
                                        pricingData = grid.dataItem(row);
                
                                    if (productId) {
                                        $timeout(function () {
                                            var index = grid.dataSource.indexOf(pricingData);
                                            openFindingsReportModal(loanAppId, productId, productData, index);                                            
                                        });
                                    }    
                                }
                            }
                        ]
                    }
                ],
                dataSource: {
                    data: productData.pricing,
                    sort: [{
                        field: 'noteRate',
                        dir: 'asc'
                    }]
                }
            };
        }

        function lowestEligibleRate(dataItem) {
            var eligibilities = _.groupBy(dataItem.pricing, 'eligibility');

            var pricing = [];
            if (eligibilities['eligible']) {
                pricing = eligibilities['eligible'];
            }
            else if (eligibilities['nearmiss']) {
                pricing = eligibilities['nearmiss'];
            }
            else if (eligibilities['ineligible']) {
                pricing = eligibilities['ineligible'];
            }

            var minimum = _.min(pricing, 'noteRate');
            if (minimum) {
                return minimum.noteRate * 100;
            }

            return '';
        }

        function openFindingsReportModal(id, productId, product, pricingIndex) {
            var modalInstance = $uibModal.open({
                size: 'lg',
                templateUrl: 'les/quick-applications/templates/quick-application-findings.tpl.html',
                controller: 'LESQuickApplicationFindingsCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id),
                    productId: _.constant(productId),
                    product: _.constant(product),
                    pricingIndex: _.constant(pricingIndex)
                }
            });

            return modalInstance.result;
        }

        function discountTemplate(field, format, dataItem) {
            var value = dataItem[field],
                formattedValue = kendo.format(format, value);

            if (value < 0) {
                return '<span class="text-bold">' + formattedValue + '</span>';
            }

            return formattedValue;
        }

        function openNewQuickAppModal(instanceId) {
            var modalInstance = null;
            
            if (instanceId) {
                modalInstance = $uibModal.open({
                    templateUrl: 'les/quick-applications/templates/edit-quick-application-modal.tpl.html',
                    controller: 'LESEditQuickApplicationModalCtrl',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        instanceId: _.constant(instanceId)
                    }
                });
            }
            else {
                modalInstance = $uibModal.open({
                    templateUrl: 'les/quick-applications/templates/new-quick-application-modal.tpl.html',
                    controller: 'LESNewQuickApplicationModalCtrl',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {}
                });
            }    

            return modalInstance.result;
        }

        function setNgModels(scope, dataSet) {
            // scope._loanPurpose = _.get(dataSet, 'loanPurpose.id', '');
            scope._estimatedClosingCosts = _.get(dataSet, 'estimatedClosingCosts', '');
            scope._subjPropGrossRent = _.get(dataSet, 'subjPropGrossRent', '');
            scope._baseLoanAmount = _.get(dataSet, 'baseLoanAmount', ''); 
            // scope._ltv = _.get(dataSet, 'ltv', ''); 
            scope._monthsOwned = _.get(dataSet, 'monthsOwned', '');
            scope._existingLienAmount = _.get(dataSet, 'existingLienAmount', '');
            scope._otherLienAmount = _.get(dataSet, 'otherLienAmount', '');
            scope._cashOut = _.get(dataSet, 'cashOut', '');
            scope._propertyType = _.get(dataSet, 'propertyType.id', '');
            scope._occupancyType = _.get(dataSet, 'occupancyType.id', '');
            scope._appraisedValue = _.get(dataSet, 'appraisedValue', '');
            scope._purchasePrice = _.get(dataSet, 'purchasePrice', '');
            scope._originalCost = _.get(dataSet, 'originalCost', '');
            scope._borrowerTaxesInsuranceAndDues = _.get(dataSet, 'borrowerTaxesInsuranceAndDues', '');
            scope._totalIncome = _.get(dataSet, 'totalIncome', '');
            scope._presentHousingExpense = _.get(dataSet, 'presentHousingExpense', '');
            scope._citizenship = _.get(dataSet, 'citizenship.id', '');
            // scope._employmentDurationMonths = _.get(dataSet, 'employmentDurationMonths', '');
            scope._fico = _.get(dataSet, 'fico', '');
            scope._totalLiquidAssets = _.get(dataSet, 'totalLiquidAssets', '');
            scope._totalGiftAssets = _.get(dataSet, 'totalGiftAssets', '');
            scope._rentalIncome = _.get(dataSet, 'rentalIncome', '');
            scope._reoMortAndLiensAmount = _.get(dataSet, 'reoMortAndLiensAmount', '');
            scope._reoTaxesInsuranceAndDues = _.get(dataSet, 'reoTaxesInsuranceAndDues', '');
            scope._totalLiabilities = _.get(dataSet, 'totalLiabilities', '');
            scope._bankruptcyType = _.get(dataSet, 'bankruptcyType.id', '');
            scope._bankruptcyDisposition = _.get(dataSet, 'bankruptcyDisposition.id', '');
            scope._bankruptcyDispositionDate = _.get(dataSet, 'bankruptcyDispositionDate', '');
            scope._deedInLieuDispositionDate = _.get(dataSet, 'deedInLieuDispositionDate', '');
            scope._foreclosureDispositionDate = _.get(dataSet, 'foreclosureDispositionDate', '');
            scope._shortSaleDispositionDate = _.get(dataSet, 'shortSaleDispositionDate', '');
        }

        function toggleMortgageLates12Months(dataSet) {
            if (!dataSet.delinquent1to12Months) {
                dataSet.num30DayDelinquencies12Months = 0;
                dataSet.num60DayDelinquencies12Months = 0;
                dataSet.num90DayDelinquencies12Months = 0;
                dataSet.num120DayDelinquencies12Months = 0;
            }
        }

        function toggleMortgageLates24Months(dataSet) {
            if (!dataSet.delinquent13to24Months) {
                dataSet.num30DayDelinquencies24Months = 0;
                dataSet.num60DayDelinquencies24Months = 0;
                dataSet.num90DayDelinquencies24Months = 0;
                dataSet.num120DayDelinquencies24Months = 0;
            }
        }

        function toggleMortgageLates36Months(dataSet) {
            if (!dataSet.delinquent25to36Months) {
                dataSet.num30DayDelinquencies36Months = 0;
                dataSet.num60DayDelinquencies36Months = 0;
                dataSet.num90DayDelinquencies36Months = 0;
                dataSet.num120DayDelinquencies36Months = 0;
            }
        }

        function isCondominium(propertyTypeDisplay) {
            if (propertyTypeDisplay) {
                return propertyTypeDisplay.indexOf('Condominium') >= 0;
            }

            return false;
        }

        function runCalculations(scope, dataSet) {
            var safeDivide = function (numerator, denominator) {
                if (!numerator || !denominator) {
                    return 0;
                }

                return numerator / denominator;
             };

            // LTV
            scope.$watchGroup(['vm.dataSet.loanPurpose.type', 'vm.dataSet.baseLoanAmount', 'vm.dataSet.purchasePrice', 'vm.dataSet.appraisedValue'], function (newValues/*, oldValues*/) {
                // if (!angular.equals(newValues, oldValues)) {
                    var loanPurpose = newValues[0],
                        baseLoanAmount = newValues[1],
                        purchasePrice = newValues[2],
                        appraisedValue = newValues[3];

                    if (loanPurpose == 'refinance') {
                        dataSet.ltv = safeDivide(baseLoanAmount, appraisedValue);
                    }
                    else {
                        dataSet.ltv = safeDivide(baseLoanAmount, Math.min(appraisedValue, purchasePrice));
                    }
                // }
            });

            // CLTV
            scope.$watchGroup(['vm.dataSet.loanPurpose.type', 'vm.dataSet.appraisedValue', 'vm.dataSet.otherLienAmount', 'vm.dataSet.baseLoanAmount'], function (newValues/*, oldValues*/) {
                // if (!angular.equals(newValues, oldValues)) {0
                    var loanPurpose = newValues[0],
                        appraisedValue = newValues[1],
                        otherLiens = newValues[2],
                        baseLoanAmount = newValues[3];

                    if (loanPurpose == 'refinance') {
                        dataSet.cltv = safeDivide(baseLoanAmount + otherLiens, appraisedValue);
                    }
                    else {
                        dataSet.cltv = 0;
                    }
                // }
            });

            // Cash Out
            scope.$watchGroup(['vm.dataSet.loanPurpose.type', 'vm.dataSet.existingLienAmount', 'vm.dataSet.estimatedClosingCosts', 'vm.dataSet.baseLoanAmount'], function (newValues, oldValues) {
                if (!angular.equals(newValues, oldValues)) {
                    var loanPurpose = newValues[0],
                        existingLiens = newValues[1],
                        estClosingCosts = newValues[2],
                        baseLoanAmount = newValues[3];

                    if (loanPurpose == 'refinance') {
                        dataSet.cashOut = Math.max(baseLoanAmount - (existingLiens + estClosingCosts), 0);
                        scope._cashOut = _.get(dataSet, 'cashOut', ''); 
                    }
                    else {
                        dataSet.cashOut = 0;
                    }
                }
            });
        }

        function displayPercentage(ltv) {
            return kendo.toString(ltv, 'p2');
        }
    })
;