angular.module('cerberus.account')
    .factory('UsersProfileListService', function UsersProfileListService() {
        return {
            profileFields: profileFields,
            addressBookCountries: addressBookCountries,
            mailServerDataModel: mailServerDataModel,
            mailServerPriorities: mailServerPriorities,
            mailServerVersions: mailServerVersions
        };

        function profileFields(){
            return {
                Address: [
                    { label: 'Display', key: 'display' },
                    { label: 'Attention', key: 'attn' },
                    { label: 'Address line 1', key: 'addr1' },
                    { label: 'Address line 2', key: 'addr2' },
                    { label: 'City', key: 'city' },
                    { label: 'State/Province/Region', key: 'state' },
                    { label: 'Zip', key: 'zip' },
                    { label: 'Country', key: 'country' }
                ],
                'Mail Server': [
                    { label: 'To', key: 'to' }
                ]
            };
        }

        function addressBookCountries(){
            return ['United States'];
        }

        function mailServerDataModel(){
            return {
                isExchangeServer: false,
                protocol: 'HTTP',
                ssl: false,
                tls: false,
                priority: "Normal",
                serverVersion: '2007'
            };
        }

        function mailServerPriorities(){
            return [
                {display: "Urgent"},
                {display: "High"},
                {display: "Normal"},
                {display: "Low"},
                {display: "Non-urgent"}
            ];
        }

        function mailServerVersions(){
            return [
                {display: "2003"},
                {display: "2007"},
                {display: "2010"}
            ];
        }
    });
