angular.module('cerberus')
    .constant('mapboxConfig', {
        token: 'pk.eyJ1IjoibmltYnVzc3ciLCJhIjoiY2o0amd3YXh5MDh4bjJxcWpxYXQwdHFzaSJ9.JwV5qI6JUww1P8-GP7DBWg'
    })
    .constant('apiPath', '/server/rest/v1/') // with trailing slash
    .constant('fbUrl', 'https://intellibrief.firebaseio.com')
    .constant('userDefinedIdRegex', /^[A-Za-z][A-Za-z0-9]{2,19}$/)
    .constant('statusList',{
        1: { name: 'Active', color: 'success' },
        2: { name: 'Deleted', color: 'danger' },
        3: { name: 'Disabled', color: 'warning' },
        4: { name: 'Pending', color: 'info' },
        5: { name: 'Failed', color: 'danger' },
        6: { name: 'Complete', color: 'primary' }
    })
;