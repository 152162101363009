angular.module('cerberus.core')
    .controller('nimVizProgressCtrl',function(_, $scope, $timeout, VizUtilityService, VizProgressService, $firebaseObject, fbUtil) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;

        vm.value = 0;
        vm.percent = 0;

        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function(){
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function() { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            });

        // Init
        init();

        // Event Bindings
        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            if($scope.progress) {
                debouncedRefresh();
            }
        });

        $scope.$on('$destroy', function () {
            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.progress) {
                $scope.progress.destroy();
                $scope.progress = null;
            }
        });

        function init() {
            var options = angular.copy($scope.pageObject.viz.settings);

            vm.dataSource = VizProgressService.buildDataSource($scope.pageObject, $scope, options);
            vm.options = options;

            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, vm.dataSource);
        }
    })
    .directive('nimVizProgress', function() {
        return {
            restrict: 'AE',
            templateUrl: 'core/pages/viz/progress/progress.tpl.html',
            scope: true,
            require: '^nimPageObject',
            controller: 'nimVizProgressCtrl',
            controllerAs: 'vm'
        };
    })
;