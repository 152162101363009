angular.module('cerberus.ui')
    .controller('nimFilterFormCtrl', function nimFilterFormCtrl(_, moment, $scope, $rootScope, $element, $timeout, OdataUtilityService, VizUtilityService) {
        var vm = this;

        $scope.btnClass = $scope.btnClass || 'default';
        $scope.debounce = {
            debounce: {
                'default': 300,
                blur: 0
            }
        };

        vm.value = null;
        vm.endValue = null;
        vm.operator = 'eq';
        vm.defaultFilterSet = false;
        vm.operatorSymbols = OdataUtilityService.operatorSymbols();
        vm.operators = OdataUtilityService.operators();
        vm.dateRangeOperators = _.map(vm.operators.dateRange, dateRangeMap);
        vm.dateRanges = {
            '': true,
            ytd: true,
            qtd: true,
            today: true,
            yesterday: true,
            week: true
        };

        vm.applyFilter = applyFilter;
        vm.clearFilter = clearFilter;
        vm.scopeApplyFilter = scopeApplyFilter;
        vm.dateRangeOpFilter = dateRangeOpFilter;
        vm.setDateRangeValues = setDateRangeValues;

        init();

        function init() {
            if($scope.params){
                vm.value = $scope.params.value || vm.value;
                vm.endValue = $scope.params.endValue || vm.endValue;
                vm.operator = $scope.params.operator || vm.operator;

                _.assign(vm.dateRanges, $scope.params.dateRangeOperators);

                vm.lookupOptions = {
                    optionLabel: {
                        id: '',
                        display: 'Choose...'
                    },
                    dataTextField: 'display',
                    dataValueField: 'id',
                    filter: 'contains',
                    change: scopeApplyFilter,
                    dataSource: {
                        type: 'odata',
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 20,
                        transport:{
                            read:{
                                dataType:'json',
                                url:'/server/rest/v1/views/' + $scope.params.viewId + '/odata.svc?$lookupDisplay=' + $scope.params.column,
                                beforeSend: function(xhr){
                                    xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                                    xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                                },
                                // data: function (dataOptions) {
                                //     var urlParams = {};
                                    
                                //     if (dataOptions.filter) {
                                //         urlParams.$filter = OdataUtilityService.toOdataFilter(dataOptions.filter);
                                //         delete dataOptions.filter;
                                //     }

                                //     return urlParams;
                                // }
                                data: function (dataOptions) {
                                    var urlParams = {},
                                        filterConfig = {filters: [], logic: 'and'};
                                    
                                    if (dataOptions.filter) {
                                        // filterConfig = angular.copy(dataOptions.filter);
                                        filterConfig.filters.push(angular.copy(dataOptions.filter));
                                        delete dataOptions.filter;
                                    }

                                    if ($scope.params.filter) {
                                        filterConfig.filters.push($scope.params.filter);
                                    }

                                    if ($scope.params.useParentFilter) {
                                        var parentFilters = [];

                                        _.forEach($scope.params.parentFilterMap, function (field, filterKey) {
                                            var filterValue = angular.copy($scope.filters[filterKey]);
                                            if (filterValue) {
                                                _.forEach(filterValue.filters, function (filter) {
                                                    filter.field = field;
                                                });

                                                parentFilters.push(filterValue);
                                            }
                                        });

                                        if (parentFilters.length > 0) {
                                            filterConfig.filters.push({
                                                filters: parentFilters,
                                                logic: $scope.params.parentFilterLogic || 'and'
                                            });
                                        }
                                    }

                                    VizUtilityService.cleanFilterConfiguration(filterConfig.filters);
                                    if (filterConfig.filters.length > 0) {
                                        urlParams.$filter = OdataUtilityService.toOdataFilter(filterConfig);
                                    }

                                    return urlParams;
                                }
                            }
                        },
                        change: function(e){
                            var selectedItem = vm.value;

                            if (selectedItem && selectedItem.id) {
                                var id = selectedItem.id,
                                    dataItem = e.sender.get(id);
                                
                                if (id !== -1 && !dataItem) {
                                    e.sender.add(selectedItem);
                                }
                                else if (!selectedItem.display && dataItem) {
                                    vm.value = dataItem;
                                }
                            }    
                        },
                        sort: {field: 'display', dir: 'asc'}
                    }
                };

                if ($scope.params.useParentFilter) { 
                    $scope.$watch(function () {
                        return _.pick($scope.filters, _.keys($scope.params.parentFilterMap));
                    }, function () {
                        _.result(vm, 'lookupFilter.dataSource.read');
                    }, true);
                }
            }

            if($scope.type === 'dateRange' && $scope.params.operator){
                setDateRangeValues($scope.params.operator);
            }

            if($scope.type === 'lookup'){
                fixLookupWidth();
            }

            if($scope.defaultFilter && !_.isEmpty($scope.defaultFilter[$scope.id])){
                vm.value = $scope.defaultFilter[$scope.id];
                vm.defaultFilterSet = true;
            }
            else if ($scope.filters[$scope.id] && !$scope.isPreview) {
                readFilter();
                return;
            }

            if(vm.value){
                applyFilter();
            }
        }

        // This adjusts the dropdownlist's width to fit the input group
        function fixLookupWidth() {
            $scope.$watchGroup([
                function () { return $element.find('.form-group').width(); },
                function () { return $element.find('.input-group-btn').width(); }
            ], function (widths) {
                var groupWidth = widths[0],
                    buttonWidth = widths[1],
                    selectWidth = groupWidth - buttonWidth;
                
                var select = $element.find('.input-group > span.k-dropdown');

                if (select.length) {
                    select.width(selectWidth);
                }
            });
        }

        function applyFilter(){
            if(!_.isNull(vm.value) || !_.isNull(vm.endValue)) {
                switch ($scope.type) {
                    case 'string':
                    case 'date':
                        $scope.filters[$scope.id] = simpleFilter();
                        return;

                    case 'number':
                        $scope.filters[$scope.id] = numberFilter();
                        return;

                    case 'lookup':
                        $scope.filters[$scope.id] = lookupFilter();
                        return;

                    case 'dateRange':
                        $scope.filters[$scope.id] = dateRangeFilter();
                        return;
                }
            }

            $scope.filters[$scope.id] = null;
        }

        // Kendo callback - manually calls digest
        function scopeApplyFilter(){
            $timeout(vm.applyFilter);
        }

        function simpleFilter(){
            if(vm.value || vm.value === 0) {
                return {
                    filters: [{
                        value: vm.value,
                        operator: vm.operator,
                        field: '__nimColumn__'
                    }],
                    logic: 'and'
                };
            }

            return null;
        }

        function lookupFilter(){
            if(_.get(vm.value, 'id') && vm.value.id !== -1) {
                return {
                    filters: [{
                        value: vm.value.id,
                        lookupDisplay: vm.value.display,
                        operator: vm.operator,
                        field: '__nimColumn__'
                    }],
                    logic: 'and'
                };
            }
            return null;
        }

        function numberFilter(){
            var filters = [];

            switch(vm.operator){
                case 'between':
                    if(vm.value || vm.value === 0){
                        filters.push({
                            value: vm.value,
                            operator: 'gte',
                            field: '__nimColumn__'
                        });
                    }

                    if(vm.endValue || vm.endValue === 0){
                        filters.push({
                            value: vm.endValue,
                            operator: 'lte',
                            field: '__nimColumn__'
                        });
                    }

                    return {
                        filters: filters,
                        logic: 'and'
                    };

                case 'notBetween':
                    if(vm.value || vm.value === 0){
                        filters.push({
                            value: vm.value,
                            operator: 'lt',
                            field: '__nimColumn__'
                        });
                    }
                    if(vm.endValue || vm.endValue === 0){
                        filters.push({
                            value: vm.endValue,
                            operator: 'gt',
                            field: '__nimColumn__'
                        });
                    }
                    return {
                        filters: filters,
                        logic: 'or'
                    };

                default:
                    return simpleFilter();
            }
        }

        function dateRangeFilter(){
            if(vm.value || vm.endValue) {
                var filters = [];

                if(vm.value){
                    filters.push({
                        value: vm.value,
                        operator: 'gte',
                        field: '__nimColumn__'
                    });
                }

                if(vm.endValue){
                    filters.push({
                        value: vm.endValue,
                        operator: 'lte',
                        field: '__nimColumn__'
                    });
                }

                return {
                    filters: filters,
                    logic: 'and'
                };
            }

            return null;
        }

        function setDateRangeValues(preset){
            var startDateTime = moment(),
                endDateTime = moment();

            switch(preset){
                case 'year':
                    startDateTime.startOf('year');
                    endDateTime.endOf('year');
                    break;

                case 'lastYear':
                    startDateTime.startOf('year').subtract(1, 'years');
                    endDateTime.endOf('year').subtract(1, 'years');
                    break;

                case 'ytd':
                    startDateTime.startOf('year');
                    endDateTime.endOf('day');
                    break;

                case 'lastYtd':
                    startDateTime.startOf('year').subtract(1, 'years');
                    endDateTime.endOf('day').subtract(1, 'years');
                    break;

                case 'quarter':
                    startDateTime.startOf('quarter');
                    endDateTime.endOf('quarter');
                    break;

                case 'lastQuarter':
                    startDateTime.startOf('quarter').subtract(1, 'quarters');
                    endDateTime.endOf('quarter').subtract(1, 'quarters');
                    break;

                case 'qtd':
                    startDateTime.startOf('quarter');
                    endDateTime.endOf('day');
                    break;

                case 'month':
                    startDateTime.startOf('month');
                    endDateTime.endOf('month');
                    break;

                case 'lastMonth':
                    startDateTime.startOf('month').subtract(1, 'months');
                    endDateTime.endOf('month').subtract(1, 'months');
                    break;

                case 'nextMonth':
                    startDateTime.startOf('month').add(1, 'months');
                    endDateTime.endOf('month').add(1, 'months');
                    break;

                case 'mtd':
                    startDateTime.startOf('month');
                    endDateTime.endOf('day');
                    break;

                case 'week':
                    startDateTime.startOf('week');
                    endDateTime.endOf('week');
                    break;

                case 'lastWeek':
                    startDateTime.startOf('week').subtract(1, 'weeks');
                    endDateTime.endOf('week').subtract(1, 'weeks');
                    break;

                case 'nextWeek':
                    startDateTime.startOf('week').add(1, 'weeks');
                    endDateTime.endOf('week').add(1, 'weeks');
                    break;

                case 'today':
                    startDateTime.startOf('day');
                    endDateTime.endOf('day');
                    break;

                case 'yesterday':
                    startDateTime.startOf('day').subtract(1, 'days');
                    endDateTime.endOf('day').subtract(1, 'days');
                    break;
            }

            vm.value = startDateTime.toDate();
            vm.endValue = endDateTime.toDate();
            if(vm.startDatePicker) {
                vm.startDatePicker.value(vm.value);
            }
            if(vm.endDatePicker) {
                vm.endDatePicker.value(vm.endValue);
            }
        }

        function dateRangeMap(display, key){
            return {
                display: display,
                value: key
            };
        }

        function dateRangeOpFilter(operator){
            return vm.dateRanges[operator.value];
        }

        function readFilter() {
            var filter = $scope.filters[$scope.id];
            if (!_.isEmpty(filter.filters)) {
                switch ($scope.type) {
                    case 'string':
                    case 'date':
                        readSimpleFilter(filter);
                        return;

                    case 'number':
                        readNumberFilter(filter);
                        return;

                    case 'lookup':
                        readLookupFilter(filter);
                        return;

                    case 'dateRange':
                        readDateRangeFilter(filter);
                        return;
                }
            }    
        }

        function readSimpleFilter(filter) {
            var firstFilter = filter.filters[0];
            vm.value = firstFilter.value;
            vm.opterator = firstFilter.operator;
        }

        function readLookupFilter(filter) {
            var firstFilter = filter.filters[0];
            vm.value = { display: '', id: firstFilter.value };
            vm.operator = firstFilter.operator;

            if (firstFilter.lookupDisplay) {
                vm.value.display = firstFilter.lookupDisplay;
            }
        }

        function readNumberFilter(filter) {
            if (filter.filters.length > 1) {
                vm.value = filter.filters[0].value;
                vm.endValue = filter.filters[1].value;

                if (filter.logic == 'and') {
                    vm.operator = 'between';
                }
                else {
                    vm.operator = 'notBetween';
                }
            }
            else {
                readSimpleFilter(filter);
            }
        }

        function readDateRangeFilter(filter) {
            _.forEach(filter.filters, function (f) {
                if (f.operator == 'gte') {
                    vm.value = f.value;
                }
                else if (f.operator == 'lte') {
                    vm.endValue = f.value;
                }
            });
        }

        function clearFilter(){
            vm.value = null;
            vm.endValue = null;

            applyFilter();
        }
    })
    .directive('nimFilterForm', function() {
        return {
            restrict: 'AE',
            controller: 'nimFilterFormCtrl',
            controllerAs: 'vm',
            templateUrl: 'ui/nim-odata/filter-form.tpl.html',
            scope: {
                filters: '=nimFilterForm',
                defaultFilter: '=nimDefaultFilters',
                id: '@',
                type: '=',
                label: '=',
                icon: '=',
                params:'=',
                btnClass:'='
            }
        };
    })
;
