angular.module('cerberus.account')
    .factory('usersProfileService', function usersProfileService($http, $q, apiPath, toaster) {
            return {
                getAll: function (type) {
                    var deferred = $q.defer();
                    var endpoint = 'users/profile';
                    if(type){
                        endpoint = endpoint + '?type=' + type;
                    }
                    $http.get(apiPath + endpoint)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                get: function (id) {
                    var deferred = $q.defer();
                    $http.get(apiPath + 'users/profile/' + id)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                create: function (data) {
                    var deferred = $q.defer();
                    $http.post(apiPath + 'users/profile', data)
                        .success(function (value) {
                            toaster.pop('success', 'Saved');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                update: function (id,data) {
                    var deferred = $q.defer();
                    $http.put(apiPath + 'users/profile/' + id, data)
                        .success(function (value) {
                            deferred.resolve(value);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                remove: function (id) {
                    var deferred = $q.defer();
                    $http({method: 'DELETE', url: apiPath + 'users/profile/' + id})
                        .success(function(value){
                            deferred.resolve(value.DATA);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                getDefaultUserProfile: function (userId) {
                    var deferred = $q.defer();
                    $http.get(apiPath + 'users/' + userId + '/profiles?type=Profile')
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                verifyMailServerConnection: function(data){
                    var deferred = $q.defer();
                    $http.post(apiPath + 'exchange/server/connection/verify', data)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
