angular.module('cerberus')
    .controller('AppCtrl', function AppCtrl(_, $scope, $rootScope, $uibModal, $window, AuthService, toaster, WaitForUserData, MenusService, OrganizationsService, $location, localStorageService) {
        // ng-if Variables
        $scope.isDoneLoading = false;
        $scope.isModalOpen = false;
        $scope.isWorkspaceModalOpen = false;

        // UI Variables
        $scope.appUI = {
            isHeaderFixed: true,
            isLeftBarCollapsed: true,
            isRightBarCollapsed: true,
            isTopBarCollapsed: true
        };

        // Login Modal
        $scope.openLoginModal = function () {
            //Make sure modal is not already open (edge case for multiple login attempts after session timeout)
            if (!$scope.isModalOpen) {
                $scope.isModalOpen = true;

                // If another backdrop already exists, turn it into a login backdrop
                var existingBackdrop = angular.element($window.document).find('[uib-modal-backdrop]').addClass('login');

                var modalInstance = $uibModal.open({
                    templateUrl: 'core/auth/auth-login.tpl.html',
                    controller: 'AuthLoginCtrl',
                    backdropClass: 'login',
                    windowClass: 'modal-login',
                    keyboard: false,
                    backdrop: 'static'
                });
                modalInstance.result.then(function () {
                    existingBackdrop.removeClass('login');
                    $scope.isModalOpen = false;
                }, function () {
                    existingBackdrop.removeClass('login');
                    $scope.isModalOpen = false;
                });
            }
        };

        // Workspace Modal
        $scope.openWorkspacesModal = MenusService.openWorkspacesModal;

        var tokenRotating = false;
        $scope.$on('auth:rotateToken', function(event){
            if(!tokenRotating){
                tokenRotating = true;
                AuthService.refreshJwt().then(function(data){
                    // Set token in local storage
                    localStorageService.set('jwt', data.jwt);

                    // Set token in rootScope
                    $rootScope.authToken = data.jwt;

                    tokenRotating = false;
                });
            }
            event.preventDefault();
        });

        // Catch logout, or multiple failed login attempts
        $scope.$on('auth:loginCancelled', function () {
            $scope.openLoginModal();
        });

        //Catch server return of unauthorized (401)
        $scope.$on('auth:loginRequired', function () {
            $scope.openLoginModal();
        });

        // Catch server return of forbidden (403)
        $scope.$on('auth:forbidden', function (event, rejection) {
            // For app.nimbussw.com, this forbidden message is supposed to happen
            if(rejection.data.DATA === 'app' && rejection.data.MESSAGE === "Organization/Workspace does not exist or you do not have access."){
                // Redirect to other workspace
                redirectToUserWorkspace();
            }
            else {
                rejection.data.MESSAGE = rejection.data.MESSAGE || 'You do not have access; contact your Administrator';
                toaster.pop('warning', rejection.data.STATUS, rejection.data.MESSAGE);

                // Checks if auth failed because of workspace validity
                if (rejection.data.MESSAGE === "Organization/Workspace does not exist or you do not have access.") {
                    checkWorkspaceValidity(rejection.data.DATA); // For this error, DATA is the organization subdomain
                }
            }
        });

        // Init App on confirmed login
        $scope.$on('auth:loginConfirmed', function () {
            // Resolve Promise so ngRouter can resolve routes
            WaitForUserData.doneWaiting();
            // Check for a defined currentWorkspace id
            if (_.has($rootScope, 'userData.currentWorkspace.id')) {
                // Show the user interface
                $scope.isDoneLoading = true;
            }
            else if(_.has($rootScope, 'userData.currentOrg.defaultWorkspace')){
                toaster.pop('info', 'Redirecting', 'Workspace not specified; loading default.');
                $window.location.replace($rootScope.userData.currentOrg.defaultWorkspace + $location.path());
            }
            else {
                // Since there was not a good currentWorkspace id we show the selection modal
                $scope.openWorkspacesModal();
            }
        });

        // Try to start jump start app in case our cookie is still valid
        AuthService.getUserData(true);

        /**
         * Checks if current organization/workspace combination is valid
         * @param subdomain - app, cpc, nsi, etc.
         */
        function checkWorkspaceValidity(subdomain){
            OrganizationsService.getUserOrganizations().then(function(orgs){
                var orgWsExists = false;
                var host = $location.host();
                if(host === 'localhost'){
                    host = subdomain + '.nimbussw.com';
                }

                // Finds the current organization
                for(var i = 0; i < orgs.length; i++){
                    if(orgs[i].sub_domain === host){
                        var org = orgs[i];

                        // If valid organization, check if current workspace
                        // belongs to that organization
                        var ws = AuthService.getUrlWorkspace($location.absUrl());
                        for(var j = 0; j < org.workspaces.length; j++){
                            if(org.workspaces[j].path === ws){
                                orgWsExists = true;
                                break;
                            }
                        }
                        break;
                    }
                }

                // If organization/workspace is invalid, open modal
                // to let user navigate to different organization
                if(!orgWsExists){
                    // passes in the already loaded orgs
                    $scope.openWorkspacesModal(orgs);
                }
            });
        }

        /**
         * If user signed into app.nimbussw.com, redirect them to a workspace they belong to
         */
        function redirectToUserWorkspace(){
            OrganizationsService.getUserOrganizations().then(function(orgs){
                // If user belongs to one workspace, automatically navigate to it
                if(orgs.length === 1) {
                    $window.open('https://' + orgs[0]['sub_domain'], '_self');
                }
                else {
                    // Let them choose which workspace to go to
                    $scope.openWorkspacesModal(orgs);
                }
            });
        }
    })
;