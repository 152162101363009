angular.module('cerberus.core')
    .controller('nimVizChartBarCtrl', function nimVizChartBarCtrl(_, $scope, VizChartService, VizChartBarService, VizUtilityService, $firebaseObject, fbUtil, $timeout) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;
        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function(){
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function() { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            });

        init();

        // Event Bindings
        var removeChartSizeWatcher = angular.noop;
        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget === $scope.chart) {
                event.preventDefault();
                event.stopPropagation();

                var debouncedResizeFunc = _.debounce(function () {
                    widget.resize();
                }, 400, { leading: true, trailing: true });
                removeChartSizeWatcher = $scope.$watch(function(){
                    return widget.wrapper.width();
                }, debouncedResizeFunc);
            }
        });

        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            debouncedRefresh();
        });

        $scope.$on('$destroy', function () {
            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.chart) {
                $scope.chart.destroy();
                $scope.chart = null;
            }

            removeChartSizeWatcher();
        });

        function init() {
            var options = angular.copy($scope.pageObject.viz.settings);
            
            // Fix any misconfigurations
            VizChartService.fixChartOptions(options);

            // Ensure that autoBind is set to false to prevent multiple server calls
            // We want to manually bind the data because some options will depend on the server's response
            // See the dataSource.fetch() callback function
            options.autoBind = false;

            vm.dataSource = VizChartService.buildDataSource($scope.pageObject, $scope, options);
            options.dataSource = vm.dataSource;

            VizChartService.setSeriesColors(options, $scope.pageObject.params);

            // Expose options to view model
            vm.options = options;

            // Watch Filters
            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, vm.dataSource);
        }
    })
;