angular.module('cerberus.ui-util')
    .filter('kendoToString', function(kendo) {
        /**
         * @param {string} value
         * @param {string} format
         * @param {string} culture
         */
        return function(value, format, culture) {
            return kendo.toString(value, format, culture);
        };
    })
    .filter('kendoDateToString', function(kendo) {
        /**
         * @param {string} value
         * @param {string} format
         * @param {string} culture
         */
        return function(value, format, culture) {
            return kendo.toString(kendo.parseDate(value), format, culture);
        };
    })
    .filter('kendoFormat', function(kendo) {
        /**
         * @param {string} format
         * @param {string} value
         */
        return function(value, format) {
            return kendo.format(format, value);
        };
    })
    .filter('truncateCharacters', function () {
        return function (input, chars, breakOnWord) {
            if (isNaN(chars)) {
                return input;
            }
            if (chars <= 0) {
                return '';
            }
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    //get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                }else{
                    while(input.charAt(input.length-1) === ' '){
                        input = input.substr(0, input.length -1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    })
    .filter('truncateWords', function () {
        return function (input, words) {
            if (isNaN(words))  {
                return input;
            }
            if (words <= 0) {
                return '';
            }
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return input;
        };
    })
    .filter('nimSplit', function() {
        return function(input, delimiter) {
            delimiter = delimiter || ',';
            var arr = [];
            try{
                arr =  input.split(delimiter);
            } catch(e){
                arr[0] = input;
            }
            return arr;
        };
    })
    .filter('toLocalCalendar', function toLocalCalendar(moment) {
            return function (str) {
                str = moment.utc(str).local().calendar() || '';
                return str;
            };
        })
    .filter('toLocalDetail', function toLocalDetail(moment) {
            return function (str) {
                str = moment.utc(str).local().format("ddd, MMM Do YYYY, h:mm a") || '';
                return str;
            };
        })

    .filter('toUpperCase', function() {
        return function(str) {
            str = str || '';
            return str.toUpperCase();
        };
    })
    .filter('toLowerCase', function() {
        return function(str) {
            str = str || '';
            return str.toLowerCase();
        };
    })
    .filter('toTitleCase', function() {
        return function (str) {
            str = str || '';
            return str.replace(/([\w&`'â€˜â€™"â€œ.@:\/\{\(\[<>_]+-? *)/g,
                function(match, p1, index, title){
                    if (index > 0 && title.charAt(index - 2) != ":" &&
                        match.search(/^(a(nd?|s|t)?|b(ut|y)|en|for|i[fn]|o[fnr]|t(he|o)|vs?\.?|via)[ -]/i) > -1) {
                        return match.toLowerCase();
                    }
                    if (title.substring(index - 1, index + 1).search(/['"_{([]/) > -1) {
                        return match.charAt(0) + match.charAt(1).toUpperCase() + match.substr(2);
                    }
                    if (match.substr(1).search(/[A-Z]+|&|[\w]+[._][\w]+/) > -1 ||
                        title.substring(index - 1, index + 1).search(/[\])}]/) > -1) {
                        return match;
                    }
                    return match.charAt(0).toUpperCase() + match.substr(1);
                });
        };
    })
    .filter('toInitials', function() {
        return function(str) {
            str = str || '';
            var initials = '';
            var arr = str.split(' ');
            for (var i = 0; i < arr.length; i++) {
                initials += arr[i].substring(0,1).toUpperCase() + '.';
            }
            return initials;
        };
    })
    .filter('parseDate', function parseDate(moment) {
            return function (str) {
                var val = str;
                if(moment(str).isValid()){
                    val = moment(str).format('MM/DD/YY');
                }
                return val;
            };
        })
;