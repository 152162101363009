angular.module('cerberus.admin')
    .controller('RulesDetailsCtrl', function(_, $scope, $location, ruleId, ConfirmModalService, RulesService){
        var vm = this;

        vm.temp = {};
        vm.orig = {};
        vm.engines = [{
            value: 'nimbus',
            display: 'Nimbus'
        }];

        vm.isUnchanged = _isUnchanged;
        vm.save = _save;
        vm.cancel = _cancel;
        vm.openDeletionModal = _openDeletionModal;

        _init();

        function _init(){
            RulesService.getRule(ruleId).then(function(rule){
                vm.orig = _.pick(rule, ['name', 'type', 'path', 'description']);
                vm.temp = angular.copy(vm.orig);
            });
        }

        function _isUnchanged(data){
            return angular.equals(data, vm.orig);
        }

        function _save(data){
            RulesService.updateRule(ruleId, data).then(function(){
                _init();
            });
        }

        function _cancel(){
            $location.replace().url('/settings/rules');
        }

        function _openDeletionModal(data){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this rule?',
                instructionText: 'Type "DELETE ' + data.name + '" to confirm.',
                actionButtonText: 'Delete Rule Forever',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + data.name);
                },
                confirm: function(){
                    return RulesService.deleteRule(ruleId);
                },
                callback: function(){
                    $location.url('/settings/rules');
                }
            });
        }
    })
;