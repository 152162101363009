angular.module('cerberus.admin')
    .controller('WidgetsImportsJobsCtrl',
    function WidgetsImportsJobsCtrl(_, $window, $location, $timeout, authToken, widgetId, workspaceId,
                                    ImportService, ImportExchangeService, usersProfileService) {
            var vm = this;

            vm.importSettingsArray = [];
            vm.importJobModel = {
                jsonData: {},
                validate: true
            };
            vm.widgetPublicKey = widgetId;
            vm.importSource = null;
            vm.mailServers = [];
            vm.errors = null;

            vm.changeSource = function(){
                for(var i = 0; i < vm.importSettingsArray.length; i++){
                    if(vm.importJobModel.importSettingId == vm.importSettingsArray[i].id){
                        vm.importSource = vm.importSettingsArray[i].type;
                    }
                }
                if(vm.importSource.substring(0,8) === 'exchange'){
                    usersProfileService.getAll('Mail Server').then(function(data){
                       vm.mailServers = data;
                    });
                }
            };
            vm.getExchangeData = function(id,importSource){
                if(id){
                    ImportExchangeService.getExchangeData(id,importSource).then(function(data){
                        vm.importJobModel.jsonData.exchangeData = data;
                    });
                }
                else{
                    vm.importJobModel.jsonData.exchangeData = [];
                }
            };
            vm.submit = function(isValid){
                if(isValid){
                    ImportService.createImportJob(vm.widgetPublicKey,vm.importJobModel).then(function(){
                        $location.url('/settings/widgets/' + vm.widgetPublicKey + '/imports');
                    });
                }
            };
            vm.asyncOptions = {
                saveUrl: '/server/rest/v1/widgets/' + vm.widgetPublicKey + '/import/jobs',
                removeUrl: 'remove',
                autoUpload: false
            };
            vm.localizationOptions = {
                uploadSelectedFiles: 'Submit'
            };

            vm.onUpload = function(e) {
                delete vm.importJobModel.jsonData;
                e.data = vm.importJobModel;
                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1 /* OPENED */) {
                            xhr.setRequestHeader('Authentication','Bearer ' + authToken);
                            xhr.setRequestHeader('WSID', workspaceId);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            };
            vm.onError = function(e){
                $timeout(function(){
                    var resp = angular.fromJson(e.XMLHttpRequest.response);
                    vm.errors = _.get(resp, 'DATA.message', '');
                });
            };
            vm.onSuccess = function (){
                $timeout(function(){
                    vm.errors = null;
                    $location.url('/settings/widgets/' + vm.widgetPublicKey + '/imports');
                });
            };
            vm.cancel = function () {
                $location.url('/settings/widgets/' + vm.widgetPublicKey + '/imports');
            };

            //Init function
            _init();
            ////////////////////
            /**
             * Init controller data and server calls
             */
            function _init() {
                ImportService.getAllSettings(widgetId).then(function(d){
                    vm.importSettingsArray = d;
                });
            }
        })
;