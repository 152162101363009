/**
 * EXAMPLE
 *
 * <div nim-instance="OtherCtrl.instanceWindow" nim-action="" nim-id="" nim-widget-id=""></div>
 *
 */
angular.module('cerberus.core')
    .controller('NimInstanceCtrl', function NimInstanceCtrl(_, kendo, moment, apiPath, $scope, $attrs, $element, $rootScope, $timeout, $window, $log, InstancesService,
                                                            InstancesWindowService, WidgetsService, CalculationService, checkPrintingService, DeviceSniffService, draftsService,
                                                            FormsService, FormValidationService, labelPrintingService, normalPrintingService, printService, usersProfileService) {
        var vm = this;

        vm.instanceId = $attrs.nimId;
        vm.draftId = $attrs.nimDraftId;
        vm.widgetId = $attrs.nimWidgetId;
        vm.windowId = $attrs.nimWindowId;
        vm.action = $attrs.nimAction;
        vm.revisionId = $attrs.nimRevisionId;
        vm.validateOnOpen = $attrs.nimValidateOnOpen;
        vm.downloadApiPath = apiPath + 'download';
        vm.window = $scope[vm.windowId];
        vm.title = vm.window.title();
        vm.isSubForm = _.startsWith(vm.windowId, 'sub_form');
        vm.windowTitle = vm.isSubForm ? _.get($scope.subWindowOptions, 'title') : _.get($scope.windowOptions, 'title');
        vm.isAdmin = $rootScope.userData.currentWorkspace.isAdmin;
        vm.readingNewRecord = false; // For record that has been added to a form table, but not saved
        /* Data Containers */
        vm.instance = {};  // This holds the data returned by the server
        vm.states = [];  // This array is used for display all the states; holds workflowObject ids
        vm.relationships = [];
        vm.participants = [];
        /* Form Edit Data Containers */
        vm.orig = {}; // holds the original data for each state
        vm.temp = {}; // holds the current/edited data for each state
        vm.fieldOptions = {};  // This object holds dropdown values.
        vm.filters = {};  // Used to hold filters when one dropdown affects another.

        /* UI Variables */
        vm.excludeModelId = null; // This is the modelId to exclude from building when acting as a subform.
        vm.isProcessingUploads = false; // Used to prevent submission of a form while uploads are in progress.
        vm.createState = null; // Holds the workflowObject Id (or key) of the state that is being created/progressed
        vm.loading = true; // Used to display loading bar
        vm.forbidden = false; // Used to display warning if user lacks permission to view instance
        vm.forbiddenState = false; // Used to display warning if user lacks permission to view state
        vm.dataLoading = {};
        vm.dataSetLoaded = false;
        vm.formDoneLoading = {};
        vm.updating = false;
        vm.invalid = false;
        vm.edit = {}; // controls which state is currently being edited
        vm.activeState = null;
        vm.collapse = {}; // controls collapsing of different states
        vm.selectedForm = {}; // determines which form is selected in which state
        vm.autoFilling = {};
        vm.autoFilledFields = {};
        vm.showAutoFillScreen = false;
        //vm.toolbarOptions = {};
        vm.userData = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.instanceForms = {};
        vm.formObjectsInvalid = {};
        vm.autoFillData = {};
        vm.formIsActive = {};

        /* Window Functions */
        vm.closeWindow = _closeWindow;

        /* Form Functions */
        vm.editState = _editState;
        vm.openInstanceWindow = _openInstanceWindow;
        vm.selectForm = _selectForm;
        vm.isUnchanged = _isUnchanged;
        vm.submitForm = _submitForm;
        vm.updateInstanceAttrs = _updateInstanceAttrs;
        vm.saveDraft = _saveDraft;
        vm.cancelState = _cancelState;
        vm.deleteFile = _deleteFile;
        vm.getDownloadKey = _getDownloadKey;

        /* Toolbar Functions */
        vm.newInstance = _newInstance;
        vm.duplicateInstance = _duplicateInstance;
        vm.deleteInstance = _deleteInstance;
        vm.cancel = _cancel;
        vm.collapseAll = _collapseAll;
        vm.expandAll = _expandAll;
        vm.revisionOnClick = _revisionOnClick;
        vm.updateSchema = _updateSchema;
        vm.exportInstance = _exportInstance;
        vm.printInstanceChecks = _printInstanceChecks;
        vm.printInstanceLabels = _printInstanceLabels;

        /*Sub Form Functions*/
        vm.subFormCreate = _subFormCreate;

        vm.getParticipantInfo = _getParticipantInfo;

        /* Filters */
        vm.sectionFilter = FormsService.sectionFilter;

        vm.tabOnFocus = tabOnFocus;
        vm.tabOnBlur = tabOnBlur;

        vm.showFileSize = _showFileSize;
        vm.gridsterItemOptions = gridsterItemOptions;

        vm.dynamicGridsterOptions = {
            pushing: true,
            swapping: false,
            floating: true
        };

        vm.staticGridsterOptions = {
            pushing: true
        };

        vm.deregisterFunc = [];

        // Printer Variables
        $scope.pageCounter = '####';
        $scope.pageCurrent = '#';
        $scope.pageTotal = '#';

        vm.isProcessingPdf = false;
        vm.isStatePrinting = {};
        vm.toPdf = function (state) {
            if (!vm.isProcessingPdf) {
                vm.isProcessingPdf = true;
                vm.isStatePrinting[state] = true;
                vm.collapse[state] = false;

                $timeout(function () {
                    var formIndex = vm.selectedForm[state],
                        form = vm.instance.workflowObjects[state].model.forms[formIndex],
                        displayVal = {},
                        enabledPrintTypes = { normal: true },
                        formPrintType = _.get(form, 'form.settings.print.type', 'normal'),
                        browser = DeviceSniffService.deviceBrowser(),
                        isIE = _.startsWith(browser, 'IE');
                    
                    displayVal[vm.instanceId] = getRecordDisplayValue(vm.orig, _.get(vm.instance, 'widgetSettings.view.column', '')) || vm.title;

                    
                    var printConfigCb = function (config) {
                        var instance = angular.copy(vm.instance),
                            formData = angular.copy(form),
                            dataSet = {};
                        
                        _.forEach(vm.states, function (s) {
                            _.assign(dataSet, vm.orig[s]);
                        });

                        _.assign(formData.defaultDataSet, dataSet);
                        
                        instance.workflowObjects[state].model.forms = [formData];
                        instance.workflowPath = [state];

                        var defaultName = kendo.format('{0} - {1:u}', displayVal[vm.instanceId], new Date());
                        
                        normalPrintingService.printInstance(vm.instanceId, defaultName, config.options, instance, dataSet, vm.autoFillData).then(function () {
                            vm.isProcessingPdf = false;
                            vm.isStatePrinting[state] = false;
                        });
                        // var elem = $element.find('.instance-state-body[nim-state-id=' + state + ']');
                        
                        // normalPrintingService.printElement(elem, config.filename, config.options).then(function () {
                        //     vm.isProcessingPdf = false;
                        //     vm.isStatePrinting[state] = false;
                        // });
                    };

                    if (isIE || formPrintType !== 'normal') {
                        enabledPrintTypes[formPrintType] = true;

                        printService.openPrintConfigurationModal(vm.widgetId, [vm.instanceId], displayVal, enabledPrintTypes, {
                            type: 'normal'
                        }).then(printConfigCb, function(){
                            vm.isProcessingPdf = false;
                            vm.isStatePrinting[state] = false;
                        });
                    }
                    else {
                        printConfigCb({
                            type: 'normal',
                            options: {
                                landscape: false,
                                paperSize: 'letter',
                                margin: {
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    top: 0
                                }
                            }
                        });
                    }
                }, 1);
            }
        };
        // vm.toPdf = function (state) {
        //     if (!vm.isProcessingPdf) {
        //         vm.isProcessingPdf = true;
        //         vm.isStatePrinting[state] = true;
        //         vm.collapse[state] = false;

        //         $timeout(function () {
        //             var elem = $element.find('.instance-state-body[nim-state-id=' + state + ']'),
        //                 formIndex = vm.selectedForm[state],
        //                 formPrintSettings = vm.instance.workflowObjects[state].model.forms[formIndex].form.settings.print;
                    
        //             var defaultConfig = {
        //                 filename: vm.instance.widgetName,
        //                 options: {
        //                     margin: _.get(formPrintSettings, 'margin', {}),
        //                     pageSize: _.get(formPrintSettings, 'pageSize', [8.5, 11])
        //                 }
        //             };

        //             ExportService.exportElement(elem, defaultConfig, function () {
        //                 vm.isProcessingPdf = false;
        //                 vm.isStatePrinting[state] = false;
        //             });
        //         }, 1);
        //     }
        // };

        vm.init = _init;

        //Init the controller
        _init();
        ////////////////////

        $scope.$on('nimSignatureAccepted', function(e, data){
            vm.sigData = data;
        });
        /**
         * This grand function preps the instance window with all UI variables and form data.
         * Admittedly this is a heavy function and could use some clever optimization. ~ O(N(N + N^3))
         * @private
         */

        function _init() {
            clearWatchers();

            // Reset these values on "Submit" or "Submit & New"
            vm.instanceId = $attrs.nimId;
            vm.action = $attrs.nimAction;
            vm.validateOnOpen = $attrs.nimValidateOnOpen;
            vm.instance = {};
            vm.states = [];
            vm.relationships = [];
            vm.participants = [];
            vm.orig = {};
            vm.temp = {};
            vm.fieldOptions = {};
            vm.filters = {};
            vm.excludeModelId = null;
            vm.isProcessingUploads = false;
            vm.createState = null;
            vm.loading = true;
            vm.forbidden = false;
            vm.dataLoading = {};
            vm.formDoneLoading = {};
            vm.updating = false;
            vm.edit = {};
            vm.activeState = null;
            vm.collapse = {};
            vm.selectedForm = {};
            vm.userData = {};
            vm.autoFilling = {};
            vm.autoFilledFields = {};
            vm.showAutoFillScreen = false;
            vm.dataSetLoaded = false;
            vm.deregisterFunc = [];
            vm.sectionsShown = {};
            vm.fieldsShown = {};
            vm.fieldsActive = {};
            vm.fieldsRequired = {};
            vm.instanceForms = {};
            vm.formObjectsInvalid = {};
            vm.formIsActive = {};

            if ($attrs.nimReadingNew) {
                vm.readingNewRecord = $scope.$eval($attrs.nimReadingNew);
            }

            if (vm.instanceId) {
                InstancesService.getInstanceParticipants(vm.instanceId).then(function (participants) {
                    vm.participants = participants.participants;
                });
            }

            if (vm.action === 'create') {
                WidgetsService.getFirstState(vm.widgetId).then(function (data) {
                    _.remove(data.workflowObjects[1].model.forms, function (form) {
                        return !_.isObject(form) || _.isEmpty(form);
                    });

                    var firstState = "1";

                    if (vm.windowId && !vm.title) {
                        var window = $scope[vm.windowId];
                        if (!window.title()) {
                            vm.title = data.widgetName;
                            window.title(data.widgetName);
                        }
                    }

                    // Populate controller variables
                    vm.instance = data;
                    vm.states = [firstState];

                    // Enable the edit template
                    vm.createState = firstState;
                    vm.edit[firstState] = true;
                    vm.activeState = firstState;
                    vm.collapse[firstState] = false;
                    vm.selectedForm[firstState] = 0;
                    vm.sectionsShown[firstState] = {};

                    if (!vm.instance.widgetSettings.defaultAction) {
                        vm.instance.widgetSettings.defaultAction = vm.instance.widgetSettings.defaultSubmitAndNew ? 'submitAndNew' : 'submitAndClose';
                    }

                    // If a sub-form
                    if (vm.isSubForm) {
                        var setExcludeField = function (value) {
                            for (var i = 0; i < data.workflowObjects[vm.activeState].model.forms.length; i++) {
                                if (data.workflowObjects[vm.activeState].model.forms[i].defaultDataSet[vm.excludeModelId]) {

                                    if (_.isEmpty(vm.orig[vm.activeState])) {
                                        vm.orig[vm.activeState] = {};
                                    }

                                    vm.orig[vm.activeState][vm.excludeModelId] = value;
                                }
                            }
                        };

                        vm.excludeModelId = $scope.nimFormObject.param.fk;
                        //Editing a data item
                        if ($scope.editSubData) {
                            vm.orig[vm.activeState] = angular.copy($scope.editSubData).__nimData;
                            vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]].fileAttachments = angular.copy($scope.editSubData).__attachments || [];
                        }
                        ////set display to current value when adding a new item in an update
                        if ($scope.nimFormObject.__meta && $scope.nimFormObject.__meta.sub && $scope.nimFormObject.__meta.sub[vm.excludeModelId]) {
                            setExcludeField($scope.nimFormObject.__meta.sub[vm.excludeModelId]);
                        }
                        //// Set display value to 'Auto' on the linking modelId when in a create
                        else if (vm.excludeModelId) {
                            setExcludeField({ display: 'Auto' });
                        }
                    }

                    _.forEach(data.workflowObjects[vm.activeState].model.forms, function (form) {
                        vm.sectionsShown[firstState][form.originatorId] = {};
                    });

                    var setWatchersFunc = InstancesWindowService.populateDataAndParseFormulas("1", data.workflowObjects["1"].model.forms, vm.orig, vm.fieldOptions, calculationWatcher, sectionConditionWatcher, fieldConditionWatcher, autoFillWatcher);

                    //Check default data, TODO: auto fill needs to work too
                    if (!vm.readingNewRecord && !(vm.isSubForm && $scope.editSubData) && $scope.defaultData && $scope.defaultData.length > 0) {
                        for (var i = 0; i < $scope.defaultData.length; i++) {
                            var d = _.get($scope.defaultData[i], 'data.value');

                            if ($scope.defaultData[i].field.isLookup || $scope.defaultData[i].field.isCustomLookup) {
                                d = { id: _.get($scope.defaultData[i], 'data.value') };

                                if (_.has($scope.defaultData[i], 'data.lookupDisplay')) {
                                    d.display = $scope.defaultData[i].data.lookupDisplay;
                                }
                                // For table filters
                                else if (_.has($scope.defaultData[i], 'data.dataItem')) {
                                    d.display = _.get($scope.defaultData[i].data.dataItem, $scope.defaultData[i].field.lookupCol);
                                }
                            }

                            vm.orig[1][$scope.defaultData[i].field.modelId] = d;

                            fieldAutoFilled(1, $scope.defaultData[i].field.modelId);
                        }
                    }

                    // Add Toolbar
                    vm.toolbarOptions = InstancesWindowService.buildToolbar($scope, vm);

                    // Retrieve Drafts
                    if (vm.draftId) {
                        draftsService.get(vm.draftId).then(function (draftData) {
                            InstancesService.getDraft(draftData, vm.orig[firstState], vm.instance.workflowObjects[1].model.forms[vm.selectedForm[1]]);
                            _.assign(vm.temp, angular.copy(vm.orig));
                            vm.deregisterFunc = vm.deregisterFunc.concat(setWatchersFunc());

                            if (vm.validateOnOpen) {
                                $timeout(function () {
                                    vm.instanceForms[firstState].$setSubmitted(true);
                                    validateState(firstState);
                                    focusFirstInvalidForm(firstState);
                                }, 1);
                            }

                            // Stop loading
                            vm.loading = false;
                            vm.dataSetLoaded = true;

                            // Wait until first round of calculations has finished to copy temp to orig
                            $timeout(function () {
                                _.assign(vm.orig, angular.copy(vm.temp));
                            }, 100);
                        });
                    }
                    else {
                        $timeout(function () {
                            _.assign(vm.temp, angular.copy(vm.orig));
                            vm.deregisterFunc = vm.deregisterFunc.concat(setWatchersFunc());

                            // Stop loading
                            vm.loading = false;
                            vm.dataSetLoaded = true;
                        });

                        if (vm.validateOnOpen) {
                            $timeout(function () {
                                vm.instanceForms[firstState].$setSubmitted(true);
                                validateState(firstState);
                                focusFirstInvalidForm(firstState);
                            }, 1);
                        }

                        // Wait until first round of calculations has finished to copy temp to orig
                        $timeout(function () {
                            _.assign(vm.orig, angular.copy(vm.temp));
                        }, 100);
                    }
                },
                    function (response) {
                        vm.loading = false;
                        vm.forbidden = response['STATUS'] === 'Forbidden';
                    });
            }
            else if (vm.action === 'read' || vm.action === 'readonly') {
                InstancesService.getInstance(vm.instanceId).then(function (data) {
                    if (vm.windowId && !vm.title) {
                        var window = $scope[vm.windowId];

                        if (!window.title()) {
                            vm.title = data.widgetName;
                            window.title(data.widgetName);
                        }
                    }

                    _.forEach(data.recordRevision, function (rev) {
                        rev.createDate = moment.utc(rev.createDate, 'MMMM, DD YYYY HH:mm:ss').local().toDate();
                    });

                    // Populate controller variables
                    vm.instance = data;
                    vm.widgetId = data.widgetId;
                    vm.states = angular.copy(data.workflowPath).reverse(); // Reverse the copy, not the original

                    if (_.get(data, 'widgetSettings.enableInstanceRelationship') === true) {
                        InstancesService.getRelationships(vm.instanceId).then(function (relationships) {
                            vm.relationships = angular.copy(relationships);
                        });
                    }

                    if (!vm.instance.widgetSettings.defaultAction) {
                        vm.instance.widgetSettings.defaultAction = vm.instance.widgetSettings.defaultSubmitAndNew ? 'submitAndNew' : 'submitAndClose';
                    }

                    // If the last state recorded in the workflowPath is not equal to the state (not at a terminus)
                    if (data.workflowPath[data.workflowPath.length - 1] !== data.currentWorkflowObjectId) {
                        if (data.workflowObjects[data.currentWorkflowObjectId]) {
                            // Add the current state to the front.
                            vm.states.unshift(data.currentWorkflowObjectId);
                            vm.createState = data.currentWorkflowObjectId;
                            // Enable the edit template
                            var editableState = !data.workflowObjects[data.currentWorkflowObjectId].model.isFinal || data.workflowObjects[data.currentWorkflowObjectId].model.editable;
                            if (editableState && vm.action !== 'readonly') {
                                // if (!data.workflowObjects[data.currentWorkflowObjectId].model.isFinal && vm.action !== 'readonly') {
                                vm.activeState = data.currentWorkflowObjectId;
                                vm.selectedForm[data.currentWorkflowObjectId] = 0;
                            }
                        }
                    }
                    else if (data.workflowObjects[data.currentWorkflowObjectId].model.isFinal && data.workflowObjects[data.currentWorkflowObjectId].model.editable && vm.action !== 'readonly') {
                        vm.activeState = data.currentWorkflowObjectId;
                        vm.selectedForm[data.currentWorkflowObjectId] = 0;
                    }
                    else if (vm.isSubForm && vm.action !== 'readonly') {
                        vm.activeState = 1;
                    }

                    var forbiddenStates = _.remove(vm.states, function (s) {
                        return !data.workflowObjects[s];
                    });

                    _.forEach(forbiddenStates, function (s) {
                        vm.forbiddenState[s] = true;
                    });

                    if (vm.isSubForm && $scope.editSubData) {
                        vm.orig[vm.activeState] = angular.copy($scope.editSubData.__nimData);
                    }

                    // Populate form data objects & UI controls
                    _.forEach(vm.states, function (state, i) {
                        if (!vm.forbiddenState[state] && data.workflowObjects[state]) {
                            if (!angular.isDefined(vm.edit[state])) {
                                vm.edit[state] = false;
                            }
                            vm.collapse[state] = false;
                            vm.selectedForm[state] = 0;
                            vm.dataLoading[state] = true;
                            vm.formDoneLoading[state] = {};
                            vm.sectionsShown[state] = {};

                            var formChunks = _.chunk(data.workflowObjects[state].model.forms);
                            _.forEach(formChunks, function (formArray, j) {
                                vm.formDoneLoading[state][j] = false;
                                InstancesService.getFormData(vm.instanceId, formArray).then(formDataCallBack(i, j));
                            });
                        }
                    });

                    if (vm.isSubForm && vm.action !== 'readonly') {
                        vm.edit[vm.activeState] = true;
                        vm.excludeModelId = $scope.nimFormObject.param.fk;
                    }

                    // Add Toolbar
                    vm.toolbarOptions = InstancesWindowService.buildToolbar($scope, vm);

                    // Stop loading
                    vm.loading = false;
                }, function (response) {
                    vm.loading = false;
                    vm.forbidden = response === 'Forbidden';
                });
            }
            else if (vm.action === 'revision') {
                InstancesService.getRevision(vm.revisionId).then(function (data) {
                    if (vm.windowId && !vm.title) {
                        var window = $scope[vm.windowId];
                        if (!window.title()) {
                            vm.title = data.widgetName;
                            window.title(data.widgetName);
                        }
                    }

                    // Populate controller variables
                    vm.instance = data;
                    vm.states = angular.copy(data.workflowPath).reverse();

                    if (vm.instanceId && _.get(data, 'widgetSettings.enableInstanceRelationship') === true) {
                        InstancesService.getRelationships(vm.instanceId).then(function (relationships) {
                            vm.relationships = angular.copy(relationships);
                        });
                    }

                    if (!vm.instance.widgetSettings.defaultAction) {
                        vm.instance.widgetSettings.defaultAction = vm.instance.widgetSettings.defaultSubmitAndNew ? 'submitAndNew' : 'submitAndClose';
                    }

                    // Populate form data objects & UI controls
                    _.forEach(vm.states, function (state, i) {
                        if (!angular.isDefined(vm.edit[state])) {
                            vm.edit[state] = false;
                        }
                        vm.collapse[state] = false;
                        vm.selectedForm[state] = 0;
                        vm.dataLoading[state] = true;
                        vm.formDoneLoading[state] = {};
                        vm.sectionsShown[state] = {};

                        var formChunks = _.chunk(data.workflowObjects[state].model.forms);
                        _.forEach(formChunks, function (formArray, j) {
                            if (formArray[0].formId) {
                                vm.formDoneLoading[state][j] = false;
                                InstancesService.getFormData(vm.instanceId, formArray, vm.revisionId).then(formDataCallBack(i, j));
                            }
                        });
                    });

                    // Add Toolbar
                    vm.toolbarOptions = InstancesWindowService.buildToolbar($scope, vm);

                    // Stop loading
                    vm.loading = false;
                });
            }
        }

        function formDataCallBack(stateIndex, formIndex){
            return function(result){
                var formData = result.formData;
                var state = vm.states[stateIndex];
                var workflowObject = vm.instance.workflowObjects[state];

                vm.sectionsShown[state][formData[0].originatorId] = {};

                vm.formDoneLoading[state][formIndex] = true;
                vm.dataLoading[state] = !_.every(vm.formDoneLoading[state]);

                if(!_.isEmpty(formData[0])) {
                    if (vm.instance.workflowPath[vm.instance.workflowPath.length - 1] !== vm.instance.currentWorkflowObjectId) {
                        formData[0] = FormsService.processFormData(formData[0], formData[0].defaultDataSet);
                    }
                    else {
                        // Since items are added to gridster one-by-one in the order of the array,
                        // it is important to sort them first to prevent placement issues
                        formData[0].form.objects = FormsService.sortFormObjects(formData[0]);
                    }
                    var setWatchersFunc = InstancesWindowService.populateDataAndParseFormulas(state, formData, vm.orig, vm.fieldOptions, calculationWatcher, sectionConditionWatcher, fieldConditionWatcher, autoFillWatcher);

                    _.defaultsDeep(vm.temp, angular.copy(vm.orig));
                    _.assign(workflowObject.model.forms[formIndex], formData[0]);

                    if (state === vm.createState && workflowObject.model.defaultData) {
                        InstancesWindowService.parseDefaultData(vm.instance.workflowObjects, state, vm.instance.instanceWorkflow);
                    }     

                    if (state === vm.activeState) {
                        vm.edit[vm.activeState] = true;
                    }

                    vm.deregisterFunc = vm.deregisterFunc.concat(setWatchersFunc());
                }
                else {
                    workflowObject.model.forms[formIndex] = {};
                }

                if(!_.some(vm.dataLoading)){
                    vm.dataSetLoaded = true;

                    _.remove(workflowObject.model.forms, function(f) {
                        var form = angular.copy(f);
                        if (_.isObject(form)) {
                            delete form.$$hashKey;
                        }
                        return !_.isObject(form) || _.isEmpty(form);
                    });

                    var viewColumn = _.get(vm.instance, 'widgetSettings.view.column', '');
                    if(vm.action !== 'revision' && viewColumn){
                        var displayVal = getRecordDisplayValue(vm.orig, viewColumn);
                        if(displayVal) {
                            if(_.has(displayVal, 'display')) {
                                displayVal = displayVal.display;
                            }
                            var window = $scope[vm.windowId];
                            var newTitle = vm.title + ': ' + displayVal;
                            window.title(newTitle);
                        }
                    }

                    if (vm.validateOnOpen && vm.action == 'read') {
                        _editState(vm.instance.currentWorkflowObjectId);

                        $timeout(function () {
                            vm.instanceForms[vm.instance.currentWorkflowObjectId].$setSubmitted(true);
                            validateState(vm.instance.currentWorkflowObjectId);
                            focusFirstInvalidForm(vm.instance.currentWorkflowObjectId);
                        }, 1);
                    }
                }
            };
        }

        function getRecordDisplayValue(dataSet, column) {
            if (!column) {
                return '';
            }
            
            var flatData = {};
            var modelId = column.split('_')[1];

            _.forEach(dataSet, function(state){
                _.assign(flatData, state);
            });

            return flatData[modelId];
        }

        /**
         * TODO:  Try moving this to the InstancesWindowService
         * Creates an angular scope watcher for form field calculations.
         * Passed into InstancesWindowService.parseFormulaFields as a parameter
         * @param state
         * @param resultVar
         * @param operands
         * @param tokenArr
         * @returns {function} - unwatcher function
         */
        function calculationWatcher(state, resultVar, operands, tokenArr) {
            var watchExpressions = [_.partial(resultFieldDisplayed, state, resultVar), function () { return vm.activeState === state; }];
            var delayCalculation = vm.action != 'create' && vm.createState != state; // If this record is not new, don't calculate initially

            _.forEach(operands, function (op) {
                watchExpressions.push(
                    _.partial(operandValue, state, op)
                );
            });

            var watcher = _.partial(evaluateExpressions, watchExpressions);

            // newResults[0] - resultVar is displayed
            // newResults[1] - form is being edited 
            // newResults[>1] - operandValues 
            return $scope.$watch(watcher, function (newResults, oldResults) {
                var isDisplayed = newResults[0],
                    editingForm = newResults[1],
                    operandValues = _.drop(newResults, 2),
                    oldOperandValues = _.drop(oldResults, 2),
                    valueChanged = resultValuesChanged(operands, resultVar, operandValues, oldOperandValues),
                    resultFormExists = _.has(vm.temp, state),
                    resultFieldValue = _.get(vm.temp, state + '.' + resultVar.model),
                    resultFieldSet = (resultFieldValue || resultFieldValue === 0) && !_.isNaN(resultFieldValue) && !(_.isDate(resultFieldValue) && resultFieldValue.toString() === 'Invalid Date'),
                    resultNeedsUpdate = valueChanged || !resultFieldSet,
                    justAutoFilled = _.get(vm.autoFilledFields[state], resultVar.model, false);

                var changedOperands = [];

                if (isDisplayed && resultFormExists && resultNeedsUpdate && !justAutoFilled && editingForm) {
                    // Add each operand's value to the token array
                    _.forEach(operands, function (operand, i) {
                        var token = tokenArr[operand.index],
                            newValue = operandValues[i];

                        if (newValue && vm.autoFillData[operand.modelId]) {
                            newValue = _.merge({}, vm.autoFillData[operand.modelId], newValue);
                        }

                        if (operand.path) {
                            newValue = _.get(newValue, operand.path);
                        }

                        // Checks if empty lookup value
                        if (angular.equals(newValue, {}) || (_.get(newValue, 'id') === '-1')) {
                            newValue = '';
                        }

                        if (!angular.equals(token.value, newValue)) {
                            changedOperands.push(operand.modelId);
                        }

                        token.value = newValue;
                    });

                    if (delayCalculation) {
                        delayCalculation = false;
                        return;
                    }

                    var result = CalculationService.calculate(tokenArr);

                    var resultValue = result.value;

                    if (result.type === 'error') {
                        $log.warn(resultVar.model, '\t', result.value);
                        resultValue = '';
                    }
                    else if (_.isNumber(resultValue) && (!_.isFinite(resultValue) || _.isNaN(resultValue))) {
                        resultValue = '';
                    }
                    else if (_.isUndefined(resultValue)) {
                        resultValue = '';
                    }
                    
                    if (!angular.equals(vm.temp[state][resultVar.model], resultValue)) {
                        $log.info('Calculate:', resultVar.model + ' (' + resultValue + ')', '<-', changedOperands.join(','));
                        $timeout(function () {
                            if (vm.temp[state]) {
                                vm.temp[state][resultVar.model] = resultValue;
                            }
                            vm.filters[resultVar.model] = resultValue;
                        }, 0);
                    }
                }
                else if (delayCalculation) {
                    delayCalculation = false;
                }
            }, true);
        }

        function resultValuesChanged(operands, resultVar, newResults, oldResults) {
            return _.some(operands, function (op, index) {
                if (op.modelId === resultVar.model) {
                    return false;
                }

                return !angular.equals(newResults[index], oldResults[index]);
            });
        }

        function evaluateExpressions(operandWatchers) {
            return _.map(operandWatchers, function(getValue){
                return getValue();
            });
        }

        function operandValue(currentState, operand){
            var flatDataSet = {},
                modelId = _.trim(operand.modelId);
            
            if (modelId) {
                _.forEach(vm.temp, function (stateData, stateId) {
                    if (stateId != currentState) {
                        _.assign(flatDataSet, stateData);
                    }    
                });

                // Add current state data last to make sure it isn't overwritten
                _.assign(flatDataSet, vm.temp[currentState]);

                flatDataSet = _.merge({}, vm.autoFillData, flatDataSet);

                return _.get(flatDataSet, operand.modelId);
            }
            
            return '';
        }

        function resultFieldDisplayed(state, resultVar){
            var stateFieldsShown = _.get(vm.fieldsShown, state, {}),
                objDisplayed = stateFieldsShown[resultVar.model],
                sectionDisplayed = true;

            if(resultVar.section && resultVar.section !== '__nimHiddenSection'){
                var stateSectionsShown = _.get(vm.sectionsShown, state, {}),
                    formSectionsShown = _.get(stateSectionsShown, resultVar.id, {});

                sectionDisplayed = formSectionsShown[resultVar.section];
            }

            return !!(sectionDisplayed && objDisplayed);
        }

        function sectionConditionWatcher(state, formOriginId, section){
            vm.sectionsShown[state] = vm.sectionsShown[state] || {};
            vm.sectionsShown[state][formOriginId] = vm.sectionsShown[state][formOriginId] || {};

            if(!_.isEmpty(section.condition)) {
                var initialConditionSet = false,
                    watchExpressions = [],
                    expressionTemplate = 'vm.temp[\'{0}\'][\'{1}\']';

                _.forEach(section.condition, function (condition) {
                    watchExpressions.push(
                        kendo.format(expressionTemplate, state, condition.modelId)
                    );

                    if (condition.isFormObj) {
                        watchExpressions.push(
                            kendo.format(expressionTemplate, state, condition.val)
                        );
                    }
                });

                return $scope.$watchGroup(watchExpressions, function (newValues, oldValues) {
                    if (!initialConditionSet || !angular.equals(newValues, oldValues)) {
                        vm.sectionsShown[state] = vm.sectionsShown[state] || {};
                        vm.sectionsShown[state][formOriginId] = vm.sectionsShown[state][formOriginId] || {};
                        vm.sectionsShown[state][formOriginId][section.name] = FormsService.sectionCondition(section.condition, vm.temp[state], section.logic, vm.action);

                        initialConditionSet = true;
                    }
                });
            }

            vm.sectionsShown[state][formOriginId][section.name] = true;
            return angular.noop;
        }

        /**
         * Creates a watcher that checks a form field's condition and shows/hides/enables/disables it.
         * @param state
         * @param formOriginId
         * @param formObjectModel
         * @returns {function} de-registration function for watcher
         */
        function fieldConditionWatcher(state, formOriginId, formObjectModel){
            vm.fieldsShown[state] = vm.fieldsShown[state] || {};
            vm.fieldsActive[state] = vm.fieldsActive[state] || {};
            vm.fieldsRequired[state] = vm.fieldsRequired[state] || {};

            var modelId = formObjectModel.config.modelId,
                params = formObjectModel.param,
                conditionallyReadonly = params.conditionalAction === 'active' || params.conditionalAction === 'readonly',
                conditionallyRequired = params.conditionalAction === 'required' || params.conditionalAction === 'optional',
                alwaysReadonly = !!(params.disabled || params.autoIncrement),
                alwaysRequired = !!(formObjectModel.validation.required && formObjectModel.display.section !== '__nimHiddenSection');
            
            if(!_.isEmpty(params.condition) && !(conditionallyReadonly && alwaysReadonly) && !(conditionallyRequired && alwaysRequired)){
                var initialConditionSet = false,
                    section = formObjectModel.display.section,
                    watchExpressions = [],
                    expressionTemplate = 'vm.temp[\'{0}\'][\'{1}\']';

                if(section && section !== '__nimHiddenSection'){
                    watchExpressions.push(
                        kendo.format('vm.sectionsShown[\'{0}\'][{1}][\'{2}\']', state, formOriginId, section)
                    );
                }
                else {
                    watchExpressions.push(function(){
                        return true;
                    });
                }

                _.forEach(params.condition, function(condition){
                    watchExpressions.push(
                        // kendo.format(expressionTemplate, state, condition.modelId)
                        function () {
                            var flatData = getFlatData(state);
                            return flatData[condition.modelId];
                        }
                    );

                    if (condition.isFormObj) {
                        watchExpressions.push(
                            kendo.format(expressionTemplate, state, condition.val)
                        );
                    }
                });
                    
                vm.fieldsShown[state][modelId] = true;
                vm.fieldsActive[state][modelId] = true;
                vm.fieldsRequired[state][modelId] = false;

                // if(params.conditionalAction === 'active' || params.conditionalAction === 'readonly'){
                //     vm.fieldsShown[state][modelId] = true;

                //     if(alwaysReadonly){
                //         vm.fieldsActive[state][modelId] = !alwaysReadonly;
                //         return angular.noop();
                //     }
                // }
                // else {
                //     vm.fieldsActive[state][modelId] = !alwaysReadonly;
                // }

                return $scope.$watchGroup(watchExpressions, function (newValues, oldValues) {
                    var isSectionShown = _.head(newValues),
                        valuesChanged = !angular.equals(newValues, oldValues),
                        updateNeeded = !initialConditionSet || valuesChanged;

                    if (isSectionShown && updateNeeded){
                        vm.fieldsShown[state] = vm.fieldsShown[state] || {};
                        vm.fieldsActive[state] = vm.fieldsActive[state] || {};
                        vm.fieldsRequired[state] = vm.fieldsRequired[state] || {};

                        var flatDataSet = getFlatData(state);

                        var wasShown = vm.fieldsShown[state][modelId],
                            conditionMet = FormsService.sectionCondition(params.condition, flatDataSet, params.conditionalLogic, vm.action);
                        
                        switch(params.conditionalAction){
                            case 'active':
                                vm.fieldsActive[state][modelId] = conditionMet;
                                break;

                            case 'readonly':
                                vm.fieldsActive[state][modelId] = !conditionMet;
                                break;

                            case 'required':
                                vm.fieldsRequired[state][modelId] = conditionMet;
                                break;

                            case 'optional':
                                vm.fieldsRequired[state][modelId] = !conditionMet;
                                break;

                            case 'hide':
                                vm.fieldsShown[state][modelId] = !conditionMet;
                                break;

                            default:
                                vm.fieldsShown[state][modelId] = conditionMet;

                                var isShown = vm.fieldsShown[state][modelId];

                                if(params.showPopup && isShown && !wasShown){
                                    $timeout(function(){
                                        $scope.$broadcast('nim-show-static-text-popup', modelId);
                                    });
                                }

                                break;
                        }

                        initialConditionSet = true;
                    }
                });
            }

            vm.fieldsShown[state][modelId] = true;
            // vm.fieldsActive[state][modelId] = !alwaysReadonly;
            vm.fieldsActive[state][modelId] = true;
            vm.fieldsRequired[state][modelId] = false;
            return angular.noop;
        }

        function getFlatData(state) {
            var flatDataSet = {};

            _.forEach(vm.temp, function (stateDataSet) {
                _.assign(flatDataSet, stateDataSet);
            });

            _.assign(flatDataSet, vm.temp[state]);

            return flatDataSet;
        }

        function autoFillWatcher(state, modelId, isFilter, selectOptions){
            vm.autoFilledFields[state] = vm.autoFilledFields[state] || {};
            vm.autoFillData[modelId] = {};

            var watchExpression = isFilter ? 'vm.filters.' + modelId + '.id' : 'vm.temp[\'' + state + '\'][\'' + modelId + '\'].id';
            var skipFirstAutoFill = false;
            if (vm.action !== 'create' || vm.readingNewRecord) {
                skipFirstAutoFill = true;
            }

            return $scope.$watch(watchExpression, function(selectedId, previousId){
                var pushData = true,
                    selectedValueChanged = !angular.equals(selectedId, previousId);

                if(vm.activeState !== state){
                    pushData = false;
                }
                //else if(!selectedValueChanged){
                //    pushData = false;
                //}

                if(selectedValueChanged || _.isEmpty(vm.autoFillData[modelId])){
                    var dataSet = vm.temp[state];
                    if(selectedId && selectedId != '-1'){
                        autoFillTriggered(modelId, true);
                        FormsService.getAutoFillValues(selectedId, selectOptions).then(function (data) {
                            vm.autoFillData[modelId] = {};
                            autoFillTriggered(modelId, false);

                            if (data.length > 0) {
                                $timeout(function () {
                                    var autoFilledFields = [];

                                    _.forEach(data, function (targetField, index) {
                                        var autoFillMap = selectOptions.autoFill[index],
                                            propertyId = autoFillMap.type == 'query' ? autoFillMap.queryId : autoFillMap.value,
                                            value = targetField.value;

                                        if (targetField.type === 'timestamp') {
                                            value = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss').toDate();
                                        }

                                        if (typeof value === 'object' && !(value instanceof Date)) {
                                            if (targetField.modelId) {
                                                $scope.$broadcast('nim-auto-fill-dropdown', targetField.modelId, value);
                                            }    

                                            if (_.isArray(value)) {
                                                vm.autoFillData[modelId][propertyId] = value;
                                            }
                                            else {
                                                vm.autoFillData[modelId][propertyId] = _.get(value, "display", "");
                                            }
                                        }
                                        else {
                                            vm.autoFillData[modelId][propertyId] = value;
                                        }

                                        if (!skipFirstAutoFill && pushData && _.get(autoFillMap, 'persists', true)) {
                                            autoFilledFields.push(targetField.modelId);

                                            if (!value && value !== 0) {
                                                //$timeout(function(){
                                                dataSet[targetField.modelId] = '';
                                                vm.filters[targetField.modelId] = '';
                                                //});
                                            }
                                            else {
                                                // $timeout(function(){
                                                dataSet[targetField.modelId] = value;
                                                vm.filters[targetField.modelId] = value;
                                                // }, 0);

                                                fieldAutoFilled(state, targetField.modelId);
                                            }

                                            if (targetField.type === "dataseries" || targetField.type === "query") {
                                                $scope.$broadcast('nim-form-table-auto-fill-' + targetField.modelId, {
                                                    data: value
                                                });
                                            }
                                        }
                                    });

                                    $log.info('Autofill', modelId, '->', autoFilledFields.join(','));
                                    skipFirstAutoFill = false;
                                });
                            }
                            else{
                                clearAutoFillData(selectOptions.autoFill);
                            }
                        }, function () {
                            clearAutoFillData(selectOptions.autoFill);
                            autoFillTriggered(modelId, false);
                        });
                    }
                    else {
                        clearAutoFillData(selectOptions.autoFill);
                    }
                }
            });
        }

        function fieldAutoFilled(state, modelId) {
            var path = [state, modelId];
            _.set(vm.autoFilledFields, path, true);

            $timeout(function () {
                _.set(vm.autoFilledFields, path, false);
            }, 3000);
        }

        function clearAutoFillData(state, autoFillArray){
            _.forEach(autoFillArray, function(autoFill){
                vm.temp[state][autoFill.current.modelId] = '';

                if(autoFill.type && autoFill.type === 'dataseries'){
                    $scope.$broadcast('nim-form-table-auto-fill-' + autoFill.current.modelId,{
                        data: null
                    });
                }
            });
        }

        /* UI Window Functions */
        function _closeWindow(reopenFlag,stopBroadcast,action,id,data) {
            clearWatchers();
            
            if(vm.windowId) {
                $scope[vm.windowId].close();
            }

            if(!stopBroadcast){
                _broadcastInstanceSaved(action, id, data);
            }

            if(reopenFlag){
                // Reopens instance window independent from parent page controller
                InstancesWindowService.openWindow({
                    action: 'create',
                    widgetId: vm.widgetId,
                    title: vm.instance.widgetName,
                    defaultData: $scope.defaultData
                });
            }
        }

        function _broadcastInstanceSaved(action, id, data){
            if(_.isFunction($scope.navFunctions.callback)){
                $scope.navFunctions.callback(id, action, data);
            }
        }

        /* UI Form Functions */
        function tabOnBlur($event){
            angular.element($event.currentTarget).attr('tabindex',vm.instance.workflowObjects[vm.activeState].model.forms[0].tabindexEnd);
        }
        function tabOnFocus($event){
            angular.element($event.currentTarget).attr('tabindex',vm.instance.workflowObjects[vm.activeState].model.forms[0].tabindexStart - 1);
        }
        
        function _editState(state) {
            $timeout(function () {
                vm.formObjectsInvalid[state] = {};
                angular.forEach(vm.edit, function (v, k) {
                    if (k === state + '') {
                        vm.edit[k] = true;
                        vm.activeState = state;
                        // vm.selectedForm[state] = 0;

                        // Expand state on edit
                        vm.collapse[state] = false;
                    }
                    else {
                        vm.edit[k] = false;
                    }
                });
            }, 1);
        }
        function _cancelState(state) {
            vm.temp[state] = angular.copy(vm.orig[state]);
            _editState();
            vm.activeState = null;
            // vm.selectedForm[state] = 0;
        }

        function _openInstanceWindow(id){
            InstancesWindowService.openWindow({
                action: 'read',
                instanceId: id,
                title: vm.instance.widgetName
            });
        }

        function _selectForm(state, index){
            vm.selectedForm[state] = index;
        }

        function _isUnchanged() {
            return angular.equals(vm.temp, vm.orig);
        }

        function _submitForm(state, nextWorkflowState, submitAction) {
            if (vm.isSubForm) { // Don't submit this as its own record if it's a subform
                // TODO: Show Message
                return;
            }

            vm.invalid = false;
            if(vm.action === 'readonly'){
                // TODO: Show Message
                return;
            }

            _.defer(function () {
                // Tells each form object to validate
                var validationResult = validateState(state);

                if (!validationResult.valid) {
                    var formIndex = vm.selectedForm[state],
                        selectedForm = vm.instance.workflowObjects[state].model.forms[formIndex];

                    if (!vm.formObjectsInvalid[state][selectedForm.originatorId]) {
                        focusFirstInvalidForm(state);
                    }

                    return;
                }

                if (vm.updating) {
                    // TODO: Show Message
                    return;
                }

                if (vm.isProcessingUploads) {
                    // TODO: Show Message
                    return;
                }

                var data;
                // If Creating
                if (vm.action === 'create' && state === vm.createState) {
                    data = {
                        widgetId: vm.instance.widgetId,
                        draftId: vm.draftId,
                        recordData: InstancesWindowService.pickShownFields(vm.temp[state], vm.orig[state], vm.instance.workflowObjects[state].model.forms, vm.sectionsShown[state], vm.fieldsShown[state]),
                        current: nextWorkflowState ? nextWorkflowState : '',
                        recurrence: vm.instance.recurrence,
                        ignoreSections: _sectionsIgnored(vm.instance.workflowObjects[state].model.forms, state)
                    };

                    vm.updating = true;
                    InstancesService.createInstance(data).then(function (result) {
                        $attrs.$set('nimValidateOnOpen', '');
                    
                        if (vm.relationships.length > 0) {
                            InstancesService.updateRelationships(result.instanceId, vm.relationships);
                        }

                        // var instanceId = _.last(result.instanceId.split('-'));
                        var instanceId = result.instanceId;

                        if (submitAction === 'submit') {
                            _updateInstanceAttrs(instanceId, 'read');

                            _broadcastInstanceSaved('create', instanceId, data.recordData);
                            _init();
                        }
                        else if (submitAction === 'submitAndNew') {
                            _broadcastInstanceSaved('create', instanceId, data.recordData);
                            _init();
                        }
                        else {
                            _closeWindow(false, false, 'create', instanceId, data.recordData);
                        }
                    }, function () {
                        vm.updating = false;
                    });
                }
                // If Progressing //
                else if (vm.action === 'read' && state === vm.createState) {
                    data = {
                        recordData: InstancesWindowService.pickShownFields(vm.temp[state], vm.orig[state], vm.instance.workflowObjects[state].model.forms, vm.sectionsShown[state], vm.fieldsShown[state]),
                        nextStateId: nextWorkflowState ? nextWorkflowState : '',
                        currentStateId: state,
                        ignoreSections: _sectionsIgnored(vm.instance.workflowObjects[state].model.forms, state)
                    };

                    vm.updating = true;
                    InstancesService.update(vm.instanceId, data).then(function () {
                        $attrs.$set('nimValidateOnOpen', '');

                        if (vm.windowId) {
                            $rootScope.$broadcast('nim-instance-saved:' + vm.instanceId, vm.windowId, vm.temp, state);
                        }

                        //if(vm.instance.workflowObjects[state].model.to.length < 2){
                        //    _closeWindow(null, null, 'update', vm.instanceId, data.recordData);
                        //}
                        //else {
                        //    vm.setCurrentWorkflowObject(id);
                        //}

                        if (submitAction === 'submitAndClose') {
                            _closeWindow(false, false, 'update', vm.instanceId, data.recordData);
                        }
                        else {
                            _broadcastInstanceSaved('update', vm.instanceId, data.recordData);
                            _init();
                            //_editState();
                            //vm.createState = nextWorkflowState;
                            //vm.activeState = null;
                            //vm.orig[state] = angular.copy(vm.temp[state]);
                            //vm.updating = false;
                        }
                    }, function () {
                        vm.updating = false;
                    });
                }
                // Else we must be editing.
                else if (vm.action !== 'create' && state !== vm.createState) {
                    data = {
                        currentStateId: state,
                        recordData: InstancesWindowService.pickShownFields(vm.temp[state], vm.orig[state], vm.instance.workflowObjects[state].model.forms, vm.sectionsShown[state], vm.fieldsShown[state]),
                        ignoreSections: _sectionsIgnored(vm.instance.workflowObjects[state].model.forms, state)
                    };

                    vm.updating = true;
                    InstancesService.update(vm.instanceId, data).then(function () {
                        $attrs.$set('nimValidateOnOpen', '');

                        if (vm.windowId) {
                            $rootScope.$broadcast('nim-instance-saved:' + vm.instanceId, vm.windowId, vm.temp, state);
                        }

                        if (submitAction === 'submitAndClose') {
                            _closeWindow(false, false, 'update', vm.instanceId, data.recordData);
                        }
                        else {
                            _broadcastInstanceSaved('update', vm.instanceId, data.recordData);
                            _init();
                            //_editState();
                            //vm.activeState = null;
                            //vm.orig[state] = angular.copy(vm.temp[state]);
                            //vm.updating = false;
                        }

                        //_closeWindow(null, null, 'update', vm.instanceId, data.recordData);
                        //TODO: rebuild static view
                        // update original
                        //vm.orig[state] = angular.copy(vm.temp[state]);
                        // end the edit session
                        //vm.edit[state] = false;
                    }, function () {
                        vm.updating = false;
                    });
                }
            });
        }

        function _updateInstanceAttrs(id, action){
            $attrs.$set('nimId', id);
            $attrs.$set('nimAction', action);
        }

        function _sectionsIgnored(forms, state){
            var ignoreSections = [];

            _.forEach(forms, function(f){
                _.forEach(f.form.sections, function(s){
                    if(!_.isEmpty(s.condition)) {
                        var formOriginId = f.originatorId;

                        if (!vm.sectionsShown[state][formOriginId][s.name]) {
                            ignoreSections.push({
                                section: s.name,
                                form_origin_id: formOriginId
                            });
                        }
                    }
                });
            });

            return ignoreSections;
        }

        function _saveDraft(submitFunction) {
            var data = {
                widgetId: vm.instance.widgetId,
                recordData: vm.temp[vm.activeState],
                stateId: vm.createState
            };
            if (vm.draftId) {
                draftsService.update(vm.draftId, data).then(function(){
                    if (angular.isFunction(submitFunction)) {
                        submitFunction();
                    }
                });
            }
            else {
                draftsService.create(data).then(function(value){
                    vm.draftId = value.draftId;
                    if (angular.isFunction(submitFunction)) {
                        submitFunction();
                    }
                });
            }
        }

        /**
         * Formats attachment size into human-readable string
         * @param bytes - Attachment size, in bytes
         * @returns {string}
         * @private
         */
        function _showFileSize(bytes){
            var kilobytes = bytes / 1024,
                megabytes = kilobytes / 1024,
                gigabytes = megabytes / 1024;

            if(bytes < 1000){
                return bytes + ' B';
            }
            else if(kilobytes < 1000){
                return _.round(kilobytes, 1) + ' KB';
            }
            else if(megabytes < 1000){
                return _.round(megabytes, 1) + ' MB';
            }
            else {
                return _.round(gigabytes, 1) + ' GB';
            }
        }

        // TODO:  Add this to a build function in the InstancesWindowService
        /* Upload Options */
        vm.uploadOptions = {
            async:{
                saveUrl: '/Server/REST/v1/upload',
                removeUrl: 'remove',
                autoUpload: false
            },
            localization: {
                dropFilesHere: 'Drop files here to start the upload.'
            },
            select:function(){
                vm.isProcessingUploads = true;
                var othis = this;
                if(vm.action === 'create' && !vm.draftId){
                    othis.disable();
                    var d = {
                        widgetId: vm.instance.widgetId,
                        recordData: vm.temp[vm.createState],
                        stateId: vm.createState
                    };
                    draftsService.create(d).then(function(value){
                        vm.draftId = value.draftId;
                        othis.enable();
                        $(".k-button.k-upload-selected").trigger('click');
                    });
                }
                else{
                    $timeout(function(){
                        $(".k-button.k-upload-selected").trigger('click');
                    }, 1);
                }
            },
            upload: function(e){
                e.data = {
                    formOriginId: vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]].originatorId,
                    formId: vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]].formId,
                    widgetId: vm.instance.widgetId
                };
                if(vm.draftId){
                    e.data.draftId = vm.draftId;
                }
                else{
                    e.data.instanceId = vm.instanceId;
                }

                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1 /* OPENED */) {
                            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                            xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            },
            success: function(e){
                $timeout(function () {
                    var itemData = e.response.DATA;
                    var form = vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]];
                    form.fileAttachments = form.fileAttachments || [];
                    itemData.disableClick = true;
                    form.fileAttachments.push(itemData);
                    if(!vm.temp[vm.activeState].nimupload){
                        vm.temp[vm.activeState].nimupload = [];
                    }
                    vm.temp[vm.activeState].nimupload.push(itemData.id + ',create');
                    vm.isProcessingUploads = false;
                    $(".k-upload-files.k-reset").find("li").remove();
                });
            },
            cancel: function(){
                $timeout(function(){
                    vm.isProcessingUploads = false;
                });
            },
            error: function(){
                $timeout(function(){
                    vm.isProcessingUploads = false;
                });
            }
        };

        /* Upload Functions */
        function _deleteFile(index){
            $timeout(function () {
                var form = vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]];
                //InstancesService.deleteAttachment(form.fileAttachments[index].id).then(function (v) {
                form.fileAttachments.splice(index, 1);
                //vm.temp[vm.activeState].nimupload.splice(index, 1);
                vm.temp[vm.activeState].nimupload[index] =  vm.temp[vm.activeState].nimupload[index] + ',delete';
                //});
            });
        }

        /* SubForm Functions */
        function _subFormCreate(reOpen) {
            $timeout(function () {
                // Wait until calculations stop running
                _.defer(function () {
                    var state = vm.activeState;
                    var validationResult = validateState(state);

                    if (!validationResult.valid) {
                        var formIndex = vm.selectedForm[state],
                            selectedForm = vm.instance.workflowObjects[state].model.forms[formIndex];

                        if (!vm.formObjectsInvalid[state][selectedForm.originatorId]) {
                            focusFirstInvalidForm(state);
                        }

                        return;
                    }

                    var forms = vm.instance.workflowObjects[state].model.forms;
                    var attachments = forms[vm.selectedForm[state]].fileAttachments || [];
                    var dataSet = {
                        __nimData: InstancesWindowService.pickShownFields(vm.temp[state], vm.orig[state], vm.instance.workflowObjects[state].model.forms, vm.sectionsShown[state], vm.fieldsShown[state]),
                        __attachments: attachments,
                        __ignoreSections: _sectionsIgnored(forms, state)
                    };

                    // Adjust formatting/displaying of values in the table
                    FormsService.fixDataseriesCols(dataSet, $scope.schemaFields);
                    //

                    if (subDataUnique(dataSet)) {
                        if ($scope.editSubData) {
                            angular.extend($scope.editSubData, dataSet);
                        }
                        else {
                            dataSet.__optionId = $scope.nimFormObject.param.optionId;
                            dataSet.__column = $scope.nimFormObject.param.column;
                            dataSet.__modelId = vm.excludeModelId;
                            dataSet.__widgetId = vm.instance.widgetId;

                            if ($scope.grid) {
                                $scope.grid.dataSource.add(dataSet);
                            }
                        }
                    }

                    if ($scope.grid) {
                        $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.grid.dataSource.data().toJSON();
                        $scope.grid.refresh();
                    }

                    $rootScope.$broadcast('nim-sub-form-closed', {
                        modelId: $scope.nimFormObject.config.modelId
                    });

                    if (reOpen) {
                        _init();
                    }
                    else if (vm.windowId) {
                        $scope[vm.windowId].close();
                    }
                });
            }, 100);    
        }

        function subDataUnique(dataItem) {
            var unique = true,
                gridData = $scope.grid.dataSource.data();

            _.forEach($scope.columns, function (c) {
                if (c.unique) {
                    var value = dataItem[c.field],
                        modelId = c.field;
                    
                    if (c.isLookUp || c.isCustomLookup) {
                        modelId = c.field.replace(/^__/, '').replace(/_display$/, '.id');
                        value = _.get(dataItem.__nimData, modelId);
                    }

                    if (_.find(gridData, '__nimData.' + modelId, value, dataItem)) {
                        unique = false;
                        return false;
                    }
                }
            });
            
            return unique;
        }

        function _getParticipantInfo(userId){
            if(!vm.userData[userId]){
                usersProfileService.getDefaultUserProfile(userId).then(function(userData){
                    vm.userData[userId] = userData[0].data;
                });
            }
        }

        function _getDownloadKey(attachment){
            if(!attachment.disableClick){
                InstancesService.getDownloadKey(attachment.id).then(function(d){
                    InstancesService.getBlob(d).then(function(pdfBlob){
                        $window.saveAs(pdfBlob, attachment.name);
                    });
                });
            }
        }

        /* Toolbar functions */

        function _newInstance(){
            _updateInstanceAttrs('', 'create');
            _init();
        }

        function _duplicateInstance() {
            if (vm.dataSetLoaded && vm.temp) {
                var dataSet = angular.copy(vm.temp[1]),
                    forms = vm.instance.workflowObjects[1].model.forms,
                    duplicateData = [];

                _.forEach(forms, function (formModel) {
                    _.forEach(formModel.form.objects, function (formObj) {
                        if (formObj.model.config.dataType !== 'static' && !formObj.model.param.autoIncrement && !formObj.model.param.preventDuplication) {
                            // Formats data to be consistent with defaultData model
                            var value = dataSet[formObj.model.config.modelId];

                            if (formObj.model.config.dataType === 'dataseries') {

                                _.forEach(value, function (row) {
                                    delete row['__instanceId'];

                                    row['__column'] = formObj.model.param.column;
                                    row['__modelId'] = formObj.model.param.fk;
                                    row['__widgetId'] = formObj.model.param.widgetId;
                                    row['__optionId'] = formObj.model.param.optionId;

                                    row['__nimData'][formObj.model.param.fk] = { display: 'Auto' };
                                });
                            }

                            duplicateData.push({
                                field: {
                                    formId: formModel.originatorId,
                                    modelId: formObj.model.config.modelId
                                },
                                data: {
                                    value: value
                                }
                            });
                        }
                    });
                });

                _closeWindow(false, true);
                InstancesWindowService.openWindow({
                    action: 'create',
                    widgetId: vm.instance.widgetId,
                    defaultData: duplicateData
                });
            }
        }

        function _deleteInstance(){
            if(vm.action !== 'create') {
                // Makes sure id is number and does not include public key
                var instanceId = vm.instanceId;

                if (_.isString(instanceId)) {
                    instanceId = parseInt(_.last(instanceId.split('-')));
                }

                InstancesWindowService.deleteInstanceModal([instanceId], function(){
                    _closeWindow(false, false, 'delete', vm.instanceId);
                });
            }
        }

        function _cancel(){
            if (vm.action === 'create') {
                if (!vm.isUnchanged()) {
                    InstancesWindowService.openConfirmInstanceCloseModal().then(function() { 
                        _closeWindow(false, true);
                    });
                } else {
                    _closeWindow(false, true);
                }    
            }
            else if(vm.activeState){
                _cancelState(vm.activeState);
            }
        }

        function _collapseAll(){
            _.forEach(vm.collapse, function(v, k){
                vm.collapse[k] = true;
            });
        }

        function _expandAll(){
            _.forEach(vm.collapse, function(v, k){
                vm.collapse[k] = false;
            });
        }

        function _revisionOnClick(revision){
            var title = vm.instance.widgetName + ': Revision - ' + kendo.toString(revision.createDate, 'MM/dd/yyyy h:mm tt') + ' - ' + revision.createBy;
            InstancesWindowService.openRevision(null, vm.instanceId, revision.id, title);
        }

        function _updateSchema(){
            InstancesService.updateSchema({ids: [vm.instanceId]}).then(function () {
                _init();
            });
        }

        // TODO: Single state
        function _exportInstance(/*type*/){
            // type = type || 'pdf';

            // if (vm.states.length === 1) {
            //     vm.toPdf(vm.states[0]);
            //     return;
            // }

            // var elem = vm.window.element.find('#record-div'),
            //     config = {
            //         type: type,
            //         filename: _getFileName()
            //     };

            // ExportService.exportElement(elem, config);

            vm.isProcessingPdf = true;

            $timeout(function () {
                var displayVal = {},
                    browser = DeviceSniffService.deviceBrowser(),
                    isIE = _.startsWith(browser, 'IE');
        
                displayVal[vm.instanceId] = getRecordDisplayValue(vm.orig, _.get(vm.instance, 'widgetSettings.view.column', '')) || vm.title;

                var printConfigCb = function (config) {
                    var instance = angular.copy(vm.instance),
                        fullDataSet = angular.copy(vm.orig),
                        dataSet = {};
                    
                    _.forEach(fullDataSet, function (stateData) {
                        _.assign(dataSet, stateData);
                    });

                    var defaultName = kendo.format('{0} - {1:u}', displayVal[vm.instanceId], new Date());

                    normalPrintingService.printInstance(vm.instanceId, defaultName, config.options, instance, dataSet, vm.autoFillData).then(function () {
                        vm.isProcessingPdf = false;
                    });
                };

                if (isIE) {
                    printService.openPrintConfigurationModal(vm.widgetId, [vm.instanceId], displayVal, { normal: true }, {
                        type: 'normal'
                    }).then(printConfigCb, function () {
                        vm.isProcessingPdf = false;
                    });
                }
                else {
                    printConfigCb({
                        type: 'normal',
                        options: {
                            landscape: false,
                            paperSize: 'letter',
                            margin: {
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0
                            }
                        }
                    });
                }
            }, 1);
        }

        function _printInstanceChecks() {
            var displayVal = {};
            
            displayVal[vm.instanceId] = getRecordDisplayValue(vm.orig, _.get(vm.instance, 'widgetSettings.view.column', '')) || vm.title;

            printService.openPrintConfigurationModal(vm.widgetId, [vm.instanceId], displayVal, {check: true}, {
                type: 'check'
            }).then(function(config){
                var formOriginId = _.last(config.options.targetForm.split('-')),
                    state = _.findKey(vm.instance.instanceWorkflow, formOriginId);

                if(vm.instance.workflowObjects[state]){
                    var formData = {};
                    formData[vm.instanceId] = angular.copy(_.find(vm.instance.workflowObjects[state].model.forms, 'originatorId', parseInt(formOriginId)));

                    if (formData[vm.instanceId]) {
                        _.assign(formData[vm.instanceId].defaultDataSet, vm.orig[state]);
                    }

                    var defaultName = kendo.format('{0} - {1:u}', displayVal[vm.instanceId], new Date());

                    checkPrintingService.printChecks([vm.instanceId], defaultName, config.options, formData, vm.autoFillData);
                }
            });
        }

        function _printInstanceLabels() {
            var displayVal = {};
            
            displayVal[vm.instanceId] = getRecordDisplayValue(vm.orig, _.get(vm.instance, 'widgetSettings.view.column', '')) || vm.title;

            printService.openPrintConfigurationModal(vm.widgetId, [vm.instanceId], displayVal, {label: true}, {
                type: 'label'
            }).then(function(config){
                var formOriginId = _.last(config.options.targetForm.split('-')),
                    state = _.findKey(vm.instance.instanceWorkflow, formOriginId);

                if (vm.instance.workflowObjects[state]) {
                    var formData = {};
                    formData[vm.instanceId] = angular.copy(_.find(vm.instance.workflowObjects[state].model.forms, 'originatorId', parseInt(formOriginId)));

                    if (formData[vm.instanceId]) {
                        _.assign(formData[vm.instanceId].defaultDataSet, vm.orig[state]);
                    }

                    var instanceAutoFillData = {};
                    instanceAutoFillData[vm.instanceId] = vm.autoFillData;

                    var defaultName = kendo.format('{0} - {1:u}', displayVal[vm.instanceId], new Date());

                    labelPrintingService.printLabels([vm.instanceId], defaultName, config.options, formData, instanceAutoFillData);
                }
            });
        }

        // function _getFileName(){
        //     if(vm.title){
        //         return vm.title;
        //     }
        //     else if(_.has(vm, 'instance.widgetName')){
        //         return vm.instance.widgetName;
        //     }

        //     return 'download';
        // }

        /**
         * Validates the active state before submittal
         * @param state - active state
         */
        function validateState(state) {
            // Tracks number of invalid form objects in each form of this state
            vm.formObjectsInvalid[state] = {};

            var stateResult = {
                    forms: {}
                },
                forms = vm.instance.workflowObjects[state].model.forms;

            _.forEach(forms, function(formModel){
                var formOriginId = formModel.originatorId,
                    formResult = validateFormObjects(formModel.form.objects, vm.temp[state], vm.instanceForms[state], vm.sectionsShown[state][formOriginId], vm.fieldsShown[state], vm.fieldsRequired[state]);

                stateResult.forms[formOriginId] = {
                    fields: formResult,
                    valid: _.every(formResult, 'valid')
                };
            });

            stateResult.valid = _.every(stateResult.forms, 'valid');

            if(!stateResult.valid){
                _.forEach(stateResult.forms, function(f, formOriginId){
                    if(!f.valid) {
                        vm.formObjectsInvalid[state][formOriginId] = _.countBy(f.fields, 'valid')[false];
                    }
                });
            }

            return stateResult;
        }

        /**
         * Validates each field in a given form
         * @param formObjects
         * @param dataSet - flat dataset of form data
         * @param instanceForm - angular form
         * @param sectionsShown
         * @param fieldsShown
         * @param fieldsRequired
         * @returns {object}
         */
        function validateFormObjects(formObjects, dataSet, instanceForm, sectionsShown, fieldsShown, fieldsRequired){
            var formResult = {};
            _.forEach(formObjects, function(formObj){
                var modelId = formObj.model.config.modelId,
                    validate = FormValidationService.validateFormObject(instanceForm, formObj.model),
                    //valueInDataSet = _.has(dataSet, modelId) || formObj.config.dataType === 'dataSeries',
                    valueInDataSet = modelId && !formObj.model.param.filter,
                    section = formObj.model.display.section,
                    sectionIncluded = !section || sectionsShown[section],
                    formObjectShown = fieldsShown[modelId],
                    formObjectRequired = fieldsRequired[modelId];

                if(valueInDataSet && sectionIncluded && formObjectShown) {
                    var result = validate(dataSet[modelId], formObjectRequired);
                    formResult[modelId] = {
                        validators: result,
                        valid: _.every(result)
                    };
                }
            });

            return formResult;
        }

        /**
         * After state validation, finds the first form with invalid fields and gives it focus
         * @param state - active state
         */
        function focusFirstInvalidForm(state) {
            var formIndex = 0,
                formIds = _.pluck(vm.instance.workflowObjects[state].model.forms, 'originatorId'),
                invalidForms = vm.formObjectsInvalid[state];
            
            if (formIds.length > 1) {
                _.forEach(formIds, function (fId, index) {
                    if (invalidForms[fId]) {
                        formIndex = index;
                        vm.formIsActive[state][0] = false;
                        return false;
                    }
                });

                vm.selectedForm[state] = formIndex;
                vm.formIsActive[state][formIndex] = true;
            }
        }

        function autoFillTriggered(modelId, bool){
            vm.autoFilling[modelId] = bool;
            vm.showAutoFillScreen = _.some(vm.autoFilling);
        }

        function gridsterItemOptions(formObjectModel/*, dataSet*/) {
            var options = _.pick(formObjectModel, ['sizeX', 'sizeY', 'row', 'col']);

            // Adjust initial size of form tables
            // if (dataSet && formObjectModel.model.config.type == 'table') {
            //     var tableData = _.get(dataSet, formObjectModel.model.config.modelId, []),
            //         numRows = tableData.length + 2,
            //         height = 30 * numRows + 16;
                
            //     var sizeY = Math.ceil(height / 8);
            //     if (sizeY > options.sizeY) {
            //         options.sizeY = sizeY;
            //     }    
            // }

            return options;
        }

        function clearWatchers() {
            if(!_.isEmpty(vm.deregisterFunc)){
                // De-register old watchers
                _.forEach(vm.deregisterFunc, function(deregisterWatcherFunc){
                    if(_.isFunction(deregisterWatcherFunc)) {
                        deregisterWatcherFunc();
                    }
                });
            }
        }

        $scope.$on('nim-instance-saved:' + vm.instanceId, function(e, windowId, data, state){
            if(windowId !== vm.windowId) {
                vm.orig[state] = angular.copy(data[state]);
                vm.temp[state] = angular.copy(data[state]);
            }
        });

        $scope.$on('auth:loginCancelled', function () {
            $timeout(function() {
                _closeWindow(false, true);
            });
        });
        
        $scope.$on('$destroy', function () {
            kendo.destroy($element);
        });
    })
    .directive('nimInstance', function nimInstance() {
        return {
            restrict: 'AE',
            templateUrl: 'core/instances/instances.tpl.html',
            controller: 'NimInstanceCtrl',
            controllerAs: 'vm'
        };
    })
;