/**
 * EXAMPLE
 ***Controller:
 *
 ***Template
 * <div nim-leaflet="123" nim-options="options"></div>
 *
 */

angular.module('cerberus.ui')
    .controller('NimLeafletCtrl', function NimLeafletCtrl(_, L, mapboxConfig, $scope, $attrs, $element, $window, $location, $log, $timeout, $compile, LeafletMapService, LeafletDefaultsService, InstancesWindowService) {
        var vm = this;

        // Set Leaflet
        L.Icon.Default.imagePath = '/assets/images';

        /* Expose Controller API */

        // Extend default configuration with user defined configs
        vm.config = {
            map: angular.extend(LeafletDefaultsService.map(), $scope.nimOptions.map),
            basemaps: $scope.nimOptions.basemaps || [],
            overlays: $scope.nimOptions.overlays || [],
            controls: angular.extend(LeafletDefaultsService.controls(), $scope.nimOptions.controls)
        };

        // Containers
        vm.id = null;
        vm.map = null;
        vm.basemaps = {};
        vm.overlays = {};
        vm.controls = {};
        vm.markers = {};
        vm.data = {};

        vm.geoAddr = "";
        vm.location = {};


        // Constructor
        vm.init = function (id) {
            // Ugly hack - prevents an empty map div
            $timeout(function () {
                init(id);
            });
        };

        ////////////////////
        /**
         * @function init
         * Configures and builds Leaflet map
         */
        function init() {
            /* Context Menu */
            // TODO: Refactor this context menu junk outta her
            vm.config.map.contextmenu = true;
            vm.config.map.contextmenuItems = [{
                text: 'Bookmark this position',
                callback: function(evt) {
                    this.fire('bookmark:new', {
                        latlng: evt.latlng
                    });
                }
            }];


            /* Map */
            vm.id = $attrs.nimLeafletId;
            // Put in timeout to allow time to update id before binding to map element
            $timeout(function () {
                if (!angular.element('#' + vm.id).length) { 
                    return;
                }

                vm.map = L.map(vm.id, vm.config.map);
                // Publish ready event
                // vm.map.whenReady(function () {
                //     $scope.$emit('nim-leaflet-ready');
                // });

                // Set center and zoom based on user configuration
                var center = vm.config.map.center;
                var zoom = vm.config.map.zoom;

                /* Set View */
                var view = LeafletMapService.getView(vm.id);
                if (view) {
                    vm.map.setView(L.latLng(view.lat, view.lng), view.zoom, true);
                    // $scope.nimBounds = vm.map.getBounds();
                }

                /* Build Layers */
                LeafletMapService.buildBasemaps(vm.config.basemaps, vm.basemaps, vm.map);

                if (vm.config.controls.draw || $scope.nimOptions.drawings) {

                    vm.overlays["My Drawings"] = new L.geoJson(null, {
                        style: function (layer) {
                            var shape = layer.geometry.type.toLowerCase();
                            if($scope.nimOptions.drawOptions.draw[shape]) {
                                return $scope.nimOptions.drawOptions.draw[shape].shapeOptions;
                            }
                            else{
                                return {};
                            }
                        }
                    });
                    vm.overlays["My Drawings"].addTo(vm.map);

                    if($scope.nimOptions.drawings){
                        var geometry = $scope.nimOptions.drawings.geometry;
                        if(geometry) {
                            vm.overlays['My Drawings'].addData(geometry);
                            var bounds = vm.overlays["My Drawings"].getBounds();

                            // Sets center and zoom based on geometry (Form Object)
                            center = bounds.getCenter();
                            zoom = vm.map.getBoundsZoom(bounds);
                        }
                    }
                }

                if(center && zoom){
                    vm.map.setView(center, zoom);
                }
                else if(center){
                    vm.map.setView(center);
                }
                else if(zoom){
                    vm.map.setZoom(zoom);
                }

                LeafletMapService.buildLayers(vm.config.overlays, vm.overlays, vm.map, vm.markers, watchCallback, $scope);

                /* Controls */
                // Setup Controls (in order of position top-right, top-left, bottom-left, bottom-right)
                if (vm.config.controls.layers) {
                    vm.controls.layersControl = L.control.layers(vm.basemaps, vm.overlays).addTo(vm.map);
                }
                if (vm.config.controls.geolocate) {
                    // Mapbox Geocoder
                    vm.controls.geocoderControl = L.mapbox.geocoderControl('mapbox.places', {
                        accessToken: mapboxConfig.token,
                        keepOpen: true
                    }).addTo(vm.map);
                }
                if (vm.config.controls.bookmarks) {
                    vm.controls.bookmarksControl = new L.Control.Bookmarks().addTo(vm.map);
                }
                if (vm.config.controls.zoom) {
                    vm.controls.zoomControl = L.control.zoom().addTo(vm.map);
                }
                if (vm.config.controls.zoomBox) {
                    vm.controls.zoomBoxControl = L.control.zoomBox().addTo(vm.map);
                }
                if (vm.config.controls.fullscreen) {
                    vm.controls.fullscreenControl = new L.Control.FullScreen().addTo(vm.map);
                }
                if (vm.config.controls.draw) {
                    var drawOptions = LeafletDefaultsService.draw(vm.overlays["My Drawings"]);
                    if($scope.nimOptions.drawOptions){
                        drawOptions = angular.extend(drawOptions, $scope.nimOptions.drawOptions);
                    }
                    vm.controls.drawControl = new L.Control.Draw(drawOptions).addTo(vm.map);
                }
                if (vm.config.controls.measure) {
                    vm.controls.measureControl = L.Control.measureControl().addTo(vm.map);
                }
                if (vm.config.controls.loading) {
                    vm.controls.loadingControl = L.Control.loading().addTo(vm.map);
                }
                if (vm.config.controls.scale) {
                    vm.controls.scaleControl = L.control.scale().addTo(vm.map);
                }
                if (vm.config.controls.coordinate) {
//                vm.controls.coordinateControl = L.control.coordinates().addTo(vm.map);
                }


                /* Map Event Listeners */
                vm.map.on("click", function () {
                    //$log.info('click', event);
                });
                
                vm.map.on("moveend", function () {
                    var thisMap = this;

                    // $log.info('moveend', event, thisMap._loaded);
                    if (!thisMap._loaded) {
                        return;
                    }

                    var view = {
                        lat: thisMap.getCenter().lat,
                        lng: thisMap.getCenter().lng,
                        zoom: thisMap.getZoom()
                    };
                    $timeout(function () {
                        if ($scope.nimBounds) {
                            _.assign($scope.nimBounds, thisMap.getBounds());
                        }
                        else {
                            $scope.nimBounds = thisMap.getBounds();
                        }    
                    });
                    LeafletMapService.setView(vm.id, view);
                });

                vm.map.on('draw:created', function (event) {
                    var layer = event.layer;

                    if(!$scope.nimOptions.allowMultiple){
                        vm.overlays["My Drawings"].clearLayers();
                    }
                    vm.overlays["My Drawings"].addLayer(layer);

                    $timeout(function(){
                        $scope.nimDrawingResult = vm.overlays["My Drawings"].toGeoJSON();
                    });
                });

                vm.map.on('draw:edited', function(){
                    $timeout(function(){
                        $scope.nimDrawingResult = vm.overlays["My Drawings"].toGeoJSON();
                    });
                });

                vm.map.on('draw:deleted', function(){
                    $timeout(function(){
                        $scope.nimDrawingResult = vm.overlays["My Drawings"].toGeoJSON();
                    });
                });

                /* $scope Event Triggers */


                /* $scope Event Listeners */
                $scope.$on('$destroy', function () {
                    // Consider destroying Controls & layers first...  .removeFrom(vm.map)
                    vm.map.clearAllEventListeners();
                    vm.map.remove();
                });

                $scope.$on('nim-map-layer-filtered', function (e, data) {
                    e.preventDefault();

                    var points = [];

                    _.forEach(data, function(marker){
                        if(_.has(marker, 'geometry.coordinates')){
                            if(marker.geometry.type === 'Point'){
                                points.push(angular.copy(marker.geometry.coordinates).reverse());
                            }
                            else {
                                points = points.concat(_.map(marker.geometry.coordinates, function(coordinates){
                                    return angular.copy(coordinates).reverse();
                                }));
                            }
                        }
                    });

                    if (points.length > 0) {
                        $timeout(function () {
                            var bounds = L.latLngBounds(points);
                            vm.map.fitBounds(bounds, {
                                maxZoom: 18
                            });
                        });
                    }    
                });

                // Popup edit function
                $scope.edit = function(id){
                    InstancesWindowService.openWindow({
                        action: 'read',
                        instanceId: id
                    });
                };

                /* Expose Leaflet API to parent Controller */
                $scope.nimLeaflet = vm.map;
                $scope.nimBounds = _.result(vm.map, 'getBounds');
            });
        }

        function refreshMarkers(index, data, newIds, oldIds) {
            var key = vm.config.overlays[index].name;

            vm.overlays[key].eachLayer(function(layer){
                var id = layer.options.id;
                var newIndex = newIds.indexOf(id);
                if(newIndex >= 0){
                    // Makes sure the same layer isn't added twice
                    newIds.splice(newIndex, 1);
                }
                else if(oldIds.indexOf(id) >= 0){
                    // Removes layer no longer in view
                    vm.overlays[key].removeLayer(layer);
                }
            });

            _.forEach(data, function(p){
                var props = p.properties;
                if(newIds.indexOf(props.id) >= 0) {
                    // Converts polygon coordinates to single point
                    var latLng = polygonToPoint(p.geometry).reverse();
                    var marker = L.marker(latLng, {
                        icon: LeafletMapService.buildIconMarker(props.markerColor),
                        riseOnHover: true,
                        showOnMouseOver: true,
                        id: props.id
                    });
                    marker.bindPopup(LeafletMapService.buildPopup(props, props.title, vm.config.controls.editPopup ? $scope : null));
                    marker.on('popupopen', LeafletMapService.popupEventHandler($scope, index, 'nim-leaflet-popup-open'));
                    marker.on('popupclose', LeafletMapService.popupEventHandler($scope, index, 'nim-leaflet-popup-close'));
                    marker.addTo(vm.overlays[key]);
                }
            });
        }

        function refreshGeoJson(index, data, newIds, oldIds){
            var key = vm.config.overlays[index].name;

            vm.overlays[key].eachLayer(function(layer){
                var id = layer.feature.properties.id;
                var newIndex = newIds.indexOf(id);
                if(newIndex >= 0){
                    // Makes sure the same layer isn't added twice
                    newIds.splice(newIndex, 1);
                }
                else if(oldIds.indexOf(id) >= 0){
                    // Removes layer no longer in view
                    vm.overlays[key].removeLayer(layer);
                }
            });

            _.forEach(data, function(g){
                if(newIds.indexOf(g.properties.id) >= 0){
                    vm.overlays[key].addData(g);
                }
            });
        }

        //TODO: If we bring heatLayer back, refactor this to refresh similarly to previous functions
        function refreshHeat(index, data) {
            var key = vm.config.overlays[index].name;

            // remove markers
            vm.markers[key] = [];

            // create markers
            for (var j = 0; j < data.length; j++){
                vm.markers[key].push(L.latLng(data[j].lat, data[j].lng));
            }
            vm.overlays[key].setLatLngs(vm.markers[key]);
        }

        function watchCallback(index, layerType) {
            $scope.$watchCollection('nimOptions.overlays[' + index + '].data', function (newValue, oldValue) {
                if (newValue && !angular.equals(newValue, oldValue)) {
                    oldValue = oldValue || [];

                    var newIds = newValue.map(function(e){ return e.properties.id; });
                    var oldIds = oldValue.map(function(e){ return e.properties.id; });

                    // Reduces set of new items to exclude items shared with oldValue
                    // currentIds = items removed
                    var currentIds = _.remove(newIds, function(e){
                        return oldIds.indexOf(e) >= 0;
                    });

                    // Reduces set of old items to exclude items shared with newValue (currentIds)
                    _.remove(oldIds, function(e){
                        return currentIds.indexOf(e) >= 0;
                    });

                    switch(layerType){
                        case 'L.heatLayer':
                            refreshHeat(index, newValue, oldValue);
                            break;
                        case 'nim.geoJson':
                            refreshGeoJson(index, newValue, newIds, oldIds);
                            break;
                        case 'nim.marker':
                        case 'nim.markerClusterGroup':
                            refreshMarkers(index, newValue, newIds, oldIds);
                            break;
                    }
                }
            });
        }

        function polygonToPoint(geom){
            if(geom.type != 'Point'){
                // Couldn't find leaflet function for getting the center for a polygon
                var coords = geom.coordinates[0];

                return coords.reduce(function (x,y) {
                    return [x[0] + y[0]/coords.length, x[1] + y[1]/coords.length];
                }, [0,0]);
            }
            else {
                return geom.coordinates;
            }
        }
    })

    .directive('nimLeaflet', function nimLeaflet() {
        return {
            restrict: 'AE',
            scope: {
                nimBounds: '=',
                nimDrawingResult: '=',
                nimOptions: '=',
                nimLeaflet: '=',
                nimLeafletId: '@'
            },
            controller: 'NimLeafletCtrl',
            controllerAs: 'vm',
            template: function(/*element, attributes*/) {
                //Model was looking for 'vm.leafletId' in nimLeaflet controller and finding nothing
                //return '<div id="' + attributes.nimLeafletId + '" style="position:absolute;top:0;bottom:0;right:0;left:0;"></div>';
                return '<div id="{{vm.id}}" style="position:absolute;top:0;bottom:0;right:0;left:0;"></div>';
            },
            link: function(scope, element, attributes, controller) {
                controller.init();
            }
        };
    })
;