angular.module('cerberus.admin')
    .controller('MenusCtrl', function MenusCtrl($scope, $location, workspaceId, localStorageService, MenusService){
        var vm = this;

        vm.menus = [];

        vm.openMenu = function(id){
            if(id) {
                $location.url('/settings/menus/' + id);
            }
            else{
                $location.url('/settings/menus/create');
            }
        };

        vm.menuFilter = menuFilter;

        init();

        function init(){
            MenusService.getMenus().then(function(menus){
                vm.menus = menus;
            });
        }

        // Getter-Setter function for filter
        function menuFilter(filterText){
            // Checks if value was passed in
            if(arguments.length){
                localStorageService.set('menuFilter.' + workspaceId, filterText);
                return filterText || '';
            }
            else {
                return localStorageService.get('menuFilter.' + workspaceId) || '';
            }
        }
    })
;