angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name LeafletDefaultsService
     * @alias cerberus/ui:LeafletDefaultsService
     * @description Provides default configuration values for the leaflet map
     */
    .factory('LeafletDefaultsService', function LeafletDefaultsService() {
        var service = {
            map: map,
            controls: controls,
            draw: draw,
            layer: layer
        };
        return service;

        ////////////////////
        function map() {
            return {
                center: [33.7, -117.8],
                zoom: 10,
                //layers: layers
                minZoom: undefined,
                maxZoom: undefined,
                maxBounds: undefined,
                dragging: true,
                touchZoom: true,
                scrollWheelZoom: true,
                doubleClickZoom: true,
                boxZoom: true,
                trackResize: true,
                closePopupOnClick: true,
                zoomControl: false,
                attributionControl: false
            };
        }
        function controls() {
            return {
                zoom: true,
                fullscreen: true,
                layers: true,
                scale: true,
                measure: false,
                loading: true,
                coordinate: true,
                zoomBox: false,
                bookmarks: false,
                draw: false
            };
        }
        function draw(layer) {
            return {
                draw: {
                    position: 'topleft',
                    polygon: {
                        title: 'Draw a sexy polygon!',
                        allowIntersection: false,
                        drawError: {
                            color: '#b00b00',
                            timeout: 1000
                        },
                        shapeOptions: {
                            color: '#bada55'
                        },
                        showArea: true
                    },
                    polyline: {
                        metric: false
                    },
                    circle: {
                        shapeOptions: {
                            color: '#662d91'
                        }
                    }
                },
                edit: {
                    featureGroup: layer
                }
            };
        }
        function layer() {
            return {
                type: null,
                name: null,
                url: null,
                visible: false,
                settings: {}
            };
        }
    })
;