angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartLineService
     * @alias cerberus/core:VizChartLineService
     * @description Provides functions for configuring line charts
     */
    .factory('VizChartLineService', function VizChartLineService(){
        return {
            //configLine: configLine
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configLine
         * @param {object} data
         * @param {object} options
         * function configLine(data, options) {}
         */

    })
;