angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name ViewsService
 * @alias cerberus/views-designer:ViewsService
 * @description Provides REST service for "views" resource
 */
    .factory('ViewsDesignerService', function ViewsDesignerService(_, $http, $q, apiPath, toaster, ConfirmModalService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getJoinTypes: getJoinTypes,
            createViewModel:createViewModel,
            updateModel: updateModel,
            getModel:getModel,
            refresh:refresh,
            simpleAggFunctions: simpleAggFunctions,
            selectAll: selectAll,
            modifySelectArray: modifySelectArray,
            findRemovedFields: findRemovedFields,
            removeOldFields: removeOldFields

    };
        ////////////////////

        /**
         * This function gets all the join types
         * @function getJoinTypes
         * @return {Object} joins
         */
        function getJoinTypes() {
            return {
                types:[
                    'INNER',
                    'LEFT OUTER',
                    'RIGHT OUTER',
                    'FULL OUTER'
                ],
                operators: [
                    '=',
                    '<>',
                    '>',
                    '>=',
                    '<',
                    '<='
                ]
            };
        }
        /**
         * This function gets all the simple aggregate functions
         * @function simpleAggFunctions
         * @return {array} simpleAggFunctions
         */
        function simpleAggFunctions() {
            return [
                {
                    func: 'SUM',
                    argType: 'float'
                },
                {
                    func:'AVG',
                    argType:'float'
                },
                {
                    func:'COUNT',
                    argType:'varchar'
                },
                {
                    func:'MAX',
                    argType:'float'
                },
                {
                    func:'MIN',
                    argType: 'float'
                }
            ];
        }
        /**
         * Makes a get call REST api "views/{id}/model" resource
         * @function getModel
         * @param {string} id - view id
         * @return {promise}
         */
        function getModel(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'views/' + id + '/model')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    if(reason.CODE == 404){
                        toaster.pop('info', "This view's widget(s) has been deleted.");
                    }
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * This function models the data before it sends it up to the server
         * @function createViewModel
         * @param {object} dataModel. - model
         * @return {Object} viewModel
         */
        function createViewModel(dataModel){
            var d = {
                widgets:{},
                joins: dataModel.joinArray,
                where: dataModel.whereArray,
                settings:dataModel.settings
            };
            for(var i = 0; i < dataModel.tableArray.length; i++){
                var tmpSelectArr = [];
                for(var j = 0; j < dataModel.selectArray.length; j++){
                    if(dataModel.selectArray[j].id === dataModel.tableArray[i].id){
                        var format = null;
                        var forms = dataModel.tableArray[i].forms;
                        for(var k = 0; k < forms.length; k++){
                            var objects = forms[k].objects;
                            for(var l = 0; l < objects.length; l++){
                                if(objects[l].modelId === dataModel.selectArray[j].modelId){
                                    format = objects[l].format || '';
                                    break;
                                }
                            }
                            if(format){
                                break;
                            }
                        }

                        var tmpCol = {
                            modelId: dataModel.selectArray[j].modelId,
                            type: dataModel.selectArray[j].type,
                            isLookUp: (dataModel.selectArray[j].isLookUp || dataModel.selectArray[j].isCustomLookup) ? true : false, // just to be sure undefined isn't passed in instead of false
                            format: format || ''
                        };
                        //For dataseries that has a fk to another widget
                        if(dataModel.selectArray[j].widgetId){
                            tmpCol.widgetId = dataModel.selectArray[j].widgetId;
                            tmpCol.fk = dataModel.selectArray[j].fk;
                        }

                        tmpSelectArr.push(tmpCol);
                    }
                }
                d.widgets[dataModel.tableArray[i].id] = {
                    id: dataModel.tableArray[i].id,
                    select:tmpSelectArr,
                    name: dataModel.tableArray[i].name,
                    uniqueName: dataModel.tableArray[i].uniqueName
                };
            }
            return d;
        }
        /**
         * Makes a put call REST api "views/{id}/model" resource by id
         * @function updateModel
         * @param {string} id
         * @param {View} data - view object
         * @return {promise}
         */
        function updateModel(id, data){
            var deferred = $q.defer();
            $http.put(apiPath + 'views/' + id + '/model', data)
                .success(function(value){
                    toaster.pop('success', 'View Saved');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    toaster.pop('error', _.get(reason, 'MESSAGE'));
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Makes a put call REST api "views/{id}/refresh" resource by id
         * @function refresh
         * @param {string} id
         * @return {promise}
         */
        function refresh(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'views/' + id + '/refresh')
                .success(function(value){
                    toaster.pop('info', 'View Refresh Started...');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * This function selects all the form fields in a widget
         * @function selectAll
         * @return {Object} joins
         */
        function selectAll(table, model) {
            var select = false;
            if(table.selectAll){
                select = true;
            }
            for(var j = 0; j < table.forms.length; j++){
                for(var k = 0; k < table.forms[j].objects.length; k++){
                    table.forms[j].objects[k].selected = select;
                    modifySelectArray(table.forms[j].objects[k],table.name, table.uniqueName, table.id, model);
                }
            }
        }
        /**
         * This function modifies the select array in the data model
         * @function modifySelectArray
         * @return {Object} joins
         */
        function modifySelectArray(object,widgetName,widgetUniqueName, widgetId, model) {
            object.name = widgetName;
            object.uniqueName = widgetUniqueName;
            object.id = widgetId;

            var index = _.findIndex(model.selectArray, {id: object.id, modelId: object.modelId});

            if(!object.selected){
                model.selectArray.splice(index,1);
            }
            else if(index < 0){
                model.selectArray.push(object);
            }
        }

        /**
         * Finds all fields in the select array that have been removed from their respective widgets
         * @param model
         * @returns {Array}
         */
        function findRemovedFields(model){
            var fields = [];

            _.forEach(model.selectArray, function(selectedField){
                // Find field's widget in table array
                var widget = _.find(model.tableArray, 'id', selectedField.id);

                if(widget){
                    // Checks if any of the forms has the selected field
                    var found = _.some(widget.forms, function(form){
                        return _.findIndex(form.objects, 'modelId', selectedField.modelId) >= 0;
                    });

                    // Field no longer exists
                    if(!found){
                        fields.push(selectedField);
                    }
                }
                else {
                    // If widget is not in table array, this field should be removed
                    fields.push(selectedField);
                }
            });

            return fields;
        }

        function removeOldFields(model){
            var removedFields = findRemovedFields(model);
            if(!_.isEmpty(removedFields)) {
                var fieldDisplay = '';

                _.forEach(removedFields, function(r){
                    fieldDisplay += '<br /><em>' + r.uniqueName + '</em>_<strong>' + r.modelId + '</strong>';
                });

                ConfirmModalService.showModal(null, {
                    headerText: 'Confirm Removal',
                    bodyText: 'These fields no longer exist. Would you like to remove them?<br />' + fieldDisplay,
                    actionButtonText: 'Remove',
                    closeButtonText: 'Cancel',
                    check: function () {
                        return true;
                    },
                    confirm: function () {
                        _.forEach(removedFields, function(r){
                            _.remove(model.selectArray, r);
                        });
                    }
                });
            }
        }
    })
;
