angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormsBuildStaticService
     * @alias cerberus/core:FormsBuildStaticService
     * @description Builds html templates for form fields values
     */
    .factory('FormsBuildStaticService', function FormsBuildStaticService(_, moment, kendo, FormsBuildHelperService, CalculationService, $log, $filter) {
        return {
            statictext: statictext,
            hr: hr,
            readonly: readonly,
            text: text,
            number: number,
            datetime: datetime,
            autocomplete: autocomplete,
            location: location,
            map: map,
            textarea: textarea,
            signature: signature,
            radio: radio,
            checkbox: checkbox,
            select: select,
            multiselect: multiselect,
            table: table,
            timer: timer,
            image: image,
            rule: rule
        };
        ////////////////////
        /**
         * @function statictext
         * @returns {string} of html
         */
        function statictext() {
            return '<span class="nim-static-text" ng-bind-html="compiledStaticText"></span>';
        }

        /**
         * @function hr
         * @param {Field} field
         * @returns {string} of html
         */
        function hr(field) {
            var val = '';
            var classes = ['divider', field.param.orientation];
            if(field.param.type === 'text'){
                val = '<span>' + field.param.value + '</span>';
            }
            else if(field.param.type === 'icon'){
                val = '<i class="fa fa-fw fa-' + field.param.value + '"></i>';
            }
            else if(field.param.type === 'none'){
                classes.push('full');
            }

            return '<div class="' + classes.join(' ') + '">' + val + '</div>';
        }

        /**
         * @function readonly
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function readonly(field, value) {
            var val = '';

            if(!_.isNull(value) && !_.isUndefined(value)){
                val = value;
            }

            if(field.calculation.formula){
                var tokenArr = angular.copy(field.calculation.tokens);
                val = CalculationService.calculate(tokenArr).value;
                val = CalculationService.toDate(val);
            }

            if(field.param.format){
                try {
                    val = kendo.format(field.param.format, val);
                } catch(e){
                    $log.error('Invalid format:', { error: e });
                }
            }

            var label = FormsBuildHelperService.makeLabel(field);
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + val + '</p>' + '</div>';
        }

        /**
         * @function text
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function text(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var val = value || '';
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + FormsBuildHelperService.filter(field.display.filter, val) + '</p>' + '</div>';
        }

        /**
         * @function number
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function number(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var displayValue = parseFloat(value);

            if(isNaN(displayValue)){
                displayValue = '';
            }

            if(field.display.format && field.display.format.length > 0 && displayValue !== ''){
                displayValue = kendo.toString(parseFloat(displayValue), field.display.format.replace(/\\\\/g,'\\'));
            }
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + FormsBuildHelperService.filter(field.display.filter, displayValue) + '</p>' + '</div>';
        }

        /**
         * @function datetime
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function datetime(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var val = '';
            if(value && (!_.isDate(value) || !_.isNaN(value.valueOf()))){
                var date = value;
                
                if (!_.isDate(value)) {
                    date = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate();
                }    

                val = kendo.toString(date, field.display.format);
            }
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + val + '</p>' + '</div>';
        }

        /**
         * @function autocomplete
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function autocomplete(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var val = value || '';
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + val + '</p>' + '</div>';
        }

        /**
         * @function location
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function location(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var val = _.get(value, 'properties.formatted_address', '');
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + val + '</p>' + '</div>';
        }

        /**
         * @function map
         * @returns {string} of html
         */
        function map() {
            return '<div nim-form-map nimFormMapStatic></div>';
        }

        /**
         * @function textarea
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function textarea(field, value) {
            var val = value;

            if (!_.isString(value)) {
                val = '';
            }
            return FormsBuildHelperService.divOpenForm() + FormsBuildHelperService.makeLabel(field) + '<p class="nim-static-textarea">' + val.trim() + '</p>' + '</div>';
        }

        /**
         * @function signature
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function signature(field, value) {
            var label = FormsBuildHelperService.makeLabel(field);
            var wrapLabelClass = label.length ? 'has-label': '';

            if (field.param.type === 'initial') {
                var initImg = '';
                var initText = '';
                var initMeta = '';

                if(_.get(value, 'path')){
                    initImg = '<img class="sign-img" src="' + value.path + '" />';
                }

                if(!_.get(value, 'path') && _.get(value, 'userId')){
                    var initials = '';
                    var arr = value.userName.split(' ');

                    for (var i = 0; i < arr.length; i++) {
                        initials += arr[i].substring(0,1).toUpperCase() + '.';
                    }

                    initText = '<div class="sign-text">' + initials + '</div>';
                }

                if(_.get(value, 'path') || _.get(value, 'userId')){
                    initMeta = '<div class="sign-meta">' +
                    '<span class="sign-name">' + (value.userName || 'Initials') + '</span>' +
                    '<span class="sign-date">Date: <span>' + $filter('date')(value.timestamp, 'MMM d, y h:mm:ss a') + '</span></span>' +
                    '</div>';
                }

                return '<div class="form-group">' +
                        label +
                        '<div class="sign-wrap ' + wrapLabelClass + '">' +
                            initImg +
                            initText +
                            initMeta +
                        '</div>' +
                    '</div>';
            }
            else {
                var sigImg = '';
                var sigText = '';
                var sigMeta = '';

                if(_.get(value, 'path')){
                    sigImg = '<img class="sign-img" src="' + value.path + '" />';
                }

                if(!_.get(value, 'path') && _.get(value, 'userId')){
                    sigText = '<div class="sign-text">' + value.userName + '</div>';
                }

                if(_.get(value, 'path') || _.get(value, 'userId')){
                    sigMeta = '<div class="sign-meta">' +
                    '<span class="sign-name">' + (value.userName || 'Signature') + '</span>' +
                    '<span class="sign-date">Date: <span>' + $filter('date')(value.timestamp, 'MMM d, y h:mm:ss a') + '</span></span>' +
                    '</div>';
                }

                return '<div class="form-group">' +
                        label +
                        '<div class="sign-wrap ' + wrapLabelClass + '">' +
                            sigImg +
                            sigText +
                            sigMeta +
                        '</div>' +
                    '</div>';
            }
        }

        /**
         * @function radio
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function radio(field, value) {
            var val = angular.fromJson(value);
            var label = FormsBuildHelperService.makeLabel(field);
            var displayVal = val.display || '';
            return FormsBuildHelperService.divOpenForm() + label + '<p>' + displayVal.trim() + '</p></div>';
        }

        /**
         * @function checkbox
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function checkbox(field, value) {
            var checkClass = value ? 'fa-check-square-o' : 'fa-square-o';
            return FormsBuildHelperService.divOpenForm() + '<label class="checkbox-inline static"><i class="fa fa-fw ' + checkClass + '"></i>&nbsp;<span>' + field.label.text + '</span></label></div>';
        }

        /**
         * @function select
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function select(field, value) {
            var displayVal = '';
            if(value && value.display){
                displayVal = value.display;
                if(isNaN(value.display)){
                    displayVal = displayVal.replace(/>/g,'&gt;').replace(/</g,'&lt;');
                }
            }
            return FormsBuildHelperService.divOpenForm() + FormsBuildHelperService.makeLabel(field) + FormsBuildHelperService.pFormControlStatic() + displayVal + '</p>' + '</div>';
        }

        /**
         * @function multiselect
         * @param {Field} field
         * @param {*} value
         * @returns {string} of html
         */
        function multiselect(field, value) {
            var displayVal = '';
            if(value){
                var val = angular.fromJson(value);
                for(var i = 0; i < val.length; i++){
                    displayVal = displayVal + '<li>' + val[i].display + '</li>';
                }
            }
            return FormsBuildHelperService.divOpenForm() + FormsBuildHelperService.makeLabel(field) + '<ul>' + displayVal + '</ul>' + '</p>' + '</div>';
        }

        /**
         * @function table
         * @param {Field} field
         * @returns {string} of html
         */
        function table(field) {
            var label = FormsBuildHelperService.makeLabel(field);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            return FormsBuildHelperService.divOpenForm() + label + '<div nim-form-table nimFormTableStatic class="nim-form-table"></div>' + help + '</div>';
        }

        /**
         * @function timer
         * @param field
         * @returns {string}
         */
        function timer(field) {
            var label = FormsBuildHelperService.makeLabel(field);
            var start = moment(field.param.start.time);
            var end = moment(field.param.end.time);
            var dur = moment.duration(end.diff(start));
            var val = dur.days() + ' days ' + dur.hours() + ' hr ' + dur.minutes() + ' min ' + dur.seconds() + ' sec';
            return FormsBuildHelperService.divOpenForm() + label + FormsBuildHelperService.pFormControlStatic() + val + '</p>' + '</div>';
        }

        /**
         * @function image
         * @param field
         * @param value
         * @returns {string}
         */
        function image(field, value){
            var label = FormsBuildHelperService.makeLabel(field);
            var imgName = field.config.modelId + '.png';
            var size = field.param.resultImageSize;

            if(value) {
                return FormsBuildHelperService.divOpenForm() + label + '<div nim-file-thumb="' + imgName + '" nim-attachment-id="' + value + '" size="' + size + 'x' + size + '"></div>' + '</div>';
            }
            else {
                return FormsBuildHelperService.divOpenForm() + label + '</div>';
            }
        }

        /**
         * @function rule
         * @param field
         * @returns {string}
         */
        function rule(field){
            return '<label>' + (field.param.ruleName || '') + '</label>';
        }
    })
;