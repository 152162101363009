angular.module('cerberus.admin')
    .controller('FormsDesigner_stringCtrl',
    function FormsDesigner_stringCtrl(_, $scope, $rootScope, $uibModalInstance, $window, $sanitize, $timeout,
                                      autoTabIndex, currentFormObjects, editFlag, formObj, formOriginId, idArray,
                                      sections, widgetId, DesignerUtilityService, FormsDesignerListService,
                                      UsersProfileListService, ViewsService, WidgetsSettingsService, WorkspacesGroupsService) {
        // Populate Scope
        $scope.formOriginId = formOriginId;
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.views = [];
        $scope.viewColumns = [];
        $scope.groups = [];
        $scope.profiles = [];
        $scope.errStr = null;
        $scope.selectedWidgetOperands = [];
        $scope.selectedProfileFields = [];
        $scope.formFields = [];
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.widgetFieldIds = idArray;
        $scope.filterFieldArray = [];
        $scope.curModelIds = idArray;
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.patternsList = FormsDesignerListService.patterns();
        $scope.masksList = FormsDesignerListService.masks();
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.separatorList = FormsDesignerListService.separators();
        $scope.isValidRegexTestCase = true;
        $scope.formInput = {
            testPattern: null
        };
        $scope.sections = sections;
        $scope.defaultFilter = {};
        $scope.defaultFilterType = null;
        $scope.defaultFilterOperators = DesignerUtilityService.filterOperators();
        $scope.conditionalActions = FormsDesignerListService.conditionalActions();
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);
        $scope.lookUpDefaultValueOptions = DesignerUtilityService.lookUpDefaultValueOptions($scope.temp.model.option);
        $scope.addressFields = FormsDesignerListService.addressFields();
        $scope.textEditorOptions = DesignerUtilityService.textEditorOptions($scope);

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Form Object Specific Logic
        $scope.checkRegexTestCase = function(){
            if ($scope.formInput.testPattern){
                var pattern = new RegExp($scope.temp.model.validation.pattern);
                $scope.isValidRegexTestCase = $scope.formInput.testPattern.match(pattern);
            }
        };

        $scope.addField = function(field, value){
            $scope.temp.model.param.fields[field.modelId] = {
                usage: field.label,
                value: value || 'locality',
                form: field.formId
            };
        };
        $scope.removeField = function(id){
            delete $scope.temp.model.param.fields[id];
        };

        $scope.toggleDefaultValue = function(objConfig, opt){
            $timeout(function(){
                if($scope.optionIsDefault(objConfig.defaultValue, opt)){
                    objConfig.defaultValue = null;
                }
                else {
                    objConfig.defaultValue = opt;
                }
            });
        };

        $scope.optionIsDefault = function(defaultVal, opt){
            return (defaultVal != null && defaultVal.id === opt.id);
        };

        $scope.addOption = function(array, data){
            var r = /\d+/;
            var a = 0;
            if(array.length > 0){
                a = Math.max.apply(Math, array.map(function(o){return o.id.match(r);}));
                a = a + 1;
            }
            data.id = 'opt' + a;
            array.push(data);
        };
        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };
        $scope.selectColumn = function(id){
            if(id){
                ViewsService.getColumns(id).then(function(data){
                    if(data.length === 0){
                        $scope.errStr = '<div class="alert alert-warning" role="alert"><strong>Warning!</strong> There are no columns in this view.</div>';
                    }
                    else{
                        $scope.errStr = null;
                    }
                    $scope.viewColumns = data;
                });
                for(var i = 0; i < $scope.views.length; i++){
                    if($scope.views[i].id === id){
                        $scope.temp.model.option.widgetId = $scope.views[i].base_widget_id;
                        break;
                    }
                }
            }
            else{
                $scope.viewColumns = [];
                $scope.errStr = null;
            }
        };

        $scope.getProfileTypes = function(){
            $scope.temp.model.option.type='profile';
            angular.forEach(UsersProfileListService.profileFields(),function(v,k){
                $scope.profiles.push(k);
            });
        };
        $scope.selectProfile = function(type){
            $scope.selectedProfileFields = UsersProfileListService.profileFields()[type];
        };
        $scope.getGroupsList = function(){
            $scope.temp.model.option.type='groups';
            WorkspacesGroupsService.getGroups($rootScope.userData.currentWorkspace.id).then(function(value){
                $scope.groups = value;
            });
        };
        $scope.sortableOptions = {
            ignore: "input",
            change: function (e) {
                $timeout(function () {
                    var item = $scope.temp.model.option.values.splice(e.oldIndex, 1)[0];
                    $scope.temp.model.option.values.splice(e.newIndex, 0, item);
                },0);
            }
        };

        // Default Filter
        $scope.notPropertyColumn = function(col){
            return col.type === 'column';
        };

        $scope.updateDefaultFilterType = function(){
            if($scope.defaultFilter.field && $scope.defaultFilter.field !== ''){
                var index = $scope.viewColumns.map(function(elem){return elem.colName;}).indexOf($scope.defaultFilter.field);
                if(index >= 0){
                    $scope.defaultFilterType = $scope.viewColumns[index].castType;
                    return;
                }
            }
            $scope.defaultFilterType = null;
        };

        $scope.addFilter = function(){
            $scope.temp.model.param.defaultFilter.filters.push($scope.defaultFilter);
            $scope.defaultFilter = {};
            $scope.defaultFilterType = null;
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFields = formFieldArray;
                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);
                $scope.widgetFieldIds = _.union(idArray, _.pluck($scope.formFieldObjects, 'modelId'));

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);

                // Grid
                $scope.mainGridOptions = DesignerUtilityService.formGrid(formFieldArray.display);
                $scope.detailGridOptions = DesignerUtilityService.formDetailGrid($scope);

                // Codemirror
                $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded(formFieldArray.operands);
            });

            ViewsService.get().then(function(views){
                $scope.views = views;

                if($scope.editFlag && $scope.temp.model.option.type === 'views'){
                    $scope.selectColumn($scope.temp.model.option.id);
                }
                else if($scope.editFlag && $scope.temp.model.option.type === 'profile'){
                    $scope.getProfileTypes($scope.temp.model.option.id);
                }
            });

            $scope.temp.model.param.defaultFilter = $scope.temp.model.param.defaultFilter || {
                logic: 'and',
                filters: []
            };

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;