angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizScheduleService
     * @alias cerberus/core:VizScheduleService
     * @description Provides functions for configuring schedulers
     */
    .factory('VizScheduleService', function VizScheduleService(_, kendo, moment, $timeout, $http, $q,
        pageObjectsService, VizUtilityService, InstancesService, InstancesWindowService) {
        return {
            buildDataSource: buildDataSource,
            configSchedule: configSchedule,
            viewRecord: viewRecord,
            createRecord: createRecord,
            updateItem: updateItem
        };

        /**
         * Builds main dataSource for schedule page object
         * @param scope
         * @param pageObject
         * @returns {kendo.data.SchedulerDataSource}
         */
        function buildDataSource(scope, pageObject) {
            var sourcesCreated = false;
            var sources = [],
                ids = [];

            var dataSourceOptions = {
                transport: {
                    read: function (options) {
                        var data = [];

                        if (!sourcesCreated) {
                             sourcesCreated = true;

                             for(var id in pageObject.viz.settings.dataSource){
                                 if(pageObject.viz.settings.dataSource.hasOwnProperty(id)) {
                                     var newSource = buildSubDataSource(scope, pageObject, id);
                                     VizUtilityService.subscribeToPageFilters(_.get(pageObject.filterMap, id, {}), scope, newSource);
                                     sources.push(newSource);
                                     ids.push(id);
                                 }
                             }
                        }
                        
                        _.forEach(sources, function (source, index) {
                            var subData = source.data().toJSON();
                            data = data.concat(subData);

                            _.forEach(subData, function (dataItem) {
                                dataItem['__sourceId'] = ids[index];
                            });
                        });

                        data.sort(byDate('end')).sort(byDate('start'));

                        options.success(data);
                    }
                },
                change: function (e) {
                    var data = e.sender.data();

                    if (scope.schedule) {
                        // Adjust and set options
                        configSchedule(data);
                    }

                    scope.data = data;
                    scope.mainPageObject.vizCtrl.data = data;
                }
            };

            // Read from sub-data-sources on refresh
            var debouncedRefresh = _.debounce(function () {
                _.forEach(sources, function (s) {
                    s.read();
                });
            }, 5000, { leading: true, trailing: true });

            scope.$on('nim-viz-reload', function (event) {
                event.preventDefault();
                debouncedRefresh();
            });

            return new kendo.data.SchedulerDataSource(dataSourceOptions);
        }

        /**
         * Builds child dataSource for schedule page object
         * @param scope
         * @param pageObject
         * @param id - data source ID
         * @returns {kendo.data.SchedulerDataSource}
         */
        function buildSubDataSource(scope, pageObject, dsid) {
            var source = pageObject.viz.settings.dataSource[dsid];
            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                requestStart: function (e) {
                    var requiredFilters = _.get(pageObject.params, ['requiredFilters', dsid], {});
                    if(!_.isEmpty(requiredFilters)){
                        _.forEach(requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                },
                change: function (e) {
                    var data = e.sender.data();

                    if (_.get(source, 'schema.model.fields.isAllDay.defaultValue')) {
                        _.forEach(data, function (dataItem) {
                            dataItem.isAllDay = true;
                        });
                    }

                    if (pageObject.viz.settings.resources) {
                        setColors(data, pageObject.viz.settings.resources[dsid]);
                    }

                    $timeout(function () {
                        if (scope.dataSource) {
                            scope.dataSource.read();
                        }
                    });
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(source)
            };
            
            var baseFilter = angular.copy(source.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            angular.extend(dataSourceOptions, source, sortOptions, filterOptions);

            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if (field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
            });

            return new kendo.data.SchedulerDataSource(dataSourceOptions);

            function read(options) {
                if (!options.data.filter) {
                    options.data.filter = {
                        logic: 'and',
                        filters: []
                    };
                }

                // Filter by calendar view
                addViewFilter(scope, source, options.data.filter.filters);

                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                httpOptions.params.$ds = dsid;

                if (pageObject.params) {
                    var recurrence = VizUtilityService.buildRecurrenceData(pageObject, scope, options.data);
                    if (recurrence) {
                        httpOptions.params.$recurrence = recurrence;
                    }
                }

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        function addViewFilter(scope, source, filterArray) {
            var view = _.result(scope.schedule, 'view');
            if (view) {
                var viewStartDate = moment(view.startDate()),
                    viewEndDate = moment(view.endDate()),
                    startField = source.schema.model.fields.start.from,
                    endField = source.schema.model.fields.end.from;

                // End date is set to midnight of the last day in the view,
                // so events on that day are outside of the date range
                viewEndDate.add(1, 'd');

                var startValue = viewStartDate.toDate(),
                    endValue = viewEndDate.toDate();

                if (startField && endField) {
                    filterArray.push(
                        {
                            operator: 'gt',
                            field: endField,
                            value: startValue
                        },
                        {
                            operator: 'lt',
                            field: startField,
                            value: endValue
                        }
                    );
                }
                else if (startField) {
                    filterArray.push(
                        {
                            operator: 'gte',
                            field: startField,
                            value: startValue
                        },
                        {
                            operator: 'lt',
                            field: startField,
                            value: endValue
                        }
                    );
                }
                else if (endField) {
                    filterArray.push(
                        {
                            operator: 'gt',
                            field: endField,
                            value: startValue
                        },
                        {
                            operator: 'lte',
                            field: endField,
                            value: endValue
                        }
                    );
                }
            }
        }

        /**
         * Modifies scheduler settings
         * @param data
         */
        function configSchedule(data){
            for (var d = 0; d < data.length; d++) {
                var dataItem = data[d];

                // Default Date-Time rules
                var hasStartTime = dataItem.start && dataItem.start instanceof Date;
                var hasEndTime = dataItem.end && dataItem.end instanceof Date;
                var defaultOffsetValue = 30;
                var defaultOffsetUnit = 'm';

                if(hasStartTime && hasEndTime){
                    var start = moment(dataItem.start);
                    var end = moment(dataItem.end);

                    // Make sure end time is not before start time
                    if(end.diff(start) < 0){
                        dataItem.end = start.add(defaultOffsetValue, defaultOffsetUnit).toDate();
                    }
                }
                else if(hasStartTime){
                    // If record has start and no end, set end to [defaultOffset] after start time
                    dataItem.end = moment(dataItem.start).add(defaultOffsetValue, defaultOffsetUnit).toDate();
                }
                else if(hasEndTime){
                    // If record has end and no start, set start to [defaultOffset] before end time
                    dataItem.start = moment(dataItem.end).subtract(defaultOffsetValue, defaultOffsetUnit).toDate();
                }
                else{
                    // If neither time is set, remove from data
                    // Lack of start time breaks gantt chart
                    data.splice(d, 1);
                    d--;
                }
            }
        }

        function byDate(dateField){
            return function (a, b){
                var dateA = a[dateField],
                    dateB = b[dateField],
                    momentA = moment(dateA);

                if(!dateA || momentA.isBefore(dateB)){
                    return -1;
                }
                else if(momentA.isAfter(dateB)){
                    return 1;
                }

                return 0;
            };
        }

        /**
         * Sets color value for each calendar event
         * @param data - Query data from singular dataSource
         * @param resources - DataSource's resource configuration
         */
        function setColors(data, resources){
            _.forEach(data, function(event){
                // Checks if event's category matches item in color map
                var match = _.find(resources.dataSource, 'value', event[resources.field]);

                if(match){
                    event.__color = match.color;
                }
                else {
                    // Assigns default color if no match is found
                    event.__color = resources.defaultColor;
                }
            });
        }

        /**
         * Opens selected record in instance window
         * @function viewRecord
         * @param id
         * @param navFunctions
         */
        function viewRecord(id, navFunctions) {
            InstancesWindowService.openWindow({
                action: 'read',
                instanceId: id,
                navFunctions: navFunctions
            });
        }

        /**
         * Creates new record using default data from selected date
         * @param pageObject
         * @param defaultDataArray
         */
        function createRecord(pageObject, defaultDataArray){
            pageObjectsService.addCustomValuesToDefaultData(defaultDataArray, pageObject.params.defaultValues);
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: pageObject.viz.widgetId,
                title: pageObject.name,
                defaultData: defaultDataArray
            });
        }

        /**
         * Updates event that has been moved
         * @param instanceId
         * @param data
         * @returns {Promise}
         */
        function updateItem(instanceId, data) {
            var promises = [];

            // If order and category are from different states, resolve revisions separately
            _.forEach(data, function(state, stateId){
                promises.push(InstancesService.update(instanceId, { currentStateId: stateId, recordData: state }));
            });

            return $q.all(promises);
        }
    })
;