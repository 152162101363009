angular.module('cerberus.core')
/**
 * @ngdoc service
 * @name vizKanbanService
 * @alias cerberus/core:vizKanbanService
 * @description Provides datasources and helpers kanban viz
 */
    .factory('vizKanbanService', function vizKanbanService(_, kendo, $q, $http, $timeout,
                                                           InstancesService, InstancesWindowService, nimUtilityService,
                                                           pageObjectsService, VizUtilityService) {
        var service = {
            buildDataSource: buildDataSource,
            buildCategoryDataSource: buildCategoryDataSource,
            filter: filter,
            buildList: buildList,
            addNextToUpdateData: addNextToUpdateData,
            updateItem: updateItem,
            templateData: templateData,
            binAggregate: binAggregate,
            stripMisplacedAttributes: stripMisplacedAttributes,
            viewRecord: viewRecord,
            addItem: addItem
        };
        return service;
        ////////////////////

        /**
         * Builds dataSource for the Kanban
         * @param pageObject
         * @param scope
         * @param loading
         * @returns {kendo.data.DataSource}
         */
        function buildDataSource(pageObject, scope, loading) {
            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: false,
                serverGrouping: false,
                pageSize: 20,
                requestStart: function (e) {
                    if(!_.isEmpty(pageObject.params.requiredFilters)){
                        _.forEach(pageObject.params.requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                    
                    if (!e.isDefaultPrevented()) {
                        $timeout(function () {
                            loading.item = true;
                        });
                    }
                },
                requestEnd: function () {
                    $timeout(function () {
                        loading.item = false;
                    });
                },
                error: function () {
                    $timeout(function () {
                        loading.item = false;
                    });
                },
                change: function () {
                    var data = this.data();

                    _.forEach(data, function (dataItem) {
                        if (dataItem.__nimAttachments.length > 0) {
                            dataItem.file = dataItem.__nimAttachments[0];
                        }
                        else {
                            dataItem.file = { name: '' };
                        }
                    });

                    scope.mainPageObject.vizCtrl.data = data;
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(pageObject.viz.settings.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, pageObject.viz.settings.dataSource, sortOptions, filterOptions);

            // Adds parse function to make sure date fields are correct
            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if (field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
                else if (field.type === 'number') {
                    field.parse = VizUtilityService.parseNumberField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        function buildCategoryDataSource(binField, loading){
            var options = {
                requestStart: function () {
                    $timeout(function () {
                        loading.category = true;
                    });
                },
                requestEnd: function () {
                    $timeout(function () {
                        loading.category = false;
                    });
                },
                error: function () {
                    $timeout(function () {
                        loading.category = false;
                    });
                }
            };

            if(binField.isLookup){
                options = {
                    type:'odata',
                    transport: {
                        read: {
                            dataType: 'json',
                            url: '/server/rest/v1/views/' + binField.viewId + '/odata.svc?$lookupDisplay=' + binField.lookupCol,
                            beforeSend: VizUtilityService.setRequestHeaders
                        }
                    }
                };
            }
            else if(binField.isCustomLookup){
                options = {
                    type: 'odata',
                    transport: {
                        read: function(opt){
                            $http.get('/server/rest/v1/forms/' + binField.formId + '/objects/' + binField.modelId + '/options').then(function(result){
                                // Format the response data
                                var data = _.get(result, 'data.DATA.values', []);
                                opt.success({
                                    d: {
                                        results: data,
                                        __count: data.length
                                    }
                                });
                            }, function (reason) {
                                opt.error(reason);
                            });
                        }
                    }
                };
            }

            return new kendo.data.DataSource(options);
        }

        /**
         * Filters data items by category
         * @param items - Data items pulled from server 
         * @param key - Category column
         * @param binValue - Bin's display value
         * @param id - Bin id
         */
        function filter(items, key, binValue, id) {
            return items.filter(function (dataItem) {
                var categoryValue = dataItem[key];

                // Data item's category value is a lookup struct
                if (_.isObject(categoryValue)) {
                    // If bin id is provided, match the ids
                    if (id) {
                        return categoryValue.id == id;
                    }
                    
                    // First check if bin value matches id
                    var idMatchesDisplay = categoryValue.id == binValue;

                    // Then check if bin value matches display
                    if (!idMatchesDisplay) {
                        return categoryValue.display == binValue;
                    }                    

                    return idMatchesDisplay;
                }
                // If bin id provided, check category value against id
                else if (id && categoryValue == id) {
                    return true;
                }

                // Compare category value with bin's display value by default
                return categoryValue == binValue;
            });
        }

        // function calcOrder(initOrder, sourceIdx, destIdx, destArr, orderKey, dir) {
        //     var order = initOrder;
        //     // If empty destination array
        //     if (destArr.length === 0) {
        //         order = 1;
        //     }
        //     // Else populated array
        //     else {
        //         // If item was moved to the top
        //         if (destIdx === 0) {
        //             if (dir == 'asc') {
        //                 order = destArr[0][orderKey] - 1;
        //             }
        //             else {
        //                 order = destArr[0][orderKey] + 1;
        //             }    
        //         }
        //         // If item was moved to the bottom
        //         if (destIdx >= destArr.length - 1) {
        //             if (dir == 'asc') {
        //                 order = destArr[destArr.length - 1][orderKey] + 1;
        //             }
        //             else {
        //                 order = destArr[destArr.length - 1][orderKey] - 1;
        //             }    
        //         }
        //         // If item was moved within the array
        //         if (destIdx > 0 && destIdx < destArr.length - 1) {
        //             var max, min, diff;
        //             // This is some funky logic that must calculate the min the max based
        //             // on whether the item was moved up or down...
        //             if (sourceIdx > destIdx) {
        //                 if (dir == 'asc') {
        //                     min = destArr[destIdx - 1][orderKey] || 0;
        //                     max = destArr[destIdx][orderKey] || 0;
        //                 }
        //                 else {
        //                     max = destArr[destIdx - 1][orderKey] || 0;
        //                     min = destArr[destIdx][orderKey] || 0;
        //                 }
        //             }
        //             else {
        //                 if (dir == 'asc') {
        //                     min = destArr[destIdx][orderKey] || 0;
        //                     max = destArr[destIdx + 1][orderKey] || 0;
        //                 }
        //                 else {
        //                     max = destArr[destIdx][orderKey] || 0;
        //                     min = destArr[destIdx + 1][orderKey] || 0;
        //                 }
        //             }
        //             diff = max - min;

        //             // If no difference
        //             if(diff === 0) {
        //                 //order = min + 1;
        //                 order = min; // If put between two items of the same priority, should be assigned same priority
        //             }
        //             // If at least a whole number difference
        //             if (diff > 1) {
        //                 order = Math.floor(min) + 1;
        //             }
        //             // If fractional difference
        //             if (diff > 0 && diff <= 1) {
        //                 order = max === min ? min + 1 : (diff / 2) + min;
        //             }

        //         }
        //     }
        //     return order;
        // }

        function buildList(binData, key) {
            var orderedList = [];
            var unorderedList = _.map(binData, function (dataItem) { return new ListItem(dataItem, key); });
            
            for (var i = 0; i < unorderedList.length; i++){
                var listItem = unorderedList[i],
                    hasPrev = !!(_.find(unorderedList, 'next', listItem.id)),
                    nextItem = null;
                
                if (listItem.next !== listItem.id){
                    nextItem = _.find(unorderedList, 'id', listItem.next);
                }
                
                if (nextItem && !hasPrev) {
                    i = -1;
                    addToOtherArray(unorderedList, orderedList, listItem);
                    addNextListItem(orderedList, unorderedList, nextItem);
                }
                else if(!nextItem){
                    listItem.next = null;

                    if (!hasPrev) {
                        i--;
                        addToOtherArray(unorderedList, orderedList, listItem);
                    }
                }
            }

            return _.pluck(orderedList.concat(unorderedList), 'data');
        }

        function ListItem(record, key) {
            if (_.endsWith(key, '_display') && _.has(record, key.replace(/_display$/, ''))) {
                key = key.replace(/_display$/, '');
            }

            this.data = record;
            this.id = record.id;
            this.next = record[key] || null;

            if (_.isObject(this.next) && !_.isDate(this.next)) {
                this.next = this.next.id;
            }
        }

        function addNextListItem(orderedList, unorderedList, prevItem) {
            addToOtherArray(unorderedList, orderedList, prevItem);

            var nextItem = _.find(unorderedList, function (item) {
                return item.id == prevItem.next;
            });

            if (nextItem) {
                addNextListItem(orderedList, unorderedList, nextItem);
            }
            else {
                // Next item does not exist
                prevItem.next = null;
            }
        }

        function addToOtherArray(currentArray, otherArray, item) {
            _.pull(currentArray, item);
            otherArray.push(item);
        }

        function addNextToUpdateData(updateData, nextField, nextKey, dataItem, nextItem) {
            var nextValue = {};

            if (dataItem) {
                var id = dataItem.id,
                    stateId = nextField.stateId;
                
                updateData[id] = updateData[id] || {};
                updateData[id][stateId] = updateData[id][stateId] || {};
            
                if (nextItem) {
                    nextValue = {
                        display: nextItem[nextField.lookupCol],
                        id: nextItem.id
                    };
                }

                updateData[id][stateId][nextField.modelId] = nextValue;
                dataItem[nextKey] = nextValue.display || '';
            }
        }

        /**
         * Updates dataItem that has been moved
         * @param data
         * @returns {Promise}
         */
        function updateItem(data) {
            var promises = [];

            _.forEach(data, function (instanceData, instanceId) {
                if (instanceId) {
                    // If order and category are from different states, resolve revisions separately
                    _.forEach(instanceData, function (state, stateId) {
                        promises.push(InstancesService.update(instanceId, { currentStateId: stateId, recordData: state }));
                    });
                }    
            });  

            return $q.all(promises);
        }

        /**
         * Generates the object to use when compiling the kendo template
         * @param dataItem
         * @returns {Object}
         */
        function templateData(dataItem){
            var id = dataItem.id;

            return {
                data: dataItem,
                nim: nimUtilityService,
                ui: {
                    checkbox: '<input class="form-control nim-list-checkbox" type="checkbox" ng-checked="vm.itemSelected(' + id + ')" ng-model="selected" ng-click="vm.selectItem(' + id + ')" />',
                    infoButton: '<button class="btn btn-link" ng-click="vm.viewRecord(' + id + ')" title="More Info"><i class="fa fa-info-circle"></i></button>'
                },
                onClick: {
                    selectSingle: 'ng-click="vm.selectSingle(' + id + ')"',
                    viewAndSelect: 'ng-click="vm.viewAndSelect(' + id + ')"'
                }
            };
        }

        /**
         * Uses aggregate settings to create footer for bin
         * @param aggregateArray
         * @param data - bin items
         * @returns {string}
         */
        function binAggregate(aggregateArray, data){
            var aggregateItems = [],
                aggregateFormat = '<span class="padding-sm-r">{0}: {1}</span>';
            _.forEach(aggregateArray, function(aggregate){
                var value = '';
                switch(aggregate.aggregate){
                    case 'sum':
                        value = aggregateSum(data, aggregate.field);
                        break;

                    case 'count':
                        value = aggregateCount(data);
                        break;

                    case 'avg':
                    case 'average':
                        value = aggregateAverage(data, aggregate.field);
                        break;

                    default:
                        break;
                }

                if(aggregate.format){
                    value = kendo.format(aggregate.format, value);
                }

                aggregateItems.push(
                    kendo.format(aggregateFormat, aggregate.nimLabel, value)
                );
            });

            return aggregateItems.join('');
        }

        /**
         * Calculates sum aggregate
         * @param data
         * @param field - Value used for sum
         * @returns {number}
         */
        function aggregateSum(data, field){
            var sum = 0;

            _.forEach(data, function(dataItem){
                sum += _.get(dataItem, field, 0);
            });

            return sum;
        }

        /**
         * Calculates count aggregate
         * @param data
         * @returns {number}
         */
        function aggregateCount(data){
            var count = 0;

            _.forEach(data, function(dataItem){
                if (_.get(dataItem, '_type') !== 'empty') {
                    count++;
                }
            });

            return count;
        }

        /**
         * Calculates average aggregate
         * @param data
         * @param field - Value used for sum
         * @returns {number}
         */
        function aggregateAverage(data, field){
            var count = aggregateCount(data);

            if (count === 0) {
                return 0;
            }

            var sum = aggregateSum(data, field);

            return sum / count;
        }

        /**
         * If someone puts one of the onClick attributes outside of an html tag, this will remove it
         * @param listItem - compiled text for list item
         * @param onClickMap - contains the ng-click definitions in string form
         * @returns {string}
         */
        function stripMisplacedAttributes(listItem, onClickMap){
            var jqHTML = angular.element(listItem);

            // Text without the html tags
            var text = jqHTML.text();

            _.forEach(onClickMap, function(attr){
                // If one of the attributes was added outside of a tag, remove it
                if(text.indexOf(attr) >= 0){
                    // Couldn't get the stupid regex to work
                    listItem = listItem.split(attr).join('');
                }
            });

            return listItem;
        }

        /**
         * Opens record in instance window
         * @param instanceId
         * @param pageObject
         * @param navFunctions
         */
        function viewRecord(instanceId, pageObject, navFunctions) {
            InstancesWindowService.openWindow({
                action: 'read',
                widgetId: pageObject.viz.widgetId,
                instanceId: instanceId,
                title: pageObject.name,
                navFunctions: navFunctions
            });
        }

        function addItem(bin, field, pageObject){
            var defaultData = [{
                data: {
                    value: bin.id,
                    lookupDisplay: bin.category
                },
                field: field
            }];

            pageObjectsService.addCustomValuesToDefaultData(defaultData, pageObject.params.defaultValues);

            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: pageObject.viz.widgetId,
                title: pageObject.name,
                defaultData: defaultData
            });
        }
    })
;