angular.module('cerberus.les')
    .config(function($routeProvider){
        $routeProvider.when('/quick-application', {
            templateUrl: 'les/quick-applications/templates/new-quick-application-page.tpl.html',
            controller: 'LESNewQuickApplicationPageCtrl',
            controllerAs: 'vm',
            title: 'Quick Application',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });

        $routeProvider.when('/quick-application/:id', {
            templateUrl: 'les/quick-applications/templates/edit-quick-application-page.tpl.html',
            controller: 'LESEditQuickApplicationPageCtrl',
            controllerAs: 'vm',
            title: 'Quick Application',
            resolve: {
                _wait: 'WaitForUserData',
                quickApplicationId: function($route){
                    return $route.current.params.id;
                }
            }
        });
      
        $routeProvider.when('/quick-application/:id/eligibility', {
            templateUrl: 'les/quick-applications/templates/quick-application-eligibility.tpl.html',
            controller: 'LESQuickApplicationEligibilityCtrl',
            controllerAs: 'vm',
            title: 'Eligibility',
            resolve: {
                _wait: 'WaitForUserData',
                quickApplicationId: function($route){
                    return $route.current.params.id;
                }
            }
        });
    })
;