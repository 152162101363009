angular.module('cerberus.admin')
    .controller('PagesCreateCtrl', function PagesCreateCtrl(_, $scope, $location, workspace, PageDesignerService, WorkspacesGroupsService){
        var vm = this;

        vm.workspace = workspace;
        vm.page = {
            name: '',
            icon: 'file-o',
            description: ''
        };

        vm.permissions = {};
        vm.groups = [];

        vm.save = save;
        vm.cancel = cancel;

        init();

        function init(){
            WorkspacesGroupsService.getGroups(workspace.id).then(function(groups){
                vm.groups = _.filter(groups, groupFilter);
            });
        }

        function groupFilter(g){
            return g.type === 'Custom' || g.type === 'User';
        }

        function save(){
            var newPage = {
                name: vm.page.name,
                icon: vm.page.icon,
                description: vm.page.description,
                permissions: vm.permissions
            };

            PageDesignerService.create(newPage).then(function(pageId){
                $location.url('/settings/pages/' + pageId);
            });
        }

        function cancel(){
            $location.url('/settings/pages');
        }
    })
;