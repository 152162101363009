angular.module('cerberus.admin')
    .controller('MenusDesignerModalCtrl', function MenusDesignerModalCtrl($scope, $rootScope, $uibModalInstance, menuData, editFlag) {
        var vm = this;

        vm.workspace = $rootScope.userData.currentWorkspace;
        vm.menuData = angular.copy(menuData);
        vm.isCollapsed = true;
        vm.groups = [];
        vm.editFlag = editFlag;

        vm.isUnchanged = isUnchanged;
        vm.cancel = cancel;
        vm.finish = finish;

        init();

        function init(){
            if (!editFlag){
                vm.menuData = {
                    icon: 'file-o',
                    items: [],
                    title: '',
                    permissions:{}
                };
            }

            $scope.$on('$locationChangeStart', function(event){
                event.preventDefault();
            });
        }
        function isUnchanged(){
            return angular.equals(vm.menuData, menuData);
        }
        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
        function finish(){
            $uibModalInstance.close(vm.menuData);
        }
    })
    .controller('DeleteMenuModalCtrl', function DeleteMenuModalCtrl($scope, $uibModalInstance, menu) {
        // Modified to delete Menu instead of page
        var vm = this;

        vm.menu = angular.copy(menu);

        vm.isNotEqual = function(data){
            return !angular.equals('DELETE ' + vm.menu.name, data);
        };
        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.finish = function(){
            $uibModalInstance.close(vm.menu);
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;