angular.module('cerberus.util')
    .directive('nimStopEnter', function(){
        return {
            restrict: 'AE',
            link: function(scope, element){
                // Prevents enter key from triggering form submission on certain fields
                element
                    .keydown(preventEnter)
                    .keypress(preventEnter)
                ;
            }
        };

        function preventEnter(e){
            if(e.key === 'Enter'){
                e.preventDefault();
            }
        }
    })
;