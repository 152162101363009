angular.module('cerberus.les')
    .controller('LESNewQuickApplicationModalCtrl', function (_, $scope, $uibModalInstance, $window, lesQuickApplicationService) {
        var vm = this;
      
        vm.filters = {};
        vm.dataSet = lesQuickApplicationService.defaultQuickAppData();
        vm.importingQuickApp = false;
        vm.showImportError = false;
        vm.importError = [];

        vm.loanPurposeOptions = lesQuickApplicationService.loanPurposeOptions($scope);
        vm.occupancyOptions = lesQuickApplicationService.occupancyOptions($scope);
        vm.propertyTypeOptions = lesQuickApplicationService.propertyTypeOptions($scope);
        vm.citizenshipOptions = lesQuickApplicationService.citizenshipOptions($scope);
        vm.bankruptcyTypeOptions = lesQuickApplicationService.bankruptcyTypeOptions($scope);
        vm.dispositionTypeOptions = lesQuickApplicationService.dispositionTypeOptions($scope);
        vm.dispositionDateOptions = lesQuickApplicationService.dispositionDateOptions();
        vm.currencyOptions = lesQuickApplicationService.currencyOptions();
        vm.unitNumberOptions = lesQuickApplicationService.unitNumberOptions();
        vm.ficoScoreOptions = lesQuickApplicationService.ficoScoreOptions();
        vm.ltvOptions = lesQuickApplicationService.ltvOptions();
        vm.employmentDurationOptions = lesQuickApplicationService.employmentDurationOptions();
        vm.delinquencyNumberOptions = lesQuickApplicationService.delinquencyNumberOptions();
        vm.delinquencyNumberLookupOptions = lesQuickApplicationService.delinquencyNumberLookupOptions();
        vm.showFormFieldValidation = lesQuickApplicationService.showFormFieldValidation;
        vm.popoverHTML = lesQuickApplicationService.popoverHTML;
        vm.toggleMortgageLates12Months = lesQuickApplicationService.toggleMortgageLates12Months;
        vm.toggleMortgageLates24Months = lesQuickApplicationService.toggleMortgageLates24Months;
        vm.toggleMortgageLates36Months = lesQuickApplicationService.toggleMortgageLates36Months;
        vm.displayPercentage = lesQuickApplicationService.displayPercentage;
        vm.isCondominium = lesQuickApplicationService.isCondominium;

        vm.submit = _submit;
        vm.cancel = _cancel;

        init();
        
        function init() {
            lesQuickApplicationService.setNgModels($scope, vm.dataSet);
            lesQuickApplicationService.runCalculations($scope, vm.dataSet);
        }

        function _submit(quickAppForm) {
            if (vm.importingQuickApp || quickAppForm.$invalid || !vm.dataSet.loanPurpose.type) {
                return;
            }

            vm.importingQuickApp = true;
            vm.showImportError = false;

            lesQuickApplicationService.submitQuickApplication(vm.dataSet).then(function (result) {
                vm.importingQuickApp = false;
                if (result.errMsg) {
                    vm.showImportError = true;
                    vm.importError = result.errMsg;
                }
                else if (result.instanceId) {
                    $uibModalInstance.close(result.instanceId);
                }
            }, function () {
                vm.importingQuickApp = false;
            });
        }

        function _cancel() {
            // $window.history.back();
            $uibModalInstance.dismiss();
        }

        // Prevents navigation while modal is open
        var allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            allowNavigation();
        });
    })
;