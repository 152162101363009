angular.module('cerberus.admin')
    .controller('FormsDesigner_ruleCtrl',
    function FormsDesigner_ruleCtrl(_, $scope, $rootScope, $uibModalInstance, $window, $sanitize, $timeout,
                                      autoTabIndex, currentFormObjects, editFlag, formObj, formOriginId, idArray,
                                      sections, widgetId, DesignerUtilityService, FormsDesignerListService,
                                      RulesService, WidgetsSettingsService) {
        // Populate Scope
        $scope.formOriginId = formOriginId;
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.formFields = [];
        $scope.formFieldObjects = [];
        $scope.widgetFieldIds = idArray;
        $scope.curModelIds = idArray;
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.sections = sections;
        $scope.availableRules = [];
        $scope.rule = null;
        $scope.state = null;
        $scope.needsUpdate = {};
        $scope.needsRemoval = {};

        $scope.deleteProperty = DesignerUtilityService.deleteProperty;
        $scope.getRule = getRule;
        $scope.setParamData = setParamData;
        $scope.sameState = sameState;
        $scope.removeParameter = removeParameter;

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                //$scope.formFields = formFieldArray;
                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);
                //$scope.widgetFieldIds = _.union(idArray, _.pluck($scope.formFieldObjects, 'modelId'));

                // Codemirror
                $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded(formFieldArray.operands);
            });

            RulesService.getAvailableRules().then(function(ruleData){
                $scope.availableRules = ruleData.rules;
            });

            getRule($scope.temp.model.param.ruleId);

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                if(form.originId + '' === formOriginId){
                    $scope.state = form.stateId;
                }
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    object.stateId = form.stateId;
                    objects.push(object);
                }
            }
            return objects;
        }

        function getRule(id){
            if(id){
                RulesService.getRule(id).then(function(rule){
                    $scope.rule = rule;
                    $scope.temp.model.param.ruleName = rule.name;

                    var params = $scope.temp.model.param.parameterMap;

                    _.forEach(rule.params, function(p){
                        params[p.name] = params[p.name] || angular.copy(p);
                    });

                    var needsUpdateParams = {},
                        needsRemovalParams = {};

                    // Check for params that need to be removed/updated
                    _.forEach(params, function(p){
                        var origParam = _.find(rule.params, 'name', p.name);

                        if(!origParam){
                            needsRemovalParams[p.name] = true;
                        }
                        else {
                            var props = _.keys(origParam),
                                newParam = _.pick(p, props);

                            if(!angular.equals(origParam, newParam)){
                                needsUpdateParams[p.name] = true;
                            }
                        }
                    });

                    $scope.needsUpdate = needsUpdateParams;
                    $scope.needsRemoval = needsRemovalParams;
                });
            }
            else {
                $scope.temp.model.param.ruleName = '';
            }
        }

        function setParamData(paramField){
            if(paramField.modelId) {
                var field = _.find($scope.formFieldObjects, 'modelId', paramField.modelId);

                if(field){
                    paramField.formId = field.formId;
                    paramField.stateId = field.stateId;
                }
            }
        }

        function sameState(formField){
            return formField.stateId === $scope.state;
        }

        function removeParameter(paramArray, paramMap, paramName){
            var origParam = _.find(paramArray, 'name', paramName);
            if($scope.needsRemoval[paramName]){
                delete paramMap[paramName];
                $scope.needsRemoval[paramName] = false;
            }
            else if($scope.needsUpdate[paramName]){
                _.assign(paramMap[paramName], origParam);
                $scope.needsUpdate[paramName] = false;
            }
            else {
                paramMap[paramName] = angular.copy(origParam);
            }
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;