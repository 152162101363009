angular.module('cerberus.les')
    .controller('LESLoanApplicationWithdrawalModalCtrl', function($scope, $uibModalInstance, loanApplicationId, lesLoanApplicationsService){
        var vm = this;

        vm.loanApplicationId = loanApplicationId;
        vm.withdrawing = false;
        vm.withdrawalData = {
            withdrawnBy: '',
            reasons: {},
            notes: ''
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            if (vm.withdrawalData.withdrawnBy) {
                vm.withdrawing = true;
                lesLoanApplicationsService.updateLoan(vm.loanApplicationId, { action: 'withdrawn', data: vm.withdrawalData }).then(function () {
                    vm.withdrawing = false;
                    $uibModalInstance.close();
                }, function () {
                    vm.withdrawing = false;
                });
            }
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;