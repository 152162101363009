angular.module('cerberus.les')
    .controller('LESLoanApplicationManualEntryModalCtrl', function (_, $scope, $uibModalInstance, $q, loanApplicationId, InstancesService, lesLoanApplicationsService) {
        var vm = this;

        vm.borrowers = [];
        vm.loadingScores = true;
        vm.savingCredit = false;
        vm.temp = {};
        vm.orig = {};

        vm.ficoScoreOptions = {
            decimals: 0,
            max: 850,
            min: 0,
            format: '###'
        };

        vm.ok = ok;
        vm.cancel = cancel;
        vm.dataIsValid = dataIsValid;
        vm.isUnchanged = isUnchanged;

        init();

        function init() {
            var loanRecordId = _.last(loanApplicationId.split('-'));
            var loadPromises = [
                lesLoanApplicationsService.getLoanApplicants(loanRecordId),
                lesLoanApplicationsService.getCreditData(loanRecordId)
            ];
            
            $q.all(loadPromises).then(function (results) {
                vm.borrowers = results[0];
                var borrowerScores = _.groupBy(results[1].scores, 'borrowerId');
                
                _.forEach(vm.borrowers, function (b) {
                    var id = b.Borrowers_instance_id;
                    if (!borrowerScores[id]) {
                        borrowerScores[id] = [{
                            borrowerFullName: b.Borrowers_BorrowerFullName,
                            borrowerId: id,
                            value: ''
                        }];
                    }
                });

                vm.orig = borrowerScores;
                vm.temp = angular.copy(borrowerScores);

                vm.loadingScores = false;
            });
        }
        
        function ok() {
            if (dataIsValid(vm.temp)) {
                vm.savingCredit = true;
                var savePromises = [];

                _.forEach(vm.temp, function (scores, id) {
                    var newValue = scores[0],
                        oldValue = vm.orig[id][0];
                    
                    if (!angular.equals(newValue, oldValue)) {
                        if (newValue.id) {
                            savePromises.push(
                                lesLoanApplicationsService.updateCreditScore(newValue)
                            );
                        }
                        else {
                            savePromises.push(
                                lesLoanApplicationsService.addCreditScore(newValue)
                            );
                        }
                    }
                });

                $q.all(savePromises).then(function () {
                    vm.savingCredit = false;
                    $uibModalInstance.close();
                }, function () {
                    vm.savingCredit = false;
                });
            }    
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        function dataIsValid(data) {
            return _.every(data, function (scores) {
                return !!(scores[0].value || scores[0].value === 0);
            });
        }

        function isUnchanged() {
            return angular.equals(vm.temp, vm.orig);
        }
    })
;