/*jshint bitwise: false*/
angular.module('cerberus.util')
    /**
     * @ngdoc service
     * @name DesignerUtilityService
     * @alias cerberus/util:DesignerUtilityService
     * @description Provides UI functions for the designer modules
     */
    .factory('DesignerUtilityService', function DesignerUtilityService(_, kendo, CodeMirror, moment, $http, $rootScope, $location, $timeout, $compile, $sanitize, ConfirmModalService) {

        return {
            generateGuid: generateGuid,
            focusFirstElement: focusFirstElement,
            preventSpace: preventSpace,
            labelToUniqueId: labelToUniqueId,
            cssToArray: cssToArray,
            arrayToCss: arrayToCss,
            decodeHtml: decodeHtml,
            textEditorOptions: textEditorOptions,
            getIcons: getIcons,
            formGrid: formGrid,
            formDetailGrid: formDetailGrid,
            codemirrorLoaded: codemirrorLoaded,
            cancelRouteNav: cancelRouteNav,
            filterOperators: filterOperators,
            conditionFilter: conditionFilter,
            conditionOperators: conditionOperators,
            conditionBooleanValues: conditionBooleanValues,
            conditionActionValues: conditionActionValues,
            getConditionType: getConditionType,
            addCondition: addCondition,
            lookUpDefaultValueOptions: lookUpDefaultValueOptions,
            deleteProperty: deleteProperty,
            getFilters: getFilters,
            parseDateField: parseDateField,
            mergeFieldsWithCurrentForm: mergeFieldsWithCurrentForm,
            firstBy: (function firstBy() {
                /* mixin for the `thenBy` property */
                function extend(f) {
                    f.thenBy = tb;
                    return f;
                }
                /* adds a secondary compare function to the target function (`this` context)
                 which is applied in case the first one returns 0 (equal)
                 returns a new compare function, which has a `thenBy` method as well */
                function tb(y) {
                    var x = this;
                    return extend(function (a, b) {
                        return x(a, b) || y(a, b);
                    });
                }
                return extend;
            })()
        };
        ////////////////////
        /**
         * generateGuid
         * @returns {string}
         */
        function generateGuid() {
            return 'i_xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
        }
        /**
         * @function focusFirstElement
         * @param {string} formName - this is an ugly hack for angular-ui modals
         */
        function focusFirstElement(formName) {
            setTimeout(function () {
                document[formName][0].focus();
            }, 1);
        }
        /**
         * @function preventSpace
         * @param {object} $event - an angular ui event
         */
        function preventSpace($event) {
            // spacebar = 32
            if ($event.keyCode === 32 || $event.which === 32) {
                $event.preventDefault();
            }
        }

        /**
         * @function labelToUniqueId
         * @param {string} label - user input
         * @param {Array} existingIds - an array of Ids not to be used
         * @return {string} unique id to be used in a user defined schema/form
         */
        function labelToUniqueId(label, existingIds) {
            var lbl = label || '';
            var id = lbl.replace(/^([^A-Za-z])+/g, ''); //Remove preceding characters that are not letters
            id = id.replace(/[^A-Z0-9a-z]/g, '').substring(0, 30);  //Remove anything that is not a letter or number
            var copyNumber, newNumber;
            if (existingIds.indexOf(id) > -1) {
                copyNumber = existingIds[existingIds.indexOf(id)].substr(existingIds[existingIds.indexOf(id)].length - 1);
                if (isNaN(copyNumber)) {
                    id = id + '1';
                }
                else {
                    newNumber = parseInt(copyNumber) + 1;
                    id = id + newNumber;
                }
            }
            return id;
        }

        /**
         * @function cssToArray
         * @param {string} css - css string "top right bottom left"
         * @return {Array} array of integers
         */
        function cssToArray(css) {
            if (css) {
                var arr = _.map(css.split('px'), function (val) {
                    return parseInt(val);
                });
                return arr.slice(0, 4);
            }
        }

        /**
         * @function cssToArray
         * @param {Array} arr - array of integers
         * @return {string} css string "top right bottom left"
         */
        function arrayToCss(arr) {
            if (arr) {
                var string = '';
                for (var i = 0; i < 4; i++) {
                    string += arr[i] + 'px ';
                }
                return string.trim();
            }
        }

        /**
         * Lame but necessary Hack
         * @function decodeHtml
         * @param {string} html - html from rich text editor
         * @return {string} clean html
         */
        function decodeHtml(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }

        function textEditorOptions(scope) {
            return {
                encoded: false,
                tools: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "justifyLeft",
                    "justifyCenter",
                    "justifyRight",
                    "justifyFull",
                    "insertUnorderedList",
                    "insertOrderedList",
                    "indent",
                    "outdent",
                    "createLink",
                    "unlink",
                    "insertImage",
                    "subscript",
                    "superscript",
                    "createTable",
                    "addRowAbove",
                    "addRowBelow",
                    "addColumnLeft",
                    "addColumnRight",
                    "deleteRow",
                    "deleteColumn",
                    //"viewHtml",
                    {
                        name: 'nimViewHtml',
                        template: '<a href="" role="button" class="k-tool k-group-start k-group-end" unselectable="on" title="View HTML"><i class="fa fa-code"></i></a>',
                        exec: function (e) {
                            e.preventDefault();
                            var editor = $(e.currentTarget).closest('.k-editor');
                            var textarea = editor.find('textarea');
                            var id = textarea.attr('id');
                            var model = textarea.attr('k-ng-model') || textarea.attr('ng-model');
                            var newId = 'nim_' + id + '_html';
                            var htmlInput = $('#' + newId);
                            if (id && scope) {
                                if (htmlInput.length === 0) {
                                    var html = '<textarea id="' + newId + '" class="form-control" rows="10" ng-model="' + model + '" ng-model-options="{debounce: {default: 500}}"></textarea>';
                                    editor.after($compile(html)(scope));
                                }
                                else {
                                    htmlInput.remove();
                                }
                            }
                        }
                    },
                    "formatting",
                    "cleanFormatting",
                    "fontName",
                    "fontSize",
                    "foreColor",
                    "backColor"
                ],
                paste: function (e) {
                    e.html = $sanitize(e.html);
                }
            };
        }

        /**
         * @function getIcons
         * @return {Array} fontawesome icons
         */
        function getIcons() {
            return $http.get('/assets/fontawesomeicons.json', { cache: true }).then(function (value) {
                return value.data.icons;
            });
        }

        /**
         * @function formGrid
         */
        function formGrid(data) {
            return {
                dataSource: {
                    data: data,
                    schema: {
                        model: {
                            fields: {
                                formName: { type: "string" }
                            }
                        }
                    },
                    pageSize: 15
                },
                detailTemplate: kendo.template('<div kendo-grid k-options="detailGridOptions(dataItem)"></div>'),
                dataBound: function () {
                    this.expandRow(this.tbody.find("tr.k-master-row").first());
                },
                columns: [{
                    field: "formName",
                    title: "Form Name"
                }]
            };
        }
        /**
         * @function formDetailGrid
         */
        function formDetailGrid(scope) {
            return function (dataItem) {
                var data = angular.copy(dataItem.objects);
                _.remove(data, function (d) {
                    return !d.modelId;
                });

                return {
                    dataSource: {
                        data: dataItem.objects,
                        schema: {
                            model: {
                                fields: {
                                    label: { type: "string" },
                                    modelId: { type: "string" },
                                    usage: { type: "string" }
                                }
                            }
                        },
                        pageSize: 20
                    },
                    selectable: "row",
                    change: function () {
                        var selectedRows = this.select();
                        var dataItemUsage = this.dataItem(selectedRows).usage;
                        $timeout(function () {
                            if (scope.temp.model.calculation.formula) {
                                scope.temp.model.calculation.formula = scope.temp.model.calculation.formula + ' ' + dataItemUsage;
                            }
                            else {
                                scope.temp.model.calculation.formula = dataItemUsage;
                            }
                        }, 0);
                    },
                    scrollable: false,
                    sortable: true,
                    pageable: true,
                    columns: [
                        { field: "label", title: "Field Name", width: "110px" },
                        { field: "modelId", title: "Unique Field Name", width: "110px" },
                        { field: "usage", title: "Usage", width: "110px" }
                    ]
                };
            };
        }

        /**
         * @function codemirrorLoaded
         */
        function codemirrorLoaded(operands) {
            return function (_editor) {
                var timeout;
                // Editor part
                var _doc = _editor.getDoc();
                //_editor.focus();

                // Options
                _editor.setOption('theme', 'nimcalc');
                _editor.setOption('lineWrapping', true);
                _editor.setOption('matchBrackets', true);
                _editor.setOption('autoCloseBrackets', '()[]""');
                _editor.setOption('mode', 'nimcalc');
                _doc.markClean();

                // Events
                _editor.on("inputRead", function (cm) {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(function () {
                        CodeMirror.showHint(cm, CodeMirror.hint.nimcalc, {
                            completeSingle: false,
                            namedVars: operands
                        });
                    }, 150);
                });
            };
        }

        /**
         * cancelRouteNav
         * @param scope
         * @param hasChanges
         */
        function cancelRouteNav(scope, hasChanges) {
            var onRouteChangeOff = scope.$on('$locationChangeStart', routeChange);
            function routeChange(event, newUrl) {
                //Navigate to newUrl if the form isn't dirty
                if (!hasChanges()) {
                    return;
                }

                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Ignore Changes',
                    headerText: 'Unsaved Changes',
                    bodyText: 'You have unsaved changes. Leave the page?',
                    check: function () {
                        return true;
                    },
                    confirm: function () {
                        onRouteChangeOff(); //Stop listening for location changes
                        var newPath = newUrl.split('/#/');
                        $location.url(newPath[1]); //Go to page they're interested in
                    }
                };

                ConfirmModalService.showModal({}, modalOptions);

                //prevent navigation by default since we'll handle it
                //once the user selects a dialog option
                event.preventDefault();
            }
        }

        function filterOperators() {
            return {
                string: [
                    { value: 'contains', display: 'Contains' },
                    { value: 'doesnotcontain', display: 'Does not contain' },
                    { value: 'eq', display: 'Equal to' },
                    { value: 'neq', display: 'Not equal to' },
                    { value: 'startswith', display: 'Starts with' },
                    { value: 'endswith', display: 'Ends with' }
                ],
                number: [
                    { value: 'eq', display: 'Equal to' },
                    { value: 'gt', display: 'Greater' },
                    { value: 'gte', display: 'Greater or equal to' },
                    { value: 'lt', display: 'Less' },
                    { value: 'lte', display: 'Less or equal to' },
                    { value: 'neq', display: 'Not equal to' }
                ],
                date: [
                    { value: 'eq', display: 'Equal to' },
                    { value: 'gt', display: 'Greater' },
                    { value: 'gte', display: 'Greater or equal to' },
                    { value: 'lt', display: 'Less' },
                    { value: 'lte', display: 'Less or equal to' },
                    { value: 'neq', display: 'Not equal to' }
                ],
                bigint: [
                    { value: 'eq', display: 'Equal to' },
                    { value: 'gt', display: 'Greater' },
                    { value: 'gte', display: 'Greater or equal to' },
                    { value: 'lt', display: 'Less' },
                    { value: 'lte', display: 'Less or equal to' },
                    { value: 'neq', display: 'Not equal to' }
                ]
            };
        }

        function conditionFilter(formOriginId, modelId) {
            return function (formObj) {
                return (formObj.modelId == '__nimRecordAction' ||
                    formObj.isCustomLookup ||
                    formObj.isLookUp ||
                    formObj.type === 'checkbox' ||
                    formObj.dataType === 'string' ||
                    formObj.dataType === 'number') &&
                    formObj.modelId !== modelId;
            };
        }

        function conditionOperators() {
            return {
                string: [
                    { value: 'str_contains', display: 'Contains' },
                    { value: 'str_doesnotcontain', display: 'Does not contain' },
                    { value: 'str_eq', display: 'Equal to' },
                    { value: 'str_neq', display: 'Not equal to' },
                    { value: 'str_startswith', display: 'Starts with' },
                    { value: 'str_endswith', display: 'Ends with' }
                ],
                number: [
                    { value: 'num_eq', display: 'Equal to' },
                    { value: 'num_gt', display: 'Greater than' },
                    { value: 'num_gte', display: 'Greater than or equal to' },
                    { value: 'num_lt', display: 'Less than' },
                    { value: 'num_lte', display: 'Less than or equal to' },
                    { value: 'num_neq', display: 'Not equal to' }
                ],
                lookup: [
                    { value: 'eq', display: 'Equal to' },
                    { value: 'neq', display: 'Not equal to' }
                ],
                action: [
                    { value: 'str_eq', display: 'Equal to' },
                    { value: 'str_neq', display: 'Not equal to' }
                ],
                boolean: [
                    { value: 'eq', display: 'Equal to' }
                ],
                '': []
            };
        }

        function conditionBooleanValues() {
            return [
                { value: true, display: 'True' },
                { value: false, display: 'False' }
            ];
        }

        function conditionActionValues() {
            return [
                { value: 'create', display: 'Create' },
                { value: 'read', display: 'Read' }
            ];
        }

        function getConditionType(formObjArray, condition, oldType) {
            var formObj = _.find(formObjArray, 'modelId', condition.modelId),
                type = '';

            if (formObj) {
                if (formObj.isLookUp || formObj.isCustomLookup) {
                    type = 'lookup';
                }
                else if (formObj.dataType === 'string') {
                    type = 'string';
                }
                else if (formObj.dataType === 'number') {
                    type = 'number';
                }
                else if (formObj.type === 'checkbox') {
                    type = 'boolean';
                }
                else if (formObj.modelId === '__nimRecordAction') {
                    type = 'action';
                }
            }

            if (type !== oldType) {
                condition.op = '';

                if (type === 'boolean') {
                    condition.op = 'eq';
                    condition.val = true;
                }

                if (oldType === 'boolean') {
                    condition.val = null;
                }
            }

            return type;
        }

        function addCondition(formObj, formOriginId) {
            _.defaults(formObj.model.param, { condition: [] });
            return function (condition) {
                var newCondition = angular.copy(condition);
                newCondition.formOriginId = formOriginId;
                formObj.model.param.condition.push(newCondition);
                _.assign(condition, { modelId: null, op: null, val: null, isFormObj: false });
            };
        }

        function lookUpDefaultValueOptions(option, sort) {
            return {
                autoBind: true,
                filter: 'contains',
                optionLabel: {
                    id: -1,
                    display: 'Choose...'
                },
                dataTextField: 'display',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                    type: 'odata',
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    pageSize: 20,
                    sort: sort || {
                        field: option.orderCol || 'display',
                        dir: option.orderBy || 'asc'
                    },
                    transport: {
                        read: function (readOptions) {
                            if (option.column && option.id) {
                                var filterValue = _.get(readOptions, 'data.filter.filters[0].value');

                                if (_.isNumber(filterValue)) {
                                    filterValue += 'f';
                                }

                                var lookupDisplay = option.column;

                                if (option.columnType === 'location') {
                                    lookupDisplay += '_formatted_address';
                                }

                                var params = {
                                    $lookupDisplay: lookupDisplay,
                                    $top: option.returnNum
                                };

                                if (readOptions.data.sort) {
                                    params.$orderby = _.map(readOptions.data.sort, function (s) {
                                        return s.field + ' ' + (s.dir || 'asc');
                                    }).join(',');
                                }

                                if (filterValue) {
                                    params.$filter = 'substringof(\'' + filterValue + '\',' + option.column + ')';
                                }

                                $http.get('/server/rest/v1/views/' + option.id + '/odata.svc', {
                                    params: params
                                }).then(function (result) {
                                    readOptions.success(result.data);
                                });
                            }
                            else {
                                readOptions.success({ d: { __count: 0, results: [] } });
                            }

                        }
                    }
                })
            };
        }

        /**
         * Deletes a property from an object; useful when removing a configuration
         * @param {object} obj
         * @param {string} prop
         */
        function deleteProperty(obj, prop) {
            if (_.has(obj, prop)) {
                delete obj[prop];
            }
        }

        /**
         * Finds all available filters in page object array
         * @param pageObjects - Array of page objects
         * @param ignoreObj - Object to exclude (if looking for filters for that object)
         * @returns {Array}
         */
        function getFilters(pageObjects, ignoreObj) {
            var filters = [];

            _.forEach(pageObjects, function (pageObj) {
                if (!angular.equals(ignoreObj, pageObj)) {
                    if (pageObj.type === 'button') {
                        _.forEach(pageObj.params.buttons, function (button) {
                            if (button.type === 'filter') {
                                filters.push(button);
                            }
                        });
                    }
                    else if (pageObj.type === 'viz') {
                        var vizType = pageObj.viz.type;
                        if (vizType === 'table' || vizType === 'kanban') {
                            var params = pageObj.params;
                            if (params.asFilter && params.filterId) {
                                filters.push({
                                    id: params.filterId,
                                    label: params.filterName
                                });
                            }
                        }
                        else if (vizType === 'map') {
                            var mapFilters = pageObj.params.filters;
                            if (mapFilters) {
                                _.forEach(mapFilters, function (filter) {
                                    if (filter.asFilter) {
                                        filters.push(filter);
                                    }
                                });
                            }
                        }
                    }
                }
            });
            
            return filters;
        }

        /**
         * Parses date string back to a Date object
         * @param value - date string serialized by Coldfusion
         * @returns {Date, string} - corrected Date or empty string if no value
         */
        function parseDateField(value) {
            if (value && !_.isDate(value)) {
                try {
                    var dateValue = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss');
                    dateValue.local();

                    return dateValue.toDate();
                }
                catch (e) { }
            }
            else if (_.isDate(value)) {
                return value;
            }

            return '';
        }

        /**
         * Merges form objects from current form with loaded widget fields object.
         * @param widgetFormFields
         * @param currentFormObjects
         */
        function mergeFieldsWithCurrentForm(widgetFormFields, currentFormObjects) {
            var form = _.find(widgetFormFields.display, 'originId', currentFormObjects.display.originId);

            if (form) {
                var length = widgetFormFields.operands.length,
                    startIndex = 0;

                if (length > 0 && form.objects.length > 0) {
                    startIndex = widgetFormFields.operands.indexOf(form.objects[0].usage);
                }

                _.forEach(currentFormObjects.operands, function (op, index) {
                    var trueIndex = startIndex + index;
                    if (index < length) {
                        widgetFormFields.operands[trueIndex] = op;
                    }
                    else {
                        widgetFormFields.operands.splice(trueIndex, 0, op);
                    }
                });

                form.objects = currentFormObjects.display.objects;
            }
            else {
                widgetFormFields.operands = widgetFormFields.operands.concat(currentFormObjects.operands);
                widgetFormFields.display.push(currentFormObjects.display);
            }
        }
    })
;