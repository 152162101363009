angular.module('cerberus.util')
    .factory('labelPrintingService', function(_, kendo, $compile, $http, $q, $rootScope, $location, $timeout, $window, DeviceSniffService){
        var service = {
            printLabels: printLabels
        };

        return service;

        /**
         * Prints labels from the specified instance
         * @param instanceIds
         * @param labelOptions - options set by user in the print configuration modal
         * @param labelOptions.targetForm - originator ID of the form of type "label" that will be printed
         * @param labelOptions.pageTotal - total number of pages that will be printed
         * @param [formData] - Loaded form data passed in from an open instance
         * @param [autoFillData] - Loaded autofill data passed in from an open instance
         */
        function printLabels(instanceIds, filename, labelOptions, formData, autoFillData) {
            var element,
                deferred = $q.defer(),    
                printSection = angular.element('#print-build-section'),
                labelScope = $rootScope.$new(true),
                paperOptions = kendo.pdf.getPaperOptions(angular.copy(labelOptions)),
                template = '<div nim-label-builder></div>';

            // Set scope variables
            labelScope.instanceIds = instanceIds;
            labelScope.paperOptions = paperOptions;
            labelScope.formOriginId = _.last(labelOptions.targetForm.split('-'));
            labelScope.pageTotals = labelOptions.pageTotal;
            labelScope.formData = {};

            if(!_.isEmpty(formData)){
                labelScope.formData = formData;
            }

            if(!_.isEmpty(autoFillData)){
                labelScope.autoFillData = autoFillData;
            }

            // Load CSS
            var cssLink = angular.element($window.document).find('link[rel=stylesheet]');
            var cssPromise = null;
            if (cssLink[0]) {
                cssPromise = $http.get(cssLink[0].href);
            }

            // Called by label-building directive when the labels have been loaded
            labelScope.done = function(){
                labelScope.$destroy();

                var browser = DeviceSniffService.deviceBrowser(),
                    isIE = _.startsWith(browser, 'IE'),
                    printWindow = null;
                
                if (!isIE) {
                    printWindow = $window.open();
                }

                // If Internet Explorer or the window is blocked, download pdf
                if (isIE || !printWindow) {
                    exportPDF(element, paperOptions).done(function (dataURI) {
                        printSection.empty();

                        kendo.saveAs({
                            dataURI: dataURI,
                            fileName: filename + '.pdf'
                        });

                        deferred.resolve();
                    });
                }
                else {
                    deferred.resolve();
                    
                    // Else, open in new window
                    printInWindow(element, printWindow, filename, cssPromise);
                    printSection.empty();
                }
            };

            element = $compile(template)(labelScope);

            printSection.empty()
                .append(element);
            
            return deferred.promise;
        }

        /**
         * Exports the rendered element to a PDF
         * @param element {jQuery}
         * @param paperOptions {Object}
         */
        function exportPDF(element, paperOptions) {
            // Check necessary page size and create printing options
            var labels = element.find('.nim-label-form'),
                pageHeight = pixelsToInteger(paperOptions.paperSize[1]),
                pageWidth = pixelsToInteger(paperOptions.paperSize[0]),
                options = {
                    forcePageBreak: '.nim-page-break',
                    paperSize: [pageWidth, pageHeight],
                    margin: {
                        top: paperOptions.margin.top,
                        bottom: paperOptions.margin.bottom
                    }
                };

            // Make sure each label stays this height
            labels.css('height', labels.height());

            return kendo.drawing.drawDOM(element, options).then(function (group) {
                return kendo.drawing.exportPDF(group, options);
            });
        }

        /**
         * Opens new window to print rendered element
         * @param element {jQuery}
         * @param printWindow {Window}
         */
        function printInWindow(element, printWindow, filename, cssPromise) {
            var printContent = element.clone(),
                base = $location.protocol() + '://' + $location.host(),
                // cssLinks = angular.element($window.document).find('link[rel=stylesheet]').clone(),
                windowHead = angular.element(printWindow.document.head),
                windowBody = angular.element(printWindow.document.body);

            windowHead
                .append('<title>' + filename + '</title><base href="' + base + '" />');
                // .append('<title>' + filename + '</title><base href="' + base + '" />')
                // .append(cssLinks);
        
            windowBody
                .addClass('nim-print-window-body')    
                .css('padding', '0')
                .css('overflow', 'visible');
        
            windowBody.append(printContent);

            if (cssPromise) {
                cssPromise.then(function (result) {
                    var style = angular.element('<style type="text/css">' + result.data + '</style>');
                    style[0].onload = function () {
                        executePrint();
                    };
                    windowHead.append(style);
                });
            }
            else {
                executePrint();
            }
            
            function executePrint() {
                $timeout(function () {
                    printWindow.document.close();
                    printWindow.focus();
                    printWindow.print();
                }, 1);
            }
        }

        /**
         * Takes string of format "0px", removes "px" and returns the number
         * @param pixelString
         * @returns {*|number}
         */
        function pixelsToInteger(pixelString){
            var valueString = pixelString.replace('px', '');
            return parseInt(valueString);
        }
    })
;