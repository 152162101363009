angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartPieService
     * @alias cerberus/core:VizChartPieService
     * @description Provides functions for configuring pie charts
     */
    .factory('VizChartPieService', function VizChartPieService(_){
        return {
            configPie: configPie
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configPie
         * @param {object} params
         */
        function configPie(params){
            return function(data, options){
                var displayNum = params.displayNum;
                if(displayNum && data.length > displayNum){
                    var valueField = options.series[0].field;

                    var categoryField = options.series[0].categoryField;
                    if(!categoryField){
                        categoryField = 'category';
                    }

                    var otherItems = data.splice(displayNum, data.length - displayNum);
                    var other = {};
                    other[categoryField] = 'Other';
                    other[valueField] = 0;
                    _.forEach(otherItems, function (item) {
                        var val = item[valueField];
                        if(val){
                            other[valueField] += val;
                        }
                    });

                    data.push(other);
                }
            };
        }
    })
;