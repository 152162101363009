angular.module('cerberus.admin')
    .directive('nimWorkspaceAvailable', function($http, apiPath) {
        return {
            require : 'ngModel',
            link : function($scope, element, attrs, ngModel) {
                ngModel.$asyncValidators.nimWorkspaceAvailable = function(name) {
                    valid(false);
                    loading(true);
                    return $http.get(apiPath + 'organization/' + $scope.orgId + '/workspaces/name/available?name='+ name).then(
                        function(value) {
                            valid(value.data.DATA);
                            loading(false);
                        },
                        function() {
                            valid(false);
                            loading(false);
                        }
                    );
                };
                ////////////////////
                function valid(bool) {
                    ngModel.$setValidity('record-unavailable', bool);
                }

                function loading(bool) {
                    ngModel.$setValidity('record-loading', !bool);
                }
            }
        };
    })
;
