angular.module('cerberus.admin')
    .controller('AreaConfigCtrl', function AreaConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters,
                                                          DesignerUtilityService, PagesDesignerListService,
                                                          pageObjectConfigService, ViewsService, vizListService) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.views = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.viewColumns = [];
        vm.editView = _.get(pageObject,'viz.settings.dataSource.nim_viewId');
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.filterType = '';
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);
        vm.labelFormats = pageObjectConfigService.chartLabelFormats();

        vm.vizDict = vizListService.vizDictionary();
        vm.tabTemplates = [
            {name: 'General', url: 'admin/pages/designer/common/general-tab.tpl.html'},
            {name: 'Wrapper', url: 'admin/pages/designer/common/wrapper-tab.tpl.html'},
            {name: 'Data', url: 'admin/pages/designer/viz/area/area-data-tab.tpl.html'},
            {name: 'Filters', url: 'admin/pages/designer/common/filter-tab.tpl.html'},
            {name: 'Tools', url: 'admin/pages/designer/common/tools-tab.tpl.html'}
        ];

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns);

        vm.dataVizThemes = PagesDesignerListService.dataVizThemes();
        vm.colorbrewer = vizListService.colorbrewer();
        vm.seriesDataTypes = vizListService.colorbrewerDataTypes();
        vm.seriesDataTypeSizes = vizListService.colorbrewerDataTypeSizes();

        vm.addColorToSeries = pageObjectConfigService.addColorToSeries;

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.setBaseWidget = _.partial(pageObjectConfigService.setBaseWidget, vm, vm.temp);

        // Column Selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        //Init
        init();

        function init() {
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            _.defaults(vm.temp.params, {
                requiredFilters: {}
            });

            vm.loadColumns(vm.editView);
            pageObjectConfigService.updateSortConfig(vm.temp.viz.settings.dataSource);
            vm.sortBySortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.dataSource.sort);
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var settings = vm.temp.viz.settings;
            var type = settings.dataSource.schema.model.fields[colField].type;

            if(type === 'number' && !settings.series[0].field){ // Value Field
                settings.series[0].field = colField;
            }
            else if(!settings.categoryAxis.field){ // Category Field
                settings.categoryAxis.field = colField;
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var settings = vm.temp.viz.settings;
            var source = settings.dataSource;

            // Category
            if(settings.categoryAxis.field === colField){
                delete settings.categoryAxis.field;
            }

            // Value
            if(settings.series[0].field === colField){
                delete settings.series[0].field;
            }

            // Sort By
            _.remove(source.sort, 'field', colField);

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;