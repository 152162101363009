angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name PagesDesignerConvenienceService
 * @alias cerberus/pages-designer:PagesDesignerConvenienceService
 * @description Constructs PageObject class
 */
    .factory('PagesDesignerConvenienceService', function PagesDesignerConvenienceService(PagesClassService, WrapperClassService, VizClassService) {
        /* Import Classes */
        var Wrapper = WrapperClassService.wrapper;
        var Header = WrapperClassService.header;
        var Body = WrapperClassService.body;
        var Footer = WrapperClassService.footer;
        var Viz = VizClassService.viz;

        var service = {
            portlet: portlet,
            tile: tile,
            circletile: circletile,
            template: template,
            table: table,
            chartArea: chartArea,
            chartBar: chartBar,
            chartBubble: chartBubble,
            chartBullet: chartBullet,
            chartDonut: chartDonut,
            chartFunnel: chartFunnel,
            chartLine: chartLine,
            chartPie: chartPie,
            chartScatter: chartScatter,
            schedule: schedule,
            scheduleGantt: scheduleGantt,
            scheduleCalendar: scheduleCalendar,
            map: map,
            progress: progress,
            listview: listview,
            kanban: kanban
        };
        return service;

        ////////////////////
        /* Define Wrappers */
        /**
         * Builds a default portlet wrapper
         * @function portlet
         * @returns {Wrapper}
         */
        function portlet() {
            return new Wrapper(
                'portlet',
                new Header(null, null, null, {
                    color: '#ffffff',
                    'border-color': '#444444',
                    background: '#444444'
                }),
                new Body(null, {}),
                new Footer(null, {
                    color: '#e6e6e6',
                    background: '#444444'
                }),
                true,
                {
                    margin: '0px 0px 0px 0px'
                }
            );
        }

        /**
         * Builds a default tile wrapper
         * @function tile
         * @returns {Wrapper}
         */
        function tile() {
            return new Wrapper(
                'tile',
                new Header(null, null, null, {}),
                new Body(null, {}),
                new Footer(null, {}),
                true,
                {
                    margin: '0px 0px 0px 0px',
                    padding: '8px 8px 8px 8px',
                    background: '#ffffff'
                }
            );
        }

        /**
         * Builds a default circleTile wrapper
         * @function circletile
         * @returns {Wrapper}
         */
        function circletile() {
            return new Wrapper(
                'circletile',
                new Header(null, null, null, {
                    'border-color':'#808080',
                    background: '#444444'
                }),
                new Body(null, {
                    background: '#444444'
                }),
                new Footer(null, {}),
                true,
                {
                    margin: '0px 0px 0px 0px'
                }
            );
        }

        /* Define Visualizations */

        /**
         * Builds a template Viz
         * @function template
         * @returns {Viz}
         */
        function template() {
            return new Viz('template', null, null,
                {
                    template: null,
                    defaultText: null,
                    schema:{},
                    columns: [],
                    dataSource: {
                        schema: {
                            model: {
                                fields: {}
                            }
                        },
                        pageSize: 1,
                        sort: []
                    },
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                }
            );
        }

        /**
         * Builds a table Viz
         * @function table
         * @returns {Viz}
         */
        function table() {
            return new Viz('table', null, null,
                {
                    columns: [],
                    batchEdit: false,
                    filterable: true,
                    groupable: false,
                    sortable: true,
                    pageable: true,
                    propertyColumns: [],
                    dataSource: {
                        pageSize: 10,
                        schema: {
                            model: {
                                id: 'id',
                                parentId: 'parentId',
                                fields: {
                                    parentId: {
                                        type:'number',
                                        nullable: true
                                    }
                                }
                            }
                        },
                        sort: [],
                        aggregate: [],
                        filter: {
                            filters: [],
                            logic: 'and'
                        }
                    }
                }
            );
        }

        /**
         * Builds a chart/area Viz
         * @function chartArea
         * @returns {Viz}
         */
        function chartArea() {
            return new Viz('chart', 'area', null, {
                theme: 'intellibrief',
                columns: [],
                dataSource: {
                    schema: {
                        model: {
                            fields: {}
                        }
                    },
                    sort: [],
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                chartArea: {
                    margin: {},
                    background: 'transparent',
                    opacity: 1
                },
                seriesDefaults: {
                    type: "area",
                    area: {
                        line: {
                            style: "smooth"
                        }
                    }
                },
                series: [{}],
                valueAxis: {
                    visible: true,
                    type: 'numeric',
                    line: {
                        visible: false
                    },
                    minorGridLines: {
                        visible: true
                    },
                    crosshair: {
                        visible: false
                    }
                },
                categoryAxis: {
                    visible: true,
                    type: 'category',
                    labels: {},
                    crosshair: {
                        visible: false
                    }
                },
                legend: {
                    visible: false,
                    position: '',
                    align: '',
                    labels: {},
                    reverse: false
                },
                tooltip: {
                    visible: false,
                    template: "#= series.name #: #= value #"
                }
            });
        }

        /**
         * Builds a chart/bar Viz
         * @function chartBar
         * @returns {Viz}
         */
        function chartBar() {
            return new Viz('chart', 'bar', null, {
                theme: 'intellibrief',
                columns: [],
                dataSource: {
                    schema: {
                        model: {
                            fields: {}
                        }
                    },
                    sort: [],
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                chartArea: {
                    margin: {},
                    background: 'transparent',
                    opacity: 1
                },
                seriesDefaults: {
                    type: "bar"
                },
                series: [{}],
                valueAxis: {
                    visible: true,
                    type: 'numeric',
                    line: {
                        visible: false
                    },
                    minorGridLines: {
                        visible: true
                    },
                    crosshair: {
                        visible: false
                    }
                },
                categoryAxis: {
                    visible: true,
                    type: 'category',
                    field: '',
                    labels: {},
                    crosshair: {
                        visible: false
                    }
                },
                legend: {
                    visible: false,
                    position: '',
                    align: '',
                    labels: {},
                    reverse: false
                },
                tooltip: {
                    visible: false,
                    template: "#= series.name #: #= value #"
                }
            });
        }

        /**
         * Builds a chart/bubble Viz
         * @function chartBubble
         * @returns {Viz}
         */
        function chartBubble() {
            return new Viz('chart', 'bubble', null, {
                theme: 'intellibrief',
                columns: [],
                dataSource: {
                    schema: {
                        model: {
                            fields: {}
                        }
                    },
                    sort: [],
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                chartArea: {
                    margin: {},
                    background: 'transparent',
                    opacity: 1
                },
                seriesDefaults: {
                    type: "bubble"
                },
                series: [{
                    yField: "",
                    xField: "",
                    sizeField: ""
                }],
                xAxis: {
                    visible: true,
                    type: 'numeric',
                    labels: {}
                },
                yAxis: {
                    visible: true,
                    type: 'numeric',
                    labels: {},
                    line: {
                        width: 0
                    }
                },
                legend: {
                    visible: false,
                    position: '',
                    align: '',
                    labels: {},
                    reverse: false
                },
                tooltip: {
                    visible: false,
                    format: '{0:n0}'
                }
            });
        }

        /**
         * Builds a chart/bullet Viz
         * @function chartBullet
         * @returns {Viz}
         */
        function chartBullet(template) {
            var tpl = template || null;
            var viz = new Viz('chart', 'bullet', tpl,
                {
                    theme: 'intellibrief',
                    autoBind: false,
                    columns: {
                        value: [],
                        plot: [],
                        target: []
                    },
                    dataSource:{
                        value: {
                            name: 'Value Source',
                            schema: {
                                model: {
                                    fields: {}
                                }
                            },
                            sort: [],
                            aggregate: [],
                            filter: {
                                filters: [],
                                logic: 'and'
                            }
                        },
                        plot: {
                            name: 'Plotband Source',
                            schema: {
                                model: {
                                    fields: {}
                                }
                            },
                            sort: [],
                            aggregate: [],
                            filter: {
                                filters: [],
                                logic: 'and'
                            }
                        },
                        target: {
                            name: 'Target Source',
                            schema: {
                                model: {
                                    fields: {
                                        category:{},
                                        target:{}
                                    }
                                }
                            },
                            sort: [],
                            aggregate: [],
                            filter: {
                                filters: [],
                                logic: 'and'
                            }
                        }
                    },
                    chartArea: {
                        margin: {},
                        background: 'transparent',
                        opacity: 1
                    },
                    legend: {
                        visible: false,
                        position: '',
                        align: '',
                        labels: {},
                        reverse: false
                    },
                    tooltip: {
                        visible: false,
                        template: "#= value.current #"
                    },
                    categoryAxis: {
                        visible: true,
                        type: 'category',
                        line: {
                            visible: true
                        },
                        majorGridLines: {
                            visible: true
                        },
                        minorGridLines: {
                            visible: false
                        },
                        labels: {},
                        crosshair: {
                            visible: false
                        }
                    },
                    valueAxis: {
                        visible: true,
                        type: 'numeric',
                        line: {
                            visible: true
                        },
                        majorGridLines: {
                            visible: true
                        },
                        minorGridLines: {
                            visible: false
                        },
                        crosshair: {
                            visible: false
                        }
                    },
                    seriesDefaults: {
                        type: "bullet"
                    },
                    series: [{
                        field: "value",
                        name: ""
                    }]
                }
            );
            if (tpl === 'sales1') {
                angular.extend(viz, {
                    tooltip: {
                        visible: true,
                        template: "Current: #= kendo.format('{0:c}',kendo.parseFloat(value.current))#"
                    },
                    valueAxis: {
                        majorGridLines: {
                            visible: false
                        },
                        line: {
                            visible: false
                        },
                        labels: {
                            template: "#= kendo.format('{0:c0}',value)#"
                        }
                    },
                    categoryAxis: {
                        majorGridLines: {
                            visible: false
                        },
                        line: {
                            visible: false
                        }
                    }
                });
            }
            return viz;
        }

        /**
         * Builds a chart/donut Viz
         * @function chart
         * @returns {Viz}
         */
        function chartDonut(){
            return new Viz('chart', 'donut', null,
                {
                    theme: 'intellibrief',
                    columns: [],
                    dataSource: {
                        schema: {
                            model: {
                                fields: {}
                            }
                        },
                        sort: [],
                        aggregate: [],
                        filter: {
                            filters: [],
                            logic: 'and'
                        }
                    },
                    chartArea: {
                        margin: {},
                        background: 'transparent',
                        opacity: 1
                    },
                    seriesDefaults: {
                        labels: {
                            visible: true,
                            background: "transparent",
                            template: "",
                            position: "outsideEnd"
                        },
                        type: 'donut',
                        donut: {
                            categoryField: ""
                        }
                    },
                    series: [{}],
                    legend: {
                        visible: false,
                        position: '',
                        align: '',
                        labels: {},
                        reverse: false
                    },
                    tooltip: {
                        visible: false,
                        template: "#= series.name #: #= value #"
                    }
                }
            );
        }

        /**
         * Builds a chart/funnel Viz
         * @function chart
         * @returns {Viz}
         */
        function chartFunnel(){
            return new Viz('chart', 'funnel', null,
                {
                    theme: 'intellibrief',
                    columns: [],
                    dataSource: {
                        schema: {
                            model: {
                                fields: {}
                            }
                        },
                        sort: [],
                        aggregate: [],
                        filter: {
                            filters: [],
                            logic: 'and'
                        }
                    },
                    chartArea: {
                        margin: {},
                        background: 'transparent',
                        opacity: 1
                    },
                    seriesDefaults: {
                        labels: {
                            align: "center",
                            visible: true,
                            background: "transparent",
                            position: "center"
                        },
                        type: 'funnel',
                        funnel: {
                            categoryField: "",
                            segmentSpacing: 0,
                            neckRatio: 0.3,
                            dynamicSlope: false,
                            dynamicHeight: true
                        }
                    },
                    series: [{}],
                    legend: {
                        visible: false,
                        position: '',
                        align: '',
                        labels: {},
                        reverse: false
                    },
                    tooltip: {
                        visible: false,
                        template: "#= category #"
                    }
                }
            );
        }

        /**
         * Builds a chart/line Viz
         * @function chart
         * @returns {Viz}
         */
        function chartLine() {
            return new Viz('chart', 'line', null,
                {
                    theme: 'intellibrief',
                    columns: [],
                    dataSource: {
                        schema: {
                            model: {
                                fields: {}
                            }
                        },
                        sort: [],
                        aggregate: [],
                        filter: {
                            filters: [],
                            logic: 'and'
                        }
                    },
                    chartArea: {
                        margin: {},
                        background: 'transparent',
                        opacity: 1
                    },
                    legend: {
                        visible: false,
                        position: '',
                        align: '',
                        labels: {},
                        reverse: false
                    },
                    tooltip: {
                        visible: false,
                        template: "#= series.name #: #= value #"
                    },
                    categoryAxis: {
                        visible: true,
                        type: 'category',
                        field: '',
                        labels: {},
                        crosshair: {
                            visible: false
                        }
                    },
                    valueAxis: {
                        visible: true,
                        type: 'numeric',
                        line: {
                            visible: false
                        },
                        minorGridLines: {
                            visible: true
                        },
                        crosshair: {
                            visible: false
                        }
                    },
                    seriesDefaults: {
                        style: 'normal',
                        "type": "line"
                    },
                    series: [{}]
                }
            );
        }

        /**
         * Builds a chart/pie Viz
         * @function chartPie
         * @returns {Viz}
         */
        function chartPie() {
            return new Viz('chart', 'pie', null,
                {
                    theme: 'intellibrief',
                    columns: [],
                    dataSource: {
                        schema: {
                            model: {
                                fields: {}
                            }
                        },
                        sort: [],
                        aggregate: [],
                        filter: {
                            filters: [],
                            logic: 'and'
                        }
                    },
                    chartArea: {
                        margin: {},
                        background: 'transparent',
                        opacity: 1
                    },
                    seriesDefaults: {
                        labels: {
                            visible: true,
                            background: "transparent"
                        },
                        type: 'pie'
                    },
                    series: [{
                        field: "",
                        categoryField: ""
                    }],
                    legend: {
                        visible: false,
                        position: '',
                        align: '',
                        labels: {},
                        reverse: false
                    },
                    tooltip: {
                        visible: false,
                        template: '',
                        format: "{0:%}%"
                    }
                }
            );
        }

        /**
         * Builds a chart/scatter Viz
         * @function chartScatter
         * @returns {Viz}
         */
        function chartScatter() {
            return new Viz('chart', 'scatter', null, {
                theme: 'intellibrief',
                dataSource: {
                    schema: {
                        model: {
                            fields: {}
                        }
                    },
                    sort: [],
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                chartArea: {
                    margin: {},
                    background: 'transparent',
                    opacity: 1
                },
                columns: [],
                legend: {
                    visible: false,
                    position: '',
                    align: '',
                    labels: {},
                    reverse: false
                },
                tooltip: {
                    visible: true,
                    template: "#= series.name #: #= value #"
                },
                seriesDefaults: {
                    type: "scatter"
                },
                series: [
                    {
                        "xField": "",
                        "yField": ""
                    }
                ],
                xAxis: {
                    visible: true,
                    type: 'numeric',
                    crosshair: {
                        visible: false,
                        tooltip: {
                            visible: true,
                            format: "n1"
                        }
                    }
                },
                yAxis: {
                    visible: true,
                    type: 'numeric',
                    crosshair: {
                        visible: false,
                        tooltip: {
                            visible: true,
                            format: "n1"
                        }
                    }
                }
            });
        }

        /**
         * Builds a schedule Viz
         * @function schedule
         * @returns {Viz}
         */
        function schedule() {
            return new Viz('schedule', null, null, {
                columns: {},
                views: [
                    { type: "day", selected: false },
                    { type: "workWeek", selected: false },
                    { type: "week", selected: false },
                    { type: "month", selected: true }
                ],
                showWorkHours: true,
                workDayStart: new Date('2013/6/6 06:00 AM'),
                workDayEnd: new Date('2013/6/6 06:00 PM'),
                editable: false,
                eventTemplate: "<span class='custom-event'>{{dataItem.title}}</span>",
                allDayEventTemplate: "<div class='custom-all-day-event'>{{dataItem.title}}</div>",
                timezone: "Etc/UTC",
                dataSource: {},
                resources: {}
            });
        }

        /**
         * Builds a schedule/gantt Viz
         * @function scheduleGantt
         * @returns {Viz}
         */
        function scheduleGantt() {
            return new Viz('schedule', 'gantt', null, {
                dataSource: {
                    aggregate: [],
                    sort: [],
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                orderId: { from: "", type: "number", defaultValue: 0 },
                                parentId: { from: "", type: "number", defaultValue: null },
                                start: { from: "", type: "date" },
                                end: { from: "", type: "date" },
                                title: { from: "", defaultValue: "", type: "string" },
                                percentComplete: { from: "", type: "number" },
                                summary: { from: "", type: "boolean", defaultValue: false },
                                expanded: { from: "", type: "boolean", defaultValue: true }
                            }
                        }
                    },
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                views: [
                    // "day",
                    { type: "week", selected: false },
                    { type: "month", selected: true }
                ],
                columns: [],
                height: 600,
                showWorkHours: false,
                showWorkDays: false,
                editable: false,
                resizable: true
            });
        }

        /**
         * Builds a schedule/calendar Viz
         * @function scheduleCalendar
         * @returns {Viz}
         */
        function scheduleCalendar() {
            return new Viz('schedule', 'calendar', null, {
//                value: new Date(2000, 10, 1),
                dataSource: {
                    schema: {
                        model: {
                            fields: {
                                date: {
                                    type: "date"
                                }
                            }
                        }
                    },
                    sort: [],
                    aggregate: [],
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                },
                columns: []
            });
        }

        /**
         * Builds a map Viz
         * @function map
         * @returns {Viz}
         */
        function map() {
            return new Viz('map', null, null, {
                dataSource: {},
                columns: {},
                map: {
                    center: [33.7, -117.8],
                    zoom: 10,
                    scrollWheelZoom: false,
                    closePopupOnClick: true
                },
                controls: {
                    zoom: true,
                    layers: true,
                    scale: true,
                    loading: true,
                    editPopup: true
                },
                basemaps: [
                    {
                        type: "mapbox.streets-basic",
                        name:"Basic",
                        visible: true
                    },
                    {
                        type: "mapbox.streets-satellite",
                        name:"Satellite",
                        visible: false
                    }
                ],
                overlays: [],
                groupColors: {}
            });
        }

        /**
         * Builds a progress bar Viz
         * @function progress
         * @returns {VizClassService.viz}
         */
        function progress(){
            return new Viz('progress', null, null, {
                type: 'percent', // 'value'|'percent'|'chunk'
                orientation: 'horizontal',
                reverse: false,
                showStatus: false,
                max: 100,
                min: 0,
                value: 0,
                percent: 0,
                columns: [],
                dataSource: {
                    aggregate: [
                        {
                            nimLabel: 'value',
                            field: '',
                            aggregate: 'sum'
                        }
                    ],
                    sort: [],
                    serverAggregates: true,
                    schema: {
                        model: {
                            fields: {
                                value: { from: "" }
                            }
                        },
                        aggregates: 'nim_aggregate'
                    },
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                }
            });
        }

        function listview(){
            return new Viz('list', null, null, {
                template: '',
                columns: [],
                dataSource: {
                    aggregate: [],
                    sort: [],
                    schema: {
                        model: {
                            fields: {
                                title: {
                                    from: '',
                                    type: 'string'
                                },
                                description: {
                                    from: '',
                                    type: 'string'
                                },
                                name: {
                                    from: '',
                                    type: 'string'
                                },
                                email: {
                                    from: '',
                                    type: 'string'
                                }
                            }
                        }
                    },
                    filter: {
                        filters: [],
                        logic: 'and'
                    },
                    pageSize: 10
                }
            });
        }

        function kanban(){
            return new Viz('kanban', null, null, {
                template: '<div class="nim-text-overflow-ellipsis">(Label Value Here) <span>(Order Value Here)</span></div>',
                columns: [],
                dataSource: {
                    aggregate: [],
                    sort: [],
                    schema: {
                        model: {
                            fields: {}
                        }
                    },
                    filter: {
                        filters: [],
                        logic: 'and'
                    }
                }
            });
        }
    })
;