angular.module('cerberus.util')
    .factory('nimUtilityService', function(_, kendo, moment, writtenNumber){
        return {
            toWrittenNumber: writtenNumber,
            format: kendo.format,
            toString: kendo.toString,
            parseInt: kendo.parseInt,
            parseFloat: kendo.parseFloat,
            parseDate: kendo.parseDate,
            toCamelCase: kendo.toCamelCase,
            toHyphens: kendo.toHyphens,
            lodash: _,
            moment: moment,
            toUpperCase: toUpperCase,
            toLowerCase: toLowerCase,
            toSentenceCase: toSentenceCase,
            truncateWords: truncateWords,
            truncateCharacters: truncateCharacters,
            toInitials: toInitials,
            toTitleCase: toTitleCase,
            dateFormat: dateFormat
        };

        function toUpperCase(str){
            str = str || '';
            return str.toUpperCase();
        }

        function toLowerCase(str){
            str = str || '';
            return str.toLowerCase();
        }

        function toSentenceCase(str){
            var sentence = '';
            if(str && str.length > 0){
                sentence += str.charAt(0).toUpperCase();
                if(str.length > 1){
                    sentence += str.substr(1, str.length - 1).toLowerCase();
                }
            }
            return sentence;
        }

        function truncateWords(input, words) {
            if (isNaN(words))  {
                return input;
            }
            if (words <= 0) {
                return '';
            }
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return input;
        }

        function truncateCharacters(input, chars, breakOnWord) {
            if (isNaN(chars)) {
                return input;
            }
            if (chars <= 0) {
                return '';
            }
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    //get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                }else{
                    while(input.charAt(input.length-1) === ' '){
                        input = input.substr(0, input.length -1);
                    }
                }
                return input + '...';
            }
            return input;
        }

        function toInitials(str) {
            str = str || '';
            var initials = '';
            var arr = str.split(' ');
            for (var i = 0; i < arr.length; i++) {
                initials += arr[i].substring(0,1).toUpperCase() + '.';
            }
            return initials;
        }

        function toTitleCase(string) {
            string = string || '';
            return string.replace(/([\w&`'â€˜â€™"â€œ.@:\/\{\(\[<>_]+-? *)/g,
                function(match, p1, index, title){
                    if (index > 0 && title.charAt(index - 2) != ":" &&
                        match.search(/^(a(nd?|s|t)?|b(ut|y)|en|for|i[fn]|o[fnr]|t(he|o)|vs?\.?|via)[ -]/i) > -1) {
                        return match.toLowerCase();
                    }
                    if (title.substring(index - 1, index + 1).search(/['"_{([]/) > -1) {
                        return match.charAt(0) + match.charAt(1).toUpperCase() + match.substr(2);
                    }
                    if (match.substr(1).search(/[A-Z]+|&|[\w]+[._][\w]+/) > -1 ||
                        title.substring(index - 1, index + 1).search(/[\])}]/) > -1) {
                        return match;
                    }
                    return match.charAt(0).toUpperCase() + match.substr(1);
                });
        }

        function dateFormat(date, formatString) {
            if (_.isDate(date)) {
                return kendo.toString(date, formatString);
            }
            else if (_.isString(date)) {
                var dateValue = kendo.parseDate(date, ['yyyy-MM-ddTHH:mm:ss.fffZ','yyyy-MM-dd HH:mm:ss.fff']);
                return kendo.toString(dateValue, formatString);
            }

            return '';
        }
    })
;