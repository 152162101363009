angular.module('cerberus.account')
    .config(function ($routeProvider) {
        $routeProvider.when('/users/settings', {
            templateUrl: 'account/users/settings.tpl.html',
            title: 'User Settings',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/profile', {
            templateUrl: 'account/users/profile.tpl.html',
            controller: 'UsersProfileCtrl',
            title: 'User Profiles',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/profile/create', {
            templateUrl: 'account/users/profile-create.tpl.html',
            controller: 'UsersProfileCreateCtrl',
            title: 'Create User Profile',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/profile/:id', {
            templateUrl: 'account/users/profile-details.tpl.html',
            controller: 'UsersProfileDetailsCtrl',
            title: 'User Profile Details',
            resolve: {
                _wait: 'WaitForUserData',
                userId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/users/settings/password', {
            templateUrl: 'account/users/password.tpl.html',
            controller: 'UsersPasswordCtrl',
            title: 'User Passwords',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/address-book', {
            templateUrl: 'account/users/address-book.tpl.html',
            controller: 'UsersAddressBookCtrl',
            title: 'User Address Book',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/address-book/create', {
            templateUrl: 'account/users/address-book-create.tpl.html',
            controller: 'UsersAddressBookCreateCtrl',
            title: 'Create Address Book',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/address-book/:id', {
            templateUrl: 'account/users/address-book-details.tpl.html',
            controller: 'UsersAddressBookDetailsCtrl',
            title: 'Users Address Book Details',
            resolve: {
                _wait: 'WaitForUserData',
                profileId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        // $routeProvider.when('/users/settings/sessions', {
        //     templateUrl: 'account/users/sessions.tpl.html',
        //     controller: 'UsersSessionsCtrl',
        //     title: 'User Sessions',
        //     resolve: {
        //         _wait: 'WaitForUserData'
        //     }
        // });
        $routeProvider.when('/users/settings/api-keys', {
            templateUrl: 'account/users/api-keys.tpl.html',
            controller: 'UsersApiKeysCtrl',
            title: 'Users API Keys',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/organizations', {
            templateUrl: 'account/users/organizations.tpl.html',
            controller: 'UsersOrganizationsCtrl',
            title: 'User Organizations',
            resolve: {
                _wait: 'WaitForUserData',
                isAdmin: function(WaitForUserData, $rootScope) {
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace.isAdmin;
                    });
                },
                userId: function(WaitForUserData, $rootScope) {
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.id;
                    });
                }
            }
        });
        $routeProvider.when('/users/settings/templates', {
            templateUrl: 'account/users/templates.tpl.html',
            controller: 'UsersTemplatesCtrl',
            title: 'User Templates',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/templates/:id', {
            templateUrl: 'account/users/templates-details.tpl.html',
            controller: 'UsersTemplatesDetailsCtrl',
            title: 'User Template Details',
            resolve: {
                _wait: 'WaitForUserData',
                templateId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/users/settings/mail-servers', {
            templateUrl: 'account/users/mail-servers.tpl.html',
            controller: 'UsersMailServersCtrl',
            title: 'User Mail Servers',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/mail-servers/create', {
            templateUrl: 'account/users/mail-servers-create.tpl.html',
            controller: 'UsersMailServersCreateCtrl',
            title: 'Create User Mail Server',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/users/settings/mail-servers/:id', {
            templateUrl: 'account/users/mail-servers-details.tpl.html',
            controller: 'UsersMailServersDetailsCtrl',
            title: 'User Mail Server Details',
            resolve: {
                _wait: 'WaitForUserData',
                serverId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/users/settings/signature', {
            templateUrl: 'account/users/signature.tpl.html',
            controller: 'UsersSignatureCtrl',
            title: 'User Signature',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
    })
;