angular.module('cerberus.admin')
    .factory('WidgetsSettingsService', function WidgetsSettingsService(_, $http, $q, $window, apiPath, toaster, OdataUtilityService) {
        return {
            addWidget: addWidget,
            updateWidget: updateWidget,
            deleteWidget: deleteWidget,
            getFormFields: getFormFields,
            parseFormObjects: parseFormObjects,
            getBaseWidgetViews: getBaseWidgetViews,
            exportAllAsExcel: exportAllAsExcel
        };

        function addWidget(data) {
            var deferred = $q.defer();
            $http.post(apiPath + 'widgets', data)
                .success(function (value) {
                    toaster.pop('success', 'Widget Created');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function updateWidget(widgetId, data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'widgets/' + widgetId, data)
                .success(function (value) {
                    toaster.pop('success', 'Widget Saved');
                    deferred.resolve(value);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function deleteWidget(widgetId) {
            var deferred = $q.defer();
            $http({ method: 'DELETE', url: apiPath + 'widgets/' + widgetId })
                .success(function (value) {
                    toaster.pop('info', 'Widget Deleted');
                    deferred.resolve(value);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getFormFields(id, formIds) {
            var deferred = $q.defer();
            var params = {};
            if (!_.isEmpty(formIds)) {
                params.formOriginIds = formIds.join(',');
            }

            $http.get(apiPath + 'widgets/' + id + '/workflow/forms/objects', { params: params })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            
            return deferred.promise;
        }

        function parseFormObjects(forms) {
            var formObjects = [];
            _.forEach(forms, function (form) {
                _.forEach(form.objects, function (object) {
                    var formObj = {
                        modelId: object.modelId,
                        label: object.label,
                        formName: form.formName,
                        formId: form.originId,
                        stateId: form.stateId,
                        isLookup: object.isLookUp,
                        isCustomLookup: object.isCustomLookup,
                        dataType: object.dataType
                    };

                    if (formObj.isLookup) {
                        formObj.lookupCol = object.column;
                        formObj.viewId = object.optionId;
                    }

                    formObjects.push(formObj);
                });
            });
            
            return formObjects;
        }

        function getBaseWidgetViews(baseWidget) {
            var deferred = $q.defer();
            $http.get(apiPath + 'widgets/' + baseWidget + '/views')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function exportAllAsExcel(id, filter, sort) {
            var filterString = '';
            if (filter) {
                filterString = OdataUtilityService.toOdataFilter(filter);
            }
            var sortString = '';
            if (sort && sort[0]) {
                sortString = sort[0].field + ' ' + sort[0].dir;
            }
            $http.get(apiPath + 'widgets/' + id + '/export', {
                params: {
                    $orderBy: sortString,
                    $filter: filterString,
                    $top: 'all'
                }
            }).then(function (result) {
                $window.open(result.data, '_self');
            });
        }
    })
;