angular.module('cerberus.util')
    /**
     * Roll Calculator
     * Equations taken from http://www.giangrandi.ch/soft/spiral/spiral.shtml
     * Inspiration taken from http://www.handymath.com/calculators.html
     */
    .factory('rollCalculationService', function() {
        var service = {
            rollLength: rollLength,
            rollOuterDiameter: rollOuterDiameter,
            rollInnerDiameter: rollInnerDiameter,
            inRollCaliper: inRollCaliper,
            rollBasisWeight: rollBasisWeight,
            rollWeight: rollWeight
        };
        return service;
        ////////////////////
        /**
         * Roll Length Calculator
         * @param {number} h - Thickness or height of material
         * @param {number} d0 - Inner Diameter
         * @param {number} d1 - Outer Diameter
         * @return {number}
         */
        function rollLength(h, d0, d1) {
            var n, l, phi0, phi1;
            // n = number of revolutions
            n = (d1 - d0) / (2 * h);
            phi0 = Math.PI * d0 / h;
            phi1 = Math.PI * d1 / h;
            l = getExact_length(phi0, phi1, h);
            return l;
        }
        /**
         * Roll Diameter Calculator
         * @param {number} h - Thickness or height of material
         * @param {number} d0 - Inner Diameter
         * @param {number} l - Roll Length
         * @return {number}
         */
        function rollOuterDiameter(h, d0, l) {
            var n, d1, phi0, phi1, delta_phi, max_iter;
            // Maximum number of interation for convergence of the Newton's method.
            max_iter = 200;
            /////////////////////////////////////////////////////////////////////////////
            // To find "phi1" from L(phi1)=(h/2pi)(...) we have to numerically solve
            // (h/2pi)(...)-L=0, so we have f(phi1)=(h/2pi)(...)-L=0
            // The approximate formula is used to find a starting point, from which we
            // use Newton's method to find a more accurate numerical solution as follows:
            //
            // phi_n+1 = phi_n - (f(phi_n)/f'(phi_n))
            //
            // Whith this method, there is no need to invert the function, it's only
            // necessary to find it's derivative. It converges quite quickly and only a
            // few iterations are required for the precision of the floating point
            // variable used.
            /////////////////////////////////////////////////////////////////////////////
            n = (h - d0 + Math.sqrt((d0 - h) * (d0 - h) + (4 * h * l) / Math.PI)) / (2 * h);
            d1 = 2 * n * h + d0;
            phi0 = Math.PI * d0 / h;
            phi1 = Math.PI * d1 / h;
            // This is the starting approximation.
            for (var i = 0; i <= max_iter; i++) {
                delta_phi = (getExact_length(phi0, phi1, h) - l) / getExact_dL_dphi(phi1, h);
                phi1 -= delta_phi;
                // Stop looping if solution already found.
                if (delta_phi === 0) {
                    break;
                }
            } // Now we have phi1 and we find d1.
            d1 = phi1 * h / Math.PI;
            // And find a more accurate N.
            // n = number of revolutions
            n = (d1 - d0) / (2 * h);
            return d1;
        }
        /**
         * Inner Diameter of Roll
         * @param {number} h - Thickness or height of material
         * @param {number} d1 - Outer Diameter
         * @param {number} l - Roll Length
         * @return {number}
         */
        function rollInnerDiameter(h, d1, l) {
            var minOuterDia, len, dia;
            minOuterDia = rollOuterDiameter(h, h, l);
            len = rollLength(h, minOuterDia, d1);
            dia = rollOuterDiameter(h, h, len);
            return dia;
        }
        /**
         * Thickness of Material on a Roll
         * Winding: Machines, Mechanics and Measurements
         * By James K. Good, David R. Roisum
         * page 124
         * @param {number} d0 - Inner Diameter
         * @param {number} d1 - Outer Diameter
         * @param {number} l - Roll Length
         * @return {number}
         */
        function inRollCaliper(d0, d1, l) {
            var h = (Math.PI / (4 * l)) * (Math.pow(d1, 2) - Math.pow(d0, 2));
            return h;
        }

        /**
         * Calculates the Basis Weight of a roll
         * Notes: 1000 square feet = 144000 square inches
         * @param {number} l - Length
         * @param {number} w - Width
         * @param {number} rw - Weight of Roll
         * @return {number}
         */
        function rollBasisWeight(l, w, rw) {
            var bw = rw / (l * w / 144000);
            return bw;
        }

        /**
         * Calculates the Weight of a roll
         * Notes: 1000 square feet = 144000 square inches
         * @param {number} l - Length
         * @param {number} w - Width
         * @param {number} bw - Basis Weight
         * @return {number}
         */
        function rollWeight(l, w, bw) {
            var rw = l * w * (bw / 144000);
            return rw;
        }

        /**
         * Length Helper
         * @param {number} phi0
         * @param {number} phi1
         * @param {number} h
         * @return {number}
         */
        function getExact_length(phi0, phi1, h) {
            var length;
            length = (phi1 / 2) * Math.sqrt(phi1 * phi1 + 1);
            length += (1 / 2) * Math.log(phi1 + Math.sqrt(phi1 * phi1 + 1));
            length -= (phi0 / 2) * Math.sqrt(phi0 * phi0 + 1);
            length -= (1 / 2) * Math.log(phi0 + Math.sqrt(phi0 * phi0 + 1));
            length *= h / (2 * Math.PI);
            return length;
        }
        /**
         * Diameter Helper
         * @param {number} phi
         * @param {number} h
         * @return {number}
         */
        function getExact_dL_dphi(phi, h) {
            var dL_dphi;
            dL_dphi = (2 * phi * phi + 1) / (2 * Math.sqrt(phi * phi + 1));
            dL_dphi += (phi + Math.sqrt(phi * phi + 1)) / (2 * phi * Math.sqrt(phi * phi + 1) + 2 * phi * phi + 2);
            dL_dphi *= h / (2 * Math.PI);
            return dL_dphi;
        }
    })
;