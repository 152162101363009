angular.module('cerberus.core')
    .controller('SearchCtrl',
    function($scope, SearchService, InstancesWindowService, $window, $location, $sce, $routeParams){
        var vm = this;
        vm.q = angular.copy($routeParams.q);
        vm.currentPage = 1;
        vm.pageSize = 20;
        vm.searchPageArray = [];
        vm.searchedText = null;
        vm.searchText = $location.search().q || null;
        vm.searchResults = null;

        vm.executeSearch = function(txt,size,current){
            $location.search('q',txt);
            SearchService.search(txt,size,current).then(function(d){
                vm.searchedText = angular.copy(vm.searchText);
                var tmpArr = SearchService.formatPagination(d.found,size,current);
                if(tmpArr.length > 0 && d.results.length > 0){
                    vm.searchResults = d;
                    vm.searchPageArray = tmpArr;
                    vm.currentPage = current;
                }
                else{
                    vm.searchResults = {results:[],found:0};
                }
            });
        };

        vm.cleanHtml = function(html){
            return $sce.trustAsHtml(html);
        };

        vm.getInstanceViaAttachmentId = function(searchItem){
            var tmp = searchItem.id.split('/');
            var fileName = tmp[tmp.length - 1];
            var tmp2 = fileName.split('.');
            var id = tmp2[0];
            SearchService.getInstanceIdViaAttachmentId(id).then(function(d){
                var itm = {
                    id:d,
                    type: 'record'
                };
                vm.openRecord(itm);
            });
        };

        vm.openRecord = function(searchItem){
            if(searchItem.type === 'file'){
                $window.open(searchItem.urlPath);
            }
            else{
                InstancesWindowService.openWindow({
                    action: 'read',
                    instanceId: searchItem.id,
                    title: searchItem.title
                });
            }
        };

        init();

        function init(){
            if(vm.q){
                vm.executeSearch(vm.q,vm.pageSize,1);
            }
        }
    }
)
;