/* Templates:
   Photos - square with a transparent black bottom area with title and more info button and a checkbox (top left or bottom right?)
   Files - similar to photos but with a transparent bottom with no checkbox and the filename with a small info button
   Entry - full width with title, description, picture, and small info at very button; no vertical spacing
   Contact - http://www.google.com/design/spec/components/chips.html#chips-contact-chips

   Card Large- 320 x 320 card with picture on top and a white bottom for Title description
   Card Medium - 320 x 160 photo left, photo right and no photo variant
   Card Small - 160 x 160 photo top and no photo variants
    http://www.google.com/design/spec/components/cards.html#cards-content
 */

angular.module('cerberus.core')
    .controller('nimVizListCtrl',function nimVizListCtrl(_, kendo, $scope, $log, $timeout, VizUtilityService, VizListViewService, $firebaseObject, fbUtil) {
        var vm = this;
        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function () {
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function () { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            }),
            dataSource = VizListViewService.buildDataSource($scope.pageObject, $scope),
            navFunctions = {
                getNext: _.partialRight(VizListViewService.viewNextRecord, dataSource),
                getPrevious: _.partialRight(VizListViewService.viewPreviousRecord, dataSource)
            };

        vm.selectedItems = [];
        vm.selectedIds = [];

        vm.viewRecord = _.partialRight(VizListViewService.viewRecord, navFunctions);
        vm.itemSelected = itemSelected;
        vm.selectItem = selectItem;
        vm.selectSingle = selectSingle;
        vm.viewAndSelect = viewAndSelect;
        vm.uploadFiles = uploadFiles;

        init();        

        // Event Bindings
        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            debouncedRefresh();

            if($scope.list) {
                $scope.list.refresh();
            }
            
            if ($scope.pager) {
                $scope.pager.refresh();
            }
        });

        $scope.$on('nim-viz-update-data', function(e, action, id/*, data*/){
            if(action === 'create'){
                // Nothing here, for now...
            }
            else if(action === 'update'){
                // Nothing here, for now...
            }
            else if(action === 'delete'){
                _.forEach(id, function (itemId) {
                    var dataItem = dataSource.get(itemId);
                    dataSource.remove(dataItem);
                });

                vm.selectedIds = [];
                vm.selectedItems = [];
                $scope.list.refresh();
            }
        });

        $scope.$on('list-source-changed', function(e, list){
            if(list == $scope.list){
                vm.data = list.dataSource.data();
                if(vm.selectedIds.length > 0) {
                    var selectedPageRows = [];
                    var pageData = list.dataSource.data();

                    _.forEach(pageData, function (dataItem) {
                        var selector = "div[data-uid=" + dataItem.uid + "]";
                        if(vm.selectedIds.indexOf(dataItem.id) >= 0){
                            selectedPageRows.push(selector);
                        }
                    });

                    $timeout(function () {
                        list.select(selectedPageRows.join(', '));
                    }, 1);
                }
            }
        });

        $scope.$on('$destroy', function () {
            delete navFunctions.getNext;
            delete navFunctions.getPrevious;

            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.list) {
                $scope.list.destroy();
                $scope.list = null;
            }
            if ($scope.pager) {
                $scope.pager.destroy();
                $scope.pager = null;
            }
        });

        function init() {
            vm.dataSource = dataSource;
            vm.options = {
                autoBind: false,
                columns: $scope.pageObject.viz.settings.columns,
                dataSource: dataSource,
                navigatable: true,
                template: function(dataItem){
                    try {
                        var template = kendo.template($scope.pageObject.viz.settings.template);
                        var templateData = VizListViewService.templateData(dataItem);
                        var html = template(templateData).replace(/&lt;/g, '<').replace(/&quot;/g, '"').replace(/&gt;/g, '>');
                        return VizListViewService.stripMisplacedAttributes(html, templateData.onClick);
                    }
                    catch(e){
                        $log.warn(e);
                        return '';
                    }
                },
                selectable: 'multiple',
                altTemplate: null,
                editTemplate: null,
                dataBound: resizeList
            };

            var pagerOptions = {
                dataSource: dataSource
            };

            if($scope.pageObject.params.pageable === true){
                vm.pagerOptions = pagerOptions;
            }
            else if($scope.pageObject.params.pageable){
                vm.pagerOptions = angular.extend(pagerOptions, $scope.pageObject.params.pageable);
            }
            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, dataSource);
            $scope.mainPageObject.vizCtrl = vm;

            debouncedRefresh();
        }

        function applyFilter(){
            var params = $scope.pageObject.params;
            if($scope.list && params.asFilter && params.filterId){
                var filters = _.map(vm.selectedIds, function (id) {
                    return {
                        field: '__nimColumn__',
                        operator: 'eq',
                        value: id
                    };
                });
                
                $scope.filters[params.filterId] = {
                    logic: 'or',
                    filters: filters
                };
            }
        }

        function resizeList(){
            if ($scope.resizeGridsterItem) {
                $timeout(function () {
                    $scope.resizeGridsterItem();
                }, 1);
            }
        }

        function itemSelected(id){
            return vm.selectedIds.indexOf(id) >= 0;
        }
        
        function selectItem(id){
            var index = vm.selectedIds.indexOf(id);
            if(index >= 0){
                vm.selectedIds.splice(index, 1);
                vm.selectedItems.splice(index, 1);
            }
            else {
                vm.selectedIds.push(id);
                vm.selectedItems.push(dataSource.get(id));
            }
            applyFilter();
        }

        function selectSingle(id){
            vm.selectedIds = [id];
            vm.selectedItems = [dataSource.get(id)];
            applyFilter();
        }

        function viewAndSelect(id){
            vm.viewRecord(id);
            vm.selectSingle(id);
        }

        function uploadFiles() {
            VizListViewService.openUploadModal($scope.pageObject);
        }
    })
    .directive('nimVizList', function() {
        return {
            restrict: 'AE',
            templateUrl: 'core/pages/viz/list/list.tpl.html',
            scope: true,
            require: '^nimPageObject',
            controller: 'nimVizListCtrl',
            controllerAs: 'vm'
        };
    })
;