angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name ImportListService
 * @alias cerberus/widgets:ImportListService
 * @description Provides REST service for "imports list" resource
 */
    .factory('ImportListService', function ImportListService() {
        var exchangeTypes = {
            exchangeContact:[
                'Assistant',
                'BusinessAddress',
                'BusinessPhoneNumber',
                'Categories',
                'Company',
                'Department',
                'Description',
                'DisplayAs',
                'Email1',
                'Email2',
                'Email3',
                'FirstName',
                'HomeAddress',
                'HomePhoneNumber',
                'HtmlDescription',
                'JobTitle',
                'LastName',
                'Manager',
                'MiddleName',
                'MobilePhoneNumber',
                'NickName',
                'Office',
                'OtherAddress',
                'OtherPhoneNumber',
                'Pager',
                'Profession',
                'SpouseName',
                'WebPage',
                'businessFax',
                'mailingAddressType'
            ],
            exchangeTask:[
                'ActualWork',
                'BillingInfo',
                'Categories',
                'Companies',
                'DateCompleted',
                'DueDate',
                'HTMLMessage',
                'Message',
                'Mileage',
                'PercentCompleted',
                'Priority',
                'ReminderDate',
                'StartDate',
                'Status',
                'Subject',
                'TotalWork'
            ],
            exchangeCalendar:[
                'AllDayEvent',
                'Categories',
                'Duration',
                'EndTime',
                'Importance',
                'IsRecurring',
                'Label',
                'Location',
                'Message',
                'OptionalAttendees',
                'Organizer',
                'RequiredAttendees',
                'Resources',
                'Sensitivity',
                'StartTime',
                'Subject'
            ]
        };

        return {
            getConfigTypes: getConfigTypes,
            getTypeColumns:getTypeColumns
        };
        ////////////////////

        /**
         * This function gets all the different config types
         * @function getConfigTypes
         * @return {object[]} types
         */
        function getConfigTypes() {
            return [
                {
                    display: 'CSV',
                    value: 'csv'
                },
                {
                    display:'Exchange Contacts',
                    value: 'exchangeContact'
                },
                {
                    display:'Exchange Tasks',
                    value: 'exchangeTask'
                },
                {
                    display:'Exchange Calendar',
                    value: 'exchangeCalendar'
                },
                {
                    display:'Files',
                    value: 'files'
                }
            ];
        }

        /**
         * This function gets all the columns for a specific type
         * @function getExchangeColumns
         * @return {object} cols
         */
        function getTypeColumns() {
            return exchangeTypes;
        }

    })
;
