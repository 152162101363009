angular.module('cerberus.admin')
    .controller('ViewsCreateCtrl', function ViewsCreateCtrl($location, viewData, ViewsService) {
        var vm = this;

        vm.tempData = angular.copy(viewData);
        vm.origData = angular.copy(viewData);
        vm.submit = _submit;
        vm.cancel = _cancel;
        vm.hasChanges = _hasChanges;

        function _submit(isValid, data){
            if(isValid){
                ViewsService.create(data).then(function(d){
                    $location.url('/settings/queries/' + d);
                });
            }
        }
        function _cancel(){
            $location.url('/settings/queries');
        }
        function _hasChanges() {
            return !angular.equals(vm.origData, vm.tempData);
        }
    })
;
