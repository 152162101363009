angular.module('cerberus.account')
    .controller('OrganizationsMailServersCreateCtrl', function OrganizationsMailServersCreateCtrl($scope, $location, orgId, OrganizationsService, usersProfileService) {
        $scope.pageTitle = 'New Mail Server';
        $scope.activeRoute = 'mail-servers';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.masterServer = {
            data: {
                protocol: 'HTTP',
                ssl: false,
                tls: false,
                priority: "Normal",
                serverVersion: '2007',
                type: 'exchange'
            }
        };
        $scope.server = angular.copy($scope.masterServer);
        $scope.verifyHtml = '<span class="text-warning">Unverified</span>';
        $scope.versions = [
            {display: "2003"},
            {display: "2007"},
            {display: "2010"}
        ];

        $scope.isUnchanged = _isUnchanged;
        $scope.submit = _submit;
        $scope.cancel = _close;
        $scope.verifyConn = _verifyConn;

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }

        function _isUnchanged(server){
            return angular.equals(server, $scope.masterServer);
        }

        function _submit(isValid, server){
            if(isValid){
                var data = {
                    data: server,
                    type: "Mail Server"
                };

                OrganizationsService.createAsset(orgId, data).then(_close);
            }
        }

        function _close(){
            $location.url('/organizations/' + orgId + '/settings/mail-servers');
        }

        function _verifyConn(){
            $scope.verifyHtml = '<i class="fa fa-spinner"></i>';
            usersProfileService.verifyMailServerConnection($scope.server).then(function(data){
                if(data){
                    $scope.verifyHtml = '<span class="text-success">Success</span>';
                }
                else{
                    $scope.verifyHtml = '<span class="text-danger">Failed</span>';
                }
            });
        }
    })
;