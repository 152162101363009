angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormValidationService
     * @alias cerberus/core:FormValidationService
     * @description Helps validate form objects
     */
    .factory('FormValidationService', function FormValidationService(_, moment, $timeout){
        return {
            validateFormObject: validateFormObject,
            validateKendoFormObject: validateKendoFormObject
        };

        /**
         * Validates the form field by checking the dataSet
         * value against the form object validation configuration.
         * @param form - instance form
         * @param formObj
         */
        function validateFormObject(form, formObj){
            return function(value, required){
                var results = {};

                var type = formObj.config.type;
                var empty = _.isEmpty(value);

                // Required (formObj.validation.required)
                if (formObj.validation.required || required) {
                    if(type === 'number'){
                        // Makes sure there is a numeric value in this field
                        results['required'] = _.isNumber(value) || (_.isString(value) && !_.isNaN(parseFloat(value)));
                    }
                    else if (type === 'datetime') {
                        // Convert string value to date for validation
                        if (_.isString(value)) {
                            value = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate();
                        }

                        // Makes sure a valid date has been selected
                        results['required'] = _.isDate(value) && value.toString() !== 'Invalid Date';
                    }
                    else if(type === 'checkbox'){
                        // Checks for truthy value for checkbox
                        results['required'] = !!value;
                    }
                    else if (type === 'text') {
                        // results['required'] = !_.isNull(value) && !_.isUndefined(value);
                        results['required'] = !!value;
                    }
                    else {
                        // Strings, objects, and arrays must have length > 0
                        // null and undefined are considered empty
                        // Edit: numbers are considered empty as well
                        results['required'] = !empty;
                    }

                    if(type === 'location'){
                        // Checks for valid address, but only if required
                        results['nimHasLocation'] = _.has(value, 'properties.formatted_address');
                    }
                }

                // Text Validation
                if (type === 'text') {
                    var length = empty ? 0 : value.length;

                    // Max Length (formObj.validation.max)
                    if (_.isNumber(formObj.validation.max)) {
                        results['maxlength'] = length <= formObj.validation.max;
                    }

                    // Min Length (formObj.validation.min)
                    if (_.isNumber(formObj.validation.min)) {
                        results['minlength'] = length >= formObj.validation.min;
                    }

                    // Pattern (formObj.validation.pattern)
                    if (formObj.validation.pattern) {
                        // Strips forward-slashes from start and end of pattern
                        var regex = new RegExp(formObj.validation.pattern.replace(/^\//, '').replace(/\/$/, ''));
                        results['pattern'] = !value || regex.test(value); // Only checks against pattern if value is given
                    }
                }

                if(form){
                    setFormObjectValidity(form, formObj.config.modelId, results);
                }

                return results;
            };
        }

        /**
         * Creates a callback for k-change event to
         * run validateFormObject
         * @param scope
         * @param form
         * @param formObj
         * @returns {Function}
         */
        function validateKendoFormObject(scope, form, formObj){
            var validate = validateFormObject(form, formObj);
            return function(e){ // k-change callback
                $timeout(function(){
                    validate(_.result(e.sender, 'value'), scope.isRequired);
                });
            };
        }

        /**
         * Sets validity for form field
         * @param form - instance form
         * @param modelId
         * @param validity - map of validity properties and validation results
         */
        function setFormObjectValidity(form, modelId, validity){
            var field = form[modelId];
            if(field) {
                $timeout(function(){
                    _.forEach(validity, function (valid, prop) {
                        field.$setDirty();
                        field.$setTouched();
                        field.$setValidity(prop, valid);
                    });
                });
            }
        }
    })
;