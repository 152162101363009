angular.module('cerberus.core')
    .controller('NimAdvancedSelectionCtrl', function(_, kendo, $scope, $rootScope, $attrs, $timeout, PagesService){
        var vm = this;

        vm.pageId = $attrs['nimPageId'];
        vm.pageObjectId = $attrs['nimPageObjectId'];
        vm.defaultFilters = _.get($scope.nimFormObject, 'param.advSel.filterMap', []);
        vm.data = [];
        vm.dataSource = new kendo.data.DataSource({
            data: []
        });
        vm.loading = true;

        $scope.selectedData = false;
        $scope.filters = {};
        $scope.defaultFilter = {};

        vm.addSelection = addSelection;
        vm.cancel = closeWindow;

        PagesService.getPage(vm.pageId).then(init);

        $scope.$on('nim-advanced-selection-updated', function(e, data){
            e.preventDefault();
            e.stopPropagation();

            if($scope.nimFormObject.config.type === 'table'){
                vm.data = _.map(data, function (dataItem) {
                    var mappedDataItem = {};

                    if(dataItem){
                        _.forIn($scope.advSelConfig.map, function (v, k) {
                            mappedDataItem[k] = dataItem[v];
                        });

                        if($scope.advSelConfig.baseLookup){
                            var idFieldName = $scope.advSelConfig.baseLookup.replace(/_display$/, '_id');
                            mappedDataItem[idFieldName] = dataItem.id;
                        }
                    }

                    return mappedDataItem;
                });

            }
            else if($scope.nimFormObject.config.type === 'select') {
                var dataItem = _.last(data),
                    idField = $scope.advSelConfig.map.id || 'id';

                if (dataItem) {
                    vm.data = [{
                        id: dataItem[idField],
                        display: dataItem[$scope.advSelConfig.map.display]
                    }];
                }
                else {
                    vm.data = [];
                }
            }

            if(vm.dataSource) {
                vm.dataSource.data(vm.data);
            }
        });

        function init(pageModel){
            vm.pageModel = pageModel;
            vm.gridsterOptions = {
                mobileBreakPoint: 768,
                margins: _.get(pageModel, 'options.gridsterConfig.margins', [15, 15]),
                outerMargin: _.get(pageModel, 'options.gridsterConfig.outerMargin', true),
                pushing: true
            };

            // Protects against potential issue with gridster margins being saved as an object instead of an array
            if(!_.isArray(vm.gridsterOptions.margins)){
                vm.gridsterOptions.margins = [
                    vm.gridsterOptions.margins[0],
                    vm.gridsterOptions.margins[1]
                ];
            }

            vm.loading = false;

            vm.sourceObject = _.find(pageModel.items, 'id', vm.pageObjectId);

            if(vm.sourceObject){
                var columns = $scope.columns || [];

                columns.push({
                    command: [
                        {
                            name: 'removeRecord',
                            text: 'Remove',
                            className: 'btn btn-sm btn-danger',
                            click: removeRecord
                        }
                    ],
                    title: ' ',
                    width: '85px'
                });

                vm.gridOptions = {
                    editable: false,
                    filterable: false,
                    columnMenu: false,
                    columns: columns,
                    dataSource: vm.dataSource
                };
            }

            _.forEach(vm.defaultFilters, function(filterObj){
                $scope.defaultFilter[filterObj.filter.id] = $scope.dataSet[filterObj.modelId];
            });
        }

        function removeRecord(e){
            e.preventDefault();

            var row = $(e.target).closest("tr");
            var uid = row.data('uid');
            var dataItem = vm.dataSource.getByUid(uid);

            $timeout(function(){
                _.remove(vm.data, 'id', dataItem.id);
                vm.dataSource.remove(dataItem);
            });
        }

        function addSelection(){
            if($scope.nimFormObject.config.type === 'table'){
                $scope.selectedData = vm.data;
            }
            else if($scope.nimFormObject.config.type === 'select') {
                $scope.selectedData = _.head(vm.data);
            }

            closeWindow();
        }

        function closeWindow() {
            $scope['adv_sel_window'].close();
        }

        $scope.$on('auth:loginCancelled', function () {
            $timeout(function(){
                closeWindow();
            });
        });
    })
    .directive('nimAdvancedSelection', function(){
        return {
            restrict: 'AE',
            templateUrl: 'core/instances/advanced-selection-page.tpl.html',
            controller: 'NimAdvancedSelectionCtrl',
            controllerAs: 'vm'
        };
    })
;