/**
 * @module cerberus/account
 * @description Includes components that service the user's account
 */
angular.module('cerberus.account', []);
/**
 * @module cerberus/account
 * @description Includes components that service the workspace and all its parts
 */
angular.module('cerberus.admin', []);
/**
 * @module cerberus/core
 * @description This module contains the core functionality for end users (non-admin)
 */
angular.module('cerberus.core', []);
/**
 * @module cerberus/slp
 * @description Custom components for the Safe Lending Platform
 */
angular.module('cerberus.les', []);
/**
 * @module cerberus/ui
 * @description Components used throughout the application to build the user interface
 */
angular.module('cerberus.ui', []);
/**
 * @module cerberus/ui-util
 * @description Components that modify the UI but not build it
 */
angular.module('cerberus.ui-util', []);
/**
 * @module cerberus/util
 * @description Background functionality and services
 */
angular.module('cerberus.util', []);
/**
 * @module cerberus
 * @description Core Module
 */
angular.module('cerberus', [
    /* Angular Dependancies */
    'ngRoute',
    'ngAnimate',
    'ngTouch',
    'ngSanitize',
    'ngMessages',
    'ngCookies',
    /* Angular UI */
    'ui.tree',
    'ui.codemirror',

    /* Angular UI Bootstrap */
    // ui bootstrap -- modules removed:'ui.bootstrap.carousel','ui.bootstrap.dateparser','ui.bootstrap.datepicker','ui.bootstrap.rating','ui.bootstrap.timepicker', 'ui.bootstrap.progressbar',
    'ui.bootstrap.alert',
    'ui.bootstrap.buttons',
    'ui.bootstrap.typeahead',
    'ui.bootstrap.accordion',
    'ui.bootstrap.collapse',
    'ui.bootstrap.position',
    'ui.bootstrap.dropdown',
    'ui.bootstrap.modal',
    'ui.bootstrap.tooltip',
    'ui.bootstrap.popover',
    'ui.bootstrap.stackedMap',
    'ui.bootstrap.pagination',
    'ui.bootstrap.tabs',
    'ui.bootstrap.tpls',

    /* Other Dependancies */
    'kendo.directives',
    'gridster',
    'LocalStorageModule',
    'ngImgCrop',
    'toaster',
    'firebase',
    'timer',

    /* Sub Modules */
    'cerberus.account',
    'cerberus.admin',
    'cerberus.core',
    'cerberus.les',
    'cerberus.ui',
    'cerberus.ui-util',
    'cerberus.util',
    'cerberus.template'
])
;