angular.module('cerberus.core')
    .config(function ($routeProvider) {
        $routeProvider.when('/notifications', {
            templateUrl: 'core/notifications/templates/notifications.tpl.html',
            controller: 'NotificationsCtrl',
            controllerAs: 'vm',
            title: 'Notifications',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
    })
;