angular.module('cerberus.admin')
    .controller('WorkspacesCurrencyCtrl', function WorkspaceCurrencyCtrl($scope, $location, workspaceSettingsFactory) {
        $scope.currencyMaster = {};
        $scope.currencyUser = {};
        $scope.currencyLookup = {
            decimalPrecision:[
                {format:'c', display:"Auto"},
                {format:'c0', display:"0 ( 1 )"},
                {format:'c1', display:"1 ( 1.0 )"},
                {format:'c2', display:"2 ( 1.00 )"}
            ],
            culture:[
                {format:'en-AU', display:"Australia"},
                {format:'en-CA', display:"Canada"},
                {format:'en-GB', display:"United Kingdom"},
                {format:'en-US', display:"United States of America"}
            ]
        };

        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.reset = _reset;

        _init();

        function _init(){
            workspaceSettingsFactory.getSettings('currencyDecimalPrecision,currencyCulture').then(function(settingsModel){
                $scope.currencyMaster = angular.copy(settingsModel);
                $scope.currencyUser = angular.copy($scope.currencyMaster);
            });
        }

        function _isUnchanged(user) {
            return angular.equals(user, $scope.currencyMaster);
        }

        function _save(user){
            workspaceSettingsFactory.putSettings({settings:user}).then(function(){
                $location.url('/settings');
            });
        }

        function _reset(){
            $location.replace().url('/settings');
        }
    })
;