angular.module('cerberus.core')
    .controller('ListUploadModalCtrl', function (_, $scope, $rootScope, $timeout, $q, $uibModalInstance, defaultData, listPageObject, draftsService, InstancesService, WidgetsService) {
        var vm = this;

        vm.widgetId = listPageObject.viz.widgetId;
        vm.filenameField = listPageObject.params.uploadFilename;
        vm.uploadImageField = listPageObject.params.uploadImageField;
        vm.formId = '';
        vm.formOriginId = '';
        vm.isProcessingUploads = false;
        vm.files = [];
        vm.draftIds = {};
        vm.recordData = {};
        vm.fileUploadOptions = {
            async:{
                saveUrl: '/Server/REST/v1/upload',
                removeUrl: 'remove',
                autoUpload: false,
                batch: false
            },
            localization: {
                dropFilesHere: 'Drop files here to start the upload.'
            },
            select: function (e) {
                vm.isProcessingUploads = true;
                e.sender.disable();
                var draftPromises = [];
                vm.files = e.files;
                _.forEach(e.files, function (file) {
                    var recordData = angular.copy(vm.defaultDataSet);

                    if (vm.filenameField) {
                        recordData[vm.filenameField.modelId] = file.name;
                    }

                    var d = {
                        widgetId: vm.widgetId,
                        recordData: recordData,
                        stateId: 1
                    };

                    vm.recordData[file.uid] = recordData;

                    var promise = draftsService.create(d);
                    
                    promise.then(function (value) {
                        vm.draftIds[file.uid] = value.draftId;
                    });

                    draftPromises.push(promise);
                });

                $q.all(draftPromises).then(function () {
                    e.sender.enable();
                    angular.element(".k-button.k-upload-selected").trigger('click');
                });
            },
            upload: function (e) {
                var file = e.files[0];
                e.data = {
                    formOriginId: vm.formOriginId,
                    formId: vm.formId,
                    widgetId: vm.widgetId
                };
                e.data.draftId = vm.draftIds[file.uid];

                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1 /* OPENED */) {
                            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                            xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            },
            success: function (e) {
                var itemData = e.response.DATA;
                var file = _.find(vm.files, 'name', itemData.name);
                if (file) {
                    var draftId = vm.draftIds[file.uid];
                    $timeout(function () {
                        // var form = vm.instance.workflowObjects[vm.activeState].model.forms[vm.selectedForm[vm.activeState]];
                        // form.fileAttachments = form.fileAttachments || [];
                        // itemData.disableClick = true;
                        // form.fileAttachments.push(itemData);
                        // if(!vm.temp[vm.activeState].nimupload){
                        //     vm.temp[vm.activeState].nimupload = [];
                        // }
                        vm.recordData[file.uid].nimupload.push(itemData.id + ',create');
                        if (vm.uploadImageField) {
                            vm.recordData[file.uid][vm.uploadImageField.modelId] = itemData.id;
                        }

                        InstancesService.createInstance({
                            widgetId: vm.widgetId,
                            draftId: draftId,
                            recordData: vm.recordData[file.uid],
                            current: vm.nextWorkflowState,
                            // recurrence: vm.instance.recurrence,
                            // ignoreSections: _sectionsIgnored(vm.instance.workflowObjects[state].model.forms, state)
                        });
                    });
                }    
            },
            complete: function () {
                $timeout(function () {
                    vm.isProcessingUploads = false;
                    $(".k-upload-files.k-reset").find("li").remove();
                    ok();
                });
            },
            cancel: function(){
                $timeout(function(){
                    vm.isProcessingUploads = false;
                });
            },
            error: function(){
                $timeout(function(){
                    vm.isProcessingUploads = false;
                });
            }
        };
        
        vm.ok = ok;
        vm.cancel = cancel;

        init();

        function init() {
            WidgetsService.getFirstState(vm.widgetId).then(function (data) { 
                var defaultDataSet = {};
                _.forEach(data.workflowObjects[1].model.forms, function (f) {
                    _.assign(defaultDataSet, f.defaultDataSet);

                    if (!vm.formId && _.get(f, 'form.settings.nimupload.enabled', false)) {
                        vm.formId = f.formId;
                        vm.formOriginId = f.originatorId;
                    }
                });

                if (defaultData) {
                    _.forEach(defaultData, function (item) {
                        var value = _.get(item, 'data.value');

                        if(item.field.isLookup || item.field.isCustomLookup){
                            value = {id: _.get(item, 'data.value')};

                            if(_.has(item, 'data.lookupDisplay')){
                                value.display = item.data.lookupDisplay;
                            }
                            // For table filters
                            else if(_.has(item, 'data.dataItem')){
                                value.display = _.get(item.data.dataItem, item.field.lookupCol);
                            }
                        }

                        defaultDataSet[item.field.modelId] = value;
                    });
                }

                vm.defaultDataSet = defaultDataSet;
                vm.nextWorkflowState = _.get(data.workflowObjects[1].model.to, 0, '');
            }, function () {
                vm.defaultDataSet = {};
            });
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    })
;