angular.module('cerberus.account')
    .controller('UsersApiKeysCtrl', function UsersApiKeysCtrl($scope, usersApiKeysService) {
        $scope.activeRoute = 'api-keys';
        $scope.userApiKey = [];
        $scope.apiName = null;
        $scope.hosts = null;
        $scope.showCreateRow = false;
        $scope.createdApiKey = null;
        $scope.editItem = null;

        $scope.submit = _submit;
        $scope.removeKey = _removeKey;
        $scope.saveApiKey = _saveApiKey;
        $scope.editApiKey = _editApiKey;
        $scope.cancelEdit = _cancelEdit;

        _init();

        function _init(){
            usersApiKeysService.get().then(function(userApiKeys){
                $scope.userApiKey = userApiKeys;
            });
        }

        function _submit(name, hosts){
            usersApiKeysService.create({name:name, hosts:hosts}).then(function(value){
                $scope.apiName = null;
                $scope.hosts = null;
                $scope.showCreateRow = false;
                $scope.createdApiKey = value.key;
                $scope.userApiKey.push({
                    id: value.id,
                    name: value.name,
                    hosts: value.hosts
                });
            });
        }

        function _removeKey(id,index){
            usersApiKeysService.remove(id).then(function(){
                $scope.userApiKey.splice(index, 1);
            });
        }

        function _saveApiKey(id, name, hosts, idx){
            usersApiKeysService.update(id, {name:name, hosts:hosts}).then(function(){
                $scope.userApiKey[idx].name = name;
                $scope.userApiKey[idx].hosts = hosts;
                _cancelEdit();
            });
        }

        function _editApiKey(index, name, hosts){
            $scope.editItem = index;
            $scope.apiName = name;
            $scope.hosts = hosts;
        }

        function _cancelEdit(){
            $scope.editItem = null;
            $scope.apiName = null;
        }
    })
;