angular.module('cerberus.admin')
    .controller('WorkspacesUsersDetailsCtrl', function WorkspacesUsersDetailsCtrl(_, kendo, $location, userId, UsersService, usersProfileService,
                                                                                  WorkspacesGroupsService, WorkspacesUserService) {
        var vm = this;

        vm.userInfo = null;
        vm.userId = userId;
        vm.tempGroupModel = {
            details: null,
            members: {current:[]},
            memberOf:{
                current:[],
                add:[],
                remove:[],
                input:[]
            }
        };
        vm.origGroupModel = angular.copy(vm.tempGroupModel);
        vm.selectedMemberOf = {};
        vm.availableMemberOfDs = null;
        vm.memberOfSelectOptions = {
            placeholder: 'Enter group(s) here...',
            dataTextField: 'name'
        };
        vm.add = WorkspacesGroupsService.addItem;
        vm.remove = WorkspacesGroupsService.removeItem;
        vm.isUnchanged = function(){
            return WorkspacesGroupsService.isUnchanged(vm.tempGroupModel,vm.origGroupModel);
        };
        vm.submit = function(isValid,data){
            if(isValid){
                if(!angular.equals(data.memberOf.current, vm.origGroupModel.memberOf.current)){
                    UsersService.updateMemberOf(vm.userId,{add:data.memberOf.add, remove:data.memberOf.remove}).then(function(){
                        $location.url('/settings/permissions');
                    });
                }
            }
        };

        vm.cancel = function(){
            $location.url('settings/permissions');
        };

        init();

        function init(){
            // Gets list of users in case user has no profile
            WorkspacesUserService.getUsers().then(function(workspaceUsers){
                var user = _.find(workspaceUsers, 'id', userId);

                if(!vm.userInfo && user){
                    vm.userInfo = {
                        firstName: user.firstName,
                        middleName: user.middleName,
                        lastName: user.lastName,
                        displayEmail: user.email
                    };
                }
            });

            // Gets user profile to display user data
            usersProfileService.getDefaultUserProfile(userId).then(function(userProfile) {
                if (userProfile[0] && userProfile[0].data) {
                    vm.userInfo = userProfile[0].data;
                }
            });

            WorkspacesUserService.getMemberOf(userId).then(function(memberOf){
                vm.tempGroupModel.memberOf.current = memberOf;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });
            WorkspacesUserService.getAvailableMemberOf(userId).then(function(availableMemberOf){
                vm.availableMemberOfDs = new kendo.data.ObservableArray(availableMemberOf);
            });
        }
    })
;