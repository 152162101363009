angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name pageObjectConfigService
 */
    .factory('pageObjectConfigService', function pageObjectConfigService(_, $compile, $sanitize, $timeout, $http, toaster, DesignerUtilityService, ViewsService, WidgetsSettingsService) {
        return {
            deleteProperty: deleteProperty,
            textEditorOptions: textEditorOptions,
            filterOperators: filterOperators,
            chartLabelFormats: chartLabelFormats,
            createSortableOptions: createSortableOptions,
            reorderItem: reorderItem,
            notPropertyColumnFilter: notPropertyColumnFilter,
            addColorToSeries: addColorToSeries,
            onViewChange: onViewChange,
            loadColumns: loadColumns,
            selectColumn: selectColumn,
            addColumn: addColumn,
            removeColumn: removeColumn,
            selectAllColumns: selectAllColumns,
            removeAllColumns: removeAllColumns,
            colIsSelected: colIsSelected,
            allColSelected: allColSelected,
            setBaseWidget: setBaseWidget,
            loadFormFields: loadFormFields,
            getFilterType: getFilterType,
            addDSFilter: addDSFilter,
            addToFilterMap: addToFilterMap,
            removeFromFilterMap: removeFromFilterMap,
            savePageObjConfig: savePageObjConfig,
            fillCSSArray: fillCSSArray,
            updateSortConfig: updateSortConfig,
            addSortItem: addSortItem,
            loadCategories: loadCategories
        };

        ////////////////////////////////////////////////////////////////////////////

        /**
         * Deletes a property from an object; useful when removing a configuration
         * @param {object} obj
         * @param {string} prop
         */
        function deleteProperty(obj, prop) {
            if(_.has(obj, prop)) {
                delete obj[prop];
            }
        }
        function textEditorOptions(scope) {
            return {
                encoded: false,
                tools: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "justifyLeft",
                    "justifyCenter",
                    "justifyRight",
                    "justifyFull",
                    "insertUnorderedList",
                    "insertOrderedList",
                    "indent",
                    "outdent",
                    "createLink",
                    "unlink",
                    "insertImage",
                    "subscript",
                    "superscript",
                    "createTable",
                    "addRowAbove",
                    "addRowBelow",
                    "addColumnLeft",
                    "addColumnRight",
                    "deleteRow",
                    "deleteColumn",
                    //"viewHtml",
                    {
                        name: 'nimViewHtml',
                        template: '<a href="" role="button" class="k-tool k-group-start k-group-end" unselectable="on" title="View HTML"><i class="fa fa-code"></i></a>',
                        exec: function(e){
                            e.preventDefault();
                            var editor = $(e.currentTarget).closest('.k-editor');
                            var textarea = editor.find('textarea');
                            var id = textarea.attr('id');
                            var model = textarea.attr('ng-model');
                            var newId = 'nim_' + id + '_html';
                            var htmlInput = $('#' + newId);
                            if(id && scope){
                                if(htmlInput.length === 0){
                                    var html = '<textarea id="' + newId + '" class="form-control" rows="10" ng-model="' + model + '" ng-model-options="{debounce: {default: 500}}"></textarea>';
                                    editor.after($compile(html)(scope));
                                }
                                else{
                                    htmlInput.remove();
                                }
                            }
                        }
                    },
                    "formatting",
                    "cleanFormatting",
                    "fontName",
                    "fontSize",
                    "foreColor",
                    "backColor"
                ],
                paste: function (e) {
                    e.html = $sanitize(e.html);
                }
            };
        }

        /**
         * Lists possible operators by input type for filter settings
         * @returns {Object}
         */
        function filterOperators() {
            return {
                string: [
                    {value: 'contains', display: 'Contains'},
                    {value: 'doesnotcontain', display: 'Does not contain'},
                    {value: 'eq', display: 'Equal to'},
                    {value: 'neq', display: 'Not equal to'},
                    {value: 'startswith', display: 'Starts with'},
                    {value: 'endswith', display: 'Ends with'}
                ],
                number: [
                    {value: 'eq', display: 'Equal to'},
                    {value: 'gt', display: 'Greater'},
                    {value: 'gte', display: 'Greater or equal to'},
                    {value: 'lt', display: 'Less'},
                    {value: 'lte', display: 'Less or equal to'},
                    {value: 'neq', display: 'Not equal to'}
                ],
                date: [
                    {value: 'eq', display: 'Equal to'},
                    {value: 'gt', display: 'Greater'},
                    {value: 'gte', display: 'Greater or equal to'},
                    {value: 'lt', display: 'Less'},
                    {value: 'lte', display: 'Less or equal to'},
                    {value: 'neq', display: 'Not equal to'}
                ],
                bigint: [
                    {value: 'eq', display: 'Equal to'},
                    {value: 'gt', display: 'Greater'},
                    {value: 'gte', display: 'Greater or equal to'},
                    {value: 'lt', display: 'Less'},
                    {value: 'lte', display: 'Less or equal to'},
                    {value: 'neq', display: 'Not equal to'}
                ]
            };
        }

        /**
         * Returns array of possible formats for viz chart labels
         * @returns {Array}
         */
        function chartLabelFormats(){
            return [
                { value: '{0}',     display: 'Default' },
                { value: '{0:n0}',  display: 'Integer' },
                { value: '{0:n2}',  display: 'Decimal' },
                { value: '{0:c}',   display: 'Currency' },
                { value: '{0:c0}',  display: 'Currency, no decimal' },
                { value: '{0:p}',   display: 'Percent' },
                { value: '{0:p0}',  display: 'Percent, no decimal' }
            ];
        }

        /**
         * Creates options for kendo sortable widget
         * @param columnArray - Column array (or map of arrays) to reorder
         * @param controller - for multi-data-source objects only
         * @returns {Object} - Kendo sortable options
         */
        function createSortableOptions(columnArray, controller){
            return {
                ignore: "input,select",
                change: function (e) {
                    $timeout(function () {
                        if(_.isArray(columnArray)){
                            reorderItem(columnArray, e.oldIndex, e.newIndex);
                        }
                        else if(controller){
                            // key of column array to reorder
                            var id = controller.editDataSource;

                            reorderItem(columnArray[id], e.oldIndex, e.newIndex);
                        }
                    },0);
                }
            };
        }

        /**
         * Helper function that moves array item to new index
         * @param array
         * @param oldIndex
         * @param newIndex
         */
        function reorderItem(array, oldIndex, newIndex){
            var item = array.splice(oldIndex, 1)[0];
            array.splice(newIndex, 0, item);
        }

        /**
         * Filter for ngRepeat that skips columns of type "property"
         * @param column - from viewColumns
         * @returns {boolean}
         */
        function notPropertyColumnFilter(column){
            return column.type !== 'property';
        }

        /**
         * Adds a color to a chart page object's series colors array
         * @param pageObj
         * @param color
         */
        function addColorToSeries(pageObj, color){
            _.defaults(pageObj.viz.settings, {seriesColors: []});

            if(pageObj.viz.settings.seriesColors.indexOf(color) < 0) {
                pageObj.viz.settings.seriesColors.push(color);
            }
        }

        /**
         * Loads view columns and sets dataSource view ID
         * @param controller
         * @param onRemove
         * @param dataSource
         * @param vizColumns
         * @param viewId
         */
        function onViewChange(controller, onRemove, dataSource, vizColumns, viewId){
            loadColumns(controller, viewId);                            // loads view columns
            dataSource.nim_viewId = viewId;                             // Source query
            removeAllColumns(dataSource, vizColumns, onRemove);
        }

        /**
         * Loads view columns and adds them to controller
         * @param controller
         * @param viewId
         */
        function loadColumns(controller, viewId){
            if(viewId){
                ViewsService.getColumns(viewId).then(function(columns){
                    controller.viewColumns = columns;
                });
            }
        }

        /**
         * Adds column to viz columns array and data source schema
         * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
         * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
         * @param column - from viewColumns array
         */
        function addColumn(dataSource, vizColumns, column){
            var field = column.colName;
            var type = column.castType;

            var settingsColumn = {
                field: field,
                title: column.name,
                type: type,
                attributes: {},
                format: column.format || '',
                aggregates: [],
                footerTemplate: ''
            };

            if(column.type === 'property'){
                settingsColumn.nim_propertyId = column.propId;
            }

            // For displaying locations on tables
            if(column.type === 'location'){
                settingsColumn.nim_locationProperty = 'formatted_address';
            }

            vizColumns.push(settingsColumn);

            dataSource.schema.model.fields[field] = {
                type: type,
                from: field
            };
        }

        /**
         * Removes column from viz columns array and data source schema
         * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
         * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
         * @param colIndex
         * @param colField
         */
        function removeColumn(dataSource, vizColumns, colIndex, colField){
            if(colIndex >= 0){
                vizColumns.splice(colIndex, 1);
                delete dataSource.schema.model.fields[colField];
            }
        }

        /**
         * Creates function to handle toggling of columns in page object.
         * Uses controller-specific functions to handle additional
         * functionality on addition/removal of columns.
         * @param onAdd - callback for addition of column
         * @param onRemove - callback for removal of column
         * @returns {Function}
         */
        function selectColumn(onAdd, onRemove){
            /**
             * Adds a view column to viz columns or removes it if already present
             * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
             * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
             * @param column - from viewColumns array
             */
            return function (dataSource, vizColumns, column){
                var field = column.colName;
                var index = _.findIndex(vizColumns, 'field', field);

                if(index >= 0) {
                    // If column is already selected, remove it
                    removeColumn(dataSource, vizColumns, index, field);

                    // Controller-specific handler
                    if(_.isFunction(onRemove)){
                        onRemove(field);
                    }
                }
                else {
                    // Else, add it to viz columns and dataSource
                    addColumn(dataSource, vizColumns, column);

                    // Controller-specific handler
                    if(_.isFunction(onAdd)){
                        onAdd(field);
                    }
                }
            };
        }

        /**
         * Creates function to handle toggling of all columns in page object.
         * Uses controller-specific functions to handle additional
         * functionality on addition/removal of individual columns.
         * @param onAdd - callback for addition of column
         * @param onRemove - callback for removal of column
         * @returns {Function}
         */
        function selectAllColumns(onAdd, onRemove){
            /**
             * Selects (or deselects) all view columns
             * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
             * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
             * @param viewColumns
             */
            return function (dataSource, vizColumns, viewColumns){
                if(allColSelected(vizColumns, viewColumns)) {
                    // If all columns selected, deselect them
                    removeAllColumns(dataSource, vizColumns, onRemove);
                }
                else{
                    // Otherwise, add columns not already selected
                    for(var j = 0; j < viewColumns.length; j++){
                        var column = viewColumns[j];
                        if(!colIsSelected(vizColumns, column)){
                            addColumn(dataSource, vizColumns, column);

                            // Controller-specific handler
                            if(_.isFunction(onAdd)){
                                onAdd(column.colName);
                            }
                        }
                    }
                }
            };
        }

        /**
         * Function that handles removal of all selected columns.
         * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
         * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
         * @param onRemove - callback for removal of column
         */
        function removeAllColumns(dataSource, vizColumns, onRemove){
            for(var i = vizColumns.length - 1; i >= 0; i--) {
                var colField = vizColumns[i].field;

                removeColumn(dataSource, vizColumns, i, colField);

                // Controller-specific handler
                if(_.isFunction(onRemove)){
                    onRemove(colField);
                }
            }
        }

        /**
         * Checks if a view column has been added to a page object's columns
         * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
         * @param column - from viewColumns array
         * @returns {boolean}
         */
        function colIsSelected(vizColumns, column){
            return _.some(vizColumns, 'field', column.colName);
        }

        /**
         * Checks if all viewColumns have been selected
         * @param vizColumns - viz.settings.columns (or viz.settings.columns[id])
         * @param viewColumns
         * @returns {boolean}
         */
        function allColSelected(vizColumns, viewColumns){
            if(!_.isEmpty(viewColumns)) {
                return _.every(viewColumns, function(viewCol){
                    return colIsSelected(vizColumns, viewCol);
                });
            }
            else {
                return false;
            }
        }

        /**
         * Sets the page object's base widget and loads form fields
         * @param controller
         * @param pageObj
         * @param viewId - Data source's nim_viewId
         * @param skipLoad - (Optional) If true, do not load form fields
         */
        function setBaseWidget(controller, pageObj, viewId, skipLoad){
            var selectedView = _.find(controller.views, 'id', viewId);
            pageObj.viz.widgetId = _.get(selectedView, 'base_widget_id');

            if(!skipLoad){
                loadFormFields(controller, pageObj.viz.widgetId);
            }
        }

        /**
         * Loads form objects for selected widget and adds them to controller
         * @param controller
         * @param widgetId
         */
        function loadFormFields(controller, widgetId){
            if(widgetId) {
                WidgetsSettingsService.getFormFields(widgetId).then(function (data) {
                    controller.formFieldsArray = WidgetsSettingsService.parseFormObjects(data.display);
                });
            }
        }

        /**
         * Checks viewColumns to get filter field's castType
         * @param viewColumns
         * @param filter - temporary filter object
         * @param oldFilterType
         * @returns {string} - "string", "number", "date"
         */
        function getFilterType(viewColumns, filter, oldFilterType){
            var filterType = '';

            if(filter){
                if(filter.field){
                    var index = _.findIndex(viewColumns, 'colName', filter.field);
                    if(index >= 0){
                        filterType = viewColumns[index].castType;

                        // Don't allow filter to keep value of different type
                        if(filterType !== oldFilterType){
                            filter.value = null;
                        }
                    }
                    else {
                        // Remove value if field is invalid
                        filter.value = null;
                    }
                }
                else{
                    _.assign(filter, {
                        operator: null,
                        value: null
                    });
                }
            }

            return filterType;
        }

        /**
         * Adds filter to dataSource and clears temporary filter object
         * @param dataSource - viz.settings.dataSource (or viz.settings.dataSource[id])
         * @param filter - temporary filter object
         * @param filterType
         */
        function addDSFilter(dataSource, filter, filterType){
            if(filterType === 'date' && !filter.value){
                filter.value = null;
            }

            _.defaults(dataSource, { filter: { filters: [], logic: 'and' } });

            dataSource.filter.filters.push(angular.copy(filter));

            // Empties temporary filter
            _.assign(filter, {
                field: null,
                operator: null,
                value: null,
                isDateValue: false,
                isColumnValue: false
            });
        }

        /**
         * Adds filter configuration to page object's filterMap
         * @param pageObj
         * @param filter - temporary filter object
         * @param id - dataSource id
         */
        function addToFilterMap(pageObj, filter, id) {
            if (filter.key) {
                var filterMap = pageObj.filterMap,
                    requiredFilters = pageObj.params.requiredFilters;
                    
                if (id) {
                    filterMap = filterMap[id];
                    requiredFilters = requiredFilters[id];
                }

                filterMap[filter.key] = filter.value;

                // For fields with recurrence enabled (table and calendar only)
                if(filter.repeat){
                    pageObj.params.repeatEnabled = filter.key;
                    filter.repeat = false;
                }

                // For tables, this marks filters that are required to show data
                if (filter.required) {
                    requiredFilters[filter.key] = true;
                    filter.required = false;
                }

                // Resets temporary filter
                filter.key = null;
                filter.value = null;
            }
        }

        /**
         * Removes filter from map and resets parameters that rely on it
         * @param pageObj
         * @param key - filter key
         * @param id - dataSource ID
         */
        function removeFromFilterMap(pageObj, key, id) {
            var filterMap = pageObj.filterMap,
                requiredFilters = pageObj.params.requiredFilters;
                
            if (id) {
                filterMap = filterMap[id];
                requiredFilters = requiredFilters[id];
            }

            delete filterMap[key];

            // Resets/Removes parameters that rely on this filter
            if(pageObj.params.repeatEnabled === key){
                delete pageObj.params.repeatEnabled;
            }

            if(!_.isEmpty(requiredFilters)){
                delete requiredFilters[key];
            }
        }

        /**
         * Creates handler for save button
         * @param modal - config modal instance
         * @param margin - margin configuration array
         * @param padding - padding configuration array
         * @returns {Function} - ngClick handler
         */
        function savePageObjConfig(modal, margin, padding) {
            /**
             * If the form is not invalid, saves the page object configuration
             * @param pageObj - page object configuration
             * @param invalid - true if modal form is invalid
             */
            return function(pageObj, invalid) {
                if(invalid){
                    toaster.pop('error', 'Form invalid', '');
                }
                else {
                    if (pageObj.wrapper.type === 'circletile') {
                        pageObj.wrapper.header.style.background = pageObj.wrapper.body.style.background;
                    }

                    // Converts the margin/padding arrays to CSS strings
                    pageObj.wrapper.style.margin = DesignerUtilityService.arrayToCss(margin);
                    pageObj.wrapper.style.padding = DesignerUtilityService.arrayToCss(padding);

                    modal.close(pageObj);
                }
            };
        }

        /**
         * Creates change handler for padding and margin numeric text-boxes
         * @param cssArray - array to be filled
         * @returns {Function}
         */
        function fillCSSArray(cssArray){
            // Kendo change handler
            return function(e){
                var value = _.result(e, 'sender.value', 0);
                _.fill(cssArray, parseInt(value));
            };
        }

        /**
         * Updates dataSource's sort configuration to be an array instead of an object
         * @param dataSource
         */
        function updateSortConfig(dataSource){
            if(!_.isArray(dataSource.sort)) {
                if (!dataSource.sort || !dataSource.sort.field) {
                    dataSource.sort = [];
                }
                else {
                    dataSource.sort = [dataSource.sort];
                }
            }
        }

        /**
         * Adds sort configuration object to array
         * @param sortArray
         * @param sortObj
         */
        function addSortItem(sortArray, sortObj){
            sortArray.push({
                field: sortObj.field,
                dir: sortObj.dir || 'desc'
            });

            // Reset temporary object
            _.assign(sortObj, {
                field: '',
                dir: 'desc'
            });
        }

        function loadCategories(vm, categoryField){
            if(categoryField.isLookup){
                $http.get('/server/rest/v1/views/' + categoryField.viewId + '/odata.svc?$lookupDisplay=' + categoryField.lookupCol)
                    .then(function(result){
                        var d = _.get(result, 'data.d');
                        if(d.__count === 1){
                            vm.categories = [d];
                        }
                        else {
                            vm.categories = d.results;
                        }
                    });
            }
            else if(categoryField.isCustomLookup){
                $http.get('/server/rest/v1/forms/' + categoryField.formId + '/objects/' + categoryField.modelId + '/options')
                    .then(function(result){
                        vm.categories = _.get(result, 'data.DATA.values', []);
                    });
            }
            else {
                vm.categories = [];
            }
        }
    })
;
