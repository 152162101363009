angular.module('cerberus.admin')
    .config(function($routeProvider){
        $routeProvider.when('/settings/page-objects', {
            templateUrl: 'admin/page-objects/page-objects.tpl.html',
            controller: 'PageObjectsCtrl',
            controllerAs: 'vm',
            title: 'Page Objects',
            reloadOnSearch: false,
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/page-objects/:id', {
            templateUrl: 'admin/page-objects/page-objects-designer.tpl.html',
            controller: 'PageObjectsDesignerCtrl',
            controllerAs: 'vm',
            title: 'Page Object Designer',
            reloadOnSearch: false,
            resolve: {
                _wait: 'WaitForUserData',
                pageObjectId: function($route){
                    return $route.current.params.id;
                }
            }
        });
    })
;