angular.module('cerberus.account')
    .controller('UsersPasswordCtrl', function UsersPasswordCtrl(_, $scope, AuthPasswordService) {
            $scope.activeRoute = 'account';
            $scope.result = {};
            $scope.data = {};
            $scope.confirmPassword = null;
            $scope.submit = function(isValid, data){
                $scope.result.visible = false;
                if (isValid) {
                    AuthPasswordService.changePassword(data).then(function () {
                        $scope.form.$setPristine();
                        $scope.form.$setUntouched();
                        $scope.data = {};
                        $scope.confirmPassword = null;
                        $scope.result.visible = true;
                        $scope.result.class = 'success';
                        $scope.result.message = "Password Changed";
                    }, function (reason) {
                        $scope.data.oldPassword = null;
                        $scope.result.visible = true;
                        $scope.result.class = 'danger';
                        $scope.result.message = _.get(reason, 'MESSAGE') || "Error please try again";
                    });
                }
            };
        })
;