angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizProgressService
     * @alias cerberus/core:VizProgressService
     * @description Provides functions for configuring progress bars
     */
    .factory('VizProgressService', function VizProgressService(_, kendo, $http, $timeout, VizUtilityService){
        return {
            buildDataSource: buildDataSource
        };

        function buildDataSource(pageObject, scope, progressOptions) {
            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                requestStart: function (e){
                    if(!_.isEmpty(pageObject.params.requiredFilters)){
                        _.forEach(pageObject.params.requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                },
                change: function(e){
                    var field = '';
                    _.forEach(e.sender.aggregate(), function (aggregate) {
                        if (aggregate.aggregate == 'sum') {
                            field = aggregate.field;
                            return false;
                        }
                    });
                    
                    var value = _.get(e.sender.aggregates(), [field, 'sum'], 0);

                    scope.mainPageObject.vizCtrl.data = e.sender.data();
                    scope.mainPageObject.vizCtrl.value = value;
                    scope.mainPageObject.vizCtrl.percent = calculatePercentage(value, progressOptions.min, progressOptions.max);

                    if (scope.progress) {
                        scope.progress.value(value);
                    }
                    else {
                        progressOptions.value = value;
                    }
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(progressOptions.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                    filters: [],
                    logic: 'and'
                };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            if(progressOptions && progressOptions.dataSource) {
                angular.extend(dataSourceOptions, progressOptions.dataSource, sortOptions, filterOptions);
            }

            // Adds parse function to make sure date fields are correct

            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if(field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        function calculatePercentage(value, min, max) {
            return parseInt(100 * (value - min) / (max - min));
        }
    })
;