angular.module('cerberus.ui')
    .controller('nimSignatureModalCtrl', function ($scope, $uibModalInstance, nimSignatureService, isInitial, restrictTo, penColor) {
        var vm = this;
        // Settings
        vm.isInitial = isInitial;
        vm.restrictTo = restrictTo;
        vm.penColor = penColor;
        // Data Container
        vm.signatureBase64 = '';
        // UI feedback
        vm.signMsg = isInitial ? 'Initial above' : 'Sign above';
        vm.signClass= 'text-muted';
        // Form Actions
        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.submit = function(isEmpty, withAccount) {
            if (isEmpty === true) {
                vm.signClass = 'text-danger';
                vm.signMsg = 'Please sign above before proceeding';
                return;
            }
            if (withAccount) {
                nimSignatureService.getSignatureWithAccount().then(success, error);
            }
            else {
                nimSignatureService.saveImage(vm.signatureBase64).then(success, error);
            }
        };
        ////////////////////
        // Promise Handlers
        function success(data) {
            var response = {
                timestamp: new Date()
            };
            angular.extend(response, data);
            $uibModalInstance.close(response);
        }
        function error() {
            vm.signClass = 'text-warning';
            vm.signMsg = 'Something went wrong; please try again';
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;