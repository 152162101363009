angular.module('cerberus.core')
    .controller('InstancesPageCtrl', function(_, $scope, $location, $timeout, $window, instanceId, FormsService,
                                              InstancesService, InstancesWindowService, nimTitleService){
        var vm = this;

        vm.title = {};
        vm.instanceIds = [];
        vm.forbidden = false;
        vm.instance = {};
        vm.states = {};
        vm.collapse = {};
        vm.dataLoading = {};
        vm.formDoneLoading = {};
        vm.fieldOptions = {};
        vm.dataSet = {};
        vm.filters = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.staticGridsterOptions = {
            pushing: true
        };

        vm.sectionFilter = FormsService.sectionFilter;

        _init();

        function _init(){
            $window.status = 'Loading';

            vm.instanceIds = instanceId.split(',');
            _.forEach(vm.instanceIds, _loadInstance);
        }

        function _loadInstance(id){
            vm.states[id] = [];
            vm.collapse[id] = {};
            vm.dataLoading[id] = {};
            vm.formDoneLoading[id] = {};
            vm.fieldOptions[id] = {};
            vm.dataSet[id] = {};
            vm.filters[id] = {};
            vm.sectionsShown[id] = {};
            vm.fieldsShown[id] = {};

            // Gets the state from the query parameters
            var queryState = $location.search()['state'];

            InstancesService.getInstance(id, queryState).then(function (data) {
                // Populate controller variables
                vm.instance[id] = data;

                if(!vm.title[id]) {
                    vm.title[id] = data.widgetName;
                    nimTitleService.set(data.widgetName);
                }

                vm.states[id] = angular.copy(data.workflowPath).reverse(); // Reverse the copy, not the original

                if(queryState && _.has(data.workflowObjects, queryState)){
                    vm.states[id] = [queryState];
                }
                else if(_.last(data.workflowPath) !== data.currentWorkflowObjectId) {
                    vm.states[id].unshift(data.currentWorkflowObjectId);
                }

                // Populate form data objects & UI controls
                _.forEach(vm.states[id], function(state, i){
                    vm.collapse[id][state] = false;
                    vm.dataLoading[id][state] = true;
                    vm.formDoneLoading[id][state] = {};
                    vm.sectionsShown[id][state] = {};
                    vm.fieldsShown[id][state] = {};

                    var formChunks = _.chunk(_.get(data.workflowObjects[state], 'model.forms', []));

                    _.forEach(formChunks, function(formArray, j){
                        vm.formDoneLoading[id][state][j] = false;
                        InstancesService.getFormData(id, formArray).then(_formDataCallBack(id, i, j));
                    });
                });

            }, function(response){
                vm.forbidden = response === 'Forbidden';
            });
        }

        function _formDataCallBack(id, stateIndex, formIndex){
            return function(result){
                var formData = result.formData,
                    instance = vm.instance[id],
                    state = vm.states[id][stateIndex],
                    dataLoading = vm.dataLoading[id],
                    formDoneLoading = vm.formDoneLoading[id][state];

                vm.sectionsShown[id][state][formData[0].originatorId] = {};

                formDoneLoading[formIndex] = true;
                dataLoading[state] = !_.every(formDoneLoading);
                updateWindowStatus();

                if(instance.workflowPath[instance.workflowPath.length - 1] !== instance.currentWorkflowObjectId){
                    formData[0] = FormsService.processFormData(formData[0], formData[0].defaultDataSet);
                }

                InstancesWindowService.populateDataAndParseFormulas(state, formData, vm.dataSet[id], vm.fieldOptions[id], angular.noop, angular.noop);
                _.assign(instance.workflowObjects[state].model.forms[formIndex], formData[0]);

                _.forEach(instance.workflowObjects[state].model.forms[formIndex].form.sections, function(section){
                    if(!_.isEmpty(section.condition)) {
                        vm.sectionsShown[id][state][formData[0].originatorId][section.name] =
                            FormsService.sectionCondition(section.condition, vm.dataSet[id][state], section.logic, 'read');
                    }
                    else {
                        vm.sectionsShown[id][state][formData[0].originatorId][section.name] = true;
                    }
                });

                // TODO: set fieldsShown for each form object
                _.forEach(instance.workflowObjects[state].model.forms[formIndex].form.objects, function(formObj){
                    var modelId = formObj.model.config.modelId,
                        section = formObj.model.display.section,
                        params = formObj.model.param;

                    if(vm.sectionsShown[id][state][formData[0].originatorId][section] && !_.isEmpty(params.condition) && params.conditionalAction !== 'active' && params.conditionalAction !== 'readonly'){
                        var result = FormsService.sectionCondition(params.condition, vm.dataSet[id][state], params.conditionalLogic, 'read');

                        if(params.conditionalAction === 'hide'){
                            vm.fieldsShown[id][state][modelId] = !result;
                        }
                        else {
                            vm.fieldsShown[id][state][modelId] = result;
                        }
                    }
                    else {
                        vm.fieldsShown[id][state][modelId] = true;
                    }
                });

                if(instance.workflowObjects[state].model.defaultData) {
                    InstancesWindowService.parseDefaultData(instance.workflowObjects, state, instance.instanceWorkflow);
                }

                //if(!_.some(dataLoading)){
                //    var displayVal = getRecordDisplayValue(vm.dataSet[id], vm.instance[id].widgetSettings.view.column);
                //    if(displayVal) {
                //        if(_.has(displayVal, 'display')) {
                //            displayVal = displayVal.display;
                //        }
                //        //vm.title[id] = vm.instance[id].widgetName + ': ' + displayVal;
                //    }
                //}
            };
        }

        //function getRecordDisplayValue(dataSet, column){
        //    var flatData = {};
        //    var modelId = column.split('_')[1];
        //
        //    _.forEach(dataSet, function(state){
        //        _.assign(flatData, state);
        //    });
        //
        //    return flatData[modelId];
        //}

        function updateWindowStatus(){
            if(!_.isEmpty(vm.dataLoading) && !_.some(vm.dataLoading, function(loading){ return _.some(loading); })){
                $timeout(function(){
                    $window.status = "Complete";
                }, 1000);
            }
        }
    })
;