angular.module('cerberus.account')
    .controller('OrganizationsMailServersCtrl', function OrganizationsMailServersCtrl($scope, $location, orgId, OrganizationsService) {
        $scope.pageTitle = 'Mail Servers';
        $scope.activeRoute = 'mail-servers';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.servers = [];
        $scope.predicate1 = '';

        $scope.openMailServer = _openMailServer;
        $scope.deleteMailServer = _deleteMailServer;

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            OrganizationsService.getAllAssets(orgId, 'Mail Server').then(function(servers){
                $scope.servers = servers;
            });
        }

        function _openMailServer(id){
            if(id){
                $location.url('/organizations/' + orgId + '/settings/mail-servers/' + id);
            }
            else {
                $location.url('/organizations/' + orgId + '/settings/mail-servers/create');
            }
        }

        function _deleteMailServer(id, index){
            OrganizationsService.deleteAsset(orgId, id).then(function(){
                $scope.servers.splice(index, 1);
            });
        }
    })
;