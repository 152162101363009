angular.module('cerberus.account')
    .factory('UsersSecurityService', function UsersSecurityService(_, kendo, $http, $q, apiPath, toaster) {
            return {
                getAll: function () {
                    var deferred = $q.defer();
                    $http.get(apiPath + 'users/tokens')
                        .success(function (value) {
                            var userTokens = value.DATA;

                            _.forEach(userTokens, function (token) {
                                token.last_requested_time = kendo.parseDate(token.last_requested_time, 'MMMM, dd yyyy HH:mm:ss'); 
                            });

                            deferred.resolve(userTokens);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                revoke: function(id){
                    var deferred = $q.defer();
                    $http({method: 'DELETE', url: apiPath + 'users/tokens/' + id})
                        .success(function(value){
                            toaster.pop('info', 'Token has been revoked.');
                            deferred.resolve(value);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
