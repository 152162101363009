angular.module('cerberus.admin')
    .controller('PagesListCtrl', function PagesListCtrl($scope, $location, workspaceId, localStorageService, PagesService){
        var vm = this;

        vm.pages = [];

        vm.openPageDesigner = openPageDesigner;
        vm.openPageDetails = openPageDetails;
        vm.pageFilter = pageFilter;

        init();

        function init(){
            PagesService.getPages().then(function(pages){
                vm.pages = pages;
            });
        }

        function openPageDesigner(id){
            $location.url('/settings/pages/' + id);
        }

        function openPageDetails(){
            $location.url('/settings/pages/create');
        }

        // Getter-Setter function for filter
        function pageFilter(filterText){
            // Checks if value was passed in
            if(arguments.length){
                localStorageService.set('pageFilter.' + workspaceId, filterText);
                return filterText || '';
            }
            else {
                return localStorageService.get('pageFilter.' + workspaceId) || '';
            }
        }
    })
;