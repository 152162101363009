angular.module('cerberus.core')
    .factory('RulesService', function(_, $http, $q, apiPath, toaster){
        var service = {
            addRule: addRule,
            getAvailableRules: getAvailableRules,
            getRule: getRule,
            updateRule: updateRule,
            deleteRule: deleteRule,
            getResult: getResult
        };

        return service;

        function addRule(data){
            var deferred = $q.defer();

            $http.post(apiPath + 'rules', data)
                .success(function(result){
                    toaster.pop('success', 'Rule Added');
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        function getAvailableRules(){
            var deferred = $q.defer();

            $http.get(apiPath + 'rules')
                .success(function(result){
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        function getRule(id){
            var deferred = $q.defer();

            $http.get(apiPath + 'rules/' + id)
                .success(function(result){
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        function updateRule(id, data){
            var deferred = $q.defer();

            $http.put(apiPath + 'rules/' + id, data)
                .success(function(result){
                    toaster.pop('success', 'Rule Saved');
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        function deleteRule(id){
            var deferred = $q.defer();

            $http.delete(apiPath + 'rules/' + id)
                .success(function(result){
                    toaster.pop('info', 'Rule Deleted');
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        function getResult(id, params){
            var deferred = $q.defer();

            $http.post(apiPath + 'rules/' + id + '/result', {params: params})
                .success(function(result){
                    deferred.resolve(result.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });

            return deferred.promise;
        }
    })
;