angular.module('cerberus.account')
    .controller('OrganizationsGroupsCreateCtrl', function OrganizationsGroupsCreateCtrl(
        $scope, $location, OrganizationsService, organizationGroupsService, orgId) {
        var vm = this;

        $scope.activeRoute = 'groups';
        $scope.orgData = {};
        $scope.orgId = orgId;

        vm.tempGroupModel = organizationGroupsService.groupModel();
        vm.origGroupModel = angular.copy(vm.tempGroupModel);

        vm.selectedMembers = {};
        vm.selectedMemberOf = {};
        vm.availableMembersDs = null;
        vm.availableMemberOfDs = null;
        vm.membersSelectOptions = {
            placeholder: 'Enter users/groups here...',
            dataTextField: 'name'
        };
        vm.memberOfSelectOptions = {
            placeholder: 'Enter group(s) here...',
            dataTextField: 'name'
        };

        vm.add = organizationGroupsService.addItem;
        vm.remove = organizationGroupsService.removeItem;
        vm.isUnchanged = organizationGroupsService.isUnchanged;
        vm.submit = function(isValid, data){
            if(isValid){
                organizationGroupsService.createOrgGroup(orgId, data.details).then(function(value){
                    $location.url('organizations/' + orgId + '/settings/groups/' + value);
                });
            }
        };

        vm.cancel = function(){
            $location.url('organizations/' + orgId + '/settings/groups');
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }
    })
;