angular.module('cerberus.account')
    .controller('UsersOrganizationsCtrl', function UsersOrganizationsCtrl($scope, $location, isAdmin, userId, OrganizationsService, usersProfileService) {
        $scope.activeRoute = 'organizations';
        $scope.userOrgs = [];
        $scope.organizations= [];
        $scope.isAdmin = isAdmin;
        $scope.userId = userId;
        $scope.defaultWorkspaces = {};
        $scope.origDefaultWorkspaces = {};

        $scope.goToOrganizationsDetails = _goToOrganizationsDetails;
        $scope.leaveOrganization = _leaveOrganization;
        $scope.hasChanges = _hasChanges;
        $scope.save = _save;

        _init();

        function _init(){
            OrganizationsService.get().then(function(d){
                $scope.userOrgs = angular.copy(d);
                usersProfileService.getAll('Default Workspaces').then(function(defaultWorkspaces){
                    $scope.origDefaultWorkspaces = angular.copy(defaultWorkspaces);
                    $scope.defaultWorkspaces = angular.copy(defaultWorkspaces);
                });
            });
        }

        function _goToOrganizationsDetails(id){
            $location.url('organizations/' + id + '/settings');
        }

        function _leaveOrganization(id){
            OrganizationsService.disableUsers(id, {userIds:[$scope.userId]}).then(function(){
                $location.url('users/settings/organizations');
            });
        }

        function _hasChanges() {
            return !angular.equals($scope.defaultWorkspaces, $scope.origDefaultWorkspaces);
        }

        function _save(){
            usersProfileService.create({data:$scope.defaultWorkspaces, type:'Default Workspaces', isUnique:true}).then(function(){
                $scope.origDefaultWorkspaces = angular.copy($scope.defaultWorkspaces);
            });

            OrganizationsService.get().then(function(userOrgs){
                $scope.userOrgs = userOrgs;
            });
        }

    })
;