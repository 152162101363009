angular.module('cerberus.account')
    /**
     * @ngdoc service
     * @name draftsService
     * @alias cerberus/drafts:draftsService
     * @description Makes calls to the "drafts" resource for user login and information
     */
    .factory('draftsService', function draftsService(_, $http, $q, apiPath, toaster, InstancesWindowService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            create: create,
            getUserDrafts: getUserDrafts,
            get: get,
            update: update,
            modifySelectArray: modifySelectArray,
            selectAll: selectAll,
            deleteDraft: deleteDraft,
            openDraft: openDraft
        };
        ////////////////////
        /**
         * Create new draft
         * @function create
         * @param {object} data
         * @returns {promise}
         */
        function create(data){
            var deferred = $q.defer();
            $http.post(apiPath + 'drafts', data)
                .success(function(value){
                    toaster.pop('success', 'Draft Created');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Gets the current user's drafts (based on token)
         * @function getUserDrafts
         * @returns {promise}
         */
        function getUserDrafts(){
            var deferred = $q.defer();
            $http.get(apiPath + 'drafts')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * delete the drafts
         * @function deleteDraft
         * @returns {promise}
         */
        function deleteDraft(selectedIds){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'drafts/' + selectedIds})
                .success(function(value){
                    toaster.pop('info', 'Draft(s) Deleted');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Gets a draft by id
         * @function get
         * @param id
         * @returns {promise}
         */
        function get(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'drafts/' + id)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Updates a draft by
         * @function update
         * @param id
         * @param {object} data
         * @returns {promise}
         */
        function update(id,data){
            var deferred = $q.defer();
            $http.put(apiPath + 'drafts/' + id, data)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Update the selected array
         * @function modifySelectArray
         */
        function modifySelectArray(draft,selectedArray){
            if(draft.selected){
                selectedArray.push(draft.id);
            }
            else{
                for(var i = 0; i < selectedArray.length; i++){
                    if(draft.id === selectedArray[i]){
                        selectedArray.splice(i,1);
                        break;
                    }
                }
            }
        }
        /**
         * Select all drafts
         * @function selectAll
         */
        function selectAll(draftsArray,selectedArray, isAllSelected){
            for(var i = 0; i < draftsArray.length; i++){
                draftsArray[i].selected = isAllSelected;
                modifySelectArray(draftsArray[i],selectedArray);
            }
        }

        /**
         * Open draft in instance window
         * @param draft
         */
        function openDraft(draft, callbackFunctions){
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: draft.widgetId,
                title: draft.widgetName,
                draftId: draft.id,
                navFunctions: callbackFunctions
            });
        }
    })
;
