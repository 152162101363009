angular.module('cerberus.account')
    .controller('UsersProfileCtrl', function UsersProfileCtrl($scope, $location, ConfirmModalService, usersProfileService) {
        $scope.activeRoute = 'profile';
        $scope.profiles = [];

        $scope.goToUserProfileDetails = _goToUserProfileDetails;
        $scope.deleteUserProfile = _deleteUserProfile;

        _init();

        function _init(){
            usersProfileService.getAll('Profile').then(function(userProfile){
                $scope.profiles = userProfile;
            });
        }

        function _goToUserProfileDetails(id){
            if(id){
                $location.url('users/settings/profile/' + id);
            }
            else{
                $location.url('users/settings/profile/create');
            }
        }

        function _deleteUserProfile(id, index){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this Profile?',
                actionButtonText: 'Delete',
                closeButtonText: 'Cancel',
                check: function(/*input*/){
                    return true;
                },
                confirm: function(){
                    return usersProfileService.remove(id);
                },
                callback: function(){
                    $scope.profiles.splice(index, 1);
                }
            });
        }
    })
    .controller('UsersProfileCreateCtrl', function UsersProfileCreateCtrl($scope, $location, usersProfileService) {
        $scope.activeRoute = 'profile';
        $scope.masterProfile = { data: {} };
        $scope.userProfile = { data: {} };
        $scope.isDefaultProfile = false;

        $scope.clearLogo = _clearLogo;
        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.cancel = _cancel;

        _init();

        function _init(){
            _clearLogo();
        }

        function _clearLogo(){
            $scope.userProfile.data.pic_img = '';
            $scope.imageSrc = null;
        }

        function _isUnchanged(data){
            return angular.equals($scope.masterProfile, data);
        }

        function _save(profile){
            profile.type = 'Profile';
            usersProfileService.create(profile).then(function(){
                $location.url('users/settings/profile');
            });
        }

        function _cancel(){
            $location.url('users/settings/profile');
        }
    })
    .controller('UsersProfileDetailsCtrl', function UsersProfileCtrl($scope, $location, userId, usersProfileService) {
        $scope.activeRoute = 'profile';
        $scope.masterProfile = {};
        $scope.userProfile = {};
        $scope.isDefaultProfile = false;
        $scope.imageSrc = null;

        $scope.clearLogo = _clearLogo;
        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.cancel = _cancel;

        _init();

        function _init(){
            usersProfileService.get(userId).then(function(userProfile){
                if(!userProfile.data.pic_img){
                    userProfile.data.pic_img = '';
                }

                $scope.masterProfile = angular.copy(userProfile);
                $scope.userProfile = angular.copy(userProfile);
                $scope.isDefaultProfile = angular.copy(userProfile.isDefault);
            });
        }

        function _clearLogo(){
            $scope.userProfile.data.pic_img = '';
            $scope.imageSrc = null;
        }

        function _isUnchanged(data){
            return angular.equals($scope.masterProfile, data);
        }

        function _save(profile){
            profile.type = 'Profile';
            usersProfileService.update(userId, profile).then(function(){
                $location.url('users/settings/profile');
            });
        }

        function _cancel(){
            $location.url('users/settings/profile');
        }
    })
;