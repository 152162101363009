angular.module('cerberus.account')
    .controller('UsersTemplatesCtrl', function UsersTemplatesCtrl($scope, $location, TemplatesServices) {
        $scope.activeRoute = 'templates';
        $scope.templatesArray = [];
        $scope.selectedType = 'Form';

        $scope.goToTemplateDetails = _goToTemplateDetails;
        $scope.deleteTemplate = _deleteTemplate;

        _init();

        function _init(){
            TemplatesServices.getUserTemplates().then(function(templatesArray){
                $scope.templatesArray = templatesArray;
            });
        }

        function _goToTemplateDetails(id){
            $location.url('users/settings/templates/' + id);
        }

        function _deleteTemplate(id, idx){
            TemplatesServices.deleteTemplate(id).then(function(){
                $scope.templatesArray.splice(idx, 1);
            });
        }
    })
    .controller('UsersTemplatesDetailsCtrl', function UsersTemplatesDetailsCtrl($scope, $location, templateId, TemplatesServices) {
        $scope.activeRoute = 'templates';
        $scope.tempData = {};
        $scope.origData = {};
        $scope.securityTypes = TemplatesServices.getSecurityLevels();

        $scope.hasChanges = _hasChanges;
        $scope.submit = _submit;

        _init();

        function _init(){
            TemplatesServices.getDetails(templateId).then(function(templateModel){
                $scope.tempData = angular.copy(templateModel);
                $scope.origData = angular.copy(templateModel);
            });
        }

        function _hasChanges(){
            return !angular.equals($scope.origData, $scope.tempData);
        }

        function _submit(isValid, data){
            if(isValid){
                TemplatesServices.update($scope.origData.id, data).then(function(){
                    $location.url('users/settings/templates');
                });
            }
        }
    })
;