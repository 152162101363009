angular.module('cerberus.account')

    .factory('organizationUsersService', function organizationUsersService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';

        return {
            getUsers: getUsers,
            getAvailableMemberOf: getAvailableMemberOf,
            getMemberOf: getMemberOf,
            addUsers: addUsers,
            removeUsers: removeUsers,
            enableUsers: enableUsers,
            disableUsers: disableUsers
        };
        ////////////////////

        function disableUsers(orgId,data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'organizations/' + orgId + '/users/disable',data)
                .success(function (value) {
                    toaster.pop('info', 'Selected user(s) has been disabled.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function enableUsers(orgId,data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'organizations/' + orgId + '/users/enable',data)
                .success(function (value) {
                    toaster.pop('info', 'Selected user(s) has been enabled.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function removeUsers(orgId,userIds){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'organizations/' + orgId + '/users/' + userIds})
                .success(function(value){
                    toaster.pop('info', 'User(s) removed.');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function addUsers(orgId,data) {
            var deferred = $q.defer();
            $http.post(apiPath + 'organizations/' + orgId + '/users',data)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                    toaster.pop('success', 'User(s) has been added.');
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getMemberOf(orgId,userId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'organizations/' + orgId + '/users/' + userId + '/memberOf')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getAvailableMemberOf(orgId,userId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'organizations/' + orgId + '/users/' + userId + '/available/memberOf')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getUsers(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'organizations/' + id + '/users')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;
