angular.module('cerberus.admin')
    .controller('PageFilterConfigCtrl', function PageFilterConfigCtrl($scope, $uibModalInstance, filter, idArray, PagesDesignerListService, ViewsService, DesignerUtilityService) {
            $scope.temp = angular.copy(filter);
            $scope.orig = angular.copy(filter);
            $scope.types = PagesDesignerListService.filters();
            $scope.editFlag = filter.id ? true: false;
            $scope.views = null;

            $scope.preventSpace = DesignerUtilityService.preventSpace;
            $scope.labelToUniqueId = function (item){
                item.id = DesignerUtilityService.labelToUniqueId(item.label, idArray);
            };

            $scope.typeChange = function (item) {
                if (item.type === 'string' || item.type === 'number') {
                    item.icon = 'filter';
                }
                if (item.type === 'date' || item.type === 'dateRange') {
                    item.icon = 'calendar';
                }
                if (item.type === 'lookup') {
                    $scope.getViews();
                }
            };

            $scope.getViews = function () {
                // Only make server call if we have too
                if ($scope.views === null) {
                    ViewsService.get(true).then(function(data){
                        $scope.views = data;
                    });
                }
            };

            $scope.viewChange = function (item){
                if(item.params && item.params.viewId){
                    ViewsService.getColumns(item.params.viewId).then(function(data){
                        if(data.length === 0){
                            $scope.errStr = '<div class="alert alert-warning" role="alert"><strong>Warning!</strong> There are no columns in this view.</div>';
                        }
                        else{
                            $scope.errStr = null;
                        }
                        $scope.viewColumns = data;
                    });
                }
                else {
                    $scope.viewColumns = [];
                    $scope.errStr = null;
                }
            };

            $scope.isUnchanged = function () {
                return angular.equals($scope.temp, $scope.orig);
            };

            $scope.save = function (item) {
                $uibModalInstance.close(item);
            };

            $scope.cancel = function(){
                $uibModalInstance.dismiss();
            };

            // Init
            if (filter.type === 'lookup') {
                $scope.getViews();
            }
            if (filter.params && filter.params.viewId) {
                $scope.viewChange(filter);
            }
        })
;