angular.module('cerberus.ui')
    .directive('nimImg', function(InstancesService){
        return {
            restrict: 'AE',
            priority: 2000,
            link: function(scope, element, attrs){
                var attachmentId = attrs.nimImg,
                    width = attrs.width,
                    height = attrs.height;
                if(attachmentId && attachmentId != 'undefined'){
                    InstancesService.getDownloadKey(attachmentId).then(function(url){
                        var imageElement = angular.element('<img src="' + url + '" />');
                        if (width) {
                            imageElement.css('width', width);
                        }
                        if (height) {
                            imageElement.css('height', height);
                        }

                        imageElement.appendTo(element.empty());
                    });
                }
            }
        };
    })
;