angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartFunnelService
     * @alias cerberus/core:VizChartFunnelService
     * @description Provides functions for configuring funnel charts
     */
    .factory('VizChartFunnelService', function VizChartFunnelService(){
        return {
            //configFunnel: configFunnel
        };

        /*function configFunnel(data, options){
            // Nothing, yet
        }*/
    })
;