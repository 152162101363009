angular.module('cerberus.core')
    .controller('NimRelationshipCtrl', function NimRelationshipCtrl(_, kendo, moment, $scope, $rootScope, $timeout, WidgetsService, InstancesService, InstancesWindowService) {
        var vm = this;
        var filterText = '';
        var relationIds = [];
        var instanceId = $scope.$parent.vm.instanceId;
        vm.widgetsArray = [];
        vm.selectedRows = {};
        vm.relationshipArray = [];
        vm.relGridOptions = {
            columnMenu: true,
            columns:  [
                //{
                //    template: '<input class="form-control" type="checkbox" ng-checked="vm.rowSelected(vm.selectedWidget.id, #: id #)" ng-model="selected" ng-click="vm.toggleSelectRow(vm.selectedWidget.id, #: id #)" />',
                //    width: 35,
                //    type: 'batch',
                //    title: '',
                //    headerTemplate: '<input class="form-control" type="checkbox" ng-model="selected" ng-click="vm.selectAllRows(vm.grid, vm.selectedWidget.id, selected)" />',
                //    menu: false
                //},
                {
                    field: 'display',
                    title: 'Description',
                    type: 'string'
                },
                {
                    field: 'create_date',
                    title: 'Date Created',
                    type: 'date',
                    width: 150,
                    format: '{0:g}'
                },
                {
                    command: [
                        {
                            name: 'addRow',
                            text: '<i class="fa fa-plus"></i>&nbsp;Add',
                            className: 'btn btn-sm btn-default',
                            click: addRow
                        }
                    ],
                    title: ' ',
                    width: '80px'
                }
            ],
            //dataSource: dataSource,
            filterable: true,
            mobile: false,
            pageable: true,
            reorderable: true,
            resizable: true,
            sortable: true,
            scrollable: true
        };

        vm.openRelatedInstance = openRelatedInstance;

        vm.applyFilter = function(text){
            filterText = angular.copy(text);
            if(vm.dataSource){
                vm.dataSource.read();
            }
        };

        vm.enterFilter = function(e, text){
            if(e.keyCode == 13){ // 13 -> Enter
                vm.applyFilter(text);
            }
        };

        vm.changeRelWidget =function(widget){
            if(widget.default_view){
                var baseWid = widget.uniqueName;
                var dataSource = new kendo.data.DataSource({
                    type: 'odata',
                    transport:{
                        read: {
                            url: '/server/rest/v1/views/' + widget.default_view.id + '/odata.svc?$lookupDisplay=' + widget.default_view.column + '&$select=create_date',
                            beforeSend: function(xhr){
                                xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            },
                            data: function(){
                                var filters = [];
                                var params = {};

                                if(relationIds.length > 0){
                                    params.$notin = baseWid + "_instance_id,'" + relationIds.join("'~~'") + "'";
                                }

                                if(filterText.length > 0){
                                    filters.push("substringof('" + filterText + "'," + widget.default_view.column + ")");
                                }

                                if(filters.length > 0){
                                    params.$filter = '(' + filters.join(' and ') + ')';
                                }

                                return params;
                            },
                            dataType: 'json'
                        }
                    },
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    pageSize: 10,
                    schema: {
                        model: {
                            fields: {
                                create_date: {
                                    type: 'date',
                                    parse: parseCreateDate
                                }
                            }
                        }
                    }
                });
                if(vm.grid){
                    vm.grid.setDataSource(dataSource);
                    vm.grid.dataSource.read();
                }
                else{
                    vm.relGridOptions.dataSource = dataSource;
                    vm.relGridOptions.dataSource.read();
                }
                vm.dataSource = dataSource;
            }
        };

        vm.toggleSelectRow = function(widgetId, rowId){
            vm.selectedRows[widgetId] = vm.selectedRows[widgetId] || [];
            var selectedRows = vm.selectedRows[widgetId];
            var index = selectedRows.indexOf(rowId);
            if(index < 0){
                selectedRows.push(rowId);
            }
            else{
                selectedRows.splice(index, 1);
            }
        };

        vm.selectAllRows = function(grid, widgetId, selected){
            vm.selectedRows[widgetId] = vm.selectedRows[widgetId] || [];
            var selectedRows = vm.selectedRows[widgetId];
            var rows = grid.dataSource.data();
            if(rows.length > 0){
                var pageNum = grid.pager.page();
                var pageSize = grid.pager.pageSize();
                var start = pageSize * (pageNum - 1);
                var end = (pageSize * pageNum) - 1;
                if(end >= rows.length){
                    end = rows.length - 1;
                }
                for(var r = start; r <= end; r++){
                    var id = rows[r].id;
                    var index = selectedRows.indexOf(id);
                    if(selected && index < 0){
                        selectedRows.push(id);
                    }
                    else if(!selected && index >= 0){
                        selectedRows.splice(index, 1);
                    }
                }
            }
        };

        vm.rowSelected = function(widgetId, id){
            vm.selectedRows[widgetId] = vm.selectedRows[widgetId] || [];
            return vm.selectedRows[widgetId].indexOf(id) >= 0;
        };

        vm.addSelected = function(){
            var data = vm.dataSource.data();
            var wId = vm.selectedWidget.id;
            var widgetName = vm.selectedWidget.name;
            var selectedRows = vm.selectedRows[wId];
            for(var sr = 0; sr < selectedRows.length; sr++){
                var rowId = selectedRows[sr];
                for(var d = 0; d < data.length; d++){
                    if(data[d].id == rowId){
                        vm.relationshipArray.push({
                            id: rowId,
                            widget: widgetName,
                            widgetId: wId,
                            description: data[d].display
                        });
                        relationIds.push(rowId);
                        break;
                    }
                }
            }
            if(vm.dataSource) {
                vm.dataSource.read();
            }
            vm.selectedRows[wId] = [];
        };

        vm.deleteRelationship = function(index, id){
            vm.relationshipArray.splice(index, 1);
            relationIds.splice(relationIds.indexOf(id), 1);

            if(vm.dataSource) {
                vm.dataSource.read();
            }
        };

        vm.close = function(){
            $scope.instanceRelationship.close();
        };

        vm.ok = function(){
            var instanceController = $scope.$parent.vm;
            if(instanceId && instanceId !== ''){
                InstancesService.updateRelationships(instanceId,vm.relationshipArray).then(function(){
                    instanceController.relationships = angular.copy(vm.relationshipArray);
                });
            }
            else{
                instanceController.relationships = angular.copy(vm.relationshipArray);
            }
            vm.close();
        };

        _init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function _init() {
            WidgetsService.getWidgets().then(function(data){
                vm.widgetsArray = data;
            });
            if(instanceId && instanceId !== ''){
                InstancesService.getRelationships(instanceId).then(function(d){
                    vm.relationshipArray = angular.copy(d);
                    relationIds = [];
                    for(var r = 0; r < vm.relationshipArray.length; r++){
                        relationIds.push(vm.relationshipArray[r].id);
                    }
                });
            }
        }

        function parseCreateDate(value){
            if(value){
                try {
                    var dateValue = value;

                    if(!_.isDate(dateValue)){
                        dateValue = kendo.parseDate(value, 'MMMM, dd yyyy HH:mm:ss');
                    }

                    var m = moment.utc(dateValue);

                    // Converts to local time
                    m.local();

                    return m.toDate();
                }
                catch(e){}
            }

            return '';
        }

        function addRow(e){
            e.preventDefault();

            var widgetId = vm.selectedWidget.id;
            var widgetName = vm.selectedWidget.name;
            var row = angular.element(e.target).closest('tr');
            var dataItem = vm.grid.dataItem(row);
            var rowId = dataItem.id;

            vm.relationshipArray.push({
                id: rowId,
                widget: widgetName,
                widgetId: widgetId,
                description: dataItem.display
            });

            relationIds.push(rowId);

            vm.dataSource.read();

            //_.remove(vm.selectedRows[widgetId], rowId);
        }

        function openRelatedInstance(rel){
            if(rel.id) {
                InstancesWindowService.openWindow({
                    action: 'read',
                    widgetId: rel.widgetId,
                    instanceId: rel.id,
                    title: rel.description
                });
            }
        }

        $scope.$on('auth:loginCancelled', function () {
            $timeout(function() {
                vm.close();
            });
        });
    })
    .directive('nimRelationship', function nimRelationship() {
        return {
            restrict: 'AE',
            templateUrl: 'core/instances/instances-relationship-form.tpl.html',
            controller: 'NimRelationshipCtrl',
            controllerAs: 'vm'
        };
    })
;