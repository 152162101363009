angular.module('cerberus.les')
    .controller('LESEditQuickApplicationModalCtrl', function (_, $scope, $uibModalInstance, $window, instanceId, lesQuickApplicationService) {
        var vm = this;
      
        vm.filters = {};
        vm.dataSet = {};
        vm.orig = {};
        vm.loadingQuickApp = true;
        vm.updatingQuickApp = false;
        vm.showUpdateError = false;
        vm.updateError = [];

        vm.loanPurposeOptions = lesQuickApplicationService.loanPurposeOptions($scope);
        vm.occupancyOptions = lesQuickApplicationService.occupancyOptions($scope);
        vm.propertyTypeOptions = lesQuickApplicationService.propertyTypeOptions($scope);
        vm.citizenshipOptions = lesQuickApplicationService.citizenshipOptions($scope);
        vm.bankruptcyTypeOptions = lesQuickApplicationService.bankruptcyTypeOptions($scope);
        vm.dispositionTypeOptions = lesQuickApplicationService.dispositionTypeOptions($scope);
        vm.dispositionDateOptions = lesQuickApplicationService.dispositionDateOptions();
        vm.currencyOptions = lesQuickApplicationService.currencyOptions();
        vm.unitNumberOptions = lesQuickApplicationService.unitNumberOptions();
        vm.ficoScoreOptions = lesQuickApplicationService.ficoScoreOptions();
        vm.ltvOptions = lesQuickApplicationService.ltvOptions();
        vm.employmentDurationOptions = lesQuickApplicationService.employmentDurationOptions();
        vm.delinquencyNumberOptions = lesQuickApplicationService.delinquencyNumberOptions();
        vm.delinquencyNumberLookupOptions = lesQuickApplicationService.delinquencyNumberLookupOptions();
        vm.showFormFieldValidation = lesQuickApplicationService.showFormFieldValidation;
        vm.popoverHTML = lesQuickApplicationService.popoverHTML;
        vm.toggleMortgageLates12Months = lesQuickApplicationService.toggleMortgageLates12Months;
        vm.toggleMortgageLates24Months = lesQuickApplicationService.toggleMortgageLates24Months;
        vm.toggleMortgageLates36Months = lesQuickApplicationService.toggleMortgageLates36Months;
        vm.displayPercentage = lesQuickApplicationService.displayPercentage;
        vm.isCondominium = lesQuickApplicationService.isCondominium;

        vm.isUnchanged = _isUnchanged;        
        vm.submit = _submit;
        vm.cancel = _cancel;

        init();

        function init() {
            vm.loadingQuickApp = true;
            lesQuickApplicationService.getQuickApplication(instanceId).then(function (quickAppData) {
                vm.loadingQuickApp = false; 
                vm.orig = quickAppData;
                vm.dataSet = angular.copy(quickAppData);

                lesQuickApplicationService.setNgModels($scope, vm.dataSet);
                lesQuickApplicationService.runCalculations($scope, vm.dataSet);
            }, function (reason) {
                if (reason == 'Not Found.') {
                    _cancel();
                }
            });
        }

        function _isUnchanged() {
            var newData = _.omit(vm.dataSet, ['ltv', 'cltv']),
                oldData = _.omit(vm.orig, ['ltv', 'cltv']);
            
            return angular.equals(newData, oldData);
        }

        function _submit(quickAppForm) {
            if(vm.loadingQuickApp || _isUnchanged()){
                $uibModalInstance.close(instanceId);
            }

            if (vm.importingQuickApp || quickAppForm.$invalid || !_.get(vm.dataSet, 'loanPurpose.type')) {
                return;
            }

            if (!_isUnchanged()) {
                vm.updatingQuickApp = true;
                vm.showUpdateError = false;

                lesQuickApplicationService.updateQuickApplication(instanceId, vm.dataSet).then(function (result) {
                    vm.updatingQuickApp = false;
                    if (result.errMsg) {
                        vm.showUpdateError = true;
                        vm.updateError = result.errMsg;
                    }
                    else {
                        $uibModalInstance.close(instanceId);
                    }
                }, function () {
                    vm.updatingQuickApp = false;
                });
            }
            else {
                $uibModalInstance.close(instanceId);
            }
        }

        function _cancel() {
            $uibModalInstance.dismiss();
        }

        // Prevents navigation while modal is open
        var allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            allowNavigation();
        });
    })
;