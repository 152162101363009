angular.module('cerberus.admin')
    .controller('WidgetDeleteModalCtrl', function WidgetDeleteModalCtrl($scope, $uibModalInstance, widget) {
        $scope.widget = angular.copy(widget);
        $scope.isNotEqual = function(data){
            return !angular.equals('DELETE ' + $scope.widget.name, data);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.finish = function(){
            $uibModalInstance.close($scope.widget);
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
    .controller('WidgetsCtrl', function WidgetsCtrl($scope, $location, workspaceId, localStorageService, WidgetsService) {
        $scope.widgetsArray = [];
        $scope.select = _select;
        $scope.widgetFilter = _widgetFilter;

        _init();

        function _init(){
            WidgetsService.getWidgets();

            $scope.$watch(WidgetsService.widgets, function (newValue){
                $scope.widgetsArray = angular.copy(newValue);
            });
        }

        function _select(id){
            if(id) {
                $location.url('/settings/widgets/' + id);
            }
        }

        // Getter-Setter function for filter
        function _widgetFilter(filterText){
            // Checks if value was passed in
            if(arguments.length){
                localStorageService.set('widgetFilter.' + workspaceId, filterText);
                return filterText || '';
            }
            else {
                return localStorageService.get('widgetFilter.' + workspaceId) || '';
            }
        }
    })
    .controller('FormModalCtrl', function FormModalCtrl($scope, $uibModalInstance, $timeout, form, TemplatesServices) {
        $scope.templatesArray = [];
        $scope.origData = angular.copy(form);
        $scope.formData = angular.copy(form);
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.isUnchanged = function(){
            return angular.equals($scope.formData, $scope.origData);
        };
        $scope.finish = function(){
            $uibModalInstance.close($scope.formData);
        };
        $scope.preventInvalidCharacters = preventInvalidCharacters;
        $scope.removeInvalidCharacters = removeInvalidCharacters;

        init();

        function init(){
            TemplatesServices.get('Form').then(function(templates){
                $scope.templatesArray = templates;
            });
        }

        function preventInvalidCharacters(event){
            var period = event.which === 190,
                underscore = event.which === 189 && event.shiftKey;

            if(period || underscore){
                event.preventDefault();
            }
        }

        function removeInvalidCharacters(){
            $timeout(function(){
                if($scope.formData.name){
                    $scope.formData.name = $scope.formData.name.replace(/[._]/g, '');
                }
            });
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
    .controller('DeleteWidgetComponentsModalCtrl', function DeleteWidgetComponentsModalCtrl($scope, $uibModalInstance, formOriginId, data, FormsDesignerService) {
        $scope.cannotDelete = null;
        $scope.data = angular.copy(data);
        $scope.isNotEqual = function(data){
            return !angular.equals($scope.data.name, data);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.finish = function(){
            $uibModalInstance.close($scope.data);
        };

        FormsDesignerService.checkFormUsedInWorkflow(formOriginId).then(function(deleteCheck){
            $scope.cannotDelete = deleteCheck;
        });

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;