angular.module('cerberus.les')
    .controller('LESNewQuickApplicationPageCtrl', function (_, $scope, $window, lesQuickApplicationService) {
        // var vm = this;

        init();
        
        function init() {
            lesQuickApplicationService.openNewQuickAppModal().then(function (instanceId) {
                lesQuickApplicationService.openQuickAppEligibilityPage(instanceId);
            }, function () {
                cancel();
            });
        }

        function cancel() {
            $window.history.back();
        }
    })
;