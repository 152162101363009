angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormsBuildDynamicService
     * @alias cerberus/core:FormsBuildDynamicService
     * @description Builds html templates for form fields (in edit mode)
     */
    .factory('FormsBuildDynamicService', function FormsBuildDynamicService(_, FormsBuildHelperService, FormsBuildStaticService) {
        return {
            statictext: statictext,
            hr: hr,
            reference: FormsBuildStaticService.reference,
            text: text,
            autocomplete: autocomplete,
            location: location,
            map: map,
            textarea: textarea,
            number: number,
            datetime: datetime,
            signature: signature,
            radio: radio,
            checkbox: checkbox,
            select: select,
            multiselect: multiselect,
            table: table,
            readonly: readonly,
            timer: timer,
            image: image,
            rule: rule
        };
        ////////////////////
        /**
         * @function statictext
         * @param {Field} field
         * @returns {string} of html
         */
        function statictext(field){
            return FormsBuildStaticService.statictext(field);
        }

        /**
         * @function hr
         * @param {Field} field
         * @returns {string} of html
         */
        function hr(field) {
            return FormsBuildStaticService.hr(field);
        }

        /**
         * @function text
         * @param {Field} field
         * @returns {string} of html
         */
        function text(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            if(field.param.filter){
                ngModel = 'filters.' + modelId;
            }
            else{
                attrs['ng-change'] = "updateFilterOnChange()";
            }

            attrs['class'] = 'form-control';
            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['type'] = 'text';
            attrs['style'] = 'width:100%;';

            attrs['ng-blur'] = 'validateFormObject(' + ngModel + ', isRequired)';
            attrs['ng-model'] = ngModel;
            attrs['ng-readonly'] = 'isReadonly';

            if(field.validation.mask){
                attrs['kendo-masked-text-box'] = '';
                attrs['k-mask'] = '\'' + field.validation.mask + '\'';
            }

            if(field.display.placeholder){
                attrs['placeholder'] = '\'' + field.display.placeholder + '\'';
            }

            return FormsBuildHelperService.divOpenForm() + label + '<input ' + FormsBuildHelperService.parseAttr(attrs) + '/>' + error + help + '</div>';
        }

        /**
         * @function autocomplete
         * @param {Field} field
         * @returns {string} of html
         */
        function autocomplete(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            attrs['class'] = 'form-control';
            attrs['id'] = modelId;
            attrs['name'] = modelId;

            attrs['k-data-text-field'] = '\'display\'';
            attrs['k-data-value-field'] = '\'id\'';
            attrs['k-min-length'] = 3;

            attrs['ng-blur'] = 'validateFormObject(' + ngModel + ', isRequired)';
            attrs['ng-model'] = ngModel;
            attrs['ng-readonly'] = 'isReadonly';

            if(field.option.type === 'custom'){
                attrs['k-data-source'] = 'fieldOptions.' + modelId;
            }
            else {
                attrs['k-data-source'] = 'oDataLookUp';
            }

            if(field.param.separator === 'None'){
                attrs['k-separator'] = '\' \'';
            }
            else {
                attrs['k-separator'] = '\'' +  field.param.separator + '\'';
            }

            return FormsBuildHelperService.divOpenForm() + label + '<input kendo-auto-complete ' +
                FormsBuildHelperService.parseAttr(attrs) + '/>' + error + help + '</div>';
        }

        /**
         * @function location
         * @param {Field} field
         * @returns {string} of html
         */
        function location(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            attrs['class'] = 'form-control nim-location-input';
            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['type'] = 'text';

            attrs['ng-blur'] = 'validateFormObject(' + ngModel + ', isRequired)';
            attrs['ng-change'] = 'setEmptyLocation()';
            attrs['ng-model'] = '_' + modelId;
            // attrs['ng-model-options'] = '{ updateOn: \'default blur\', debounce: { \'default\': 500, \'blur\': 0 } }';
            attrs['ng-readonly'] = 'isReadonly';

            attrs['nim-stop-enter'] = '';

            attrs['uib-typeahead'] = 'address as address.place_name for address in getLocationAutoComplete($viewValue)';
            attrs['typeahead-on-select'] = 'setLocationData($item, $model, $label, $event)';
            attrs['typeahead-wait-ms'] = '500';

            if(field.display.placeholder){
                attrs['placeholder'] = field.display.placeholder;
            }

            return FormsBuildHelperService.divOpenForm() + label +
                '<input ' + FormsBuildHelperService.parseAttr(attrs) + ' />' +
                error + help +
                '</div>';
        }

        /**
         * @function map
         * @param {Field} field
         * @returns {string} of html
         */
        function map(field) {
            var modelId = field.config.modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);

            var attrs = {};
            attrs['class'] = 'nim-form-map';
            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['style'] = 'bottom:0; left:0; position:absolute; right:0; top:14px;';

            //attrs['ng-class'] = '{\'has-success\': fieldValidity() === true,\'has-error\': fieldValidity() === false}';
            // attrs['ng-class'] = '{\'has-error\': fieldValidity === false}';
            attrs['ng-model'] = '_' + modelId; // To register with ngForm

            return FormsBuildHelperService.divOpenForm() + label + error + help + '</div>' +
                '<div nim-form-map ' + FormsBuildHelperService.parseAttr(attrs) + '></div>';
        }

        /**
         * @function textarea
         * @param {Field} field
         * @returns {string} of html
         */
        function textarea(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            attrs['class'] = 'form-control';
            attrs['id'] = modelId;
            attrs['name'] = modelId;

            attrs['ng-readonly'] = 'isReadonly';

            if (field.param.showToolbar === true) {
                attrs['k-change'] = 'validateKendoFormObject';
                attrs['k-ng-model'] = ngModel;
                attrs['k-options'] = 'textEditorOptions';
                attrs['kendo-editor'] = '';

                attrs['ng-model'] = '_' + modelId;
            }
            else {
                attrs['class'] = 'form-control';
                attrs['ng-blur'] = 'validateFormObject(' + ngModel + ', isRequired)';
                attrs['ng-model'] = ngModel;
                attrs['ng-model-options'] = '{ updateOn: \'blur\', debounce: { \'blur\': 0 } }';

                if(field.display.placeholder){
                    attrs['placeholder'] = field.display.placeholder;
                }
            }

            return FormsBuildHelperService.divOpenForm() + label + '<textarea ' + FormsBuildHelperService.parseAttr(attrs) + '></textarea>' + error + help + '</div>';
        }

        /**
         * @function number
         * @param {Field} field
         * @returns {string} of html
         */
        function number(field) {
            var modelId = field.config.modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            attrs['class'] = 'form-control';
            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['style'] = 'width:100%;';
            attrs['type'] = 'number';

            attrs['k-decimals'] = field.param.decimals;
            attrs['k-format'] =  "'" + _.escape(field.display.format) + "'";
            attrs['k-max'] = field.validation.max;
            attrs['k-min'] = field.validation.min;
            attrs['k-step'] = field.param.step;

            attrs['ng-model'] = '_' + modelId;
            attrs['ng-readonly'] = 'isReadonly';

            if(field.param.filter){
                attrs['k-ng-model'] = 'filters.' + modelId;
                attrs['k-change'] = 'validateKendoFormObject';
            }
            else{
                attrs['k-ng-model'] = 'dataSet.' + modelId;
                attrs['k-change'] = 'validateAndUpdateOnChange';
            }

            if(field.display.placeholder){
                attrs['placeholder'] = "'" + field.display.placeholder + "'";
            }

            return FormsBuildHelperService.divOpenForm() + label + '<input kendo-numeric-text-box="nimNumberFormObject" ' + FormsBuildHelperService.parseAttr(attrs) + '/>' + error + help + '</div>';
        }

        /**
         * @function datetime
         * @param {Field} field
         * @returns {string} of html
         */
        function datetime(field) {
            var modelId = field.config.modelId,
                ngModel = 'dataSet.' + modelId,
                label = FormsBuildHelperService.makeLabel(field, true),
                help = FormsBuildHelperService.makeHelpBlock(field.help),
                error = FormsBuildHelperService.makeValidationHelpBlock(modelId),
                attrs = angular.copy(field.tag.attrs),
                format = _.trim(field.display.format),
                //usesDateTimeFormat = format.match(/^[FGgsu]$/), // Uses single-character specifier for kendo date-time format
                usesDateFormat = format.match(/^[DdMmYy]$/),    // Uses single-character specifier for kendo date format
                usesTimeFormat = format.match(/^[Tt]$/),        // Uses single-character specifier for kendo time format
                hasDatePattern = format.match(/[dMy]/g),        // Uses specifiers for kendo date pattern
                hasTimePattern = format.match(/[fhHmst]/g);     // Uses specifiers for kendo date pattern
            
            var parseFormatString = '[';
            _.forEach(_.get(field.param, 'parseFormats', []), function (pf) {
                parseFormatString += '\'' + pf + '\',';
            });
            parseFormatString += '\'yyyy-MM-ddTHH:mm:ss.fffZ\',\'yyyy-MM-dd HH:mm:ss.fff\']';

            attrs['class'] = 'form-control';
            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['style'] = 'width:100%;';

            attrs['k-change'] = 'validateKendoFormObject';
            attrs['k-ng-model'] = ngModel;
            attrs['k-format'] = '\'' + format + '\'';
            // attrs['k-parse-formats'] = '[\'yyyy-MM-ddTHH:mm:ss.fffZ\',\'yyyy-MM-dd HH:mm:ss.fff\']';
            attrs['k-parse-formats'] = parseFormatString;

            attrs['ng-model'] = '_' + modelId;
            attrs['ng-readonly'] = 'isReadonly';

            var pickerType = 'date-time',
                depth = '\'month\'';

            // Matches date-time pattern
            if(hasDatePattern && hasTimePattern){
                if(format.includes('d')){
                    depth = '\'month\'';
                }
                else if(format.includes('M')){
                    depth = '\'year\'';
                }
                else if(format.includes('yy')){
                    depth = '\'decade\'';
                }
            }
            // Matches time pattern
            else if(usesTimeFormat || hasTimePattern){
                pickerType = 'time';
            }
            // Matches date pattern
            else if(usesDateFormat || hasDatePattern){
                pickerType = 'date';

                if(!usesDateFormat){
                    if(format.includes('d')){
                        depth = '\'month\'';
                    }
                    else if(format.includes('M')){
                        depth = '\'year\'';
                    }
                    else if(format.includes('yy')){
                        depth = '\'decade\'';
                    }
                }
            }

            attrs['k-depth'] = depth;
            attrs['k-start'] = depth;

            return FormsBuildHelperService.divOpenForm() + label + '<input kendo-' + pickerType + '-picker="nimDateFormObject" ' +  FormsBuildHelperService.parseAttr(attrs) + ' />' + error + help + '</div>';
        }

        /**
         * @function signature
         * @param {Field} field
         * @returns {string} of html
         */
        function signature(field) {
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var attrs = angular.copy(field.tag.attrs);
            var model = 'dataSet.' + field.config.modelId;
            var ngDisabled = '';
            var wrapLabelClass = label.length ? 'has-label': '';
            if(field.param.condition){
                ngDisabled = 'ng-readonly="isReadonly" ';
            }
            if (field.param.type === 'initial') {
                return '<div class="form-group">' +
                    label +
                    '<div class="sign-wrap ' + wrapLabelClass + '">' +
                    '<img class="sign-img" ' +
                    'ng-if="' + model + '.path" ' +
                    'ng-src="{{' + model + '.path}}" />' +
                    '<div class="sign-text"' +
                    'ng-if="!' + model + '.path && ' + model + '.userId" ' +
                    'ng-bind="' + model + '.userName | toInitials"></div>' +
                    '<div class="sign-meta"' +
                    'ng-if="' + model + '.path || ' + model + '.userId">' +
                    '</div>' +
                    '<a href="" ng-if="' + model + '.path || ' + model + '.userId" ' +
                    'ng-click="signButtonClick(vm.sigData, true)" class="sign-again" >Initial again</a>' +
                    '<div ng-if="!' + model + '.path && !' + model + '.userId" class="btn-group-justify">' +
                    '<button class="btn btn-info" type="button" ' +
                    'ng-click="signButtonClick(vm.sigData)" ' +
                    ngDisabled +
                    'tabindex="' + attrs.tabindex + '"><i class="fa fa-pencil"></i>&nbsp;Initial</button>' +
                    '</div>' +
                    '</div>' + help +
                    '</div>';
            }
            else {
                return '<div class="form-group">' +
                    label +
                    '<div class="sign-wrap ' + wrapLabelClass + '">' +
                    '<img class="sign-img" ' +
                    'ng-if="' + model + '.path" ' +
                    'ng-src="{{' + model + '.path}}" />' +
                    '<div class="sign-text"' +
                    'ng-if="!' + model + '.path && ' + model + '.userId" ' +
                    'ng-bind="' + model + '.userName"></div>' +
                    '<div class="sign-meta"' +
                    'ng-if="' + model + '.path || ' + model + '.userId">' +
                    '<span class="sign-name" ng-bind="' + model + '.userName || \'Signature\'"></span>' +
                        //'<span class="pull-left" ng-if="!' + model + '.userId">IP: <span ng-bind="' + model + '.userIpAddr || \'TBD\'"></span></span> ' +
                    '<span class="sign-date">Date: <span ng-bind="' + model + '.timestamp | date:\'MMM d, y h:mm:ss a\'"></span></span>' +
                    '</div>' +
                    '<a href="" ng-if="' + model + '.path || ' + model + '.userId" ' +
                    'ng-click="signButtonClick(vm.sigData, true)" class="sign-again" >Sign again</a>' +
                    '<div ng-if="!' + model + '.path && !' + model + '.userId" class="btn-group-justify">' +
                    '<button class="btn btn-info btn-block btn-lg" type="button" ' +
                    'ng-click="signButtonClick(vm.sigData)" ' +
                    ngDisabled + ' ' +
                    'tabindex="' + attrs.tabindex + '"><i class="fa fa-pencil"></i>&nbsp;Sign</button>' +
                    '</div>' +
                    '</div>' + help +
                    '</div>';
            }
        }

        /**
         * @function radio
         * @param {Field} field
         * @returns {string} of html
         */
        //TODO: refactor radio build
        function radio(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);  // For radio group
            var subAttrs = {};                          // For individual radio buttons

            attrs['id'] = modelId;
            attrs['name'] = modelId;

            attrs['ng-model'] = '_' + modelId; // For registering with ngForm

            subAttrs['ng-blur'] = 'validateFormObject(' + ngModel + ', isRequired)';
            subAttrs['ng-model'] = ngModel;
            subAttrs['ng-readonly'] = 'isReadonly';

            if(!field.param.isButton){
                subAttrs['type'] = 'radio';
                subAttrs['value'] = '{{::opt}}';

                if(field.option.type === 'custom') {
                    attrs['ng-repeat'] = 'opt in ::fieldOptions.' + modelId;
                }
                else{
                    attrs['ng-repeat'] = 'opt in oDataLookUp.data()';
                    subAttrs['ng-change'] = 'onSelectionChange(null,' + ngModel + '.id)';
                }

                if(field.display.isInline === true){
                    attrs['style'] = 'display:inline;';
                } else {
                    attrs['class'] = 'radio';
                }

                var inputs = '<div ' + FormsBuildHelperService.parseAttr(attrs) + '><label class="radio-inline"><input nim-store-object ' + FormsBuildHelperService.parseAttr(subAttrs) + ' /><span ng-bind="::opt.display"></span></label></div>';
                return FormsBuildHelperService.divOpenForm() + label + '<div>' + inputs + '</div>' + error + help + '</div>';
            }
            else {
                var buttonClass = field.param.buttonClass || 'default';
                subAttrs['uib-btn-radio'] = 'opt';
                subAttrs['class'] = 'btn btn-' + buttonClass;
                subAttrs['type'] = 'button';

                subAttrs['ng-bind'] = '::opt.display';

                if(field.option.type === 'custom') {
                    var options = 'fieldOptions.' + modelId;
                    subAttrs['ng-repeat'] = 'opt in ::' + options;
                    subAttrs['ng-style'] = '{\'z-index\': ' + options + '.length - $index}';
                }
                else{
                    subAttrs['ng-change'] = 'onSelectionChange(null,' + ngModel + '.id)';
                    subAttrs['ng-repeat'] = 'opt in oDataLookUp.data()';
                    subAttrs['ng-style'] = '{\'z-index\': oDataLookUp.total() - $index}';
                }

                if(field.display.isInline === true){
                    attrs['class'] = 'btn-group';
                }
                else {
                    attrs['class'] = 'btn-group-vertical';
                }

                if(field.param.buttonGroupArrow){
                    attrs['class'] += ' btn-group-arrows';
                }

                var buttons = '<div ' + FormsBuildHelperService.parseAttr(attrs) + '><button ' + FormsBuildHelperService.parseAttr(subAttrs) + '></button></div>';
                return FormsBuildHelperService.divOpenForm() + label + buttons + error + help + '</div>';
            }
        }

        /**
         * @function checkbox
         * @param {Field} field
         * @returns {string} of html
         */
        function checkbox(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            //var label = FormsBuildHelperService.makeLabel(field.label, modelId);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var attrs = angular.copy(field.tag.attrs);

            attrs['id'] = field.config.modelId;
            attrs['name'] = field.config.modelId;
            attrs['type'] = 'checkbox';

            attrs['ng-model'] = ngModel;
            //attrs['ng-readonly'] = 'isReadonly';
            attrs['ng-disabled'] = 'isReadonly';

            return FormsBuildHelperService.divOpenForm() + '<label class="checkbox-inline"><input ' + FormsBuildHelperService.parseAttr(attrs) + ' />&nbsp;<span>' + field.label.text + '</span></label>' + help + '</div>';
        }

        /**
         * @function select
         * @param {Field} field
         * @returns {string} of html
         */
        function select(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var defaultDisplay = field.display.placeholder || 'Choose...';
            var attrs = angular.copy(field.tag.attrs);

            attrs['id'] = 'nim_' + modelId;
            attrs['name'] = modelId;
            attrs['style'] = 'width:100%;';

            attrs['k-data-text-field'] = '\'display\'';
            attrs['k-data-value-field'] = '\'id\'';
            attrs['k-option-label'] = '{display:\'' + defaultDisplay + '\',id:-1}';
            attrs['k-select'] = 'validateKendoFormObject';

            attrs['ng-model'] = '_' + modelId;
            attrs['ng-readonly'] = 'isReadonly';

            if(field.param.filter){
                attrs['k-ng-model'] = 'filters.' + modelId;
            }
            else{
                attrs['k-ng-model'] = ngModel;
            }

            if(field.option.type === 'custom'){
                var options = 'fieldOptions.' + modelId;
                attrs['k-close'] = 'dropdownClose';
                attrs['k-data-source'] = options;
                attrs['k-ng-delay'] = options;
            }
            else{
                attrs['k-change'] = 'onSelectionChange';
                attrs['k-close'] = 'dropdownCloseHandler';
                attrs['k-data-bound'] = 'dropdownDataBoundHandler';
                attrs['k-data-source'] = 'oDataLookUp';
                attrs['k-filter'] = field.param.filterOperator || '\'contains\'';
                attrs['k-filtering'] = 'onFilter';
                attrs['k-open'] = 'dropdownOpenHandler';
            }

            return FormsBuildHelperService.divOpenForm() + label + '<select kendo-drop-down-list ' + FormsBuildHelperService.parseAttr(attrs) + '></select>' + error + help + '</div>';
        }

        /**
         * @function multiselect
         * @param {Field} field
         * @returns {string} of html
         */
        function multiselect(field) {
            var modelId = field.config.modelId;
            var ngModel = 'dataSet.' + modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(modelId);
            var attrs = angular.copy(field.tag.attrs);

            attrs['id'] = modelId;
            attrs['name'] = modelId;
            attrs['style'] = 'width:100%;';

            attrs['k-change'] = 'validateKendoFormObject';
            attrs['k-close'] = 'dropdownClose';
            attrs['k-data-bound'] = 'multiSelectDataBound';
            attrs['k-data-source'] = 'oDataLookUp';
            attrs['k-data-text-field'] = '\'display\'';
            attrs['k-data-value-field'] = '\'id\'';
            attrs['k-filter'] = field.param.filterOperator || '\'contains\'';
            attrs['k-filtering'] = 'onFilter';
            attrs['k-ng-model'] = ngModel;

            attrs['ng-model'] = '_' + modelId;
            attrs['ng-readonly'] = 'isReadonly';

            return FormsBuildHelperService.divOpenForm() + label + '<select kendo-multi-select ' + FormsBuildHelperService.parseAttr(attrs) + '></select>' + error + help + '</div>';
        }

        /**
         * @function table
         * @param {Field} field
         * @returns {string} of html
         */
        function table(field) {
            var modelId = field.config.modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var error = FormsBuildHelperService.makeValidationHelpBlock(field.config.modelId);
            return FormsBuildHelperService.divOpenForm() + label + '<div nim-form-table class="nim-form-table"></div><input type="hidden" id="' + modelId + '" name="' + modelId + '" ng-model="dataSet.' + modelId + '" />' + error + help + '</div>';
        }

        /**
         * @function readonly
         * @param {Field} field
         * @returns {string} of html
         */
        function readonly(field) {
            var modelId = field.config.modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var model = 'dataSet.' + modelId;
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            return FormsBuildHelperService.divOpenForm() + label + '<p class="form-control-static" ng-bind="formatReadonly(' + model + ')"></p>' + help + '</div>';
        }

        /**
         * @function timer
         * @param {Field} field
         * @returns {string}
         */
        function timer(field) {
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);

            return FormsBuildHelperService.divOpenForm() + label + '<timer start-time="timerStart">{{hours}} hour{{hoursS}}, {{minutes}} minute{{minutesS}}, {{seconds}} second{{secondsS}}.</timer>' + help + '</div>';
        }

        /**
         * @function image
         * @param {Field} field
         * @returns {string}
         */
        function image(field){
            var modelId = field.config.modelId;
            var label = FormsBuildHelperService.makeLabel(field, true);
            var help = FormsBuildHelperService.makeHelpBlock(field.help);
            var model = 'dataSet.' + modelId;
            var imageSource = 'imageSource';
            var attrs = angular.copy(field.tag.attrs);

            attrs['area-type'] = field.param.areaType;
            attrs['image'] = imageSource;
            attrs['result-image'] = model;
            attrs['result-image-size'] = field.param.resultImageSize;

            return FormsBuildHelperService.divOpenForm() + label +
                '<div>' +
                '<input type="file" class="btn btn-default" style="float:left;" nim-input-image="imageSource" />' +
                '<div style="float:right;">' +
                '<button type="button" class="btn btn-default" uib-btn-checkbox ng-model="previewImage" ng-show="imageSource">Preview</button>&nbsp;' +
                '<button type="button" class="btn btn-default" ng-click="clearImage()">Clear</button>' +
                '</div>' +
                '</div>' +
                '<div class="crop-area" style="width:100%" ng-if="imageSource">' +
                '<img-crop ' + FormsBuildHelperService.parseAttr(attrs) + '></img-crop>' +
                '</div>' +
                '<div ng-if="imageSource && previewImage" style="background-color:#ffffff; bottom:0; clear:both; position:absolute; top:49px; width:100%; z-index:1">' +
                '<img ng-src="{{' + model + '}}" />' +
                '</div>' +
                '<div ng-if="!imageSource && imageUrl" style="clear:both">' +
                '<img ng-src="{{imageUrl}}" />' +
                '</div>' + help + '</div>';
        }

        /**
         * @function rule
         * @param {Field} field
         * @returns {*}
         */
        function rule(field){
            return FormsBuildStaticService.rule(field);
        }
    })
;