angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name OdataUtilityService
     * @alias cerberus/ui:OdataUtilityService
     * @description Assists in the creation of Odata filters
     */
    .factory('OdataUtilityService', function OdataUtilityService(kendo, moment) {
        // Declare oData filters to allow for easier upgrades to future specs
        var odataFilters = {
            eq: "eq",
            neq: "ne",
            gt: "gt",
            gte: "ge",
            lt: "lt",
            lte: "le",
            contains : "substringof",
            doesnotcontain: "substringof",
            endswith: "endswith",
            startswith: "startswith",
            isnull: "isnull",
            isnotnull: "isnotnull"
        };

        return {
            toOdataFilter: toOdataFilter,
            toOdataString: toOdataString,
            operators: operators,
            messages: messages,
            operatorSymbols: operatorSymbols
        };

        ////////////////////
        /**
         * @function messages
         * @return {object} of ui messages
         */
        function messages() {
            return {
                info: "Show items that:",
                isTrue: "is true",
                isFalse: "is false",
                filter: "Filter",
                clear: "Clear",
                and: "And",
                or: "Or",
                selectValue: "-Select value-",
                operator: "Operator",
                value: "Value",
                cancel: "Cancel"
            };
        }
        /**
         * @function operatorSymbols
         * @return {object} of Odata Operator symbols by type
         */
        function operatorSymbols() {
            return {
                between: 'From',
                notBetween: 'Not from',
                eq: '=',
                neq: '!=',
                gte: ">=",
                gt: ">",
                lte: "<=",
                lt: "<",
                startswith: 'Start with:',
                contains: '&sub;',
                doesnotcontain: '&nsub;',
                endswith: 'End with:'
            };
        }
        /**
         * @function operators
         * @return {object} of Odata Operators by type
         */
        function operators() {
            var EQ = "Equal to";
            var NEQ = "Not equal to";
            return {
                date: {
                    eq: EQ,
                    neq: NEQ,
                    gte: "After or equal to",
                    gt: "After",
                    lte: "Before or equal to",
                    lt: "Before"
                },
                dateRange: {
                    year: 'This Year',
                    lastYear: 'Last Year',
                    ytd: 'Year to Date',
                    lastYtd: 'Last Year to Date',
                    quarter: 'This Quarter',
                    lastQuarter: 'Last Quarter',
                    qtd: 'Quarter to Date',
                    month: 'This Month',
                    lastMonth: 'Last Month',
                    nextMonth: 'Next Month',
                    mtd: 'Month to Date',
                    week: 'This Week',
                    lastWeek: 'Last Week',
                    nextWeek: 'Next Week',
                    today: 'Today',
                    yesterday: 'Yesterday'
                },
                number: {
                    between: 'Between or equal to',
                    notBetween: 'Not Between or equal to',
                    eq: EQ,
                    neq: NEQ,
                    gte: "Greater or equal to",
                    gt: "Greater",
                    lte: "Less or equal to",
                    lt: "Less"
                },
                string: {
                    eq: EQ,
                    neq: NEQ,
                    startswith: "Start with",
                    contains: "Contain",
                    doesnotcontain: "Does not contain",
                    endswith: "End with"
                },
                enums: {
                    eq: EQ,
                    neq: NEQ
                },
                lookup: {
                    eq: EQ,
                    neq: NEQ
                }
            };
        }
        /**
         * @function toOdataFilter
         * @param {object} filter - an object with javascript values
         * @return {object} with values in the proper string Odata format
         */
        function toOdataFilter(filter) {
            var result = [],
                logic = filter.logic || "and",
                idx,
                length,
                field,
                type,
                format,
                operator,
                value,
                // ignoreCase,
                filters = filter.filters,
                isColumnValue;

            for (idx = 0, length = filters.length; idx < length; idx++) {
                filter = filters[idx];
                field = filter.field;
                value = filter.value;
                operator = filter.operator;
                isColumnValue = filter.isColumnValue;

                if (filter.filters) {
                    filter = toOdataFilter(filter);
                } else if(field) {
                    // ignoreCase = filter.ignoreCase;
                    field = field.replace(/\./g, "/");
                    filter = odataFilters[operator];

                    if(filter === "isnull"){
                        filter = kendo.format("{0} eq null", field);
                    }
                    else if(filter === "isnotnull"){
                        filter = kendo.format("{0} ne null", field);
                    }
                    else if (filter && value !== undefined) {
                        type = $.type(value);
                        if (type === "string" && !isColumnValue && value !== 'null') {
                            var parsedDateValue;
                            if (value === '@nim_now') {
                                parsedDateValue = new Date();
                            }
                            else if (value === '@nim_today') {
                                result.push(
                                    todayFilter(field, filter)
                                );
                                continue;
                            }
                            else {
                                parsedDateValue = kendo.parseDate(value, ['yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                            }

                            if(parsedDateValue instanceof Date){
                                value = parsedDateValue.toISOString();
                                format = "datetime'{1}'";
                            }
                            else {
                                format = "'{1}'";
                                value = value.replace(/'/g, "''");

                                // if (ignoreCase === true) {
                                //     field = "tolower(" + field + ")";
                                // }
                            }

                        } else if (type === "date") {
                            value = value.toISOString();
                            format = "datetime'{1}'";
                        } else {
                            format = "{1}";

                            if(type === 'number'){
                                value += 'f';
                            }
                        }

                        if (filter.length > 3) {
                            if (filter !== "substringof") {
                                format = "{0}({2}," + format + ")";
                            } else {
                                format = "{0}(" + format + ",{2})";
                                if (operator === "doesnotcontain") {
                                    format += " eq false";
                                }
                            }
                        } else {
                            format = "{2} {0} " + format;
                        }
                        filter = kendo.format(format, filter, value, field);
                    }
                }

                result.push(filter);
            }

            filter = result.join(" " + logic + " ");

            //if (result.length > 1) {
                filter = "(" + filter + ")";
            //}

            return filter;
        }
        /**
         * @function todayFilter
         */
        function todayFilter(field, operator) {
            var format = '',
                startOfDay = moment().startOf('d').toISOString(),
                endOfDay = moment().endOf('d').toISOString();
            
            switch (operator){
                case 'ne':
                    format = '({0} lt datetime\'{1}\' or {0} gt datetime\'{2}\')';
                    break;

                case 'ge':
                    format = '{0} ge datetime\'{1}\'';
                    break;

                case 'gt':
                    format = '{0} gt datetime\'{2}\'';
                    break;
                    
                case 'le':
                    format = '{0} le datetime\'{2}\'';
                    break;
                    
                case 'lt':
                    format = '{0} lt datetime\'{1}\'';
                    break;

                default: // eq
                    format = '({0} ge datetime\'{1}\' and {0} le datetime\'{2}\')';
            }

            return kendo.format(format, field, startOfDay, endOfDay);
        }
        /**
         * @function toOdataString
         * @param {object} operator - Odata operator
         * @param {Array} values - left and values for operator comparison
         * @param {object} field - field name for filtering by
         * @return {string} URL parameter for oData
         */
        function toOdataString(operator, values, field) {
            values = values.map(function(val){
                if(typeof val === 'number'){
                    return val + 'f';
                }
            });

            var filter = {
                logic: 'and',
                filters: [
                    {
                        field: field,
                        value: values[0],
                        operator: operator
                    }
                ]
            };
            if (operator === 'between') {
                filter.filters[0].operator = 'gte';
                filter.filters[1] = {
                    field: field,
                    value: values[1],
                    operator: 'lte'
                };
            }
            if (operator === 'notBetween') {
                filter.filters[0].operator = 'lte';
                filter.filters[1] = {
                    field: field,
                    value: values[1],
                    operator: 'gte'
                };
            }
            return filter;
        }
    })
;