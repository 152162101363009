angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name FormsDesignerService
 * @alias cerberus/forms-designer:FormsDesignerService
 * @description Makes calls transformative calls to the "forms" resource
 */
    .factory('FormsDesignerService', function FormsDesignerService(_, $http, $q, apiPath, WidgetsService, toaster, DesignerUtilityService) {
        return {
            create: create,
            remove: remove,
            publish: publish,
            createRevision: createRevision,
            getRevisions: getRevisions,
            moveArrayItem: moveArrayItem,
            breakingChangesCheck: breakingChangesCheck,
            addSectionToForm: addSectionToForm,
            removeSectionFromForm: removeSectionFromForm,
            updateSection: updateSection,
            sectionConditionString: sectionConditionString,
            checkFormUsedInWorkflow: checkFormUsedInWorkflow
        };
        ////////////////////

        function checkFormUsedInWorkflow(formOriginId){
            var deferred = $q.defer();
            $http.get(apiPath + 'forms/'+ formOriginId + '/workflow/check')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * breakingChangesCheck
         * @param formId
         * @param modelId
         * @returns {*}
         */
        function breakingChangesCheck(formId, modelId){
            var deferred = $q.defer();
            $http.get(apiPath + 'forms/'+ formId +'/breaking/changes?modelId=' + modelId)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Creates a new form
         * @function create
         * @param {object} data - contains form definition
         * @param {string} widgetId
         * @returns {promise}
         */
        function create(data, widgetId){
            var deferred = $q.defer();
            $http.post(apiPath + 'widgets/'+ widgetId +'/forms', data)
                .success(function(value){
                    WidgetsService.getWidget(widgetId);
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Deletes a form
         * @function remove
         * @param {string} originatorId
         * @param {string} widgetId
         * @returns {promise}
         */
        function remove(originatorId, widgetId){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'forms/' + originatorId})
                .success(function(value){
                    WidgetsService.getWidget(widgetId);
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Makes the form the default form when called by originator Id
         * @function publish
         * @param {string} id
         * @returns {promise}
         */
        function publish(id) {
            var deferred = $q.defer();
            $http.put(apiPath + 'forms/' + id + '/publish')
                .success(function (value) {
                    toaster.pop('success', 'Form Published', 'This revision has been published.');
                    deferred.resolve(value);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * @function createRevision
         * @param {string} id - originator Id
         * @param {object} formModel
         * @returns {promise}
         */
        function createRevision(id, formModel) {
            var form = formModel.form;
            var formSorted = form.objects.sort(
                DesignerUtilityService.firstBy(function(v1,v2){return v1.row - v2.row;})
                    .thenBy(function(v1,v2){return v1.col - v2.col;})
            );
            var dataObject = {
                id: id,
                name: formModel.name,
                description: formModel.description,
                form: {
                    objects: formSorted,
                    settings: _.get(form, 'settings', {}),
                    sections: _.get(form, 'sections', [])
                }
            };
            var deferred = $q.defer();
            $http.put(apiPath + 'forms/' + id + '/revision', dataObject)
                .success(function(value){
                    toaster.pop('success', 'Form Saved', 'New revision added!');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * @function getRevisions
         * @param {string} id - originator Id
         * @returns {promise}
         */
        function getRevisions(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'forms/' + id + '/revision')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * moveArrayItem
         * @param newIndex
         * @param oldIndex
         * @param array
         * @param length
         */
        function moveArrayItem(newIndex, oldIndex, array, length) {
            //Handle Negative
            while (oldIndex < 0) {
                oldIndex += length;
            }
            //Handle Negative
            while (newIndex < 0) {
                newIndex += length;
            }
            //Pad the array with an undefined if newIndex is beyond the array (just in case)
            if (newIndex >= array.length) {
                var i = newIndex - array.length;
                while ((i--) + 1) {
                    array.push(undefined);
                }
            }
        }

        function addSectionToForm(form, section) {
            var sections = _.get(form, 'sections', []);
            sections.push(section);
            form.sections = sections;
        }

        /**
         * @function removeSectionFromForm
         * @description Removes section and its objects from form
         * @param form
         * @param index
         */
        function removeSectionFromForm(form, index){
            var section = form.sections[index];
            _.remove(form.sections, section);       // Removes section from form
            _.remove(form.objects, function(obj){   // Removes form objects in section from form
                return obj.model.display.section === section.name;
            });
        }

        function updateSection(form, section, index){
            var oldName = form.sections[index].name;
            _.assign(form.sections[index], section);

            _.forEach(form.objects, function(obj){
                if(obj.model.display.section === oldName){
                    obj.model.display.section = section.name;
                }
                else if(!_.has(obj.model.display, 'section')){
                    obj.model.display.section = false;
                }
            });
        }

        function sectionConditionString(condition){
            return condition.modelId + ' ' + condition.op + ' "' + condition.val + '"';
        }
    })
;
