angular.module('cerberus.admin')
    .controller('ViewsDesignerAdvCtrl', function ViewsDesignerAdvCtrl($scope, CodeMirror, $timeout, DesignerUtilityService, ViewsDesignerService, ViewsPropertiesService, WidgetsService) {
        var vm = this;
        $scope.viewDesignerAdvCtrl.vm = vm;
        vm.advModel = {};
        vm.advModelOrig = {};
        vm.sql = '';
        vm.sqlOrig = '';
        vm.widgets = [];
        vm.widgetIds = [];
        vm.tempViewProp = [];
        vm.origViewProp = [];
        vm.editPropIndex = null;
        vm.editProp = null;
        vm.agg = {};
        vm.preventSpace = DesignerUtilityService.preventSpace;
        // Codemirror
        vm.codemirrorLoaded = function(_editor){
            var timeout;
            // Editor part
            var _doc = _editor.getDoc();

            // Options
            _editor.setOption('theme', 'nimcalc');
            _editor.setOption('lineWrappißng', true);
            _editor.setOption('matchBrackets', true);
            _editor.setOption('autoCloseBrackets', "()[]\"\"");
            _editor.setOption('indentWithTabs', true);
            _editor.setOption('autofocus', true);
            _editor.setOption('lineNumbers', true);
            _editor.setOption('mode', 'text/x-pgsql');
            _editor.setSize('100%', 500);
            _doc.markClean();
            _editor.on("inputRead", function(cm) {
                if (timeout) {
                    clearTimeout(timeout);
                }
                timeout = $timeout(function() {
                    if (cm.getTokenAt(cm.getCursor()).string != ' ') {
                        CodeMirror.showHint(cm, CodeMirror.hint.sql, {
                            completeSingle: false
                            // TODO: Pass in two options
                            // tables: an object with table names as keys and array of respective column names as values.
                            // defaultTable: a string corresponding to a table name in tables for autocompletion.
                        });
                    }
                }, 150);
            });
        };
        vm.addProperty = function(data,type){
            var modeledData = ViewsPropertiesService.modelData(data,true,type);
            if(modeledData.isValid){
                ViewsPropertiesService.add($scope.viewId,modeledData.d).then(function(){
                    vm.tempViewProp.push(modeledData.d);
                    data.name = null;
                    data.description = null;
                    data.adv = null;
                    data.where = null;
                });
            }
        };
        vm.delProperty = function(property){
            var id = property.id;
            ViewsPropertiesService.remove(id).then(function(){
                var index = vm.tempViewProp.indexOf(property);
                vm.tempViewProp.splice(index,1);
            });
        };
        vm.editProperty = function(index, property){
            vm.editPropIndex = index;
            vm.editProp = angular.copy(property);
        };

        vm.cancelProperty = function(){
            vm.editProp = null;
            vm.editPropIndex = null;
        };
        vm.updateProperty = function(prop, data){
            if(data.name && data.property.adv){
                ViewsPropertiesService.update(prop.id, data).then(function(){
                    angular.extend(prop, data);
                    vm.cancelProperty();
                });
            }
        };
        vm.bindWidgetDependencies = function(e){
            e.sender.value(vm.advModel.widgetDependencies);
        };
        //Init function
        _init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function _init() {
            WidgetsService.getWidgets().then(function (d) {
                vm.widgets = d;
                vm.widgetIds = d.map(function(e){return {name: e.name, id: parseId(e.id)};});
            });
            ViewsDesignerService.getModel($scope.viewId).then(function(d){
                vm.advModel = d;
                vm.advModelOrig = angular.copy(vm.advModel);
            });
            ViewsPropertiesService.get($scope.viewId).then(function(d){
                vm.tempViewProp = d;
                vm.origViewProp = angular.copy(vm.tempViewProp);
            });
        }

        function parseId(fullId){
            var components = fullId.split('-');
            var id = components[components.length - 1];
            return parseInt(id);
        }
    })
;