angular.module('cerberus')
    .run(function ($rootScope, $, kendo, CodeMirror, localStorageService) {
        // Recover Session
        $rootScope.authToken = localStorageService.get('jwt');

        //Ajax handle json prefix
        $.ajaxSetup({
            dataFilter: function(data){
                // Do not try to run substring on non-existent data
                // Also, check if our prefix is present
                if(data && data.indexOf(')]}\',') === 0) {
                    return data.substring(5, data.length);
                }

                return data;
            },
            headers: {
                //'X-Requested-With': 'XMLHttpRequest' // for CSRF protection
            }
        });

        // To stop PostgreSQL MIME mode from commenting lines that have a hash (#)
        CodeMirror.mimeModes['text/x-pgsql'].support.commentHash = false;

        // Kendo Configs
        kendo.ui.editor.ColorTool.prototype.options.palette = null;
        kendo.pdf.defineFont({
            // DejaVu Sans
            "DejaVu Sans": "/assets/fonts/DejaVu/DejaVuSans.ttf",
            "DejaVu Sans|Bold": "/assets/fonts/DejaVu/DejaVuSans-Bold.ttf",
            "DejaVu Sans|Bold|Italic": "/assets/fonts/DejaVu/DejaVuSans-BoldOblique.ttf",
            "DejaVu Sans|Italic": "/assets/fonts/DejaVu/DejaVuSans-Oblique.ttf",

            // Sans-serif
            "sans-serif": "/assets/fonts/DejaVu/DejaVuSans.ttf",
            "sans-serif|Bold": "/assets/fonts/DejaVu/DejaVuSans-Bold.ttf",
            "sans-serif|Bold|Italic": "/assets/fonts/DejaVu/DejaVuSans-BoldOblique.ttf",
            "sans-serif|Italic": "/assets/fonts/DejaVu/DejaVuSans-Oblique.ttf",

            // DejaVu Serif
            "DejaVu Serif": "./assets/fonts/DejaVu/DejaVuSerif.ttf",
            "DejaVu Serif|Bold": "./assets/fonts/DejaVu/DejaVuSerif-Bold.ttf",
            "DejaVu Serif|Bold|Italic": "./assets/fonts/DejaVu/DejaVuSerif-BoldOblique.ttf",
            "DejaVu Serif|Italic": "./assets/fonts/DejaVu/DejaVuSerif-Oblique.ttf",

            // Serif
            "serif": "/assets/fonts/DejaVu/DejaVuSerif.ttf",
            "serif|Bold": "/assets/fonts/DejaVu/DejaVuSerif-Bold.ttf",
            "serif|Bold|Italic": "/assets/fonts/DejaVu/DejaVuSerif-BoldOblique.ttf",
            "serif|Italic": "/assets/fonts/DejaVu/DejaVuSerif-Oblique.ttf",

            // Open Sans
            "Open Sans Condensed": "/assets/fonts/Open_Sans_Condensed/OpenSans-CondLight.ttf",
            "Open Sans Condensed|Italic": "/assets/fonts/Open_Sans_Condensed/OpenSans-CondLightItalic.ttf",
            "Open Sans Condensed|Bold": "/assets/fonts/Open_Sans_Condensed/OpenSans-CondBold.ttf",

            // Roboto
            "Roboto": "/assets/fonts/Roboto/Roboto-Regular.ttf",
            "Roboto|Italic": "/assets/fonts/Roboto/Roboto-Italic.ttf",
            "Roboto|Bold": "/assets/fonts/Roboto/Roboto-Bold.ttf",
            "Roboto|Bold|Italic": "/assets/fonts/Roboto/Roboto-BoldItalic.ttf",
            "Roboto|700": "/assets/fonts/Roboto/Roboto-Bold.ttf"
        });
        kendo.dataviz.ui.registerTheme('intellibrief',
            {
                chart: {
                    title: { color: '#444444', font: '16px Open Sans Condensed' },
                    legend: {
                        labels: { color: '#444444', font: '12px Roboto' },
                        inactiveItems: {
                            labels: { color: '#999999' },
                            markers: { color: '#9A9A9A' }
                        }
                    },
                    seriesDefaults: {
                        labels: { color: '#444444', font: '11px Roboto' },
                        overlay: { gradient: 'none' },
                        errorBars: { color: '#343434' },
                        notes: {
                            icon: {
                                background: '#000000',
                                border: { color: '#000000' }
                            },
                            label: { color: '#444444', font: '11px Roboto' },
                            line: { color: '#000000' }
                        },
                        pie: { overlay: { gradient: 'none' } },
                        donut: { overlay: { gradient: 'none' } },
                        line: { markers: { background: '#ffffff' } },
                        bubble: { opacity: 0.6 },
                        scatter: { markers: { background: '#ffffff' } },
                        scatterLine: { markers: { background: '#ffffff' } },
                        area: { opacity: 0.8 },
                        candlestick: {
                            downColor: '#d0d0d0',
                            line: { color: '#444444' },
                            border: {
                                _brightness: 1.5,
                                opacity: 1
                            },
                            highlight: {
                                border: {
                                    color: '#b8b8b8',
                                    opacity: 0.2
                                }
                            }
                        },
                        waterfall: { line: { color: '#cccccc' } },
                        horizontalWaterfall: { line: { color: '#cccccc' } },
                        ohlc: { line: { color: '#444444' } }
                    },
                    chartArea: { background: '#ffffff' },
                    seriesColors: [
                        '#00bcd4',
                        '#e91e63',
                        '#ffc107',
                        '#673ab7',
                        '#cddc39',
                        '#795548',
                        '#2196f3',
                        '#ff5722',
                        '#9c27b0',
                        '#607d8b',
                        '#333333',
                        '#ffeb3b'
                    ],
                    axisDefaults: {
                        line: { color: '#cccccc' },
                        minorTicks: { color: '#ebebeb' },
                        majorTicks: { color: '#cccccc' },
                        labels: { color: '#333333', font: '12px Roboto' },
                        majorGridLines: { color: '#cccccc' },
                        minorGridLines: { color: '#ebebeb' },
                        title: { color: '#444444' },
                        crosshair: { color: '#000000' },
                        notes: {
                            icon: {
                                background: '#000000',
                                border: { color: '#000000' }
                            },
                            label: { color: '#ffffff', font: '11px Roboto' },
                            line: { color: '#000000' }
                        }
                    }
                },
                gauge: {
                    pointer: { color: '#0074b1' },
                    scale: {
                        rangePlaceholderColor: '#cccccc',
                        labels: { color: '#444444', font: '12px Roboto' },
                        minorTicks: { color: '#ebebeb' },
                        majorTicks: { color: '#cccccc' },
                        line: { color: '#cccccc' }
                    }
                },
                diagram: {
                    shapeDefaults: {
                        fill: { color: '#0074b1' },
                        connectorDefaults: {
                            fill: { color: '#444444' },
                            stroke: { color: '#ffffff' },
                            hover: {
                                fill: { color: '#ffffff' },
                                stroke: { color: '#333333' }
                            }
                        },
                        content: { color: '#333333' }
                    },
                    editable: {
                        resize: {
                            handles: {
                                fill: { color: '#ffffff' },
                                stroke: { color: '#444444' },
                                hover: {
                                    fill: { color: '#444444' },
                                    stroke: { color: '#444444' }
                                }
                            }
                        },
                        rotate: {
                            thumb: {
                                stroke: { color: '#444444' },
                                fill: { color: '#444444' }
                            }
                        }
                    },
                    selectable: { stroke: { color: '#444444' } },
                    connectionDefaults: {
                        stroke: { color: '#c4c4c4' },
                        content: { color: '#444444' },
                        selection: {
                            handles: {
                                fill: { color: '#ffffff' },
                                stroke: { color: '#444444' }
                            },
                            stroke: { color: '#444444' }
                        }
                    }
                },
                treeMap: {
                    colors: [
                        ['#428bca', '#d1e0ec'],
                        ['#5bc0de', '#d6eaf0'],
                        ['#5cb85c', '#d6e9d6'],
                        ['#5cb85c', '#f4e8d7'],
                        ['#e67d4a', '#f2ddd3'],
                        ['#da3b36', '#f0d0cf']
                    ]
                }
            }
        );
    }
)
;