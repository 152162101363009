angular.module('cerberus.account')
    .controller('OrganizationsMailServersDetailsCtrl', function OrganizationsMailServersDetailsCtrl($scope, $location, orgId, serverId, OrganizationsService, usersProfileService) {
        $scope.pageTitle = 'Mail Server Details';
        $scope.activeRoute = 'mail-servers';
        $scope.orgId = orgId;
        $scope.serverId = serverId;
        $scope.orgData = {};
        $scope.masterServer = {};
        $scope.server = {};
        $scope.verifyHtml = '<span class="text-warning">Unverified</span>';
        $scope.versions = [
            {display: "2003"},
            {display: "2007"},
            {display: "2010"}
        ];

        $scope.isUnchanged = _isUnchanged;
        $scope.submit = _submit;
        $scope.cancel = _close;
        $scope.verifyConn = _verifyConn;

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            OrganizationsService.getAsset(orgId, serverId).then(function(server){
                $scope.masterServer = angular.copy(server.data);
                $scope.server = angular.copy(server.data);
            });
        }

        function _isUnchanged(server){
            return angular.equals(server, $scope.masterServer);
        }

        function _submit(isValid, server){
            if(isValid){
                var data = {
                    data: server,
                    type: "Mail Server"
                };

                OrganizationsService.updateAsset(orgId, serverId, data).then(_close);
            }
        }

        function _close(){
            $location.url('/organizations/' + orgId + '/settings/mail-servers');
        }

        function _verifyConn(){
            $scope.verifyHtml = '<i class="fa fa-spinner"></i>';
            usersProfileService.verifyMailServerConnection($scope.server).then(function(data){
                if(data){
                    $scope.verifyHtml = '<span class="text-success">Success</span>';
                }
                else{
                    $scope.verifyHtml = '<span class="text-danger">Failed</span>';
                }
            });
        }
    })
;