angular.module('cerberus.les')
    .controller('LESLoanApplicationRegistrationModalCtrl', function (_, kendo, $scope, $uibModalInstance, loanApplicationId, productId, product, pricing, queryParams, lesLoanApplicationsService) {
        var vm = this;

        vm.loanApplication = {};
        vm.product = product;
        vm.pricing = pricing;
        vm.registering = false;
        
        vm.ok = ok;
        vm.cancel = cancel;

        init();

        function init(){
            lesLoanApplicationsService.getLoanApplication(loanApplicationId).then(function (loanApplication) {
                if(loanApplication.recordData.findings){
                    vm.loanApplication = loanApplication.recordData.loanApp;
                }
                else{
                    vm.loanApplication = loanApplication.recordData;
                }
            });
        }

        function ok() {
            // var registrationData = _.assign({
            //     product: {
            //         id: productId,
            //         display: product.productName
            //     },
            //     conduit: product.conduit || {},
            //     docType: product.docType
            // }, pricing);
            var registrationData = {
                programId: product.programId,
                productId: productId,
                docType: product.docType,
                baseRate: pricing.baseRate,
                rate: pricing.rate,
                conduit: product.conduit || {}
            };

            vm.registering = true;
            lesLoanApplicationsService.updateLoan(loanApplicationId, {
                action: 'registered',
                data: registrationData
            }, queryParams).then(function () {
                vm.registering = false;
                $uibModalInstance.close();
            }, function () {
                vm.registering = false;
            });

        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Prevents navigation while modal is open
        vm.allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            vm.allowNavigation();
        });
    })
;