angular.module('cerberus.admin')
    .controller('WidgetsImportsCtrl', function WidgetsImportsCtrl($scope, $location, widgetId, ImportService, WidgetsService, WidgetsSettingsService) {

        /* Populate Scope */
        $scope.widgetPublicKey = widgetId;
        $scope.widgetModel = {};
        $scope.importSettingsArray = [];
        $scope.importJobs = [];
        $scope.formFieldsArray = [];
        $scope.activeRoute = 'imports';
        $scope.newImportCollapse = true;

        /*
         * Import Functions and Settings
         */
        $scope.deleteImportConfig = function(id){
            ImportService.deleteConfig(id).then(function(){
                for(var i = 0; i < $scope.importSettingsArray.length; i++ ){
                    if($scope.importSettingsArray[i].id === id){
                        $scope.importSettingsArray.splice(i,1);
                        break;
                    }
                }
            });
        };
        $scope.goToImportSettings = function(id){
            if(id){
                $location.url('/settings/widgets/' + $scope.widgetPublicKey + '/imports/rules/' + id);
            }
            else{
                $location.url('/settings/widgets/' + $scope.widgetPublicKey + '/imports/rules/create');
            }
        };
        $scope.goToImportJobs = function(){
            $location.url('/settings/widgets/' + $scope.widgetPublicKey + '/imports/jobs');
        };

        $scope.rollback = function(item){
            ImportService.rollback(item.id).then(function () {
                $scope.importJobs.splice(0, 1);
            });
        };
        $scope.deleteJob = function(id, idx){
            ImportService.deleteJob(id).then(function(){
                $scope.importJobs.splice(idx, 1);
            });
        };

        $scope.startImportJob = function(id, idx){
            $scope.importJobs[idx].status = 'Importing';
            ImportService.startImport(id, $scope.importJobs[idx].name);
        };

        $scope.validateImport = function(id){
            ImportService.validateImport(id).then(function(){
                ImportService.getAllJobs($scope.widgetPublicKey).then(function(data){
                    $scope.importJobs = angular.copy(data);
                });
            });
        };

        _init();

        function _init(){
            WidgetsService.getWidget(widgetId).then(function(widgetModel){
                $scope.widgetModel = widgetModel;
            });

            ImportService.getAllSettings(widgetId).then(function(importSettings){
                $scope.importSettingsArray = importSettings;
            });

            ImportService.getAllJobs(widgetId).then(function(importJobs){
                $scope.importJobs = importJobs;
            });

            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldsArray){
                $scope.formFieldsArray = formFieldsArray;
            });
        }
    })
;