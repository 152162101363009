angular.module('cerberus.admin')
    .config(function($routeProvider){
        $routeProvider.when('/settings/rules', {
            templateUrl: 'admin/rules/rules.tpl.html',
            controller: 'RulesCtrl',
            title: 'Rules',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });

        $routeProvider.when('/settings/rules/add', {
            templateUrl: 'admin/rules/rules-create.tpl.html',
            controller: 'RulesCreateCtrl',
            controllerAs: 'vm',
            title: 'New Rule',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });

        $routeProvider.when('/settings/rules/:id', {
            templateUrl: 'admin/rules/rules-details.tpl.html',
            controller: 'RulesDetailsCtrl',
            controllerAs: 'vm',
            title: 'Edit Rule',
            resolve: {
                _wait: 'WaitForUserData',
                ruleId: function(WaitForUserData, $route){
                    return WaitForUserData.then(function(){
                        return $route.current.params.id;
                    });
                }
            }
        });
    })
;