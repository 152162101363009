angular.module('cerberus.core')
    .controller('PagesNestedCtrl', function PagesNestedCtrl(_, $scope, $timeout, PagesService) {
        $scope.nested = true;
        $scope.gridsterOptions = {
            mobileBreakPoint: 768,
            margins: [0,0],
            outerMargin: false,
            pushing: true,
            floating: true
        };
        $scope.getPage = function (id) {
            if(id) {
                PagesService.getPage(id)
                    .then(function (data) {
                        var resizeGridsterItem = $scope.resizeGridsterItem || angular.noop;
                        if (!angular.equals(data, $scope.pageModelNested)) {
                            $scope.pageModelNested = data;
                            $scope.gridsterOptions.margins = _.get($scope.pageModel, 'options.gridsterConfig.margins', [15, 15]);

                            // Protects against potential issue with gridster margins being saved as an object instead of an array
                            if(!_.isArray($scope.gridsterOptions.margins)){
                                $scope.gridsterOptions.margins = [
                                    $scope.gridsterOptions.margins[0],
                                    $scope.gridsterOptions.margins[1]
                                ];
                            }
                        }

                        $timeout(function(){
                            resizeGridsterItem();
                        }, 400);
                    });
            }
        };
    })
;