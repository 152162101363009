angular.module('cerberus.core')
    .directive('nimVizChart', function() {
        return {
            restrict: 'AE',
            templateUrl: function (tElement, tAttributes) {
                if (tAttributes.subType) {
                    if (tAttributes.template) {
                        return 'core/pages/viz/' + tAttributes.subType + '/chart-' + tAttributes.subType + '-' + tAttributes.template + '.tpl.html';
                    }
                    else {
                        return 'core/pages/viz/' + tAttributes.subType + '/chart-' + tAttributes.subType + '.tpl.html';
                    }
                }
                else {
                    return 'core/pages/viz/chart/chart.tpl.html';
                }
            },
            scope: true,
            require: '^nimPageObject',
            controller: "@",
            name: 'controllerName',
            controllerAs: 'vm'
        };
    })
;
