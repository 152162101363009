angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name AuthHttpBuffer
     * @alias cerberus/auth:AuthHttpBuffer
     * @description Holds failed http calls when 401 is received; can reject all promises or retry all calls
     */
    .factory('AuthHttpBuffer', function($injector) {
        /** Holds all the requests, so they can be re-requested in future. */
        var buffer = [];
        /** Service initialized later because of circular dependency problem. */
        var $http;

        return {
            append: append,
            rejectAll: rejectAll,
            retryAll: retryAll
        };
        ////////////////////
        /**
         * This will retry an http request
         * @function retryHttpRequest
         * @param {object} config - the configuration of the original call
         * @param {promise} deferred - the promise from original call
         */
        function retryHttpRequest(config, deferred) {
            function successCallback(response) {
                deferred.resolve(response);
            }
            function errorCallback(response) {
                deferred.reject(response);
            }
            $http = $http || $injector.get('$http');
            $http(config).then(successCallback, errorCallback);
        }

        /**
         * Appends HTTP request configuration object with deferred response attached to buffer.
         * @function append
         * @param {object} config
         * @param {promise} deferred
         */
        function append(config, deferred) {
            buffer.push({
                config: config,
                deferred: deferred
            });
        }

        /**
         * Abandon or reject (if reason provided) all the buffered requests.
         * @function rejectAll
         * @param {*|object} reason
         */
        function rejectAll(reason) {
            if (reason) {
                for (var i = 0; i < buffer.length; ++i) {
                    buffer[i].deferred.reject(reason);
                }
            }
            buffer = [];
        }

        /**
         * Retries all the buffered requests clears the buffer.
         * @function retryAll
         * @param {function} updater - a function to update or change configuration before retrying
         *
         */
        function retryAll(updater) {
            for (var i = 0; i < buffer.length; ++i) {
                retryHttpRequest(updater(buffer[i].config), buffer[i].deferred);
            }
            buffer = [];
        }

    })
;