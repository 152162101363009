angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name ViewsService
 * @alias cerberus/views:ViewsService
 * @description Provides REST service for "views" resource
 */
    .factory('ViewsService', function ViewsService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        // ViewsArray cache, I am not sure if this is a good or bad idea.
        // Angular has its own caching system...
        var viewsArray = [];
        return {
            views: views,
            get: get,
            getDetails: getDetails,
            getColumns: getColumns,
            create: create,
            remove: remove
        };
        ////////////////////
        /**
         * This function makes a convenient function for watcher
         * @function views
         * @return {Array} viewsArray
         */
        function views() {
            return viewsArray;
        }

        /**
         * Makes a get call REST api "views" resource
         * @function get
         * @return {promise}
         */
        function get(full) {
            var fullData = false;
            if(full){
                fullData = full;
            }
            var deferred = $q.defer();
            $http.get(apiPath + 'views?full=' + fullData)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Makes a get call REST api "views/{id}/details" resource
         * @function getDetails
         * @param {string} id - view id
         * @return {promise}
         */
        function getDetails(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'views/' + id + '/details')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Makes a get call REST api "views/{id}/columns" resource
         * @function getColumns
         * @param {string} id - view id
         * @returns {promise}
         */
        function getColumns(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'views/' + id + '/columns')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
        /**
         * Makes a post call REST api "views" resource
         * @function create
         * @param {View} data - view object
         * @return {promise}
         */
        function create(data){
            var deferred = $q.defer();
            $http.post(apiPath + 'views',data)
                .success(function(value){
                    toaster.pop('success', 'View Created.');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Makes a delete call REST api "views" resource by id
         * @function remove
         * @param {string} id
         * @return {promise}
         */
        function remove(id){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'views/' + id + '/details'})
                .success(function(value){
                    toaster.pop('info', 'View Deleted');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;
