angular.module('cerberus.ui')
    .controller('ExportConfigModalCtrl', function(_, $scope, $uibModalInstance, defaultConfig){
        var vm = this;

        vm.exportConfig = {
            type: 'pdf',
            filename: 'download',
            options: {
                paperSize: 'letter',
                landscape: false,
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            }
        };

        vm.exportTypes = [
            {value: 'pdf', display: 'PDF'},
            {value: 'png', display: 'PNG'},
            {value: 'svg', display: 'SVG'}
        ];

        vm.supportedPaperSizes = [
            {value: 'auto', display: '(AUTO)'},
            //{value: 'a0', display: 'A0'},
            //{value: 'a1', display: 'A1'},
            //{value: 'a2', display: 'A2'},
            //{value: 'a3', display: 'A3'},
            //{value: 'a4', display: 'A4'},
            //{value: 'a5', display: 'A5'},
            //{value: 'a6', display: 'A6'},
            //{value: 'a7', display: 'A7'},
            //{value: 'a8', display: 'A8'},
            //{value: 'a9', display: 'A9'},
            //{value: 'a10', display: 'A10'},
            //{value: 'b0', display: 'B0'},
            //{value: 'b1', display: 'B1'},
            //{value: 'b2', display: 'B2'},
            //{value: 'b3', display: 'B3'},
            //{value: 'b4', display: 'B4'},
            //{value: 'b5', display: 'B5'},
            //{value: 'b6', display: 'B6'},
            //{value: 'b7', display: 'B7'},
            //{value: 'b8', display: 'B8'},
            //{value: 'b9', display: 'B9'},
            //{value: 'b10', display: 'B10'},
            //{value: 'c0', display: 'C0'},
            //{value: 'c1', display: 'C1'},
            //{value: 'c2', display: 'C2'},
            //{value: 'c3', display: 'C3'},
            //{value: 'c4', display: 'C4'},
            //{value: 'c5', display: 'C5'},
            //{value: 'c6', display: 'C6'},
            //{value: 'c7', display: 'C7'},
            //{value: 'c8', display: 'C8'},
            //{value: 'c9', display: 'C9'},
            //{value: 'c10', display: 'C10'},
            {value: 'executive', display: 'Executive'},
            {value: 'folio', display: 'Folio'},
            {value: 'legal', display: 'Legal'},
            {value: 'letter', display: 'Letter'},
            {value: 'tabloid', display: 'Tabloid'}
        ];

        vm.pdfOrientations = [
            {value: false, display: 'Portrait'},
            {value: true, display: 'Landscape'}
        ];

        vm.fileTypeOptions = {
            pdf: {
                paperSize: 'Letter',
                landscape: false,
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            png: {
                height: '',
                width: ''
            },
            svg: {}
        };

        vm.confirm = _confirm;
        vm.cancel = _cancel;
        vm.getFileTypeOptions = _getFileTypeOptions;

        if(!_.isEmpty(defaultConfig)){
            vm.exportConfig = _.defaultsDeep(defaultConfig, vm.exportConfig);
        }

        function _confirm(config){
            $uibModalInstance.close(config);
        }

        function _cancel(){
            $uibModalInstance.dismiss('Canceled');
        }

        function _getFileTypeOptions(type){
            return angular.copy(vm.fileTypeOptions[type]);
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;