angular.module('cerberus.admin')
    .controller('PageObjectsCtrl', function PageObjectsCtrl($scope, $timeout, $location){
        $scope.$on('nim-page-object-list-selected', function(e, pageObject){
            e.stopPropagation();
            // Navigate to Page Object Designer when page object is selected
            $timeout(function(){
                $location.url('/settings/page-objects/' + pageObject.id);
            });
        });
    })
;