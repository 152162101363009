angular.module('cerberus.admin')
    .controller('WorkflowsDesignerCtrl', function WorkflowDesignerCtrl(kendo, $log, moment, $scope, workspaceId, $timeout, $uibModal, $location, toaster, _, originId,
                                                                       DesignerUtilityService, WorkflowsService, WorkflowsClassService, WorkflowDiagramService) {
        $scope.originId = originId;

        $scope.copiedWorkflowObject = null;

        $scope.revisionsCollapse = true;

        $scope.diagram = null;
        $scope.diagramZoom = 100;

        $scope.lastPos = 75;

        var undoing = false;
        var checkingConnections = false;

        //var Shape = kendo.dataviz.diagram.Shape,
        //    Connection = kendo.dataviz.diagram.Connection,
        var Connector = kendo.dataviz.diagram.Connector;

        $scope.connectorConfig = WorkflowDiagramService.connectorConfig();
        $scope.shapeConfig = WorkflowDiagramService.shapeConfig();

        $scope.diagramZoomOptions = {
            min: 10,
            max: 200,
            value: 100,
            smallStep: 10,
            largeStep: 50,
            tickPlacement: 'none',
            showButtons: false,
            change: updateSliderIndicator,
            slide: updateSliderIndicator
        };

        $scope.hasChanges = function(){
            return !angular.equals($scope.workflowData, $scope.masterWorkflowData);
        };

        DesignerUtilityService.cancelRouteNav($scope, $scope.hasChanges);

        $scope.close = function(){
            $location.url('/settings/widgets/' + $scope.workflowData.widgetId + '/workflow');
        };

        $scope.save = function(after){
            var workflowCopy = angular.copy($scope.workflowData.workflow);
            WorkflowsService.newRevision({workflowData: _.omit(workflowCopy, 'settings')}, $scope.originId).then(function(value){
                $scope.workflowId = value.id;
                $scope.masterWorkflowData = angular.copy($scope.workflowData);
                if(after){
                    after();
                }
            });
        };

        $scope.publish = function(){
            WorkflowsService.publish($scope.workflowId).then(function(){
                $timeout(function(){
                    $scope.workflowData.published = true;
                    $scope.masterWorkflowData.published = true;
                    for(var r = 0; r < $scope.revisions.length; r++){
                        $scope.revisions[r].is_published = $scope.revisions[r].id == $scope.workflowId;
                    }
                });
            });
        };

        $scope.edit = function(shapeObj){
            var shapeOptions = shapeObj.options;
            var id = shapeObj.id;
            var workflowObj = $scope.workflowData.workflow.objects[id];
            $scope.openShapeModal(shapeOptions, workflowObj, id);
        };

        $scope.copyWFObject = function(){
            var selected = $scope.diagram.select();
            if(selected.length){
                var id = selected[0].id;
                $scope.copiedWorkflowObject = angular.copy($scope.workflowData.workflow.objects[id]);
                if($scope.copiedWorkflowObject.model.type == 'decision'){
                    $scope.copiedWorkflowObject.model.to = {};
                }
                else {
                    $scope.copiedWorkflowObject.model.to = [];
                }
            }
        };

        $scope.pasteWFObject = function(id){
            if($scope.copiedWorkflowObject && id){
                if($scope.copiedWorkflowObject.model.type == 'document') {
                    $scope.copiedWorkflowObject.display.order = $scope.workflowData.workflow.newIndex();
                }
                $scope.workflowData.workflow.objects[id] = $scope.copiedWorkflowObject;
            }
        };

        $scope.copy = function() {
            $scope.diagram.copy();
            $scope.copyWFObject();
        };

        $scope.remove = function(shapeObj){
            var id = shapeObj.id;
            if(id == '1'){
                $log.warn('Cannot delete Initial State');
            }
            else {
                $scope.diagram.remove(shapeObj);
            }
        };

        function trimParams(obj){
            var params = WorkflowsClassService.getParams(obj.model.subType);
            for(var p in params){
                if(params.hasOwnProperty(p) && obj.model.params[p]){
                    params[p] = obj.model.params[p];
                }
            }
            obj.model.params = params;
        }

        function updateSliderIndicator(e) {
            $timeout(function(){
                $scope.diagram.zoom(e.value / 100);
            });
        }

        $scope.zoomOut = function(){
            $timeout(function(){
                var newZoom = $scope.diagramZoom - $scope.diagramZoomOptions.smallStep;
                if(newZoom < $scope.diagramZoomOptions.min){
                    $scope.diagramZoom = $scope.diagramZoomOptions.min;
                }
                else {
                    $scope.diagramZoom = newZoom;
                }
                $scope.diagram.zoom($scope.diagramZoom / 100);
            });
        };

        $scope.zoomIn = function(){
            $timeout(function(){
                var newZoom = $scope.diagramZoom + $scope.diagramZoomOptions.smallStep;
                if(newZoom > $scope.diagramZoomOptions.max){
                    $scope.diagramZoom = $scope.diagramZoomOptions.max;
                }
                else {
                    $scope.diagramZoom = newZoom;
                }
                $scope.diagram.zoom($scope.diagramZoom / 100);
            });
        };

        $scope.diagramClickHandler = function(e){
            if(e.target.localName == 'circle'){
                $timeout(function(){
                    checkConnections();
                });
            }
        };

        $scope.diagramKeyHandler = function(e){
            if(e.keyCode == 46 && getSelectedId() == '1'){ // Delete
                e.stopImmediatePropagation();
            }
            else if(e.keyCode == 67 && e.ctrlKey){ // Copy
                $scope.copyWFObject();
            }
            else if(e.keyCode == 68 && e.ctrlKey){ // Duplicate
                $scope.copyWFObject();
                $timeout(function(){
                    $scope.pasteWFObject(getSelectedId());
                });
            }
            else if(e.keyCode == 86 && e.ctrlKey){ // Paste
                $timeout(function(){
                    $scope.pasteWFObject(getSelectedId());
                });
            }
            else if(e.keyCode == 88 && e.ctrlKey){ // Cut
                $scope.copyWFObject();
            }
            else if(e.keyCode == 89 && e.ctrlKey){ // Redo
                e.stopImmediatePropagation();
            }
            else if(e.keyCode == 90 && e.ctrlKey){ // Undo
                e.stopImmediatePropagation();
            }
        };

        function getSelectedId(){
            return _.get($scope.diagram.select()[0], 'id');
        }

        function connectionError(msg){
            toaster.pop('error', 'Connection Error', msg);
        }

        function checkConnections(){
            if(checkingConnections){
                return;
            } else {
                checkingConnections = true;
            }
            $timeout(function () {
                // Copies workflow objects and resets "to" attribute for each
                var workflowObjects = angular.copy($scope.workflowData.workflow.objects);
                for (var id in workflowObjects) {
                    if (workflowObjects.hasOwnProperty(id)) {
                        var workflowObj = workflowObjects[id];
                        if (workflowObj.model.type !== 'decision') {
                            workflowObj.model.to = [];
                        }
                    }
                }

                var pointOccupied = {}; // Used to track if each point is occupied
                var hasSource = {};
                for (var c = 0; c < $scope.diagram.connections.length; c++) {
                    var connection = $scope.diagram.connections[c];
                    var source = connection.source().shape;
                    var target = connection.target().shape;
                    var sourcePoint = connection.sourcePoint();
                    var targetPoint = connection.targetPoint();
                    var sourcePath = [sourcePoint, source.id];
                    var targetPath = [targetPoint, target.id];

                    // Checks if connected at both ends
                    if(!(connection.source() instanceof Connector) || !(connection.target() instanceof Connector)){
                        connectionError('Did not connect to second shape');
                        undo();
                        checkingConnections = false;
                        return;
                    }

                    // Checks if source is output node
                    if(connection.source().options.description != 'output'){
                        connectionError('Input nodes only accept incoming connections');
                        undo();
                        checkingConnections = false;
                        return;
                    }

                    // Checks if target is input node
                    if(connection.target().options.description != 'input'){
                        connectionError('Output nodes only accept outgoing connections');
                        undo();
                        checkingConnections = false;
                        return;
                    }

                    // Checks if target is State 1
                    if(connection.target().shape.id == '1'){
                        connectionError('State 1 does not accept incoming connections');
                        undo();
                        checkingConnections = false;
                        return;
                    }

                    // Checks if point already has connection
                    // Decisions are the exception
                    if (source.type !== 'decision' &&(_.get(pointOccupied, sourcePath, false) || _.get(pointOccupied, targetPath, false))) {
                        connectionError('Node already has connection');
                        undo();
                        checkingConnections = false;
                        return;
                    } else {
                        _.set(pointOccupied, sourcePath, true); // Each point is identified by position and shape id
                    }

                    // Checks if source Shape has too many outgoing connections
                    // If not, adds target to workflow object's "to" property
                    var to = workflowObjects[source.id].model.to;
                    if (source.type !== 'decision') {
                        if (to.length > 0) {
                            connectionError('This object may have only 1 output connection');
                            undo();
                            checkingConnections = false;
                            return;
                        } else {
                            to.push('' + target.id);
                        }
                    }
                    else {
                        if(!to[target.id]){
                            to[target.id] = {};
                        }
                    }

                    var targetHasSourcePath = [target.id, source.id];
                    // Checks if Shape has two incoming connections from the same source
                    if(_.get(hasSource, targetHasSourcePath, false)){
                        connectionError('This object may not have two connections from the same source.');
                        undo();
                        checkingConnections = false;
                        return;
                    } else {
                        _.set(hasSource, targetHasSourcePath, true);
                    }
                }

                $scope.workflowData.workflow.objects = workflowObjects;
                checkingConnections = false;
            });
        }

        function undo(){
            if(!undoing) {
                undoing = true;
                $scope.diagram.undo();
                $timeout(function(){
                    undoing = false;
                }, 1, false);
            }
        }

        $scope.getWorkflow = function(id){
            WorkflowsService.getRevision(id).then(function(wf){
                angular.extend($scope.masterWorkflowData, wf);
                $scope.workflowData = angular.copy($scope.masterWorkflowData);
                $scope.workflowId = wf.workflowId;
                $scope.diagram.load(WorkflowDiagramService.workflowModelToShapes($scope.workflowData.workflow));
            });
        };

        $scope.openShapeModal = function(shapeOptions, object, id){
            var shape = angular.copy(shapeOptions);
            var formIdArray = [];
            var states = [];
            if(id) {
                formIdArray = $scope.workflowData.workflow.getForms('' + id);
                states = $scope.workflowData.workflow.getStates('' + id);
            }

            var formIds = formIdArray.concat([]);
            if(object.model.type == 'document') {
                for (var fid = 0; fid < object.model.forms.length; fid++) {
                    if (formIds.indexOf(object.model.forms[fid]) == -1) {
                        formIds.push(object.model.forms[fid]);
                    }
                }
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/workflows/designer/workflow-shape-modal.tpl.html',
                controller: 'WorkflowsDesignerShapeModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    formIdArray: _.constant(formIdArray),
                    stateId: _.constant(id),
                    states: _.constant(states),
                    widgetId: _.constant($scope.workflowData.widgetId),
                    workflowObject: _.constant(object),
                    workspaceId: _.constant(workspaceId)
                }
            });
            modalInstance.result.then(function(wfObject){
                shape.content = shape.content || {};
                shape.content.text = wfObject.model.name;
                if(shape.x == null || shape.y == null) {
                    shape.x = $scope.lastPos;
                    shape.y = $scope.lastPos;
                    $scope.lastPos += 25;
                }
                if(wfObject.model.type == 'process'){
                    trimParams(wfObject);
                }
                if(id){
                    var shapeObject = $scope.diagram.getShapeById(id);
                    shapeObject.redraw(shape);
                    $scope.workflowData.workflow.objects[id] = wfObject;
                }
                else {
                    var addedShape = $scope.diagram.addShape(shape);
                    $scope.workflowData.workflow.objects[addedShape.id] = wfObject;
                }
            });
        };

        _init();

        function _init(){
            WorkflowsService.getPublishedWorkflow(originId).then(function(workflow){
                $scope.workflowId = workflow.workflowId;
                $scope.workflowData = angular.copy(workflow);
                $scope.masterWorkflowData = angular.copy(workflow);

                $scope.diagramOptions = WorkflowDiagramService.buildWFDiagram($scope, 'workflowData.workflow');
            });

            WorkflowsService.getRevisions(originId).then(function(revisions){
                $scope.revisions = revisions;

                _.forEach(revisions, function(rev){
                    var serverTime = rev.SYS_CREATED_DATE;
                    var m = moment.utc(serverTime,'MMMM, DD YYYY HH:mm:ss');

                    // Converts to local time
                    m.local();

                    rev.SYS_CREATED_DATE = m.toDate();
                });
            });
        }
    })
;