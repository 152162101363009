angular.module('cerberus.admin')
    .controller('RulesCtrl', function($scope, $location, $timeout, workspaceId, localStorageService, RulesService){
        $scope.ruleFilter = _ruleFilter;
        $scope.filterDataSource = _filterDataSource;
        $scope.rulesGridOptions = {
            columns: [
                {
                    title: 'Name',
                    field: 'name'
                },
                {
                    title: 'Engine',
                    field: 'type'
                },
                {
                    title: 'Path',
                    field: 'path'
                }
            ],
            dataSource: {
                transport: {
                    read: function(options){
                        RulesService.getAvailableRules().then(function(result){
                            options.success(result.rules);
                        });
                    }
                },
                schema: {
                    model: {
                        fields: {
                            name: {
                                type: 'string'
                            }
                        }
                    }
                },
                serverFiltering: false,
                filter: {
                    field: 'name',
                    operator: 'contains',
                    value: _ruleFilter()
                }
            },
            selectable: "row",
            change: function(e){
                var grid = e.sender,
                    selected = grid.select();

                if(selected.length > 0){
                    var ruleId = grid.dataItem(selected[0]).id;
                    $timeout(function(){
                        $location.replace().url('settings/rules/' + ruleId);
                    });
                }
            }
        };

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                if ($scope.rulesGrid) {
                    $scope.rulesGrid.destroy();
                    $scope.rulesGrid = null;
                }
            }    
        });

        // Getter-Setter function for filter
        function _ruleFilter(filterText){
            // Checks if value was passed in
            if(arguments.length){
                localStorageService.set('ruleFilter.' + workspaceId, filterText);
                return filterText || '';
            }
            else {
                return localStorageService.get('ruleFilter.' + workspaceId) || '';
            }
        }

        function _filterDataSource(ds){
            if(ds){
                ds.filter({
                    field: 'name',
                    operator: 'contains',
                    value: _ruleFilter()
                });
            }
        }
    })
;