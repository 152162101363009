angular.module('cerberus.les')
    .controller('LESLoanApplicationImportPageCtrl', function (_, $scope, $location, $window, lesLoanApplicationsService) {
        var vm = this;

        var instanceFunctions = {
            callback: linkLiabilitiesAndOpenApplication
        };

        vm.init = init;
        vm.cancel = cancel;
        vm.openIncompleteLoanApplication = _.partialRight(lesLoanApplicationsService.openIncompleteLoanApplication, instanceFunctions);

        init();
        
        function init() {
            vm.errors = [];

            lesLoanApplicationsService.openImportModal().then(function (instanceId) {
                openNewLoanApplication(instanceId);
            }, function (reason) {
                if (!reason || reason == 'backdrop click') {
                    cancel();
                }
                else if (reason.errMsg) {
                    if (_.isArray(reason.errMsg)) {
                        vm.errors = _.map(reason.errMsg, function (err) {
                            var msg = err.modelId + ':';

                            msg += '<ul>';

                            _.forEach(err.invalidArr, function (m) {
                                msg += '<li>' + m + '</li>';
                            });

                            msg += '</ul>';

                            return msg;
                        });
                    }
                    else {
                        vm.errors = [reason.errMsg];
                    }

                    vm.incompleteData = reason.incompleteData;
                    if (vm.incompleteData) {
                        vm.openIncompleteLoanApplication(vm.incompleteData);
                    }    
                }
            });
        }

        function openNewLoanApplication(instanceId) {
            $location.url('loan-applications/' + instanceId);
        }

        function linkLiabilitiesAndOpenApplication(instanceId) {
            vm.errors = [];
            vm.linkingLiabilities = true;
            lesLoanApplicationsService.updateLoan(instanceId, { action: 'linkLiabilities', data: {} }).then(function () {
                openNewLoanApplication(instanceId);
            });
        }

        function cancel() {
            $window.history.back();
        }

        $scope.$on('$destroy', function (e) {
            e.preventDefault();

            delete instanceFunctions.callback;
        });
    })
;