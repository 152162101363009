angular.module('cerberus.admin')
    .controller('ButtonConfigCtrl', function ButtonConfigCtrl(_, kendo, $scope, $rootScope, $uibModalInstance, toaster, pageObject, filters,
                                                              DesignerUtilityService, OdataUtilityService, PagesService, PagesDesignerListService,
                                                              pageObjectConfigService, ViewsService, WidgetsService, WidgetsSettingsService) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.pages = [];
        vm.views = [];
        vm.widgets = [];
        vm.idArray = _.compact(_.pluck(filters, 'id'));
        vm.filters = filters;
        vm.buttonFilters = [];
        vm.viewColumns = {};
        vm.widgetFields = {};
        vm.viewData = {};
        vm.tabTemplates = [{name: "General", url: "admin/pages/designer/button/general-tab.tpl.html"}];
        vm.editButton = null;
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0, 0, 0, 0];
        vm.tempParentFilter = {};
        vm.tempDefaultData = {};

        vm.actions = [
            {action: 'route', display: 'Route'},
            {action: 'link', display: 'Link'},
            {action: 'create', display: 'Create'}
        ];

        vm.buttonTypes = [
            {type: 'single', display: 'Single'},
            {type: 'split', display: 'Split'},
            {type: 'dropdown', display: 'Drop-down'},
            {type: 'filter', display: 'Filter'}
        ];

        vm.buttonStyles = [
            {style: 'default', display: 'Default'},
            {style: 'info', display: 'Info'},
            {style: 'primary', display: 'Primary'},
            {style: 'warning', display: 'Warning'},
            {style: 'danger', display: 'Danger'}
        ];

        vm.tempFilter = {};
        vm.filterType = '';
        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;
        vm.removeItemFromArray = _.pullAt;
        vm.buttonTypeChanged = buttonTypeChanged;
        vm.buttonActionChanged = buttonActionChanged;
        vm.loadFormFields = loadFormFields;

        vm.filterTypes = PagesDesignerListService.filters();
        vm.operators = OdataUtilityService.operators();
        vm.dateRangeOperators = _.map(vm.operators.dateRange, dateRangeMap);

        vm.dataSource = new kendo.data.DataSource({
            type: 'odata',
            serverFiltering:true,
            serverPaging: true,
            serverSorting: true,
            pageSize: 20,
            sort: {field: 'display', dir:'asc'},
            transport:{
                read:{
                    dataType:'json',
                    url: function(){
                        var displayText = '';
                        if(vm.editButton.filterParams.column){
                            displayText = '?$lookupDisplay=' + vm.editButton.filterParams.column;
                        }
                        return '/server/rest/v1/views/' + vm.editButton.filterParams.viewId + '/odata.svc' + displayText;
                    },
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                        xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                    }
                }
            }
        });

        vm.lookupFilterOptions = {
            filter: 'startswith',
            optionLabel: {
                id: '',
                display: 'Choose...'
            },
            dataTextField: 'display',
            dataValueField: 'id',
            dataSource: vm.dataSource,
            change: function(e){
                if(!e.sender.value()){
                    vm.editButton.filterParams.value = null;
                }
            }
        };

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;

        vm.filterTypeChange = function(){
            vm.editButton.filterParams.value = null;
            vm.editButton.filterParams.endValue = null;
            vm.editButton.filterParams.operator = null;

            if (!vm.editButton.filterParams.filter) {
                vm.editButton.filterParams.filter = {
                    logic: 'and',
                    filters: []
                };
            }

            if(!vm.editButton.filterParams.dateRangeOperators){
                vm.editButton.filterParams.dateRangeOperators = {};
                _.forEach(vm.dateRangeOperators, function(op){
                    vm.editButton.filterParams.dateRangeOperators[op.value] = true;
                });
            }
        };

        vm.addButton = function(){
            var newButton = {
                icon: '',
                label: '',
                action: '',
                targetId: '',
                type: 'single',
                style: 'default',
                items: [],
                filterParams: {},
                defaultData: []
            };
            vm.temp.params.buttons.push(newButton);
            vm.setEditButton(newButton);
        };

        vm.setEditButton = function (button) {
            vm.editButton = button;

            vm.buttonFilters = getFilters();
            vm.tempDefaultData = {};
        };

        vm.removeButton = function(button){
            if(button === vm.editButton){
                vm.editButton = null;
            }

            removeArrayItem(vm.temp.params.buttons, button);
        };

        vm.addItem = function(button){
           button.items.push({
               icon: '',
               label: '',
               action: '',
               targetId: ''
           });
        };

        vm.removeItem = function(button, item){
            removeArrayItem(button.items, item);
        };

        vm.labelToUniqueId = function (item){
            item.id = DesignerUtilityService.labelToUniqueId(item.label, vm.idArray);
        };

        function removeArrayItem(array, item){
            var index = array.indexOf(item);
            if(index >= 0){
                array.splice(index, 1);
            }
        }

        vm.defaultParentConfiguration = defaultParentConfiguration;
        vm.addParentFilter = addParentFilter;
        vm.removeParentFilter = removeParentFilter;
        vm.getFilters = getFilters;

        // Modal Commands
        vm.save = function () {
            if($scope.form.$invalid){
                toaster.pop('warning', 'Form invalid', '');
            }
            else {
                vm.temp.wrapper.style.margin = DesignerUtilityService.arrayToCss(vm.margin);
                $uibModalInstance.close(vm.temp);
            }
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        vm.loadColumns = function(vid){
            if(!vm.viewColumns[vid]){
                ViewsService.getColumns(vid).then(function(columns){
                    vm.viewColumns[vid] = columns;
                    loadViewData(columns);
                });
            }
            else {
                loadViewData(vm.viewColumns[vid]);
            }
        };

        function loadViewData(columns){
            if(vm.editButton && vm.editButton.type === 'filter' && vm.editButton.action === 'lookup') {
                vm.editButton.filterParams.value = {};
                vm.editButton.filterParams.endValue = null;

                var hasColumns = columns.length > 0,
                    columnSelected = !!(_.find(columns, 'colName', vm.editButton.filterParams.column));
                
                if (hasColumns && !columnSelected) {
                    vm.editButton.filterParams.column = columns[0].colName;
                    vm.dataSource.read();
                }
                else if(!columnSelected) {
                    vm.editButton.filterParams.column = null;
                }
            }
        }

        vm.dateRangeFilter = dateRangeFilter;
        vm.dateRangeOpToggled = dateRangeOpToggled;

        // Init
        init();

        function init() {
            PagesService.getPages().then(function(pages){
                vm.pages = pages;
            });

            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            WidgetsService.getWidgets().then(function (widgets) {
                vm.widgets = widgets;
            });

            for (var b = 0; b < vm.temp.params.buttons.length; b++) {
                var button = vm.temp.params.buttons[b];

                _.defaults(button, {
                    defaultData: []
                });

                if ((button.type === 'single' || button.type === 'split') && button.action === 'create' && button.targetId) {
                    loadFormFields(button.targetId);
                }

                if (button.filterParams && button.filterParams.viewId) {
                    vm.loadColumns(button.filterParams.viewId);
                }
            }

            for (var i = 0; i < filters.length; i++) {
                if (filters[i].id) {
                    vm.idArray.push(filters[i].id);
                }
            }
        }

        function dateRangeFilter(operator){
            return vm.editButton.filterParams.dateRangeOperators[operator.value];
        }

        function dateRangeOpToggled(key){
            var opDisabled = !vm.editButton.filterParams.dateRangeOperators[key],
                opSelected = vm.editButton.filterParams.operator === key;

            if(opDisabled && opSelected){
                vm.editButton.filterParams.operator = '';
            }
        }

        function buttonTypeChanged(button) {
            if (button.type !== 'single' && button.type !== 'split') {
                button.defaultData = [];
            }
        }

        function buttonActionChanged(button) {
            button.targetId = '';
        }

        function loadFormFields(targetId) {
            if (targetId && !vm.widgetFields[targetId]) {
                WidgetsSettingsService.getFormFields(targetId).then(function (data) {
                    vm.widgetFields[targetId] = WidgetsSettingsService.parseFormObjects(data.display);
                });
            }
        }

        function dateRangeMap(display, key){
            return {
                display: display,
                value: key
            };
        }

        function getFilters() {
            var buttonFilters = _.filter(vm.temp.params.buttons, function (b) {
                return b.type === 'filter' && b.id !== vm.editButton.id;
            });

            return filters.concat(buttonFilters);
        }

        // Parent Filter

        function defaultParentConfiguration(filterParams) {
            if (!filterParams.parentFilterMap) {
                filterParams.parentFilterMap = {};
            }
        }

        function addParentFilter(parentFilterMap, tempFilter) {
            parentFilterMap[tempFilter.key] = tempFilter.value;

            delete tempFilter.value;
            delete tempFilter.key;
        }

        function removeParentFilter(parentFilterMap, key) {
            delete parentFilterMap[key];
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;
