angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizListViewService
     * @alias cerberus/core:VizListViewService
     * @description Provides functions for configuring list (card) views
     */
    .factory('VizListViewService', function VizListViewService(_, kendo, $http, $timeout, $uibModal, nimUtilityService, VizUtilityService, InstancesWindowService){
        return {
            buildDataSource: buildDataSource,
            viewRecord: viewRecord,
            viewNextRecord: viewNextRecord,
            viewPreviousRecord: viewPreviousRecord,
            templateData: templateData,
            stripMisplacedAttributes: stripMisplacedAttributes,
            openUploadModal: openUploadModal
        };

        function buildDataSource(pageObject, scope){
            var dataSourceOptions = {
                type: 'odata',
                transport:{
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                pageSize: 20,
                requestStart: function (e) {
                    if (!_.isEmpty(pageObject.params.requiredFilters)) {
                        _.forEach(pageObject.params.requiredFilters, function (required, filter) {
                            if (required && VizUtilityService.isFilterEmpty(scope.filters[filter])) {
                                e.preventDefault();

                                $timeout(function () {
                                    e.sender.success({ d: { results: [], __count: 0 } });
                                });

                                return false;
                            }
                        });
                    }
                },
                change: function(e){
                    var data = e.sender.data();
                    _.forEach(data, function (dataItem) {
                        if(dataItem.__nimAttachments.length > 0){
                            dataItem.file = dataItem.__nimAttachments[0];
                        }
                        else{
                            dataItem.file = {name:''};
                        }
                    });
                    scope.vm.data = data;
                    scope.$emit('list-source-changed', scope.list);
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(pageObject.viz.settings.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                    filters: [],
                    logic: 'and'
                };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, pageObject.viz.settings.dataSource, sortOptions, filterOptions);

            // Adds parse function to make sure date and number fields are correct
            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if(field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
                else if(field.type === 'number') {
                    field.parse = VizUtilityService.parseNumberField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);
                
                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        /**
         * Generates the object to use when compiling the kendo template
         * @param dataItem
         * @returns {Object}
         */
        function templateData(dataItem){
            var id = dataItem.id;

            return {
                data: dataItem,
                nim: nimUtilityService,
                ui: {
                    checkbox: '<input class="form-control nim-list-checkbox" type="checkbox" ng-checked="vm.itemSelected(' + id + ')" ng-model="selected" ng-click="vm.selectItem(' + id + ')" />',
                    infoButton: '<button class="btn btn-link" ng-click="vm.viewRecord(' + id + ')" title="More Info"><i class="fa fa-info-circle"></i></button>'
                },
                onClick: {
                    selectSingle: 'ng-click="vm.selectSingle(' + id + ')"',
                    viewAndSelect: 'ng-click="vm.viewAndSelect(' + id + ')"'
                }
            };
        }

        /**
         * If someone puts one of the onClick attributes outside of an html tag, this will remove it
         * @param listItem - compiled text for list item
         * @param onClickMap - contains the ng-click definitions in string form
         * @returns {string}
         */
        function stripMisplacedAttributes(listItem, onClickMap){
            var jqHTML = $(listItem);

            // Text without the html tags
            var text = jqHTML.text();

            _.forEach(onClickMap, function(attr){
                // If one of the attributes was added outside of a tag, remove it
                if(text.indexOf(attr) >= 0){
                    // Couldn't get the stupid regex to work
                    listItem = listItem.split(attr).join('');
                }
            });

            return listItem;

        }

        function viewRecord(instanceId, navFunctions) {
            InstancesWindowService.openWindow({
                action: 'read',
                instanceId: instanceId,
                navFunctions: navFunctions
            });
        }

        /**
         * Navigation function used to open next record from instance window
         * @param instanceController
         * @param dataSource
         */
        function viewNextRecord(instanceController, dataSource){
            var currentId = instanceController.instanceId,
                currentDataItem = dataSource.get(currentId),
                currentIndex = dataSource.indexOf(currentDataItem),
                currentPageSize = dataSource.data().length,
                nextItem;

            if(currentIndex >= 0) {
                if (currentIndex < currentPageSize - 1) {
                    nextItem = dataSource.at(currentIndex + 1);
                }

                if (nextItem) {
                    instanceController.updateInstanceAttrs(nextItem.id, 'read');
                    instanceController.init();
                }
            }
        }

        /**
         * Navigation function used to open previous record from instance window
         * @param instanceController
         * @param dataSource
         */
        function viewPreviousRecord(instanceController, dataSource){
            var currentId = instanceController.instanceId,
                currentDataItem = dataSource.get(currentId),
                currentIndex = dataSource.indexOf(currentDataItem),
                prevItem;

            if(currentIndex >= 0) {
                if (currentIndex > 0) {
                    prevItem = dataSource.at(currentIndex - 1);
                }

                if (prevItem) {
                    instanceController.updateInstanceAttrs(prevItem.id, 'read');
                    instanceController.init();
                }
            }
        }

        function openUploadModal(pageObject) {
            var modal = $uibModal.open({
                templateUrl: 'core/pages/viz/list/list-upload-modal.tpl.html',
                controller: 'ListUploadModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    listPageObject: _.constant(pageObject)
                }
            });

            return modal.result;
        }
    })
;