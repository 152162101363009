angular.module('cerberus.util')
    .factory('printService', function PagesCtrl(_, $window, $q, $uibModal, checkPrintingService, labelPrintingService, normalPrintingService, WidgetsService/*, InstancesService*/) {

        var windowArea = angular.element('#nim-window-area');
        var $printSection = angular.element("#print-section");

        if ($window.matchMedia) {
            var mediaQueryList = $window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
        }
        $window.onbeforeprint = beforePrint;
        $window.onafterprint = afterPrint;

        var service = {
            printElement: printElement,
            openPrintConfigurationModal: openPrintConfigurationModal,
            configureAndPrint: configureAndPrint
        };
        return service;
        ///////////////////
        /**
         *
         * @param elem
         */
        function printElement(elem) {
            var domClone = elem.cloneNode(true);
            $printSection.empty().append(domClone);
        }
        function beforePrint() {
            var windows = windowArea.find('.k-window');
            if (windows.length > 0) {
                return;
            }
            var domClone = angular.element('#content-wrap').clone(true, true);
            $printSection.empty().append(domClone);
        }
        function afterPrint() {
            var windows = windowArea.find('.k-window');
            if (windows.length > 0) {
                return;
            }
            $printSection.empty();
        }

        /**
         * Opens configuration modal to set print options
         * @param widgetId - widget the specified instances belong to
         * @param instanceIds - instances to export to pdf
         * @param displayValues - Display values for check printing
         * @param enabledPrintTypes - map that specifies which print types the user may select
         * @param defaultConfiguration - default options to be passed into the config modal
         * @returns {Promise}
         */
        function openPrintConfigurationModal(widgetId, instanceIds, displayValues, enabledPrintTypes, defaultConfiguration) {
            var openModal = function () { 
                var printConfigurationModal = $uibModal.open({
                    templateUrl: 'util/print/print-configuration-modal.tpl.html',
                    controller: 'PrintConfigurationModalCtrl',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        defaultConfiguration: _.constant(defaultConfiguration || {}),
                        enabledPrintTypes: _.constant(enabledPrintTypes || {}),
                        instanceIds: _.constant(instanceIds || []),
                        displayValues: _.constant(displayValues || {}),
                        widgetId: _.constant(widgetId || '')
                    }
                });

                return printConfigurationModal.result;
            };

            // Shortcut for printing single instance's labels
            if (_.get(defaultConfiguration, 'type') == 'label' && instanceIds.length == 1) {
                var deferred = $q.defer();
                var config = {
                    type: 'label',
                    options: {
                        targetForm: '',
                        pageTotal: {}
                    }
                };

                config.options.pageTotal[instanceIds[0]] = 1;

                _.assign(config.options, _.get(defaultConfiguration, 'label'));

                WidgetsService.getAllForms(widgetId, 'label').then(function (forms) {
                    if (forms.length == 1) {
                        // Set first form as the target form
                        var form = forms[0];
                        var formOriginId = form.originatorId;
                        var pageTotalModel = form.settings.print.pageTotal;

                        config.options.targetForm = formOriginId;
                        config.options.paperSize = form.settings.print.pageSize || [8.5, 11];
                        config.options.margin = form.settings.print.margin || 0;

                        if (pageTotalModel) {
                            openModal().then(function(c){
                                deferred.resolve(c);
                            });
                        }
                        else {
                            deferred.resolve(config);
                        }    
                    }
                    else {
                        openModal().then(function(c){
                            deferred.resolve(c);
                        });
                    }
                });

                return deferred.promise;
            }

            return openModal();
        }

        /**
         * Configures print options then automatically calls the correct function based on print type
         * @param widgetId - widget the specified instances belong to
         * @param instanceIds - instances to export to pdf
         * @param displayValues - Map of display values for each record (for check printing)
         * @param enabledPrintTypes - map that specifies which print types the user may select
         * @param defaultConfiguration - default options to be passed into the config modal
         */
        function configureAndPrint(widgetId, instanceIds, displayValues, enabledPrintTypes, defaultConfiguration, defaultName){
            var deferred = $q.defer();
            
            service.openPrintConfigurationModal(widgetId, instanceIds, displayValues, enabledPrintTypes, defaultConfiguration).then(function (config) {
                defaultName = config.filename || defaultName || 'download';

                // When configuration is complete, call the correct service according to the print type selected
                if(config.type === 'normal'){
                    normalPrintingService.printInstance(instanceIds[0], defaultName, config.options).then(function () {
                        deferred.resolve();
                    });
                }
                else if(config.type === 'label'){
                    labelPrintingService.printLabels(instanceIds, defaultName, config.options).then(function () {
                        deferred.resolve();
                    });
                }
                else if (config.type === 'check') {
                    checkPrintingService.printChecks(instanceIds, defaultName, config.options).then(function () {
                        deferred.resolve();
                    });
                }
                else {
                    deferred.resolve();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    })
;