angular.module('cerberus.admin')
    .controller('WorkspacesNumbersCtrl', function WorkspaceNumbersCtrl($scope, $location, workspaceSettingsFactory) {
        $scope.numbersMaster = {};
        $scope.numbersUser = {};
        $scope.numbersLookup = {
            decimalPrecision:[
                {format:'n', display:"Auto"},
                {format:'n0', display:"0 ( 1 )"},
                {format:'n1', display:"1 ( 1.0 )"},
                {format:'n2', display:"2 ( 1.00 )"}
            ],
            exponentPrecision:[
                {format:'e', display:"Auto"},
                {format:'e0', display:"0"},
                {format:'e1', display:"1"},
                {format:'e2', display:"2"}
            ],
            culture:[
                {format:'en-AU', display:"Australia"},
                {format:'en-CA', display:"Canada"},
                {format:'en-GB', display:"United Kingdom"},
                {format:'en-US', display:"United States of America"}
            ]
        };

        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.reset = _reset;

        _init();

        function _init(){
            workspaceSettingsFactory.getSettings('numbersDecimalPrecision,numbersExponentPrecision,numbersCulture').then(function(settingsModel){
                $scope.numbersMaster = angular.copy(settingsModel);
                $scope.numbersUser = angular.copy($scope.numbersMaster);
            });
        }

        function _isUnchanged(user) {
            return angular.equals(user, $scope.numbersMaster);
        }

        function _save(user){
            workspaceSettingsFactory.putSettings({settings:user}).then(function(){
                $location.url('/settings');
            });
        }

        function _reset(){
            $location.replace().url('/settings');
        }
    })
;