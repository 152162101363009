angular.module('cerberus.account')
    .controller('OrganizationsUsersCtrl', function OrganizationsUsersCtrl($scope, $location, statusList, orgId, userId,
                                                                          ConfirmModalService, OrganizationsService, organizationUsersService) {
        var vm = this;
        $scope.activeRoute = 'users';
        $scope.orgId = orgId;
        $scope.orgData = {};

        vm.usersArray = [];
        vm.statusList = statusList;
        vm.currentUserId = userId;
        vm.selectedUsers = {
            checked: false,
            users:{}
        };
        vm.userEmailsBatch = null;
        vm.userEmails = null;
        vm.addUsers = function(emails){
            organizationUsersService.addUsers($scope.orgId, {userEmails:emails}).then(function(){
                init();
            });
        };
        vm.removeUsers = function(data){
            var users = parseSelected(data.users);
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to remove these users from the organization?',
                actionButtonText: 'Remove',
                closeButtonText: 'Cancel',
                check: function(){
                    return true;
                },
                confirm: function(){
                    return organizationUsersService.removeUsers($scope.orgId, users);
                },
                callback: function(){
                    init();
                }
            });
        };
        vm.enableUsers = function(data){
            var users = parseSelected(data.users);
            organizationUsersService.enableUsers($scope.orgId, {userIds:users}).then(function(){
                init();
            });
        };
        vm.disableUsers = function(data){
            var users = parseSelected(data.users);
            organizationUsersService.disableUsers($scope.orgId, {userIds:users}).then(function(){
                init();
            });
        };

        vm.goToUser = function(id){
            $location.url('organizations/' + $scope.orgId + '/settings/users/' + id);
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            organizationUsersService.getUsers(orgId).then(function(data){
                vm.usersArray = data;
            });
        }

        function parseSelected(sel){
            var idArray = [];
            angular.forEach(sel,function(v,k){
                if(v){
                    idArray.push(k);
                }
            });
            return idArray;
        }
    })
;