angular.module('cerberus.core')
    .controller('nimFormObjectCtrl', function (_, moment, kendo, $scope, $rootScope, $element, $timeout, $attrs, $log,
                                               ConfirmModalService, FormsService, FormValidationService,
                                               NimFormObjectService, InstancesService, InstancesWindowService) {
        $scope.nimFormObject = evalAttr($attrs.nimFormObject);
        $scope.fieldOptions = evalAttr($attrs.fieldOptions);
        $scope.filters = evalAttr($attrs.filters);
        $scope.formOriginId = evalAttr($attrs.formOriginId);
        $scope.nimStatic = evalAttr($attrs.nimStatic);
        $scope.nimInstanceAction = evalAttr($attrs.nimInstanceAction) || '';
        $scope.nimEditing = evalAttr($attrs.nimEditing);
        $scope.dataSet = evalAttr($attrs.dataset);
        $scope.autoFillData = evalAttr($attrs.autoFillData);
        $scope.instanceForm = evalAttr($attrs.nimInstanceForm);
        $scope.validateOnOpen = evalAttr($attrs.validateOnOpen) || '';

        $scope.signed = false;
        $scope.imageSource = null;
        $scope.imageUrl = '';
        $scope.attachmentId = '';
        $scope.previewImage = false;
        $scope.compiledStaticText = '';
        $scope.dropDownWrapperEle = null;
        $scope.textEditorOptions = NimFormObjectService.textEditorOptions();
        $scope.addItemFunctions = {
            callback: addItemCallback
        };
        $scope.isReadonly = false;
        $scope.isRequired = false;
        
        $scope.validateFormObject = FormValidationService.validateFormObject($scope.instanceForm, $scope.nimFormObject);
        $scope.validateKendoFormObject = FormValidationService.validateKendoFormObject($scope, $scope.instanceForm, $scope.nimFormObject);
        $scope.validateAndUpdateOnChange = validateAndUpdateOnChange;
        $scope.formatReadonly = formatReadonly;
        // $scope.pushChange = pushChange;
        $scope.dropdownDataBoundHandler = dropdownDataBoundHandler;
        $scope.dropdownCloseHandler = dropdownCloseHandler;
        $scope.dropdownOpenHandler = dropdownOpenHandler;
        $scope.openCreateWindow = openCreateWindow;
        $scope.updateFilterOnChange = updateFilterOnChange;
        $scope.updateFilter = updateFilter;
        $scope.onSelectionChange = onSelectionChange;
        $scope.onFilter = onFilter;
        $scope.dropdownClose = dropdownClose;
        $scope.multiSelectDataBound = multiSelectDataBound;
        $scope.getLocationAutoComplete = NimFormObjectService.getLocationAutoComplete;
        $scope.setLocationData = _.partial(NimFormObjectService.setLocationData, $scope);
        $scope.setEmptyLocation = NimFormObjectService.setEmptyLocation($scope);
        $scope.clearImage = clearImage;

        var alertPromise;
        var tempModelId = '_' + $scope.nimFormObject.config.modelId;

        // Initialize form object
        init();

        $scope.$on('$destroy', function () {
            if (alertPromise) {
                $timeout.cancel(alertPromise);
            }

            delete $scope.addItemFunctions.callback;
        });

        $scope.$on('nim-remove-form-objects', function () {
            if ($scope.nimNumberFormObject){
                $scope.nimNumberFormObject.destroy();
                $scope.nimNumberFormObject = null;
            }

            if ($scope.nimDateFormObject){
                $scope.nimDateFormObject.destroy();
                $scope.nimDateFormObject = null;
            }

            if($scope.dropDownWrapperEle) {
                $scope.dropDownWrapperEle.off('focus click mouseup mousedown');
            }
        });

        function init() {
            // Copy dataset value to filters struct
            if ($scope.dataSet[$scope.nimFormObject.config.modelId]) {
                $scope.filters[$scope.nimFormObject.config.modelId] = $scope.dataSet[$scope.nimFormObject.config.modelId];
            }

            switch ($scope.nimFormObject.config.type) {
                case 'location':
                    initLocation();
                    break;

                case 'datetime':
                    initDateTime();
                    break;

                case 'textarea':
                    initTextarea();
                    break;

                case 'signature':
                    initSignature();
                    break;

                case 'image':
                    initImage();
                    break;

                case 'rule':
                    initRule();
                    break;

                case 'multiselect':
                    initMultiSelect();
                    break;

                case 'select':
                    initSelect();
                    break;

                case 'radio':
                    initRadio();
                    break;

                case 'autocomplete':
                    initAutoComplete();
                    break;

                case 'number':
                    initNumber();
                    break;

                case 'statictext':
                    initStaticText();
                    break;

                case 'timer':
                    initTimer();
                    break;
            }

            // Set as readonly
            var readonlyByDefault = !!($scope.nimFormObject.param.disabled || $scope.nimFormObject.param.autoIncrement);
            $scope.isReadonly = readonlyByDefault;
            if (!readonlyByDefault && $attrs.nimReadonly) {
                $scope.isReadonly = $scope.$eval($attrs.nimReadonly);

                // Watch nim-readonly attribute if not hard-set as readonly
                $scope.$watch($attrs.nimReadonly, function (readonly) {
                    $scope.isReadonly = readonly;
                });
            }
            
            // Set as required
            var requiredByDefault = !!($scope.nimFormObject.validation.required && $scope.nimFormObject.display.section !== '__nimHiddenSection');
            $scope.isRequired = requiredByDefault;
            if (!requiredByDefault && $attrs.nimRequired) {
                $scope.isRequired = $scope.$eval($attrs.nimRequired);

                // Watch nim-required attribute if not hard-set as required
                $scope.$watch($attrs.nimRequired, function (required) { 
                    $scope.isRequired = required;
                });
            }

            // Validate form object on render if form has already been submitted
            if($scope.instanceForm && $scope.instanceForm.$submitted){
                $timeout(function(){
                    $scope.validateFormObject($scope.dataSet[$scope.nimFormObject.config.modelId], $scope.isRequired);
                });
            }
        }

        function initLocation() { 
            if (!$scope.nimStatic) {
                //Set the text in the autocomplete field when editing
                $scope[tempModelId] = _.get($scope.dataSet, $scope.nimFormObject.config.modelId + '.properties.formatted_address', '');
            }
        }

        function initDateTime() {
            if (!$scope.nimStatic) {
                var dateFormat = _.trim($scope.nimFormObject.display.format);
        
                // Sets the ngModel so the input doesn't appear blank despite having a default value set
                $timeout(function () {
                    var defaultDate = _.get($scope.dataSet, $scope.nimFormObject.config.modelId, '');
                    $scope[tempModelId] = kendo.toString(defaultDate, dateFormat);
                });

                var after = $scope.nimFormObject.param.after;
                if (after) {
                    NimFormObjectService.watchAfterDateField($scope, after, 'nimDateFormObject');
                }

                var before = $scope.nimFormObject.param.before;
                if (before) {
                    NimFormObjectService.watchBeforeDateField($scope, before, 'nimDateFormObject');
                }

                var removeDateRenderWatcher = $scope.$on("kendoWidgetCreated", function (e, widget) {
                    e.preventDefault();
                    removeDateRenderWatcher();

                    if ($scope.nimDateFormObject == widget) {
                        // Fix for weird calculation bug
                        $timeout(function () {
                            var initialDateValue = _.get($scope.dataSet, $scope.nimFormObject.config.modelId, '');
                            if (initialDateValue && _.has(initialDateValue, 'indexOf') && widget) {
                                $scope[tempModelId] = kendo.toString(initialDateValue, dateFormat);
                                widget.value(initialDateValue);
                            }
                        }, 100);
                    }
                });
            }    
        }

        function initTextarea() {
            if (!$scope.nimStatic) {
                // Sets the ngModel so the input doesn't appear blank despite having a default value set
                $scope[tempModelId] = _.get($scope.dataSet, $scope.nimFormObject.config.modelId, '');
            }
         }

        function initSignature() { 
            if (!$scope.nimStatic) {
                //$scope.signed = _.has($scope, 'dataSet.' + $scope.nimFormObject.config.modelId + '.path');
                $scope.signButtonClick = signButtonClick;
            }
        }

        function initImage() {
            if (!$scope.nimStatic) {
                $scope.attachmentId = $scope.dataSet[$scope.nimFormObject.config.modelId];

                if ($scope.attachmentId) {
                    InstancesService.getDownloadKey($scope.attachmentId).then(function (url) {
                        $scope.imageUrl = url;
                    });
                }
            }
         }

        function initRule() {
            if (!$scope.nimStatic) {
                NimFormObjectService.enforceRule($scope, $scope.nimFormObject.param, $scope.dataSet, $scope.nimFormObject.config.modelId);
            }
         }

        function initMultiSelect() {
            if ($scope.nimStatic) {
                multiSelectDataBound();
            }
            else {
                var defaultMultiSelectValue = {};
                defaultMultiSelectValue[$scope.nimFormObject.config.modelId] = [];
                _.defaults($scope.dataSet, defaultMultiSelectValue);

                if ($scope.nimFormObject.option.type != 'custom') {
                    initDataSource();
                    
                    if ($scope.filters[$scope.nimFormObject.config.modelId]) {
                        _.forEach($scope.filters[$scope.nimFormObject.config.modelId], function (item) {
                            $scope.oDataLookUp.add(item);
                        });
                    }
                    else if ($scope.dataSet && $scope.dataSet[$scope.nimFormObject.config.modelId]) {
                        _.forEach($scope.dataSet[$scope.nimFormObject.config.modelId], function (item) {
                            $scope.oDataLookUp.add(item);
                        });
                    }

                    // Logs error that lookupFormOrigin is missing
                    if (!$scope.nimFormObject.param.lookupFormOrigin) {
                        $log.error('Multiselect field "' + $scope.nimFormObject.config.modelId + '" is missing parameter "lookupFormOrigin".');
                    }
                }
            }
         }

        function initSelect() {
            if (!$scope.nimStatic) {
                var initialValue = _.get($scope.dataSet[$scope.nimFormObject.config.modelId], 'id', '');

                if (initialValue) {
                    $scope[tempModelId] = initialValue;
                }

                if ($scope.nimFormObject.option.type != 'custom') {
                    initDataSource();

                    if ($scope.nimFormObject.option.type === 'views' && _.get($scope.nimFormObject.param, 'advSel.enabled') && _.get($scope.nimFormObject.param, 'advSel.pageObjectId')) {
                        $scope.openAdvSelPage = _.partial(InstancesWindowService.openAdvSelPage, $scope, function (data) {
                            if (data) {
                                if (data.id && !$scope.oDataLookUp.get(data.id)) {
                                    $scope.oDataLookUp.add(data);
                                }

                                $scope.dataSet[$scope.nimFormObject.config.modelId] = data;
                                $scope.onSelectionChange(null, data.id);
                            }
                        });
                    }
                }
            }
         }

        function initRadio() {
            if (!$scope.nimStatic) {
                // Makes sure radio binds to correct value
                var radioVal = _.get($scope.dataSet, $scope.nimFormObject.config.modelId);  // Saved radio value

                if (!_.isEmpty(radioVal)) {
                    var radioOptions = _.get($scope.fieldOptions, $scope.nimFormObject.config.modelId, []);

                    // Finds corresponding option and pushes it to the dataSet
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = _.find(radioOptions, radioVal);
                }

                if ($scope.nimFormObject.option.type != 'custom') {
                    initDataSource();

                    $scope.oDataLookUp.fetch(function () {
                        if ($scope.oDataLookUp.data().length < 1) {
                            $scope.oDataLookUp.data([{ id: '-1', display: '(No Results)' }]);
                        }
                    });
                }
            }
         }

        function initAutoComplete() {
            if (!$scope.nimStatic) {
                if ($scope.nimFormObject.option.type != 'custom') {
                    initDataSource();
                    
                    $scope.oDataLookUp.fetch(function () {
                        if ($scope.oDataLookUp.data().length < 1) {
                            $scope.oDataLookUp.data([{ id: '-1', display: '(No Results)' }]);
                        }
                    });
                }
            }
         }
        
        function initNumber() {
            if (!$scope.nimStatic) {
                $scope[tempModelId] = _.get($scope.dataSet, $scope.nimFormObject.config.modelId, '');

                var removeNumberRenderWatcher = $scope.$on("kendoWidgetCreated", function (e, widget) {
                    e.preventDefault();
                    removeNumberRenderWatcher();

                    if ($scope.nimNumberFormObject == widget) {
                        // Fix for weird calculation bug
                        $timeout(function () {
                            var initialNumberValue = _.get($scope.dataSet, $scope.nimFormObject.config.modelId, '');
                            if (_.isNumber(initialNumberValue)) {
                                widget.value(initialNumberValue);
                                $scope[tempModelId] = initialNumberValue;
                            }
                        }, 100);
                    }
                });
            }
         }

        function initStaticText() {
            NimFormObjectService.compileStaticText($scope, $element);

            var conditionallyShown = $scope.nimFormObject.param.conditionalAction === 'show' || !$scope.nimFormObject.param.conditionalAction,
                hasCondition = !_.isEmpty($scope.nimFormObject.param.condition);

            if (!$scope.nimStatic && $scope.nimFormObject.param.showPopup && conditionallyShown && hasCondition) {
                $scope.$on('nim-show-static-text-popup', function (e, id) {
                    e.preventDefault();
                    var bodyText = $scope.compiledStaticText;

                    if (id === $scope.nimFormObject.config.modelId) {
                        alertPromise = $timeout(function () {
                            ConfirmModalService.showModal(null, {
                                headerText: $scope.nimFormObject.label.text || 'Alert',
                                bodyText: bodyText,
                                showActionButton: true,
                                actionButtonClass: 'btn-primary',
                                actionButtonPosition: 'footer',
                                showCloseButton: false,
                                closeButtonText: 'Cancel',
                                footerAlignment: 'center',
                                check: function () {
                                    return true;
                                }
                            });
                        }, 2000);
                    }
                });
            }
        }

        function initTimer() {
            if (!$scope.nimStatic) {
                $scope.timerStart = _.get($scope.nimFormObject,'param.start.time', moment().valueOf());
            }
         }

        // Call this for select, multiselect, radio, autocomplete
        function initDataSource() {

            // Text from drop-down's search field
            $scope.filterInput = null;

            $scope.oDataLookUp = NimFormObjectService.createODataLookUp($scope);

            if ($scope.nimFormObject.param.cascadeFilter) {
                _.forEach($scope.nimFormObject.param.cascadeFilter.filters, function (cascadeFilter) {
                    if (cascadeFilter.field && cascadeFilter.value) {
                        $scope.$watch('filters["' + cascadeFilter.value + '"]', cascadeWatcher);
                    }
                });
            }

            if (_.get($scope.dataSet[$scope.nimFormObject.config.modelId],  'id') != null) {
                if ($scope.nimInstanceAction === 'create') {
                    onSelectionChange(null, $scope.dataSet[$scope.nimFormObject.config.modelId].id);
                }
                else {
                    updateFilter($scope.dataSet, $scope.nimFormObject.config.modelId);

                    if ($scope.nimFormObject.config.type === 'select') {
                        if ($scope.dataSet[$scope.nimFormObject.config.modelId].id < 0) {
                            $scope.dataSet[$scope.nimFormObject.config.modelId] = '';
                        }
                    }
                }
            }

            $scope.$on('nim-auto-fill-dropdown', function(e, modelId, value){
                e.preventDefault();

                if ($scope.nimFormObject.config.modelId === modelId && $scope.oDataLookUp && value && value.id) {
                    if(!$scope.oDataLookUp.get(value.id)) {
                        $scope.oDataLookUp.add(value);
                    }
                }
            });
        }

        // function isReadonly(){
        //     var readonly = !!($scope.nimFormObject.param.disabled || $scope.nimFormObject.param.autoIncrement);
        //     if(!readonly && $attrs.nimReadonly){
        //         readonly = readonly || $scope.$eval($attrs.nimReadonly);
        //     }
        //     return readonly;
        // }

        // function isRequired() {
        //     var required = !!($scope.nimFormObject.validation.required && $scope.nimFormObject.display.section !== '__nimHiddenSection');
        //     if (!required && $attrs.nimRequired) {
        //         required = required || $scope.$eval($attrs.nimRequired);
        //     }

        //     return required;
        // }

        function dropdownClose(){
            $scope.filterInput = null;
        }

        function updateFilterOnChange(){
            updateFilter($scope.dataSet, $scope.nimFormObject.config.modelId);
        }

        function updateFilter(dataSet, modelId){
            $timeout(function(){
                if(!dataSet[modelId] || dataSet[modelId].id === '-1' || dataSet[modelId].id === -1){
                    $scope.filters[modelId] = '';
                }
                else{
                    $scope.filters[modelId] = dataSet[modelId];
                }
            });
        }

        function validateAndUpdateOnChange(e) {
            updateFilterOnChange();
            $scope.validateKendoFormObject(e);
        }

        function onSelectionChange(/*e, id*/) {
            if($scope.nimStatic){
                return;
            }

            // If the dropdown is a filter, the filter map has already been updated
            if(!$scope.nimFormObject.param.filter) {
                $scope.updateFilter($scope.dataSet, $scope.nimFormObject.config.modelId);
            }
            else {
                var id = _.get($scope.filters[$scope.nimFormObject.config.modelId], 'id');
                if(id === -1 || id === '-1'){
                    $scope.filters[$scope.nimFormObject.config.modelId] = '';
                }
            }
            if($scope.nimFormObject.config.type === 'select'){
                if($scope.dataSet[$scope.nimFormObject.config.modelId].id < 0){
                    $scope.dataSet[$scope.nimFormObject.config.modelId] = '';
                }
            }
        }

        function onFilter(e) {
            e.preventDefault();
            $scope.filterInput = e.filter;

            $scope.oDataLookUp.fetch(function () {
                if ($scope.oDataLookUp.data().length < 1) {
                    $scope.oDataLookUp.data([{id: '-1', display: '(No Results)'}]);
                }
            });
        }

        function signButtonClick(sigData, reSign) {
            var isInitial = $scope.nimFormObject.param.type === 'initial';
            var restrictTo = $scope.nimFormObject.param.restrictTo;
            var penColor = $scope.nimFormObject.param.penColor;
            FormsService.signatureHandler(sigData, isInitial, restrictTo, penColor, reSign).then(function (sigModel) {
                // Copy the signature model
                var model = angular.copy(sigModel);
                if (isInitial) {
                    model.path = angular.copy(sigModel.iniPath);
                } else {
                    model.path = angular.copy(sigModel.sigPath);
                }
                // Clean up model
                delete model.sigPath;
                delete model.iniPath;
                // Save the cleaned signature model
                $scope.dataSet[$scope.nimFormObject.config.modelId] = model;
                // Save this Data on the form so future signatures can be signed without all this hoopla
                if(restrictTo !== 'anon') {
                    $scope.$emit('nimSignatureAccepted', sigModel);
                }
            });
        }

        function openCreateWindow(){
            var defaultData = [];

            if(!_.isEmpty($scope.nimFormObject.param.defaultData)){
                _.forEach($scope.nimFormObject.param.defaultData, function(fieldMap){
                    if(_.has($scope.dataSet, fieldMap.sourceField.modelId)){
                        var value = _.get($scope.dataSet, fieldMap.sourceField.modelId),
                            data = {
                                value: value
                            };

                        if (fieldMap.sourceField.isLookUp || fieldMap.sourceField.isCustomLookup) {
                            if (_.isEmpty(value)) {
                                return;
                            }
                            
                            data.value = _.get(value, 'id');
                            data.lookupDisplay = _.get(value, 'display');
                        }

                        defaultData.push({
                            data: data,
                            field: fieldMap.targetField
                        });
                    }
                });
            }

            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: $scope.nimFormObject.option.widgetId,
                title: $scope.nimFormObject.label.text,
                defaultData: defaultData,
                navFunctions: $scope.addItemFunctions
            });
        }

        function multiSelectDataBound(e){
            var param = $scope.nimFormObject.param;
            var dataSet = $scope.dataSet[$scope.nimFormObject.config.modelId];

            if (dataSet) {
                _.forEach(dataSet, function (dataItem) {
                    var lookupData = _.get(dataItem, ['__nimData', param.lookup]);
                    
                    if(lookupData && (!dataItem.display || !dataItem.id)) {
                        dataItem.display = lookupData.display;
                        dataItem.id = lookupData.id;
                    }
                });

                if (_.has(e, 'sender')) {
                    $timeout(function(){
                        var dataSource = e.sender.dataSource;
                        if(_.isEmpty(e.sender.value())){
                            var values = _.map(dataSet, function (item) {
                                if(!dataSource.get(item.id)){
                                    dataSource.pushCreate(item);
                                }

                                return item.id + '';
                            });
                            e.sender.value(values);
                        }

                        // Removes "No Results" item from dataSource if other results are added
                        var noResult = dataSource.get(-1);
                        if(dataSource.total() > 1 && noResult){
                            dataSource.pushDestroy(noResult);
                        }
                    });
                }
            }
        }

        function cascadeWatcher(newValue, oldValue){
            var currentValue = $scope.dataSet[$scope.nimFormObject.config.modelId],
                parentValueSet = parentLookupSet(newValue),
                parentValueWasSet = parentLookupSet(oldValue),
                valueChanged = parentValueChanged(newValue, oldValue);


            if(!parentValueSet && !parentValueWasSet){
                valueChanged = false;
            }
            if(valueChanged || (_.isEmpty(currentValue) && parentValueSet)) {
                $scope.oDataLookUp.read().then(function () {
                    if ($scope.nimFormObject.config.type === 'select') {
                        $timeout(function () {
                            currentValue = $scope.dataSet[$scope.nimFormObject.config.modelId];

                            if ($scope.oDataLookUp.data().length < 1) {
                                $scope.oDataLookUp.data([{ id: '-1', display: '(No Results)' }]);
                                $scope.dataSet[$scope.nimFormObject.config.modelId] = '';
                            }
                            else if (parentValueSet) {
                                var currentId = _.get(currentValue, 'id');

                                // If current value not in filtered data, select first result 
                                if (!$scope.oDataLookUp.get(currentId)) {
                                    var dataItem = {};

                                    if (!$scope.nimFormObject.param.disableCascadeAutoFill) {
                                        dataItem = $scope.oDataLookUp.data()[0];
                                    }

                                    // If this lookup is a filter, don't push to dataSet
                                    if ($scope.nimFormObject.param.filter) {
                                        $scope.filters[$scope.nimFormObject.config.modelId] = angular.copy(dataItem);
                                    }
                                    else {
                                        $scope.dataSet[$scope.nimFormObject.config.modelId] = angular.copy(dataItem);
                                    }

                                    $scope.onSelectionChange(null, dataItem.id);
                                }
                            }
                        });
                    }    
                });
            }
        }

        function parentLookupSet(value){
            if(_.isNumber(value)){
                return true;
            }
            else if(!_.isEmpty(value)){
                var id = value.id;

                return id && id != -1 && id != '-1';
            }

            return false;
        }

        function parentValueChanged(newValue, oldValue) {
            if (_.isObject(newValue)) {
                newValue = newValue.id;
            }
            if (_.isObject(oldValue)) {
                oldValue = oldValue.id;
            }

            return !angular.equals(newValue, oldValue);
        }

        function evalAttr(expr){
            if(expr) {
                return $scope.$eval(expr.replace(/^{{/, '').replace(/}}$/, ''));
            }
            return expr;
        }

        function formatReadonly(val){
            if(!_.isNull(val) && !_.isUndefined(val)){
                if($scope.nimFormObject.param.format){
                    try {
                        return kendo.format($scope.nimFormObject.param.format, val);
                    } catch(e){
                        $log.error('Invalid format:', { error: e });
                    }
                }

                return val;
            }
        }

        function clearImage(){
            // If editing a new image, clear the crop area and display old image (if there is one)
            if($scope.imageSource){
                $scope.imageSource = null;
                $scope.previewImage = false;

                // Puts saved image ID back into the dataSet
                $scope.dataSet[$scope.nimFormObject.config.modelId] = $scope.attachmentId;
            }
            else {
                // Clears saved image
                $scope.dataSet[$scope.nimFormObject.config.modelId] = '';
                $scope.imageUrl = '';
            }
        }

        function dropdownDataBoundHandler(e){
            var filterValue = e.sender.filterInput.val(),
                data = e.sender.dataSource.data();

            if(filterValue && data.length > 0) {
                var index = _.findIndex(data, 'display', filterValue);

                if(index < 0){
                    index = 0;
                }

                e.sender.select(index + 1);
                e.sender.trigger('select');
            }
            else if(!e.sender.value() && !_.isEmpty($scope.dataSet[$scope.nimFormObject.config.modelId])){
                e.sender.value($scope.dataSet[$scope.nimFormObject.config.modelId].id);
            }
        }

        function dropdownOpenHandler(e) {
            var filter = _.get($scope.filterInput, 'value', '');

            e.sender.filterInput.val(filter);   // Makes sure filter input displays current search value
            e.sender._prev = filter;            // Makes sure dropdownlist directive tracks this change correctly
        }

        function dropdownCloseHandler(e){
            if(e.sender.value() !== $scope.dataSet[$scope.nimFormObject.config.modelId]){
                e.sender.trigger('change');
            }
        }

        function addItemCallback(id) {
            NimFormObjectService.getValue($scope.nimFormObject.option.id,$scope.nimFormObject.option.column,id).then(function(d){
                var dataItem = {
                    id: _.last(id.split('-')),
                    display: d
                };

                $scope.dataSet[$scope.nimFormObject.config.modelId] = dataItem;

                $timeout(function(){
                    $scope.oDataLookUp.add(dataItem);
                });
            });
        }
    })
    .directive('nimFormObject', function ($compile, $timeout) {
        return {
            restrict: 'AE',
            controller: 'nimFormObjectCtrl',
            link: function (scope, element, attrs) {
                var field = scope.nimFormObject;
                var template;
                var isViewing = attrs.nimStatic || false;
                if (isViewing) {
                    var modelIdValue = null;
                    if (scope.dataSet) {
                        modelIdValue = scope.dataSet[field.config.modelId];
                    }
                    template = field.buildStatic(modelIdValue);
                }
                else {
                    template = field.build();
                }

                var addedTemp = $(template).appendTo(element.empty());
                $compile(addedTemp)(scope);
                if(scope.nimFormObject.config.type === 'select'){
                    $timeout(function(){ // Makes k-dropdown :enabled so nimFocus won't skip it
                        element.find('.k-dropdown').prop('disabled', false);
                    });
                }
                else if (scope.nimFormObject.config.type === 'textarea' && scope.nimStatic) {
                    // Resizes static textareas
                    $timeout(function(){
                        if(scope.gridsterItem && scope.gridsterItem.$element){
                            var height = element.children().height(),
                                rows = Math.ceil(height / 8);

                            scope.gridsterItem.setSizeY(rows);
                        }
                    });
                }

                // Handles adding/removing the has-error class on the form-group
                // Only runs if field is inside instanceForm (in edit mode)
                if (scope.instanceForm) {
                    scope.$watch('instanceForm[nimFormObject.config.modelId].$valid', function(valid) {
                        var formGroup = null;
                        // map form objects are not nested under form-groups
                        // apply class to nim-form-map instead
                        if (field.config.type == 'map') {
                            formGroup = element.find('div[nim-form-map]');
                        }
                        else {
                            formGroup = element.find('.form-group');
                        }

                        // element not found
                        if (formGroup.length < 1) { return; }

                        if (valid && formGroup.hasClass('has-error')) {
                            formGroup.removeClass('has-error');
                        }
                        else if (!valid && !formGroup.hasClass('has-error')){
                            formGroup.addClass('has-error');
                        }
                    });
                }
            }
        };
    })
;